import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "metersToFeetinches",
})
export class MetersToFeetinchesPipe implements PipeTransform {
  private static METERS_PER_FOOT = 0.3048;
  private static INCHES_PER_FOOT = 12.0;

  transform(valueInMeters: number): string {
    const valueInFeet = valueInMeters / MetersToFeetinchesPipe.METERS_PER_FOOT;
    const feet = Math.floor(valueInFeet);
    const inches =
      (valueInFeet - feet) * MetersToFeetinchesPipe.INCHES_PER_FOOT + 0.5;
    return `${feet}' ${inches}''`;
  }
}
