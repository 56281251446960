/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DeviceOutOfBedEvent = {
    type: DeviceOutOfBedEvent.type;
    payload: {
        /**
         * Timestamp of the event in milliseconds since epoch
         */
        timestamp: number;
    };
};

export namespace DeviceOutOfBedEvent {

    export enum type {
        OutOfBed = 12,
    }


}

