<mat-card class="login-container" style="margin-top: 20px">
  <mat-card-content style="padding: 30px 32px">
    <form
      class="forgot-password-box"
      *ngIf="!emailSent"
      #loginForm="ngForm"
      [formGroup]="formGroup"
      (ngSubmit)="confirm()"
    >
      <span class="forgotPasswordText" i18n="@@forgot-password-text">
        Having trouble signing in?
      </span>
      <p class="forgotPasswordSubtext" i18n="@@forgot-password-subtext">
        Enter your email, and we'll send you a link to reset you password
      </p>
      <mat-form-field appearance="outline">
        <mat-label i18n="@@enter-your-email">Enter your email</mat-label>
        <div class="login-field">
          <div class="icon-wrapper">
            <img src="../assets/icons/email-icon.svg" />
          </div>
          <input
            matInput
            type="text"
            name="email"
            formControlName="email"
            required
          />
        </div>
        <mat-error
          *ngIf="formGroup.controls.email.hasError('required', 'email')"
        >
          {{ getEmailErrorMessage() }}
        </mat-error>
      </mat-form-field>
      <button
        [disabled]="inProgress"
        type="submit"
        mat-raised-button
        class="btn"
        [disabled]="!isValid()"
      >
        <div class="spinner-wrapper">
          <mat-spinner diameter="25" *ngIf="inProgress"> </mat-spinner>
        </div>
        <span i18n="@@confirm">Confirm</span>
      </button>
    </form>
    <div *ngIf="emailSent" class="forgot-password-box" style="padding: 5em 0">
      <span class="forgotPasswordText" style="text-align: center"
        >{{ linkSentStr }}
      </span>
    </div>
  </mat-card-content>
</mat-card>
