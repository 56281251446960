/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssignedResourceResponseV3 } from '../models/AssignedResourceResponseV3';
import type { CareGiver } from '../models/CareGiver';
import type { ConfigurationHistoryEntry } from '../models/ConfigurationHistoryEntry';
import type { Device } from '../models/Device';
import type { DeviceAdminResponse } from '../models/DeviceAdminResponse';
import type { DeviceCommand } from '../models/DeviceCommand';
import type { DeviceConfigGen2 } from '../models/DeviceConfigGen2';
import type { DeviceConfigGen2Patch } from '../models/DeviceConfigGen2Patch';
import type { DeviceDisabled } from '../models/DeviceDisabled';
import type { DeviceState } from '../models/DeviceState';
import type { DeviceStatus } from '../models/DeviceStatus';
import type { DismissAlertRequest } from '../models/DismissAlertRequest';
import type { ResourceResponse } from '../models/ResourceResponse';
import type { ResourceResponseV2 } from '../models/ResourceResponseV2';
import type { ResourceResponseV3 } from '../models/ResourceResponseV3';
import type { UnAssignedResourceResponseV3 } from '../models/UnAssignedResourceResponseV3';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DeviceAdminAPI {

    /**
     * Get Emergency Contacts by Caregiver Id
     * @param uid
     * @returns CareGiver OK
     * @throws ApiError
     */
    public static getCaregiverEmergencyContacts(
        uid: string,
    ): CancelablePromise<Array<CareGiver>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/deviceAdmin/caregivers/{uid}/emergencyContacts',
            path: {
                'uid': uid,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get Device Offline State
     * @param status
     * @returns DeviceState OK
     * @throws ApiError
     */
    public static queryDevicesState(
        status?: DeviceStatus,
    ): CancelablePromise<Array<DeviceState>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/deviceAdmin/devices/state',
            query: {
                'status': status,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get Device Offline State
     * @param lastUpdateThreshold
     * @returns DeviceState OK
     * @throws ApiError
     */
    public static getDeviceOfflineState(
        lastUpdateThreshold?: number,
    ): CancelablePromise<Array<DeviceState>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/deviceAdmin/devices/state/offline',
            query: {
                'lastUpdateThreshold': lastUpdateThreshold,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get Device State
     * @param deviceId
     * @returns DeviceState OK
     * @throws ApiError
     */
    public static getDeviceState(
        deviceId: string,
    ): CancelablePromise<DeviceState> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/deviceAdmin/devices/{deviceId}/state',
            path: {
                'deviceId': deviceId,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Device admin query devices
     * @param deviceId
     * @param deviceName
     * @param startCreationTimestamp
     * @param endCreationTimestamp
     * @param startSerial
     * @param endSerial
     * @param caregiverUid
     * @param caregiverName
     * @param caregiverNumber
     * @param fromDeviceId
     * @param orderBy
     * @param direction
     * @param limit
     * @returns DeviceAdminResponse OK
     * @throws ApiError
     */
    public static queryDevices(
        deviceId?: string,
        deviceName?: string,
        startCreationTimestamp?: number,
        endCreationTimestamp?: number,
        startSerial?: string,
        endSerial?: string,
        caregiverUid?: string,
        caregiverName?: string,
        caregiverNumber?: string,
        fromDeviceId?: string,
        orderBy?: string,
        direction?: 'desc' | 'asc',
        limit?: number,
    ): CancelablePromise<Array<DeviceAdminResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/deviceAdmin/devices/query',
            query: {
                'deviceId': deviceId,
                'deviceName': deviceName,
                'startCreationTimestamp': startCreationTimestamp,
                'endCreationTimestamp': endCreationTimestamp,
                'startSerial': startSerial,
                'endSerial': endSerial,
                'caregiverUid': caregiverUid,
                'caregiverName': caregiverName,
                'caregiverNumber': caregiverNumber,
                'fromDeviceId': fromDeviceId,
                'orderBy': orderBy,
                'direction': direction,
                'limit': limit,
            },
        });
    }

    /**
     * Get device meta data
     * @param deviceId
     * @returns Device OK
     * @throws ApiError
     */
    public static getDeviceMetaData(
        deviceId: string,
    ): CancelablePromise<Device> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/deviceAdmin/{deviceId}',
            path: {
                'deviceId': deviceId,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * remove device from db and iot
     * @param deviceId
     * @returns any OK
     * @throws ApiError
     */
    public static removeCaregiverResources(
        deviceId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/deviceAdmin/{deviceId}',
            path: {
                'deviceId': deviceId,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Send device command
     * @param deviceId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static sendCommand(
        deviceId: string,
        requestBody?: DeviceCommand,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/deviceAdmin/{deviceId}/command',
            path: {
                'deviceId': deviceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get device config
     * @param deviceId
     * @returns DeviceConfigGen2 OK
     * @throws ApiError
     */
    public static getDeviceConfig(
        deviceId: string,
    ): CancelablePromise<Array<DeviceConfigGen2>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/deviceAdmin/{deviceId}/config',
            path: {
                'deviceId': deviceId,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Set device config
     * @param deviceId
     * @param requestBody
     * @returns DeviceConfigGen2 OK
     * @throws ApiError
     */
    public static setDeviceConfig(
        deviceId: string,
        requestBody?: DeviceConfigGen2Patch,
    ): CancelablePromise<DeviceConfigGen2> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/deviceAdmin/{deviceId}/config',
            path: {
                'deviceId': deviceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get device config history
     * @param deviceId
     * @param timestamp
     * @param limit
     * @returns ConfigurationHistoryEntry OK
     * @throws ApiError
     */
    public static getHistoricConfiguration(
        deviceId: string,
        timestamp: number,
        limit?: number,
    ): CancelablePromise<Array<ConfigurationHistoryEntry>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/deviceAdmin/{deviceId}/config/history',
            path: {
                'deviceId': deviceId,
            },
            query: {
                'timestamp': timestamp,
                'limit': limit,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Dismiss Event Alert
     * @param roomId
     * @param eventType
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static dismissAlert(
        roomId: string,
        eventType: 'fall' | 'sensitiveFall',
        requestBody?: DismissAlertRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/deviceAdmin/{roomId}/dismissAlert/{eventType}',
            path: {
                'roomId': roomId,
                'eventType': eventType,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Fetch all resources by caregiver id
     * @param uid
     * @returns ResourceResponseV2 OK
     * @throws ApiError
     */
    public static getCaregiverResourcesV2(
        uid: string,
    ): CancelablePromise<ResourceResponseV2> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/deviceAdmin/caregiver/{uid}/resources/v2',
            path: {
                'uid': uid,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Fetch all resources by caregiver id (v3)
     * @param uid
     * @returns ResourceResponseV3 OK
     * @throws ApiError
     */
    public static getCaregiverResourcesV3(
        uid: string,
    ): CancelablePromise<ResourceResponseV3> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/deviceAdmin/caregiver/{uid}/resources/v3',
            path: {
                'uid': uid,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Fetch all resources by caregiver id
     * @param uid
     * @returns ResourceResponse OK
     * @throws ApiError
     */
    public static getCaregiverResources(
        uid: string,
    ): CancelablePromise<ResourceResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/deviceAdmin/caregiver/{uid}/resources',
            path: {
                'uid': uid,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Pagination fetch all assigned resources by caregiver id (v3)
     * @param uid
     * @param apiVer
     * @param pageSize
     * @param pageNum Current page number，default value is 1.
     * @returns AssignedResourceResponseV3 OK
     * @throws ApiError
     */
    public static getPagingForCaregiverAssignedResources(
        uid: string,
        apiVer: 'v1' | 'v2' | 'v3',
        pageSize?: number,
        pageNum?: number,
    ): CancelablePromise<AssignedResourceResponseV3> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/deviceAdmin/caregiver/{uid}/pagination/assigned/resources/{apiVer}',
            path: {
                'uid': uid,
                'apiVer': apiVer,
            },
            query: {
                'pageSize': pageSize,
                'pageNum': pageNum,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Fetch all unassigned resources by caregiver id (v3)
     * @param uid
     * @param apiVer
     * @returns UnAssignedResourceResponseV3 OK
     * @throws ApiError
     */
    public static getCaregiverUnAssignedResources(
        uid: string,
        apiVer: 'v1' | 'v2' | 'v3',
    ): CancelablePromise<UnAssignedResourceResponseV3> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/deviceAdmin/caregiver/{uid}/unassigned/resources/{apiVer}',
            path: {
                'uid': uid,
                'apiVer': apiVer,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Export csv file include suites, rooms, devices info
     * @returns binary OK
     * @throws ApiError
     */
    public static exportDeviceRelateInfo(): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/deviceAdmin/export/deviceRelateInfo',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * set device disabled
     * @param deviceId
     * @param requestBody
     * @returns DeviceDisabled OK
     * @throws ApiError
     */
    public static postDeviceAdminDeviceDisabled(
        deviceId: string,
        requestBody?: DeviceDisabled,
    ): CancelablePromise<DeviceDisabled> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/deviceAdmin/device/{deviceId}/disabled',
            path: {
                'deviceId': deviceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * get the device disabled
     * @param deviceId
     * @returns DeviceDisabled OK
     * @throws ApiError
     */
    public static getDeviceAdminDeviceDisabled(
        deviceId: string,
    ): CancelablePromise<DeviceDisabled> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/deviceAdmin/device/{deviceId}/disabled',
            path: {
                'deviceId': deviceId,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

}
