/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Silent = {
    type: Silent.type;
};

export namespace Silent {

    export enum type {
        Silent = 'Silent',
    }


}

