/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum FallEventStatus {
    FallDetected = 'fall_detected',
    FallConfirmed = 'fall_confirmed',
    Calling = 'calling',
    Finished = 'finished',
    FallExit = 'fall_exit',
    Canceled = 'canceled',
    OnCall = 'on_call',
    Resolution = 'resolution',
}
