<ng-content></ng-content>
<div [formGroup]="configurationForm">
  <div formGroupName="deviceSettingsForm">
    <div class="tab-body-left">
      <h4 i18n="@@room-settings">ROOM SETTINGS</h4>
      <div class="grid-div" data-cy="suite-name">
        <span><span class="field-title" i18n="@@suite-name">Suite Name</span>:<span class="opaque-text">{{ suite?.name }}</span></span>
      </div>
      <div class="grid-div" data-cy="room-name">
        <p class="field-title" i18n="@@room-name">Room Name</p>
        <mat-form-field appearance="outline" class="value-field">
          <input
                  matInput
                  name="roomName"
                  placeholder="Type Name Here"
                  formControlName="roomName"
                  i18n-placeholder="@@type-name-here"
                  [errorStateMatcher]="roomNameValMatcher"
          />
          <mat-error *ngIf="deviceSettings.invalid">
            {{ getRoomNameErrorMessage(deviceSettings,"roomName") }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="grid-div">
        <p class="field-title" i18n="@@room-type">Room Type</p>
        <mat-select
                class="roomtype-select"
                formControlName="roomType"
                [attr.data-value]="roomType.value"
        >
          <mat-select-trigger>
            <div class="room-type-select-trigger">
              <svg class="status-icon" viewBox="0 0 60 50">
                <use [attr.xlink:href]="getDeviceIcon(roomType.value)"></use>
              </svg>
              <span>{{
                getDeviceName(roomType.value)
                }}</span>
            </div>
          </mat-select-trigger>
          <mat-option
                  *ngFor="let type of roomTypes"
                  [value]="type"
                  [attr.data-type]="type"
                  class="roomtype-option"
          >
            <div>
              <svg class="status-icon">
                <use [attr.xlink:href]="getDeviceIcon(type)"></use>
              </svg>
              <span>{{
                getDeviceName(type)
                }}</span>
            </div>
          </mat-option>
        </mat-select>
      </div>
      <br>
      <div>
        <p class="field-title" i18n="@@device-location">Device Location</p>
        <mat-select
                class="mounttype-select"
                formControlName="sensorMounting"
                data-cy="sensorMounting"
                [attr.data-value]="sensorMounting.value"
                (selectionChange)="validateRoomSizeAgainstSensorMount()"
        >
          <mat-option
                  [value]="SensorMounting.Wall"
                  data-cy="wall-mounting"
                  i18n="@@wall"
          >
            Wall
          </mat-option>
          <mat-option
                  *ngIf="shouldDisplayToggleCeiling"
                  [value]="SensorMounting.Ceiling"
                  data-cy="ceiling-mounting"
                  i18n="@@ceiling"
          >
            Ceiling
          </mat-option>
          <mat-option
                  [value]="SensorMounting.Ceiling45Deg"
                  data-cy="ceiling-45-deg-mounting"
                  i18n="@@ceiling-45-deg"
          >
            Ceiling 45 Deg
          </mat-option>
          <mat-option
                  [value]="SensorMounting.Tilt45Deg"
                  data-cy="wall-mount-tilt-mounting"
                  i18n="@@wall-mount-tilt"
          >
            Wall Mount Tilt
          </mat-option>
        </mat-select>
      </div>
      <!-- enterDuration and  exitDuration-->
      <p>
        <span class="field-title" i18n="@@enter-exit-durations">Room Enter/Exit Duration</span>
        <span class="cdk-visually-hidden" #dirationTooltipMessage i18n="@@enter-duration-tooltip">
            Enter Duration - The minimum detection time required to establish presence in the room (sec).
            Exit Duration - The minimum detection time required to establish that there is no presence in the room (sec).
          </span>
        <mat-icon color="primary" class="info-icon" [matTooltip]="dirationTooltipMessage.innerHTML"
                  matTooltipClass="tooltip"
                  matTooltipPosition="right">
          info
        </mat-icon>
      </p>
      <div class="grid-div">
        <div class="subregion-fields-row duration">
          <div>
            <mat-form-field appearance="outline" class="value-field">
              <input
                      matInput
                      type="number"
                      name="enterDuration"
                      formControlName="enterDuration"
                      [errorStateMatcher]="enterDurationValMatcher"
              />
              <span matPrefix i18n="@@enterDuration">Enter Duration</span>
              <span matSuffix>s</span>
              <mat-error *ngIf="deviceSettings.invalid">
                {{ getDurationErrorMessage(deviceSettings,"enterDuration") }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="subregion-fields-row duration">
          <div>
            <mat-form-field appearance="outline" class="value-field">
              <input
                      matInput
                      type="number"
                      name="exitDuration"
                      formControlName="exitDuration"
                      [errorStateMatcher]="exitDurationValMatcher"
              />
              <span matPrefix i18n="@@exitDuration">Exit Duration</span>
              <span matSuffix>s</span>
              <mat-error *ngIf="deviceSettings.invalid">
                {{ getDurationErrorMessage(deviceSettings, "exitDuration") }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div>
        <h4 i18n="@@wi-fi-info" *ngIf="wifiState" class="wifi-info-header">WI-FI INFO</h4>
        <p class="field-title" *ngIf="wifiState">
          <span>RSSI: <span class="opaque-text">{{wifiState.rssi}} dBm</span></span><br>
          <span>SSID: <span class="opaque-text">{{wifiState.ssid}}</span></span><br>
          <span>BSSID: <span class="opaque-text">{{wifiState.bssid}}</span></span>
        </p>
      </div>
      <div class="reboot-button-container">
        <div class="spinner-wrapper">
          <mat-spinner diameter="25" *ngIf="inRebootProgress">
          </mat-spinner>
        </div>
        <button
                mat-button
                class="reboot-button"
                [disabled]="inRebootProgress"
                (click)="rebootDevice()"
                i18n="@@reboot-device"
        >
          Reboot Device
        </button>
      </div>
    </div>
    <div class="tab-body-right">
      <h4 i18n="@@device-info">DEVICE INFO</h4>
      <div class="grid-div">
        <span><span class="field-title" i18n="@@device-id">Device ID</span>:<span class="opaque-text" data-cy="general-details-device-id">{{ _device.deviceId }}</span></span>
      </div>
      <p class="field-title" *ngIf="deviceInfo" data-cy="general-details-serial-number">
        <span i18n="@@serial-number">Serial Number</span>: <span class="opaque-text">{{ deviceInfo.androidSerial }}</span>
      </p>
      <p class="field-title" *ngIf="deviceInfo">
        <span i18n="@@device-mac-address">Device MAC Address</span>: <span class="opaque-text">{{ deviceInfo.bt_mac }}</span>
      </p>
      <p class="field-title" *ngIf="deviceInfo">
        <span i18n="@@version-name">VersionName</span>: <span class="opaque-text">{{ deviceInfo.currentApkMetadata.versionName }}</span>
      </p>
      <p class="field-title" *ngIf="lastSeen">
        <span i18n="@@lastseen">Lastseen</span>: <span class="opaque-text">{{ lastSeen | date: dateTimeFormat }}</span>
      </p>
      <p class="field-title" *ngIf="config.appConfig.learningModeStartTs > 0">
        <span i18n="@@learning-mode-start-date">Learning Mode Start Date</span>: <span class="opaque-text">{{ config.appConfig.learningModeStartTs | date: dateTimeFormat }}</span>
      </p>
      <p class="field-title" *ngIf="config.appConfig.learningModeEndTs > 0">
        <span i18n="@@learning-mode-end-date">Learning Mode End Date</span>: <span class="opaque-text">{{ config.appConfig.learningModeEndTs | date: dateTimeFormat }}</span>
      </p>
      <h4 i18n="@@device-settings" class="device-settings-header">DEVICE SETTINGS</h4>
      <div class="grid-div item-config" data-cy="ledLight">
        <div class="led-light-container">
          <span class="field-title" i18n="@@led-light">LED Light</span>
          <span class="cdk-visually-hidden" #translatedLedLightTooltipMessage i18n="@@led-light-tooltip">
            Controls the LED light at the top of the device.&#13;
            If “On”- the light will flash in the event of a fall, while in pairing mode, or if the device is disconnected from Wi-Fi.&#13;
            If “Off”- no light will appear.
          </span>
          <mat-icon color="primary" class="info-icon" [matTooltip]="translatedLedLightTooltipMessage.innerHTML" matTooltipClass="tooltip tooltip-w-450" matTooltipPosition="right">
            info
          </mat-icon>
        </div>
        <mat-select class="ledLight-select" formControlName="ledLight">
          <mat-option value="AllEvents">
            <span i18n="@@led-light-on">On</span>
          </mat-option>
          <mat-option value="Off">
            <span i18n="@@led-light-off">Off</span>
          </mat-option>
        </mat-select>
      </div>
      <div class="item-config" data-cy="silentMode">
        <span class="field-title" i18n="@@silent-mode">Silent Mode</span>
        <mat-slide-toggle formControlName="silentMode">
        </mat-slide-toggle>
      </div>
      <div class="item-config" data-cy="enableTestMode">
        <span class="field-title" i18n="@@test-mode">Test Mode</span>
        <mat-slide-toggle formControlName="testMode"> </mat-slide-toggle>
      </div>
      <div class="item-config" data-cy="learningMode">
      <span>
        <span class="field-title" i18n="@@learning-mode"
        >Learning Mode</span
        >
        <span class="cdk-visually-hidden" #translatedTooltipMessage i18n="@@learning-mode-tooltip">
          Reset device fall optimization if the device has moved to a new room or the layout of the room has changed.
          Please note: this will only be effective if the device ID has been sent to Vayyar Customer Success when initially installed.
        </span>
        <mat-icon color="primary" class="info-icon" [matTooltip]="translatedTooltipMessage.innerHTML"
                  matTooltipClass="tooltip"
                  matTooltipPosition="right">
          info
        </mat-icon>
      </span>
        <mat-slide-toggle formControlName="learningMode">
        </mat-slide-toggle>
      </div>
      <div class="item-config" data-cy="fallingMitigatorEnabled">
      <span>
        <span class="field-title" i18n="@@false-alarm-mitigator"
        >False Alarm Mitigator (v40)</span
        >
        <span class="cdk-visually-hidden" #falseAlarmMitigatorTooltip i18n="@@false-alarm-mitigator-tooltip">
          A machine learning algorithm which analyzes fall events and reduces false alarms. Supported from device version 40 and up.
        </span>
        <mat-icon color="primary" class="info-icon" [matTooltip]="falseAlarmMitigatorTooltip.innerHTML"
                  matTooltipClass="tooltip"
                  matTooltipPosition="right">
          info
        </mat-icon>
      </span>
        <mat-slide-toggle formControlName="fallingMitigatorEnabled">
        </mat-slide-toggle>
      </div>
      <ng-container *ngIf="deviceSettings.get('sensorMounting').value === SensorMounting.Ceiling45Deg">
        <div class="item-config" data-cy="enableDoorEvents">
          <span>
            <span class="field-title" i18n="@@door-events">Door Events (v40)</span>
            <span class="cdk-visually-hidden" #doorEventsTooltip i18n="@@door-events-tooltip">
              Enables indications of room occupancy/vacancy according to the detection of movement across the entrance of the room (a door subregion must be defined).
            </span>
            <mat-icon color="primary" class="info-icon" [matTooltip]="doorEventsTooltip.innerHTML" matTooltipClass="tooltip" matTooltipPosition="right">
              info
            </mat-icon>
          </span>
          <mat-slide-toggle formControlName="enableDoorEvents"></mat-slide-toggle>
        </div>
        <div *ngIf="deviceSettings.get('roomType').value === RoomType.Bedroom" class="item-config" data-cy="enableEmptyBedEvents">
          <span>
            <span class="field-title" i18n="@@empty-bed-events">Empty Bed Events (v40)</span>
            <span class="cdk-visually-hidden" #emptyBedEventsTooltip i18n="@@empty-bed-events-tooltip">
              Empty bed event is triggered once the person moves away from the defined bed subregion and the radar confirms that there is no indication of human presence in the subregion. Supported for ceiling installation only.
            </span>
            <mat-icon color="primary" class="info-icon" [matTooltip]="emptyBedEventsTooltip.innerHTML" matTooltipClass="tooltip" matTooltipPosition="right">
              info
            </mat-icon>
          </span>
          <mat-slide-toggle formControlName="enableOutOfBed"></mat-slide-toggle>
        </div>
      </ng-container>
      <div class="item-config" data-cy="volume">
        <span class="field-title" i18n="@@buzzer-sound">Buzzer Sound</span>
        <mat-slide-toggle formControlName="buzzerSound">
        </mat-slide-toggle>
      </div>
      <mat-accordion>
        <mat-expansion-panel class="dry-contacts">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div i18n="@@dry-contacts" class="dry-contacts-label">Dry contacts</div>
              <span class="cdk-visually-hidden" #dryContactsTooltipMessage i18n="@@dry-contacts-tooltip">
                Relevant only for Type C devices and if the Vayyar Accessory Board has been connected to a transmitter.
              </span>
              <mat-icon color="primary" class="info-icon" [matTooltip]="dryContactsTooltipMessage.innerHTML"
                        matTooltipClass="tooltip"
                        matTooltipPosition="right">
                info
              </mat-icon>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="item-config-label">
          <span class="field-title" i18n="@@dry-contact-primary">
            Primary
          </span>
            <div class="delimeter"></div>
          </div>
          <div
                  class="item-config"
                  data-cy="dryContactPrimary"
          >
          <span class="field-title" i18n="@@dry-contact-alarm">
            Alarm
          </span>
            <mat-slide-toggle formControlName="dryContactPrimaryPolicy">
            </mat-slide-toggle>
          </div>

          <mat-button-toggle-group
                  #group="matButtonToggleGroup"
                  formControlName="dryContactPrimaryMode"
          >
            <mat-button-toggle [value]="1" i18n="@@normally-open" data-cy="primaryNormallyOpen">
              Normally Open (NO)
            </mat-button-toggle>
            <mat-button-toggle [value]="0" i18n="@@normally-closed" data-cy="primaryNormallyClosed">
              Normally Closed (NC)
            </mat-button-toggle>
          </mat-button-toggle-group>
          <div class="item-config-label">
          <span class="field-title" i18n="@@dry-contact-secondary">
            Secondary
          </span>
            <div class="delimeter"></div>
          </div>
          <div
                  class="item-config"
                  data-cy="dryContactSecondary"
          >
          <span class="field-title" i18n="@@dry-contact-alarm">
            Alarm
          </span>
            <mat-slide-toggle formControlName="dryContactSecondaryPolicy">
            </mat-slide-toggle>
          </div>

          <mat-button-toggle-group
                  #group="matButtonToggleGroup"
                  formControlName="dryContactSecondaryMode"
          >
            <mat-button-toggle [value]="1" i18n="@@normally-open" data-cy="secondaryNormallyOpen">
              Normally Open (NO)
            </mat-button-toggle>
            <mat-button-toggle [value]="0" i18n="@@normally-closed" data-cy="secondaryNormallyClosed">
              Normally Closed (NC)
            </mat-button-toggle>
          </mat-button-toggle-group>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
