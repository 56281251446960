/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * NOT USED (deprecated - see `dspRecordsPublishPolicy` for tracker big data)
 */
export enum TrackerTargetsDebugPolicy {
    OFF = 0,
    ON = 1,
}
