/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DoorEvent = {
    /**
     * Recorded time (DSP) of the event in milliseconds epoch
     */
    recordTime: number;
    /**
     * Type of door event
     */
    doorEventType: DoorEvent.doorEventType;
    /**
     * The ID of the door the event occurred at
     */
    doorId: number;
};

export namespace DoorEvent {

    /**
     * Type of door event
     */
    export enum doorEventType {
        ENTER = 'Enter',
        EXIT = 'Exit',
    }


}

