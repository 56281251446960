/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DashboardEventBase } from './DashboardEventBase';
import type { DashboardPresenceEvent } from './DashboardPresenceEvent';
import type { DeviceState } from './DeviceState';
import type { DoorEvent } from './DoorEvent';
import type { FallEvent } from './FallEvent';

export type DashboardEvent = (DashboardEventBase & {
    type: DashboardEvent.type;
    payload: (FallEvent | DashboardPresenceEvent | DeviceState | DoorEvent);
});

export namespace DashboardEvent {

    export enum type {
        Presence = 0,
        Fall = 1,
        Status = 2,
        FallDetected = 3,
        FallConfirmed = 4,
        FallExit = 5,
        FallFinished = 6,
    }


}

