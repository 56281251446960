/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateRoomPayload } from '../models/CreateRoomPayload';
import type { DashboardConfig } from '../models/DashboardConfig';
import type { DeviceRoomConfig } from '../models/DeviceRoomConfig';
import type { DeviceState } from '../models/DeviceState';
import type { DoorEventStatus } from '../models/DoorEventStatus';
import type { FallEvent } from '../models/FallEvent';
import type { FallEventStatus } from '../models/FallEventStatus';
import type { PresenceEvent } from '../models/PresenceEvent';
import type { PresenceEventStatus } from '../models/PresenceEventStatus';
import type { Room } from '../models/Room';
import type { RoomDetailsResponse } from '../models/RoomDetailsResponse';
import type { RoomEvent } from '../models/RoomEvent';
import type { RoomStatusHistory } from '../models/RoomStatusHistory';
import type { RoomType } from '../models/RoomType';
import type { SensitiveFallEventStatus } from '../models/SensitiveFallEventStatus';
import type { UpdateRoomFcmTokenPayload } from '../models/UpdateRoomFcmTokenPayload';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RoomsAPI {

    /**
     * Get User's Rooms with linked devices
     * @returns RoomDetailsResponse OK
     * @throws ApiError
     */
    public static getRoomsDevices(): CancelablePromise<RoomDetailsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/rooms',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Create New Room
     * @param requestBody
     * @returns Room OK
     * @throws ApiError
     */
    public static createRoom(
        requestBody?: CreateRoomPayload,
    ): CancelablePromise<Room> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/rooms',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get Events history rooms related to CareGiver
     * @param roomIds Filter results by a List of Room IDs
     * @param from Filter results 'FROM' date in timestamp format
     * @param to Filter results 'TO' date in timestamp format
     * @param limit Limit the number of results, default 500
     * @param fallEventStatuses fall event relevant statuses to fetch
     * @param sensitiveFallEventStatuses sensitive event relevant statuses to fetch
     * @param presenceEventStatuses presence event relevant statuses to fetch
     * @param doorEventStatuses presence event relevant statuses to fetch
     * @returns any OK
     * @throws ApiError
     */
    public static getRoomsEventsHistory(
        roomIds?: Array<string>,
        from?: number,
        to?: number,
        limit?: number,
        fallEventStatuses?: Array<FallEventStatus>,
        sensitiveFallEventStatuses?: Array<SensitiveFallEventStatus>,
        presenceEventStatuses?: Array<PresenceEventStatus>,
        doorEventStatuses?: Array<DoorEventStatus>,
    ): CancelablePromise<{
        events: Array<RoomEvent>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/rooms/eventsHistory',
            query: {
                'roomIds': roomIds,
                'from': from,
                'to': to,
                'limit': limit,
                'fallEventStatuses': fallEventStatuses,
                'sensitiveFallEventStatuses': sensitiveFallEventStatuses,
                'presenceEventStatuses': presenceEventStatuses,
                'doorEventStatuses': doorEventStatuses,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get User's Dashboard Configuration
     * @returns DashboardConfig OK
     * @throws ApiError
     */
    public static getDashboardConfig(): CancelablePromise<DashboardConfig> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/rooms/config',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Update User's Dashboard Configuration
     * @param requestBody
     * @returns DashboardConfig OK
     * @throws ApiError
     */
    public static updateDashboardConfig(
        requestBody?: DashboardConfig,
    ): CancelablePromise<DashboardConfig> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/rooms/config',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Update User's Room FCM Token
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateRoomToken(
        requestBody?: UpdateRoomFcmTokenPayload,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/rooms/token',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get Mapped Device State
     * @returns any OK
     * @throws ApiError
     */
    public static getMappedDeviceState(): CancelablePromise<Array<{
        deviceId: string;
        state: DeviceState;
    }>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/rooms/state',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get Mapped Devices Fall
     * @returns FallEvent OK
     * @throws ApiError
     */
    public static getMappedDevicesFall(): CancelablePromise<Array<FallEvent>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/rooms/falls',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get Mapped Devices Presence
     * @returns PresenceEvent OK
     * @throws ApiError
     */
    public static getMappedDevicesPresence(): CancelablePromise<Array<PresenceEvent>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/rooms/presence',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Assign room to suite
     * @param roomId
     * @param suiteId
     * @returns any OK
     * @throws ApiError
     */
    public static assignRoomToSuite(
        roomId: string,
        suiteId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/rooms/{roomId}/suite/{suiteId}',
            path: {
                'roomId': roomId,
                'suiteId': suiteId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Unassign room to suite
     * @param roomId
     * @param suiteId
     * @returns any OK
     * @throws ApiError
     */
    public static unassignRoomToSuite(
        roomId: string,
        suiteId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/rooms/{roomId}/suite/{suiteId}',
            path: {
                'roomId': roomId,
                'suiteId': suiteId,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get Status history rooms related to suite
     * @param suiteId Filter results related to suite
     * @param fromTimestamp Filter results 'fromTimestamp' date in timestamp format
     * @param toTimestamp Filter results 'toTimestamp' date in timestamp format
     * @param fromDuration Filter results with longer duration
     * @param limit Limit the number of results, default 500
     * @returns any OK
     * @throws ApiError
     */
    public static getRoomsStatusHistory(
        suiteId: string,
        fromTimestamp: string,
        toTimestamp?: string,
        fromDuration?: number,
        limit?: number,
    ): CancelablePromise<{
        events: Array<RoomStatusHistory>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/rooms/statusHistory',
            query: {
                'suiteId': suiteId,
                'fromTimestamp': fromTimestamp,
                'toTimestamp': toTimestamp,
                'fromDuration': fromDuration,
                'limit': limit,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get rooms by suite id
     * @param suiteId
     * @returns Room OK
     * @throws ApiError
     */
    public static getRoomsBySuite(
        suiteId: string,
    ): CancelablePromise<Array<Room>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/rooms/suite/{suiteId}',
            path: {
                'suiteId': suiteId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get room devices
     * @param roomId
     * @returns DeviceRoomConfig OK
     * @throws ApiError
     */
    public static getRoomDevices(
        roomId: string,
    ): CancelablePromise<Array<DeviceRoomConfig>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/rooms/{roomId}/devices',
            path: {
                'roomId': roomId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Update User's Room
     * @param roomId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateRoom(
        roomId: string,
        requestBody?: Room,
    ): CancelablePromise<{
        id: string;
        name: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/rooms/{roomId}',
            path: {
                'roomId': roomId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Delete User's Room
     * @param roomId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteRoom(
        roomId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/rooms/{roomId}',
            path: {
                'roomId': roomId,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Assign device to room
     * @param roomId
     * @param deviceId
     * @param requestBody
     * @returns DeviceRoomConfig OK
     * @throws ApiError
     */
    public static assignDeviceToRoom(
        roomId: string,
        deviceId: string,
        requestBody?: {
            uid: string;
            /**
             * @deprecated
             */
            deviceId?: string;
            /**
             * @deprecated
             */
            roomId?: string;
        },
    ): CancelablePromise<DeviceRoomConfig> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/rooms/{roomId}/{deviceId}',
            path: {
                'roomId': roomId,
                'deviceId': deviceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Update Room's Device Config
     * @param roomId
     * @param deviceId
     * @param requestBody
     * @returns DeviceRoomConfig OK
     * @throws ApiError
     */
    public static updateDeviceConfig(
        roomId: string,
        deviceId: string,
        requestBody?: {
            uid: string;
            type: RoomType;
            /**
             * @deprecated
             */
            deviceId: string;
            /**
             * @deprecated
             */
            roomId: string;
        },
    ): CancelablePromise<DeviceRoomConfig> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/rooms/{roomId}/{deviceId}',
            path: {
                'roomId': roomId,
                'deviceId': deviceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Delete Room's Device
     * @param roomId
     * @param deviceId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteDeviceFromRoom(
        roomId: string,
        deviceId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/rooms/{roomId}/{deviceId}',
            path: {
                'roomId': roomId,
                'deviceId': deviceId,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

}
