import { ExportToCsv } from "export-to-csv";
import { Component, Input } from "@angular/core";

interface CsvOptions {
  fieldSeparator: string;
  quoteStrings: string;
  decimalseparator: string;
  showLabels: boolean;
  headers: string[];
  showTitle: boolean;
  useBom: boolean;
  removeNewLines: boolean;
  keys: string[];
}

@Component({
  selector: "app-angular2csv",
  template: '<div (click)="onDownload()"><ng-content></ng-content></div>',
})
export class CustomAngular2csvComponent {
  @Input() options: CsvOptions;
  @Input() filename: string;
  @Input() data;

  private getDataFunc: () => Promise<any[]>;

  public setDataGeneratorFunctions(getDataFunc: () => Promise<any[]>) {
    this.getDataFunc = getDataFunc;
  }

  public setOptions(options: CsvOptions) {
    this.options = options;
  }

  public async onDownload() {
    if (this.getDataFunc) {
      console.log(this.getDataFunc);
      this.data = await this.getDataFunc();
    }
    const csvExporter = new ExportToCsv(
      Object.assign({ filename: this.filename }, this.options)
    );
    /**
     * Fixme: workaround for https://github.com/Vayyar/VayyarHomeCloud/issues/2349
     * Wait for https://github.com/alexcaza/export-to-csv/issues/10 to be fixed
     */

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const _getBody = csvExporter["_getBody"].bind(csvExporter);
    csvExporter["_getBody"] = function () {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        _getBody();
      } catch (e) {
        console.warn(e);
      }
    };
    csvExporter.generateCsv(this.data);
  }
}
