/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum SensorMounting {
    Wall = 0,
    Ceiling = 1,
    Ceiling45Deg = 2,
    Tilt45Deg = 3,
}
