import { Component, OnInit } from "@angular/core";
import { AppComponent, slideInAnimation } from "../app/app.component";
import { Mixin } from "../utils";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-root",
  templateUrl: "./vc-platform-app.component.html",
  styleUrls: ["./vc-platform-app.component.css"],
  animations: [slideInAnimation],
})
export class VCPlatformAppComponent
  extends Mixin(AppComponent)
  implements OnInit
{
  defaultUserImageUrl = "../assets/icons/account_circle_new.svg";
  defaultRoute = "alerts";
  isSandbox = false;
  demoUser = $localize`:@@demo-user:Demo User`;

  ngOnInit() {
    super.ngOnInit();
    let historyReceived = false;
    this.deviceService.ready
      .pipe(takeUntil(this.ngUnsubsrcibe))
      .subscribe((ready) => {
        if (ready && !historyReceived) {
          historyReceived = true;
          this.deviceService.loadAlerts();
        }
      });
    this.isSandbox = <boolean>this.environment.analyticsUsesSandbox;
  }

  async afterLogin() {
    const basePath = localStorage.getItem("basePath") || this.DEFAULT_URL_SLUG,
      defaultURL = `/${basePath}/${this.defaultRoute}`;
    await this.router.navigateByUrl(defaultURL);
  }
}
