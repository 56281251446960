<div style="width: 100%; height: 100%; display: flex" *ngIf="(monitoringService.ready | async) === false">
  <mat-spinner style="margin: auto"></mat-spinner>
</div>
<div *ngIf="monitoringService.ready | async">
  <div class="sub-header">
      <span>
        <span class="title" *ngIf="monitoringService.totalNumberOfDevices">{{ getTotalNumberOfDevicesText() }}</span>
        <span class="title" *ngIf="!monitoringService.totalNumberOfDevices" i18n="@@rooms-empty-state">Please go to the configuration screen to setup rooms</span>
      </span>
    <div *ngIf="monitoringService.totalNumberOfDevices" class="controls">
      <mat-form-field>
        <mat-label style="font-weight: 700" i18n="@@sort-by">Sort By</mat-label>
        <mat-select [(ngModel)]="sorting" (selectionChange)="onSortingChanged()">
          <mat-option value="by-alert" i18n="@@by-alerts">Alerts</mat-option>
          <mat-option value="alphabetically" i18n="@@alphabetically">A-Z</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <mat-list>
    <div #card *ngFor="let suite of suiteStatusArr; trackBy: suiteTrackBy">
      <mat-card
              [ngClass]="{
            'fall-detected':
                getAlertForSuite(suite.id).isPresented &&
                getAlertForSuite(suite.id).isFallActive,
            'bed-exit':
                getAlertForSuite(suite.id).isPresented &&
                getAlertForSuite(suite.id).isBedExitActive,
            disconnected: hasDisconnectedDevice(suite.id)
          }"
      >
        <mat-icon *ngIf="getAlertForSuite(suite.id).isPresented || hasDisconnectedDevice(suite.id)" class="small-bell">circle_notifications</mat-icon>
        <mat-card-title [matTooltip]="suite.name" [matTooltipPosition]="'above'">{{ suite.name }}</mat-card-title>
        <mat-card-content>
          <ng-container *ngFor="let room of roomsToArray(suite.rooms); trackBy: roomTrackBy">
            <ng-container *ngFor="let device of devicesToArray(room.devices); trackBy: deviceTrackBy">
                <span *ngIf="device.deviceId === monitoringService.simulatedFallEventDeviceId && fallMapping?.[monitoringService.simulatedFallEventDeviceId]?.fallEvent.isMock" class="alert-type">
                  Simulated Alert
                </span>
              <span *ngIf="device.deviceId === monitoringService.simulatedFallEventDeviceId && fallMapping?.[monitoringService.simulatedFallEventDeviceId]?.fallEvent.isReal" class="alert-type">
                  Real Alert
                </span>
              <ng-container *appVar="isDeviceDisconnected(suite.id, room.id, device.deviceId) as isDeviceDisconnected">
                <div class="device"
                     [attr.data-device-id]="device.deviceId"
                     [class.disconnected]="isDeviceDisconnected"
                     [class.alarm]="(alertsEnabled && device.isFallActive) || device.isBedExitActive"
                     (click)="openTracker(suite, room.id, device.deviceId)"
                     (mockFall)="mockFallAlert(device.deviceId)"
                     (mockOutOfRoom)="mockOutOfRoom(device.deviceId)"
                     (mockDisconnected)="mockDisconnected(device.deviceId)"
                     (mockDismissFall)="mockDismissFall(room.id)"
                     (mockPresenceDetected)="mockPresenceDetected(device.deviceId)"
                >
                  <mat-icon  *ngIf="isBedExitApp()" class="buzzer-sound" (click)="onBuzzerSoundClick(device.deviceId); $event.stopPropagation();">
                    {{monitoringService.deviceConfigMap[device.deviceId]?.appConfig.volume > 0 ? 'volume_up' : 'volume_off'}}
                  </mat-icon>

                  <div class="row">
                    <div class="left-column">
                      <svg class="device-status-icon" [class.disconnected]="isDeviceDisconnected" [attr.data-type]="device.roomType">
                        <use  [attr.xlink:href]="getDeviceIcon(suite.id, room.id, device.deviceId)"></use>
                      </svg>
                    </div>
                    <span class="room-name" [class.disconnected]="isDeviceDisconnected">{{room.name}}</span>
                  </div>
                  <div class="row">
                    <div class="left-column">
                      <mat-icon class="connectivity status-icon"
                                [class.initialized]="isDeviceInitialized(suite.id, room.id, device.deviceId)"
                                [class.disconnected]="isDeviceDisconnected"
                                [class.suspended]="isDeviceSuspended(suite.id, room.id, device.deviceId)"
                      >
                        <ng-container *ngIf="!isDeviceInitialized(suite.id, room.id, device.deviceId) || isDeviceDisconnected || isDeviceSuspended(suite.id, room.id, device.deviceId); else sensorIsActive">
                          sensors_off
                        </ng-container>
                        <ng-template #sensorIsActive>
                          sensors
                        </ng-template>
                      </mat-icon>
                    </div>
                    <mat-spinner *ngIf="isLoading(suite.id, room.id, device.deviceId)" diameter="20"></mat-spinner>
                    <span>
                        <span *ngIf="!isLoading(suite.id, room.id, device.deviceId)" class="status-text" [class.disconnected]="isDeviceDisconnected" [innerHTML]="getDeviceStatus(suite.id, room.id, device.deviceId)"></span>
                        <img src="../../assets/icons/room/weak-wifi.svg" *ngIf="isWeakWifi(suite.id, room.id, device.deviceId)" class="weak-wifi" [appTooltip]="getTooltipText(suite.id, room.id, device.deviceId)" />
                      </span>
                  </div>
                  <div *ngIf="!isLoading(suite.id, room.id, device.deviceId) && !isDeviceDisconnected && !device.isFallActive && !device.isBedExitActive" class="row">
                    <div class="left-column">
                      <ng-container *ngIf="device.roomType === RoomType.Bedroom && device.presenceRegions; else regularIcon">
                        <ng-container *appVar="(device.presenceRegions | keyvalue).length as numberOfRegions">
                          <ng-container *ngIf="numberOfRegions > 0; else regularIcon">
                            <ng-container>
                              <ng-container *ngIf="numberOfRegions === 1; else manyRegions">
                                <ng-container *ngIf="device.presenceRegions[0] === Presence.HasPresence; else regularIcon">
                                  <svg><use xlink:href="../assets/icons/room/in-bed.svg#icon"></use></svg>
                                </ng-container>
                              </ng-container>
                              <ng-template #manyRegions>
                                <ng-container *ngIf="device.presenceRegions[0] === Presence.HasPresence && device.presenceRegions[1] !== Presence.HasPresence">
                                  <svg><use xlink:href="../assets/icons/room/in-bed-first.svg#icon"></use></svg>
                                </ng-container>
                                <ng-container *ngIf="device.presenceRegions[0] !== Presence.HasPresence && device.presenceRegions[1] === Presence.HasPresence">
                                  <svg><use xlink:href="../assets/icons/room/in-bed-second.svg#icon"></use></svg>
                                </ng-container>
                                <ng-container *ngIf="device.presenceRegions[0] === Presence.HasPresence && device.presenceRegions[1] === Presence.HasPresence">
                                  <svg><use xlink:href="../assets/icons/room/in-bed-both.svg#icon"></use></svg>
                                </ng-container>
                                <ng-container *ngIf="device.presenceRegions[0] !== Presence.HasPresence && device.presenceRegions[1] !== Presence.HasPresence">
                                  <ng-container *ngTemplateOutlet="regularIcon"></ng-container>
                                </ng-container>
                              </ng-template>
                            </ng-container>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                      <ng-template #regularIcon>
                        <mat-icon class="occupancy status-icon" [class.occupied]="device.isPresenceDetected">
                          <ng-container *ngIf="device.isPresenceDetected; else vacant">account_circle</ng-container>
                          <ng-template #vacant>radio_button_unchecked</ng-template>
                        </mat-icon>
                      </ng-template>
                    </div>
                    <span class="occupancy-text">{{getOccupiedStatus(suite.id, room.id, device.deviceId)}}</span>
                  </div>
                  <div *ngIf="isDeviceDisconnected" class="row">
                    <div class="left-column"></div>
                    <span class="datetime">
                        <span *ngIf="language !== JaLang" i18n="@@since" style="text-transform: lowercase">Since</span>
                        <ng-container *ngIf="deviceStateMap[device.deviceId].updateTimestamp; else unknown">
                          {{ deviceStateMap[device.deviceId].updateTimestamp | date: dateTimeFormat }}
                        </ng-container>
                        <ng-template #unknown>
                          &nbsp;<ng-container i18n="@@unknown">Unknown</ng-container>
                        </ng-template>
                        <span *ngIf="language === JaLang" i18n="@@since" style="text-transform: lowercase" data-cy="japanese-since">Since</span>
                        <mat-icon *ngIf="deviceStateMap[device.deviceId].updateTimestamp" matTooltipPosition="above" matTooltipClass="tooltip" [matTooltip]="deviceStateMap[device.deviceId].updateTimestamp | date: 'full'">info</mat-icon>
                      </span>
                  </div>
                  <ng-container *ngIf="device.isBedExitActive">
                    <div class="row">
                      <div class="left-column"></div>
                      <span i18n="@@the-patient-left-the-bed" class="alarm-title">The patient left bed</span>
                    </div>
                    <div class="row">
                      <div class="left-column">
                      </div>
                      <span class="datetime">
                        <span i18n="@@on">on</span> {{bedExitImminentMapping[device.deviceId].bedExitImminentEvent.deviceTime | date: dateTimeFormat}}
                        <mat-icon matTooltipPosition="above" matTooltipClass="tooltip" [matTooltip]="bedExitImminentMapping[device.deviceId].bedExitImminentEvent.deviceTime | date: 'full'">info</mat-icon>
                    </span>
                    </div>
                    <div class="row">
                      <div class="left-column"></div>
                      <button class="dismiss-alert" (click)="cancelBedExitAlarm(suite.id); $event.stopPropagation();">
                        <span i18n="@@dismiss-alert">Dismiss Alert</span>
                      </button>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="alertsEnabled && device.isFallActive">
                    <div class="row">
                      <div class="left-column"></div>
                      <span i18n="@@fall-has-been-detected" class="alarm-title"> Fall has been detected </span>
                    </div>
                    <div class="row">
                      <div class="left-column"></div>
                      <span class="datetime">
                          <span i18n="@@on">on</span>
                        {{fallMapping[device.deviceId].fallEvent.timestamp | date: dateTimeFormat}}
                        <mat-icon matTooltipPosition="above" matTooltipClass="tooltip" [matTooltip]="fallMapping[device.deviceId].fallEvent.timestamp | date: 'full'">info</mat-icon>
                        </span>
                    </div>
                    <div class="row" *ngIf="fallMapping[device.deviceId].fallEvent.status === FinishedFallStatus">
                      <div class="left-column"></div>
                      <button class="dismiss-alert" (click)="openFeedBack($event, card, suite, room.id)">
                        <span i18n="@@dismiss-alert">Dismiss Alert</span>
                      </button>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
          <span *ngIf="getAlertForSuite(suite.id).isPresented && getAlertForSuite(suite.id).isFallActive && roomsToArray(suite.rooms).length > 1" i18n="@@dismiss-all" class="dismiss-all" (click)="openFeedBack($event, card, suite)">
              Dismiss All
            </span>
        </mat-card-content>
      </mat-card>
    </div>
  </mat-list>

  <div class="popup" (click)="$event.stopPropagation()" #feedbackPopup [ngClass]="{ opened: feedBackPopupIsOpened }">
    <div class="popup-details">
      <div style="text-align: center">
          <span *ngIf="feedBackPopupShouldDismissAll; else dismissSingleTitle" class="room-detail-title" i18n="@@please-provide-feedback-all-events">
            Please provide feedback on these events
          </span>
        <ng-template #dismissSingleTitle>
            <span class="room-detail-title" i18n="@@please-provide-feedback-single-event">
              Please provide feedback on this event
            </span>
        </ng-template>
      </div>
      <hr />
      <div class="room-details-title"></div>
      <mat-radio-group [(ngModel)]="fallEventDismissFeedBack">
        <mat-radio-button data-cy="feedback-yes" value="yes" i18n="@@real-fall">Real Fall</mat-radio-button>
        <mat-radio-button value="no" i18n="@@false-alarm">False Alarm</mat-radio-button>
        <mat-radio-button value="test" i18n="@@test-fall">Test Fall</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="popup-actions">
      <div class="cancel-alarm alarm-fall">
        <span i18n="@@fall-has-been-detected"> Fall has been detected </span>
        <button class="send-feedback" [disabled]="!fallEventDismissFeedBack" (click)="dismissFallAlert()">
          <span i18n="@@send-feedback">Send Feedback</span>
        </button>
      </div>
    </div>
  </div>
</div>
