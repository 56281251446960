/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Device application on-demand log streaming level
 */
export enum AppLogOnDemandLevel {
    Disable = 'Disable',
    Error = 'Error',
    Warn = 'Warn',
    Info = 'Info',
    Debug = 'Debug',
    QaDebug = 'QaDebug',
    Verbose = 'Verbose',
}
