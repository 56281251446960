/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * The result of fall inference
 */
export enum FallInferenceResult {
    FALSE_ALARM = 'FalseAlarm',
    REAL_FALL = 'RealFall',
    NO_FALL = 'NoFall',
}
