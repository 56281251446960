import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "dateTimezoneName",
})
export class DateTimezoneNamePipe extends DatePipe implements PipeTransform {
  transform(
    value: string | number | Date,
    format?: string,
    timezone?: string,
    locale?: string
  ): any {
    let timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
    timezoneName = timezoneName.split("/")[1] || timezoneName;
    return (
      super.transform(value, format, timezone, locale) + " " + timezoneName
    );
  }
}
