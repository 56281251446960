/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Invalid = {
    type: Invalid.type;
};

export namespace Invalid {

    export enum type {
        Invalid = 'Invalid',
    }


}

