<mat-card *ngIf="_device">
  <mat-card-title> {{_device.deviceId.deviceId}} {{packageName}}
  </mat-card-title>
  <div class="device-info" *ngIf="_device.data">
    <b>Device Info</b>
    <p>Room Type: {{roomType}}</p>
    <p>Device Name: {{_device.data.name}}</p>
    <b>Room Info</b>
    <p>Room Id: {{_device.data.roomId}}</p>
    <p>Room Type: {{roomType}}</p>
    <p>Room Name: {{_device.data.roomName}}</p>
    <b>Suite Info</b>
    <p>Suite Id: {{_device.data.suiteId}}</p>
    <p>Suite Name: {{_device.data.suiteName}}</p>
  </div>
  <mat-card-content>
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Device Info
          </mat-panel-title>
          <mat-panel-description>

          </mat-panel-description>
        </mat-expansion-panel-header>
        <span>BuildTime: {{_device.buildInfo.buildTime|date: 'yyyy-MM-dd HH:mm:ss':'GMT'}}</span>
        <mat-list>
          <mat-list-item *ngFor="let detail of detailsMap">
            <span>{{detail.key}}</span>: {{detail.val}}
          </mat-list-item>
        </mat-list>
      </mat-expansion-panel>
      <mat-expansion-panel *ngIf="packageName!=='com.walabot.home.client'" #remoteConfigPanel
        (opened)="loadRemoteConfig()">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Remote Configuration (Gen2)
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <app-remote-config-gen2 #remoteConfigGen2 [device]="_device"></app-remote-config-gen2>
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="deviceId=_device.deviceId.deviceId">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Emergency Contacts
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <app-caregivers [deviceId]=_device.deviceId.deviceId></app-caregivers>
        </ng-template>
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="deviceId=_device.deviceId.deviceId">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Caregiver Details
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <app-caregivers-details [deviceId]=_device.deviceId.deviceId></app-caregivers-details>
        </ng-template>
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="getLastState()">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Device State
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <mat-spinner *ngIf="!deviceState"></mat-spinner>
          <div *ngIf="deviceState">Status: "{{deviceState.status}}"</div>
          <div *ngIf="deviceState">Update Time: "{{deviceState.timestamp | date:'full' }}"</div>
          <div *ngIf="deviceState">Up time: "{{deviceState.upTime|date:
                'dd/MM/yyyy HH:MM:ss':'UTC'}} UTC"</div>
          <div *ngIf="deviceState">WifiState:{{deviceState.wifiState|json}}</div>
        </ng-template>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Update Wifi credentials
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            <div>
                <mat-form-field>
                    <input [(ngModel)]="credentials.ssid" matInput placeholder="ssid">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <input [(ngModel)]="credentials.password" matInput placeholder="password">
                </mat-form-field>
            </div>
            <button style="margin-top:7px; margin-bottom:7px" class="mat-raised-button" (click)="updateWifi()">Update</button>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
    <div class="heartBeat">
      <button mat-raised-button (click)="getLastMqttTime()">Get Last seen in Mqtt</button>
      <div *ngIf="deviceMetaData">Last seen: "{{deviceMetaData.lastHeartbeatTime}}"</div>
    </div>
  </mat-card-content>

</mat-card>
