/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddEmergencyContactPayload } from '../models/AddEmergencyContactPayload';
import type { CareGiver } from '../models/CareGiver';
import type { UpdateEmergencyContactPayload } from '../models/UpdateEmergencyContactPayload';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EmergencyContactsAPI {

    /**
     * Get Emergency Contacts by Group ID
     * @param groupId
     * @returns CareGiver OK
     * @throws ApiError
     */
    public static getEmergencyContactsById(
        groupId: string,
    ): CancelablePromise<Array<CareGiver>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/emergencyContacts/{groupId}',
            path: {
                'groupId': groupId,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Add Emergency Contacts by Group ID
     * @param groupId
     * @param requestBody
     * @param notify
     * @returns CareGiver OK
     * @throws ApiError
     */
    public static addEmergencyContact(
        groupId: string,
        requestBody: AddEmergencyContactPayload,
        notify?: string,
    ): CancelablePromise<CareGiver> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/emergencyContacts/{groupId}',
            path: {
                'groupId': groupId,
            },
            query: {
                'notify': notify,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Update Emergency Contacts by Group ID
     * @param groupId
     * @param requestBody
     * @returns CareGiver OK
     * @throws ApiError
     */
    public static updateEmergencyContacts(
        groupId: string,
        requestBody?: Array<CareGiver>,
    ): CancelablePromise<Array<CareGiver>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/emergencyContacts/{groupId}',
            path: {
                'groupId': groupId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Update One Emergency Contact From Group by ID
     * @param groupId
     * @param contactId
     * @param requestBody
     * @returns CareGiver OK
     * @throws ApiError
     */
    public static updateOneEmergencyContact(
        groupId: string,
        contactId: string,
        requestBody?: UpdateEmergencyContactPayload,
    ): CancelablePromise<CareGiver> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/emergencyContacts/{groupId}/{contactId}',
            path: {
                'groupId': groupId,
                'contactId': contactId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Delete One Emergency Contact From Group by ID
     * @param groupId
     * @param contactId
     * @returns string OK
     * @throws ApiError
     */
    public static removeEmergencyContact(
        groupId: string,
        contactId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/emergencyContacts/{groupId}/{contactId}',
            path: {
                'groupId': groupId,
                'contactId': contactId,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

}
