/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UploadDspLogs = {
    /**
     * Upload Dsp Logs
     */
    type: UploadDspLogs.type;
};

export namespace UploadDspLogs {

    /**
     * Upload Dsp Logs
     */
    export enum type {
        UploadDspLogs = 'UploadDspLogs',
    }


}

