/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DismissAlertEvent = {
    type: DismissAlertEvent.type;
    /**
     * Event room id
     */
    roomId: string;
};

export namespace DismissAlertEvent {

    export enum type {
        DismissAlert = 11,
    }


}

