<h3 i18n="@@new-fallers-past-4-weeks">New Fallers - Past 4 weeks</h3>
<div  style="width: 100%; height: 100%; overflow: auto;">
    <table mat-table [dataSource]="newFallersList">
        <ng-container matColumnDef="suite">
            <th mat-header-cell *matHeaderCellDef i18n="@@apartment">Apartment</th>
            <td mat-cell *matCellDef="let element"> {{element.suite_name}} </td>
        </ng-container>
        <ng-container matColumnDef="fallsNumber">
            <th mat-header-cell *matHeaderCellDef i18n="@@number-of-Falls">Number of Falls</th>
            <td mat-cell *matCellDef="let element">
                {{element.num_of_falls_from_new_fallers}}
            </td>
        </ng-container>
        <ng-container matColumnDef="lastDate">
            <th mat-header-cell *matHeaderCellDef i18n="@@last-fall-time-and-date">Last Fall</th>
            <td mat-cell *matCellDef="let element">
                {{element.last_fall_from_new_fallers_timestamp | date: dateTimeFormat}} {{zoneAbbr}}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
<button mat-stroked-button i18n="@@close" class="close" (click)="close()">Close</button>
