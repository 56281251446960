<div style="display: flex;">
  <mat-list-item class="emergency-contacts-list-item" *ngFor="let careGiver of emergencyContacts">
    <mat-card>
      <div>Priority:{{careGiver.priority}}</div>
      <p>
        <mat-form-field>
          <input [readonly]=true matInput placeholder="Name: " value={{careGiver.name}}>
        </mat-form-field>
      </p>

      <p>
        <mat-form-field>
          <input [readonly]=true matInput placeholder="Phone Number: " value={{careGiver.phoneNumber}}>
        </mat-form-field>
      </p>

      <p>
        <mat-form-field>
          <input [readonly]=true matInput placeholder="ID: " value={{careGiver.uid}}>
        </mat-form-field>
      </p>

    </mat-card>
  </mat-list-item>
  <div>