import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import * as intlTelInput from "intl-tel-input";
import {
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from "@angular/forms";

interface moment {
  tz: {
    zonesForCountry(country: string, with_offset?: boolean): string[];
    countries(): string[];
  };
}
import * as momentTimezone from "moment-timezone/builds/moment-timezone-with-data-10-year-range.min.js";
const moment: moment = momentTimezone as moment;

@Component({
  selector: "app-intl-tel-input",
  templateUrl: "./intl-tel-input.component.html",
  styleUrls: ["./intl-tel-input.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: IntlTelInputComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: IntlTelInputComponent,
    },
  ],
})
export class IntlTelInputComponent implements AfterViewInit, OnDestroy {
  @Input() placeholder: string;
  @ViewChild("input") input: ElementRef<HTMLInputElement>;

  private telInputPlugin: intlTelInput.Plugin;
  private value: string;

  onChange: (value: string) => void;
  onTouched: () => void;

  touched = false;

  disabled = false;

  constructor() {
    window.intlTelInputGlobals.loadUtils(
      // eslint-disable-next-line
      require("intl-tel-input/build/js/utils.js")
    );
  }

  writeValue(value: string) {
    this.value = value;
    if (this.telInputPlugin) {
      this.telInputPlugin.setNumber(value);
    }
  }

  registerOnChange(onChange: () => void) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  validate(): ValidationErrors | null {
    const isValid = this.telInputPlugin
      ? this.telInputPlugin.isValidNumber()
      : true;
    if (!isValid) {
      return {
        invalidNumber: this.telInputPlugin.getValidationError(),
      };
    }
  }

  ngAfterViewInit(): void {
    const initialCountry = this.getInitialCountry();
    this.telInputPlugin = intlTelInput(this.input.nativeElement, {
      separateDialCode: true,
      preferredCountries: [],
      formatOnDisplay: false,
      ...initialCountry,
    });
    if (this.value?.length) {
      this.telInputPlugin.setNumber(this.value);
    }
    this.input.nativeElement.addEventListener("countrychange", () => {
      this.onInputChange();
    });
    this.input.nativeElement.addEventListener("open:countrydropdown", () => {
      this.input.nativeElement.type = "text";
    });
    this.input.nativeElement.addEventListener("close:countrydropdown", () => {
      setTimeout(() => {
        this.input.nativeElement.type = "number";
      });
    });
  }

  getInitialCountry() {
    let initialCountry: {
      initialCountry: string;
      geoIpLookup?: (callback: (countryCode: string) => void) => void;
    };

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const countryCode = this.getCountryCodeByTimeZone(userTimeZone);
    console.log("User country based on time zone:", countryCode);
    console.log("navigator.language:", navigator.language);
    if (countryCode) {
      initialCountry = {
        initialCountry: countryCode,
      };
    } else {
      initialCountry = {
        initialCountry: "auto",
        geoIpLookup: function (callback) {
          fetch("https://ipapi.co/json")
            .then(function (res) {
              return res.json();
            })
            .then(function (data: { country_code: string }) {
              callback(data.country_code);
            })
            .catch(function () {
              const languageList = navigator.language?.split("-");
              const country =
                languageList && languageList.length === 2
                  ? languageList[1]
                  : "IL";
              console.log("navigator.language:", country);
              callback(country);
            });
        },
      };
      console.warn("get countryCodes failed by timeZone", userTimeZone);
    }

    return initialCountry;
  }

  getCountryCodeByTimeZone(userTimeZone: string) {
    const countries = moment.tz.countries();

    for (const country of countries) {
      const timeZones = moment.tz.zonesForCountry(country);

      if (timeZones.includes(userTimeZone)) {
        return country;
      }
    }
  }

  ngOnDestroy() {
    this.telInputPlugin.destroy();
  }

  onInputChange() {
    this.value = this.telInputPlugin.getNumber();
    this.onChange(this.value);
    this.markAsTouched();
  }
}
