import { Injectable } from "@angular/core";
import { Room, RoomsAPI, Suite, SuitesAPI } from "@walabot-mqtt-dashboard/api";
import { BehaviorSubject, Observable, Subscription } from "rxjs";

export type SuiteRoomMapping = { [suiteId: string]: Array<Room> };

@Injectable({
  providedIn: "root",
})
export class SuitesService {
  private _suiteSubject: BehaviorSubject<Suite[]> = new BehaviorSubject<
    Suite[]
  >([]);
  private _suiteRoomMapping: BehaviorSubject<SuiteRoomMapping> =
    new BehaviorSubject({});
  private suiteListSub: Subscription;

  get suiteList(): Observable<Suite[]> {
    return this._suiteSubject.asObservable();
  }

  get roomMapping(): Observable<SuiteRoomMapping> {
    return this._suiteRoomMapping.asObservable();
  }

  public async init() {
    await this.reLoadSuiteList();
    if (this.suiteListSub) {
      this.suiteListSub.unsubscribe();
    }
    this.suiteListSub = this.suiteList.subscribe((suites) => {
      (async () => {
        if (suites) {
          if (suites.length > 0) {
            await this.loadSuiteRoomMapping();
          } else {
            this._suiteRoomMapping.next({});
          }
        }
      })().catch((err) => {
        console.log(err);
      });
    });
  }

  async addSuite(name: string) {
    console.log("Adding suite ", name);
    await SuitesAPI.createSuite({ name });
    return this.reLoadSuiteList();
  }

  async updateSuite(id: string, name: string) {
    console.log("Updating suite ", name);
    await SuitesAPI.updateSuite(id, { name });
    return this.reLoadSuiteList();
  }

  removeSuite(id: string) {
    return SuitesAPI.deleteSuite(id);
  }

  async assignRoomToSuite(roomId: string, suiteId: string) {
    await RoomsAPI.assignRoomToSuite(roomId, suiteId);
    return this.loadSuiteRoomMapping();
  }

  async loadSuiteRoomMapping() {
    const mapping = {},
      pool = this._suiteSubject.value.map((suite) => {
        return RoomsAPI.getRoomsBySuite(suite.id)
          .then((rooms) => {
            mapping[suite.id] = rooms;
          })
          .catch((err) => {
            console.log(err);
          });
      });

    await Promise.all(pool);
    this._suiteRoomMapping.next(mapping);
  }

  async reLoadSuiteList() {
    const suites = await SuitesAPI.getSuites();
    this._suiteSubject.next(suites);
  }

  updateRoomInfoInMapping(room: Room) {
    const roomToUpdate = this._suiteRoomMapping.value[room.suiteId].find(
      (r) => r.id === room.id
    );
    roomToUpdate.name = room.name;
    roomToUpdate.type = room.type;
  }

  public clear() {
    this._suiteRoomMapping.next({});
    this._suiteSubject.next([]);
  }
}
