import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  HostListener,
} from "@angular/core";

@Directive({
  selector: "[appTooltip]",
})
export class TooltipDirective implements OnDestroy {
  @Input("appTooltip") tooltip = "";
  @Input() delay? = 100;
  private myPopup: HTMLDivElement;

  tooltipArrowWidth = 24;

  constructor(private el: ElementRef<HTMLElement>) {}

  ngOnDestroy(): void {
    if (this.myPopup) {
      this.myPopup.remove();
    }
  }

  @HostListener("mouseenter") onMouseEnter() {
    let left = false;
    let x =
      this.el.nativeElement.getBoundingClientRect().left +
      this.el.nativeElement.offsetWidth +
      5;
    const y =
      this.el.nativeElement.getBoundingClientRect().top -
      this.el.nativeElement.offsetHeight * 2 +
      5;
    this.createContainer();
    const popupWidth = this.myPopup.clientWidth + 10;
    if (
      window.innerWidth - this.el.nativeElement.getBoundingClientRect().x <
      popupWidth + this.tooltipArrowWidth
    ) {
      x = x - popupWidth - this.el.nativeElement.clientWidth;
      left = true;
    }

    this.createTooltipPopup(x, y, left);
  }

  @HostListener("mouseleave") onMouseLeave() {
    if (this.myPopup) {
      this.myPopup.remove();
    }
  }

  private createContainer() {
    const popup = document.createElement("div");
    popup.innerHTML = this.tooltip;
    popup.setAttribute("class", "tooltip-container");
    popup.style.opacity = "0";
    document.body.appendChild(popup);
    this.myPopup = popup;
  }

  private createTooltipPopup(x: number, y: number, position: boolean) {
    if (position) {
      this.myPopup.classList.add("left");
    }
    this.myPopup.style.top = y.toString() + "px";
    this.myPopup.style.left = x.toString() + "px";
    this.myPopup.style.opacity = "1";
    document.body.appendChild(this.myPopup);
  }
}
