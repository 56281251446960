/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DashboardUserRole {
    Admin = 'admin',
    Viewer = 'viewer',
    Support = 'support',
    Developer = 'developer',
}
