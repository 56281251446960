import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "formatPercentage",
})
export class FormatPercentagePipe implements PipeTransform {
  transform(value: number): unknown {
    if (value > 0) {
      return `+${value}%`;
    } else if (value < 0) {
      return `${value}%`;
    } else {
      return "";
    }
  }
}
