import { Component, OnInit } from "@angular/core";
import { BaseComponent } from "../base-component";
import { DeviceMonitoringService } from "../ui.module";
import { Presence } from "@walabot-mqtt-dashboard/api";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-status",
  templateUrl: "./status.component.html",
  styleUrls: ["./status.component.css"],
})
export class StatusComponent extends BaseComponent implements OnInit {
  deviceEvents: {
    [deviceId: string]: {
      presence: string;
      status: string;
      fall: string;
      bedExitImminent: string;
    };
  } = {};
  devices: string[];
  constructor(private monitoringService: DeviceMonitoringService) {
    super();
  }

  ngOnInit() {
    this.monitoringService.deviceStateMap
      .pipe(takeUntil(this.ngUnsubsrcibe))
      .subscribe((deviceStateMap) => {
        Object.keys(deviceStateMap).forEach((key) => {
          if (!this.deviceEvents[key]) {
            this.deviceEvents[key] = {
              presence: "",
              fall: "",
              status: "",
              bedExitImminent: "",
            };
          }
          this.deviceEvents[key].status = deviceStateMap[key].state.status;
        });
        this.devices = Object.keys(this.deviceEvents);
      });
    this.monitoringService.presenceObservable
      .pipe(takeUntil(this.ngUnsubsrcibe))
      .subscribe((presenceEventMapping) => {
        if (presenceEventMapping) {
          Object.keys(presenceEventMapping).forEach((deviceId) => {
            const presenceEvent = presenceEventMapping[deviceId];
            let presenceStr = "No Presence";
            if (
              presenceEvent.presenceIndication.roomPresenceIndication ===
              Presence.HasPresence
            ) {
              presenceStr = "Presence Detected";
            }
            if (presenceEvent.presenceIndication.presenceRegionMap) {
              const presenceDetectedKeys = Object.keys(
                presenceEvent.presenceIndication.presenceRegionMap
              ).filter(
                (regionId) =>
                  presenceEvent.presenceIndication.presenceRegionMap[regionId]
              );
              presenceStr += " In regions: " + String(presenceDetectedKeys);
            } else {
              presenceStr += " in room";
            }
            if (!this.deviceEvents[deviceId]) {
              this.deviceEvents[deviceId] = {
                presence: "",
                fall: "",
                status: "",
                bedExitImminent: "",
              };
            }
            this.deviceEvents[deviceId].presence = presenceStr;
            this.devices = Object.keys(this.deviceEvents);
          });
        }
      });
    this.monitoringService.fallsObservables
      .pipe(takeUntil(this.ngUnsubsrcibe))
      .subscribe((fallEventMap) => {
        if (fallEventMap) {
          Object.keys(fallEventMap).forEach((deviceId) => {
            const fallEvent = fallEventMap[deviceId];
            if (!this.deviceEvents[deviceId]) {
              this.deviceEvents[deviceId] = {
                presence: "",
                fall: "",
                status: "",
                bedExitImminent: "",
              };
            }
            this.deviceEvents[
              deviceId
            ].fall = `Fall event in progress, status: ${fallEvent.fallEvent.status}`;
            this.devices = Object.keys(this.deviceEvents);
          });
        }
      });
    this.monitoringService.bedExitImminentObservables
      .pipe(takeUntil(this.ngUnsubsrcibe))
      .subscribe((bedExitImminentEventMap) => {
        if (bedExitImminentEventMap) {
          Object.keys(bedExitImminentEventMap).forEach((deviceId) => {
            if (!this.deviceEvents[deviceId]) {
              this.deviceEvents[deviceId] = {
                presence: "",
                fall: "",
                status: "",
                bedExitImminent: "",
              };
            }
            this.deviceEvents[deviceId].bedExitImminent =
              "BedExitImminent Detected";
            this.devices = Object.keys(this.deviceEvents);
          });
        }
      });
  }
}
