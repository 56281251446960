import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate } from "@angular/router";
import { AuthService } from "../auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuardService implements CanActivate {
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;

  constructor(private authService: AuthService) {}

  async canActivate() {
    let isDashboardUser = false;
    try {
      isDashboardUser = await this.authService.isDashboardUser();
      console.log(isDashboardUser);
    } catch (err) {
      console.warn(err);
    }
    return isDashboardUser;
  }
}
