/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddEmergencyContactPayload } from '../models/AddEmergencyContactPayload';
import type { CareGiver } from '../models/CareGiver';
import type { CareGiverPushToken } from '../models/CareGiverPushToken';
import type { LinkDevicePayload } from '../models/LinkDevicePayload';
import type { PairedDevice } from '../models/PairedDevice';
import type { PartialCareGiver } from '../models/PartialCareGiver';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CaregiverAPI {

    /**
     * Get Paired Care Giver
     * @param deviceId
     * @returns CareGiver OK
     * @throws ApiError
     */
    public static getPairedCaregiver(
        deviceId: string,
    ): CancelablePromise<CareGiver> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/careGiver/paired/{deviceId}',
            path: {
                'deviceId': deviceId,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get Caregivers
     * @param deviceId
     * @returns CareGiver OK
     * @throws ApiError
     */
    public static getCareGivers(
        deviceId?: string,
    ): CancelablePromise<Array<CareGiver>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/careGiver',
            query: {
                'deviceId': deviceId,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get Caregiver
     * @param uid
     * @returns any OK
     * @throws ApiError
     */
    public static getCaregiver(
        uid: string,
    ): CancelablePromise<{
        details?: CareGiver;
        pairedDevices?: Array<PairedDevice>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/careGiver/{uid}',
            path: {
                'uid': uid,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Update Caregiver
     * @param uid
     * @param requestBody
     * @returns CareGiver OK
     * @throws ApiError
     */
    public static updateCaregiver(
        uid: string,
        requestBody?: CareGiver,
    ): CancelablePromise<CareGiver> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/careGiver/{uid}',
            path: {
                'uid': uid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Delete Caregiver
     * @param uid
     * @returns any OK
     * @throws ApiError
     */
    public static deleteCaregiver(
        uid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/careGiver/{uid}',
            path: {
                'uid': uid,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Update Caregiver push token
     * @param uid
     * @param requestBody
     * @returns CareGiverPushToken OK
     * @throws ApiError
     */
    public static putCareGiverPushToken(
        uid: string,
        requestBody?: CareGiverPushToken,
    ): CancelablePromise<CareGiverPushToken> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/careGiver/{uid}/pushToken',
            path: {
                'uid': uid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Link Device
     * @param uid
     * @param deviceId
     * @param requestBody
     * @returns PairedDevice OK
     * @throws ApiError
     */
    public static linkDevice(
        uid: string,
        deviceId: string,
        requestBody?: LinkDevicePayload,
    ): CancelablePromise<PairedDevice> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/careGiver/{uid}/{deviceId}',
            path: {
                'uid': uid,
                'deviceId': deviceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Unlink Device
     * @param uid
     * @param deviceId
     * @returns boolean OK
     * @throws ApiError
     */
    public static unlinkDevice(
        uid: string,
        deviceId: string,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/careGiver/{uid}/{deviceId}',
            path: {
                'uid': uid,
                'deviceId': deviceId,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * admin user update only the specific parameter "voiceEnabled"(true/false),path parameter is User ID of the updated information
     * @param uid
     * @param requestBody
     * @returns CareGiver OK
     * @throws ApiError
     */
    public static adminUpdateCaregiver(
        uid: string,
        requestBody?: PartialCareGiver,
    ): CancelablePromise<CareGiver> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/careGiver/admin/voiceEnabled/{uid}',
            path: {
                'uid': uid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Add Emergency Contact
     * @param uid
     * @param requestBody
     * @returns AddEmergencyContactPayload OK
     * @throws ApiError
     */
    public static adminAddEmergencyContact(
        uid: string,
        requestBody?: AddEmergencyContactPayload,
    ): CancelablePromise<AddEmergencyContactPayload> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/careGiver/{uid}/emergencyContact',
            path: {
                'uid': uid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Caregiver not found error`,
            },
        });
    }

}
