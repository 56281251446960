import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DATE_TIME_FORMAT } from "../ui.module";
import { moment } from "../models";

import * as momentTimezone from "moment-timezone/builds/moment-timezone-with-data-10-year-range.min.js";
import { NewFallers } from "../analytics.service";
const moment: moment = momentTimezone as moment;

@Component({
  selector: "app-new-fallers",
  templateUrl: "./new-fallers.component.html",
  styleUrls: ["./new-fallers.component.css"],
})
export class NewFallersComponent {
  displayedColumns = ["suite", "fallsNumber", "lastDate"];
  dateTimeFormat = DATE_TIME_FORMAT;
  zoneAbbr: string;

  constructor(
    public dialog: MatDialogRef<NewFallersComponent>,
    @Inject(MAT_DIALOG_DATA)
    public newFallersList: NewFallers[]
  ) {
    const zone = moment.tz.guess();
    this.zoneAbbr = moment.tz(zone).format("z");
  }

  close() {
    this.dialog.close();
  }
}
