import { Injectable } from "@angular/core";
import { interval, Subject, Subscription } from "rxjs";

/**
 * websocket Service
 */
@Injectable({
  providedIn: "root",
})
export class WebsocketService {
  messageSubject: Subject<any>; // subject object send message
  private url: string; // websocket connecturl
  private webSocket: WebSocket;
  connectSuccess = false; // is websocket connect Success
  period: number = 1 * 60 * 1000; // 1 minute check period
  serverTimeoutSubscription: Subscription = null; // regularly check websocket
  reconnectFlag = false; // webSocket reconnect Flag true false
  reconnectPeriod: number = 5 * 1000; // reconnect fail, then reconnect once in 5 seconds
  reconnectSubscription: Subscription = null; // subscription to websocket reconnect
  runTimeSubscription: Subscription; // subscription to record websocket connection run time
  runTimePeriod: number = 5 * 60 * 1000; // 5 minutes run Time Period
  isFrontEndClose = false;

  constructor() {
    this.messageSubject = new Subject();
  }

  /**
   * sendMessage to Server
   * @param message
   */
  sendMessage(message: string) {
    this.webSocket.send(message);
  }

  /**
   * create new WebSocket
   * @param url connect url
   */
  connect(url: string) {
    console.log("vayyar_debug webSocket url: ", url);
    if (this.url && !this.reconnectFlag) {
      this.destoryWebSocket();
    }

    this.url = url;
    this.createWebSocket();
  }

  createWebSocket() {
    // If a connection has been create , return to avoid repetition
    if (this.connectSuccess) {
      console.log(
        "vayyar_debug a connection has been create, return to avoid repetition!"
      );
      return;
    }
    this.isFrontEndClose = false;
    this.webSocket = new WebSocket(this.url);

    // connect Handlers
    this.webSocket.onopen = (e) => this.onOpen(e);
    this.webSocket.onmessage = (e) => this.onMessage(e);
    this.webSocket.onclose = (e) => this.onClose(e);
    this.webSocket.onerror = (e) => this.onError(e);
  }

  destoryWebSocket() {
    if (!this.webSocket) return;
    console.log("vayyar_debug websocket destoryWebSocket");

    this.isFrontEndClose = true;
    this.webSocket.close();
    this.webSocket = null;
    this.connectSuccess = false;
    this.heartCheckStop();
    this.stopRunTime();
    if (this.reconnectFlag) {
      this.stopReconnect();
    }
    this.url = undefined;
  }

  /**
   * webSocket connect Success
   * @param event
   */
  onOpen(event: any) {
    console.log("vayyar_debug websocket connect Success!");

    this.connectSuccess = true;
    // If reconnecting
    if (this.reconnectFlag) {
      this.stopReconnect();
      //   this.startCalcRunTime();
    }

    this.heartCheckStart();
  }

  /**
   * receive messages
   * @param event
   */
  onMessage(event: { [key: string]: any }) {
    // console.log("websocket receive message:", event.data);
    if (event.data === "online") {
      console.log("vayyar_debug websocket receive message:", event.data);
      return;
    }

    const message = event.data
      ? <{ [key: string]: any }>JSON.parse(<string>event.data)
      : null;
    message && this.messageSubject.next(message);
  }

  /**
   * webSocket connect close
   */
  private onClose(e: any) {
    console.log("vayyar_debug webSocket connect close!!!", e);
    this.connectSuccess = false;
    // this.webSocket.close();

    // reconnect
    this.stopRunTime();
    this.heartCheckStop();
    if (!this.isFrontEndClose) {
      this.reconnect();
    }
    this.isFrontEndClose = false;
    // throw new Error('webSocket connection closed:)');
  }

  /**
   * webSocket connect Error
   */
  private onError(e: any) {
    // at onClose reconnect()
    console.log("vayyar_debug webSocket connect Error: ", e);
    this.connectSuccess = false;
    // throw new Error('webSocket connection error:)');
  }

  /**
   * reconnect websocket
   */
  reconnect() {
    // If it have reconnected, return to avoid repetition
    if (this.connectSuccess) {
      this.stopReconnect();
      console.log(
        "vayyar_debug it have reconnected, stop to avoid repetition!"
      );
      return;
    }
    // If it is reconnecting, retrun to avoid repetition
    if (this.reconnectFlag) {
      console.log(
        "vayyar_debug it is reconnecting, retrun to avoid repetition!"
      );
      return;
    }

    console.log("vayyar_debug websocket start try reconnect!");
    // start reconnect
    this.reconnectFlag = true;
    // If the connection fails, it will be reconnected regularly
    this.reconnectSubscription = interval(this.reconnectPeriod).subscribe(
      (val) => {
        console.log(`vayyar_debug connect: ${val} second`);

        this.connect(this.url);
      }
    );
  }

  /**
   * stop reconnect websocket
   */
  stopReconnect() {
    this.reconnectFlag = false;
    // Unsubscribe
    if (
      typeof this.reconnectSubscription !== "undefined" &&
      this.reconnectSubscription != null
    ) {
      this.reconnectSubscription.unsubscribe();
    }

    console.log("vayyar_debug websocket stop try reconnect!");
  }

  /**
   * start check heart
   */
  heartCheckStart() {
    this.serverTimeoutSubscription = interval(this.period).subscribe((val) => {
      // check webSocket status
      if (this.webSocket != null && this.webSocket.readyState === 1) {
        const num = val + 1;
        console.log(
          "vayyar_debug websocket run time [",
          `${(num * this.period) / 60 / 1000} minutes]`
        );
        console.log(num, "vayyar_debug webSocket is OK send online");
        this.webSocket.send("online");
      } else {
        this.heartCheckStop();

        console.log("vayyar_debug websocket have close ,reconnect");
        this.reconnect();
      }
    });
  }

  /**
   * stop check heart
   */
  heartCheckStop() {
    // unsubscribe heck heart
    if (
      typeof this.serverTimeoutSubscription !== "undefined" &&
      this.serverTimeoutSubscription != null
    ) {
      this.serverTimeoutSubscription.unsubscribe();
    }
  }

  /**
   * start calc websocket run time
   */
  startCalcRunTime() {
    this.runTimeSubscription = interval(this.runTimePeriod).subscribe(
      (period) => {
        console.log("vayyar_debug websocket run time [", `${period} minutes]`);
      }
    );
  }

  /**
   * stop calc websocket run time
   */
  stopRunTime() {
    if (
      typeof this.runTimeSubscription !== "undefined" &&
      this.runTimeSubscription !== null
    ) {
      this.runTimeSubscription.unsubscribe();
    }
  }
}
