/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Telemetry transport (Deprecated: value ignored - always using MQTT/QoS0)
 */
export enum TelemetryTransport {
    MqttQos0 = 0,
    MqttQos1 = 1,
    Http = 2,
}
