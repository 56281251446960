/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeviceLocation } from '../models/DeviceLocation';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DeviceLocationApiAPI {

    /**
     * Get device location
     * @param deviceId
     * @returns DeviceLocation OK
     * @throws ApiError
     */
    public static getDeviceLocation(
        deviceId: string,
    ): CancelablePromise<DeviceLocation> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/deviceAdmin/{deviceId}/location',
            path: {
                'deviceId': deviceId,
            },
            errors: {
                404: `Device not found error`,
            },
        });
    }

}
