import { Component, Input } from "@angular/core";
import { DevicesService } from "../devices.service";
import { CareGiver, PairedCareGiver } from "@walabot-mqtt-dashboard/api";
@Component({
  selector: "app-caregivers",
  templateUrl: "./emergency-contacts-details.component.html",
  styleUrls: ["./emergency-contacts-details.component.css"],
})
export class EmergencyContactsDetailsComponent {
  private _deviceId: string;
  emergencyContacts: (CareGiver | PairedCareGiver)[];

  @Input() set deviceId(deviceId: string) {
    console.log("Settings deviceId", deviceId);
    if (this._deviceId !== deviceId) {
      this._deviceId = deviceId;
      void this.getEmergencyContacts(this._deviceId);
    }
  }

  constructor(private devicesService: DevicesService) {}

  private getEmergencyContacts(deviceId: string) {
    this.devicesService
      .getPairedCareGiver(deviceId)
      .then((careGiver) => {
        return this.devicesService
          .getEmergencyContacts(careGiver.uid)
          .then((careGivers) => {
            this.emergencyContacts = careGivers;
          });
      })
      .catch((err) => {
        console.error(err);
      });
  }
}
