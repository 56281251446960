<div [formGroup]="configurationForm" style="height: 100%;">
  <div formGroupName="roomSizeForm" style="display: flex; flex-direction: row; width: 100%; height: 100%">
    <div class="tab-body-left" style="display: flex; flex-direction: column">
      <!-- xMin -->
      <span class="field-title" i18n="@@xmin">xMin</span>
      <div *ngIf="unit === metric">
        <mat-form-field appearance="outline" class="value-field">
          <input
            matInput
            type="number"
            name="xMin"
            formControlName="roomXMin"
            (ngModelChange)="validateSubregionSizeAgainstRoomChanged('roomXMin')"
            [errorStateMatcher]="ceilingXMinMatcher"
          />
          <span matSuffix>m</span>
          <mat-error *ngIf="roomSize.invalid">
            {{ roomSize.getError("xMinInvalid") || roomSize.getError("xMinAxisLengthInvalid")}}
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="unit !== metric" class="grid-div">
        <div class="feet-inches-fields-row">
          <div style="width: 50%">
            <mat-form-field appearance="outline" class="value-field">
              <input
                matInput
                type="number"
                name="xMinFeet"
                formControlName="roomXMinFeet"
                (ngModelChange)="validateSubregionSizeAgainstRoomChanged('roomXMin')"
                (keypress)="filterNegativeValueFeet($event, 'roomXMinInches')"
                [errorStateMatcher]="ceilingXMinMatcher"
              />
              <span matSuffix>'</span>
              <mat-error *ngIf="roomSize.invalid">
                {{ roomSize.getError("xMinInvalid") || roomSize.getError("xMinAxisLengthInvalid")}}
              </mat-error>
            </mat-form-field>
          </div>
          <div style="width: 50%">
            <mat-form-field appearance="outline" class="value-field">
              <input
                matInput
                type="number"
                name="xMinInches"
                formControlName="roomXMinInches"
                (ngModelChange)="validateSubregionSizeAgainstRoomChanged('roomXMin')"
                (keypress)="filterNegativeValueInches($event, 'roomXMinFeet')"
                [errorStateMatcher]="ceilingXMinMatcher"
              />
              <span matSuffix>"</span>
              <mat-error *ngIf="roomSize.invalid">
                {{ roomSize.getError("ceilingXMinInchesInvalid") }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <!-- xMax -->
      <span class="field-title" i18n="@@x-max">xMax</span>
      <div *ngIf="unit === metric">
        <mat-form-field appearance="outline" class="value-field">
          <input
            matInput
            type="number"
            name="xMax"
            formControlName="roomXMax"
            (ngModelChange)="validateSubregionSizeAgainstRoomChanged('roomXMax')"
            [errorStateMatcher]="ceilingXMaxMatcher"
          />
          <span matSuffix>m</span>
          <mat-error *ngIf="roomSize.invalid">
            {{ roomSize.getError("xMaxInvalid") || roomSize.getError("xMaxAxisLengthInvalid")}}
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="unit !== metric" class="grid-div">
        <div class="feet-inches-fields-row">
          <div style="width: 50%">
            <mat-form-field appearance="outline" class="value-field">
              <input
                matInput
                type="number"
                name="xMaxFeet"
                formControlName="roomXMaxFeet"
                (ngModelChange)="validateSubregionSizeAgainstRoomChanged('roomXMax')"
                (keypress)="filterNegativeValueFeet($event, 'roomXMaxInches')"
                [errorStateMatcher]="ceilingXMaxMatcher"
              />
              <span matSuffix>'</span>
              <mat-error *ngIf="roomSize.invalid">
                {{ roomSize.getError("xMaxInvalid") || roomSize.getError("xMaxAxisLengthInvalid")}}
              </mat-error>
            </mat-form-field>
          </div>
          <div style="width: 50%">
            <mat-form-field appearance="outline" class="value-field">
              <input
                matInput
                type="number"
                name="xMaxInches"
                formControlName="roomXMaxInches"
                (ngModelChange)="validateSubregionSizeAgainstRoomChanged('roomXMax')"
                (keypress)="filterNegativeValueInches($event, 'roomXMaxFeet')"
                [errorStateMatcher]="ceilingXMaxMatcher"
              />
              <span matSuffix>"</span>
              <mat-error *ngIf="roomSize.invalid">
                {{ roomSize.getError("ceilingXMaxInchesInvalid") }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <!-- yMin Not wallMount -->
      <ng-container *ngIf="!isWallMount">
        <span class="field-title" i18n="@@y-min">yMin</span>
        <div *ngIf="unit === metric">
          <mat-form-field appearance="outline" class="value-field">
            <input
              matInput
              type="number"
              name="yMin"
              formControlName="roomYMin"
              (ngModelChange)="validateSubregionSizeAgainstRoomChanged('roomYMin')"
              [errorStateMatcher]="ceilingYMinMatcher"
            />
            <span matSuffix>m</span>
            <mat-error *ngIf="roomSize.invalid">
              {{
                roomSize.getError("yMinInvalid") ||
                roomSize.getError("yMinAxisLengthInvalid")
              }}
            </mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="unit !== metric" class="grid-div">
          <div class="feet-inches-fields-row">
            <div style="width: 50%">
              <mat-form-field appearance="outline" class="value-field">
                <input
                  matInput
                  type="number"
                  name="yMinFeet"
                  formControlName="roomYMinFeet"
                  (ngModelChange)="validateSubregionSizeAgainstRoomChanged('roomYMin')"
                  (keypress)="filterNegativeValueFeet($event, 'roomYMinInches')"
                  [errorStateMatcher]="ceilingYMinMatcher"
                />
                <span matSuffix>'</span>
                <mat-error *ngIf="roomSize.invalid">
                  {{
                    roomSize.getError("yMinInvalid") ||
                    roomSize.getError("yMinAxisLengthInvalid")
                  }}
                </mat-error>
              </mat-form-field>
            </div>
            <div style="width: 50%">
              <mat-form-field appearance="outline" class="value-field">
                <input
                  matInput
                  type="number"
                  name="yMinInches"
                  formControlName="roomYMinInches"
                  (ngModelChange)="validateSubregionSizeAgainstRoomChanged('roomYMin')"
                  (keypress)="filterNegativeValueInches($event, 'roomYMinFeet')"
                  [errorStateMatcher]="ceilingYMinMatcher"
                />
                <span matSuffix>"</span>
                <mat-error *ngIf="roomSize.invalid">
                  {{ roomSize.getError("ceilingYMinInchesInvalid") }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- yMax -->
      <span class="field-title" i18n="@@y-max">yMax</span>
      <div *ngIf="unit === metric">
        <mat-form-field appearance="outline" class="value-field">
          <input
            matInput
            type="number"
            name="yMax"
            formControlName="roomYMax"
            (ngModelChange)="validateSubregionSizeAgainstRoomChanged('roomYMax')"
            [errorStateMatcher]="ceilingYMaxMatcher"
          />
          <span matSuffix>m</span>
          <mat-error *ngIf="roomSize.invalid">
            {{
              roomSize.getError("yMaxInvalid") ||
              roomSize.getError("yMaxAxisLengthInvalid") 
            }}
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="unit !== metric" class="grid-div">
        <div class="feet-inches-fields-row">
          <div style="width: 50%">
            <mat-form-field appearance="outline" class="value-field">
              <input
                matInput
                type="number"
                name="yMaxFeet"
                formControlName="roomYMaxFeet"
                (ngModelChange)="validateSubregionSizeAgainstRoomChanged('roomYMax')"
                (keypress)="filterNegativeValueFeet($event, 'roomYMaxInches')"
                [errorStateMatcher]="ceilingYMaxMatcher"
              />
              <span matSuffix>'</span>
              <mat-error *ngIf="roomSize.invalid">
                {{
                  roomSize.getError("yMaxInvalid") ||
                  roomSize.getError("yMaxAxisLengthInvalid") 
                }}
              </mat-error>
            </mat-form-field>
          </div>
          <div style="width: 50%">
            <mat-form-field appearance="outline" class="value-field">
              <input
                matInput
                type="number"
                name="yMaxInches"
                formControlName="roomYMaxInches"
                (ngModelChange)="validateSubregionSizeAgainstRoomChanged('roomYMax')"
                (keypress)="filterNegativeValueInches($event, 'roomYMaxFeet')"
                [errorStateMatcher]="ceilingYMaxMatcher"
              />
              <span matSuffix>"</span>
              <mat-error *ngIf="roomSize.invalid">
                {{ roomSize.getError("ceilingYMaxInchesInvalid") }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <!-- Device height WallMount-->
      <ng-container *ngIf="deviceSettings?.get('sensorMounting').value === SensorMounting.Wall; else ceilingHight">
        <span class="field-title" i18n="@@height">Device height</span>
        <div *ngIf="unit === metric">
          <mat-form-field appearance="outline" class="value-field">
            <input
              matInput
              type="number"
              name="height"
              formControlName="roomCeilingHeight"
              [errorStateMatcher]="roomHeightMatcher"
              #height="matAutocompleteTrigger"
              [matAutocomplete]="auto"
              [readonly]="standardSensorHeightWasSelected"
              (input)="height.closePanel(); standardSensorHeightWasSelected = false;"
              (keypress)="onSensorHeightMetersKeyPress($event)"
              (click)="height.openPanel()"
            />
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="sensorHeightMetersOptionSelected($event)">
              <mat-option [value]="sensorHeightInMeters">
                {{standardInstallationMetersOptionLabel}}
              </mat-option>
              <mat-option [value]="null" i18n="@@in-bed-installation" data-cy="in-bed-custom">
                "In-Bed" Installation (Custom)
              </mat-option>
            </mat-autocomplete>
            <span matSuffix [class.readonly]="standardSensorHeightWasSelected">m</span>
            <mat-error *ngIf="roomSize.invalid">
              {{ roomSize.getError("roomHeightInvalid") }}
            </mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="unit !== metric" class="grid-div">
          <div class="feet-inches-fields-row" #panel>
            <div style="width: 50%">
              <mat-form-field appearance="outline" class="value-field">
                <input
                  matInput
                  type="number"
                  name="roomCeilingHeightFeet"
                  formControlName="roomCeilingHeightFeet"
                  [errorStateMatcher]="roomHeightMatcher"
                  #heightFeet="matAutocompleteTrigger"
                  [matAutocomplete]="auto"
                  [readonly]="standardSensorHeightWasSelected"
                  (input)="heightFeet.closePanel(); standardSensorHeightWasSelected = false;"
                  (click)="heightFeet.openPanel()"
                />
                <span matSuffix>'</span>
                <mat-error *ngIf="roomSize.invalid">
                  {{ roomSize.getError("roomHeightInvalid") }}
                </mat-error>
              </mat-form-field>
            </div>
            <div style="width: 50%">
              <mat-form-field appearance="outline" class="value-field">
                <input
                  matInput
                  type="number"
                  name="roomCeilingHeightInches"
                  formControlName="roomCeilingHeightInches"
                  #heightInches="matAutocompleteTrigger"
                  #inchesInput
                  [matAutocomplete]="auto"
                  [readonly]="standardSensorHeightWasSelected"
                  (input)="heightInches.closePanel(); standardSensorHeightWasSelected = false;"
                  (click)="heightInches.openPanel()"
                />
                <span matSuffix>"</span>
                <mat-error *ngIf="roomSize.invalid">
                  {{ roomSize.getError("ceilingHeightInchesInvalid") }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <mat-autocomplete
            #auto="matAutocomplete"
            [panelWidth]="panel.clientWidth"
            [class]="lastSensorHeightFeetInputTriggerAutocomplete === inchesInput ? 'autocomplete-feet' : ''"
            (opened)="onSensorHeightFeetAutocompleteOpen()"
            (optionSelected)="sensorHeightFeetOptionSelected($event)"
          >
            <mat-option [value]="sensorHeightInFeet[0]">
              {{standardInstallationFeetOptionLabel}}
            </mat-option>
            <mat-option [value]="null" i18n="@@in-bed-installation" data-cy="in-bed-custom">
              "In-Bed" Installation (Custom)
            </mat-option>
          </mat-autocomplete>
        </div>
      </ng-container>
      <!-- Device height Not WallMount-->
      <ng-template #ceilingHight>
        <span class="field-title" i18n="@@height">Device height</span>
        <div *ngIf="unit === metric">
          <mat-form-field appearance="outline" class="value-field">
            <input
              matInput
              type="number"
              name="height"
              formControlName="roomCeilingHeight"
              [errorStateMatcher]="roomHeightMatcher"
            />
            <span matSuffix>m</span>
            <mat-error *ngIf="roomSize.invalid">
              {{ roomSize.getError("roomHeightInvalid") }}
            </mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="unit !== metric" class="grid-div">
          <div class="feet-inches-fields-row">
            <div style="width: 50%">
              <mat-form-field appearance="outline" class="value-field">
                <input
                  matInput
                  type="number"
                  name="heightFeet"
                  formControlName="roomCeilingHeightFeet"
                  (keypress)="filterNegativeValueFeet($event, 'roomCeilingHeightInches')"
                  [errorStateMatcher]="roomHeightMatcher"
                />
                <span matSuffix>'</span>
                <mat-error *ngIf="roomSize.invalid">
                  {{ roomSize.getError("roomHeightInvalid") }}
                </mat-error>
              </mat-form-field>
            </div>
            <div style="width: 50%">
              <mat-form-field appearance="outline" class="value-field">
                <input
                  matInput
                  type="number"
                  name="heightInches"
                  formControlName="roomCeilingHeightInches"
                  (keypress)="filterNegativeValueInches($event, 'roomCeilingHeightFeet')"
                  [errorStateMatcher]="roomHeightMatcher"
                />
                <span matSuffix>"</span>
                <mat-error *ngIf="roomSize.invalid">
                  {{ roomSize.getError("ceilingHeightInchesInvalid") }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="tab-body-right">
      <ng-container [ngTemplateOutlet]="content?.templateRef"></ng-container>
    </div>
  </div>
</div>
