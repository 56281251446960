/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Tenant } from '../models/Tenant';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TenantsAPI {

    /**
     * Delete Tenant
     * @param tenantId
     * @returns Tenant OK
     * @throws ApiError
     */
    public static deleteTenant(
        tenantId: string,
    ): CancelablePromise<Tenant> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/tenant/{tenantId}',
            path: {
                'tenantId': tenantId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

}
