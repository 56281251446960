/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ExternalDeviceEvent = {
    type: ExternalDeviceEvent.type;
    /**
     * Timestamp of the event in milliseconds since epoch
     */
    timestamp: number;
    /**
     * MAC address of the device
     */
    mac: string;
    /**
     * UUID of the device
     */
    uuid: string;
    /**
     * RSSI of the device received transmission
     */
    rssi: number;
    /**
     * Classification of the reported event
     */
    eventType: ExternalDeviceEvent.eventType;
};

export namespace ExternalDeviceEvent {

    export enum type {
        ExternalDevice = 9,
    }

    /**
     * Classification of the reported event
     */
    export enum eventType {
        ButtonPressed = 'ButtonPressed',
    }


}

