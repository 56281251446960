/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DashboardEventBase } from './DashboardEventBase';
import type { DoorEvent } from './DoorEvent';

export type DashboardDoorEvent = (DashboardEventBase & {
    type: DashboardDoorEvent.type;
    payload: DoorEvent;
});

export namespace DashboardDoorEvent {

    export enum type {
        Enter = 6,
        Exit = 7,
    }


}

