/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Rebooting = {
    type: Rebooting.type;
};

export namespace Rebooting {

    export enum type {
        Rebooting = 'Rebooting',
    }


}

