/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FallEvent } from '../models/FallEvent';
import type { PresenceEvent } from '../models/PresenceEvent';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DeviceHistoryAPI {

    /**
     * Get last fall event
     * @param deviceId
     * @returns FallEvent OK
     * @throws ApiError
     */
    public static getHistoryFall(
        deviceId: string,
    ): CancelablePromise<FallEvent> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/history/fall/{deviceId}',
            path: {
                'deviceId': deviceId,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get last presence event
     * @param deviceId
     * @returns PresenceEvent OK
     * @throws ApiError
     */
    public static getHistoryPresence(
        deviceId: string,
    ): CancelablePromise<PresenceEvent> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/history/presence/{deviceId}',
            path: {
                'deviceId': deviceId,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

}
