/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * ESP log level. NOTE: DO NOT USE VERBOSE level for production devices!
 */
export enum LogLevel {
    V_LOG_LEVEL_VERBOSE = -1,
    V_LOG_LEVEL_DEBUG = 0,
    V_LOG_LEVEL_INFO = 1,
    V_LOG_LEVEL_WARN = 2,
    V_LOG_LEVEL_ERROR = 3,
}
