import { RoomType } from "@walabot-mqtt-dashboard/api";

export const RoomTypeMapping = new Map<
  RoomType,
  { icon: string; name: string }
>([
  [
    RoomType.Bathroom,
    { icon: "bathroom", name: $localize`:@@room-type-bathroom:Bathroom` },
  ],
  [
    RoomType.Bedroom,
    { icon: "bedroom", name: $localize`:@@room-type-bedroom:Bedroom` },
  ],
  [
    RoomType.DiningRoom,
    {
      icon: "dining-room",
      name: $localize`:@@room-type-dining-room:Dining Room`,
    },
  ],
  [
    RoomType.FamilyRoom,
    {
      icon: "family-room",
      name: $localize`:@@room-type-family-room:Family Room`,
    },
  ],
  [
    RoomType.Hall,
    { icon: "hallway", name: $localize`:@@room-type-hallway:Hallway` },
  ],
  [
    RoomType.LivingRoom,
    {
      icon: "living-room",
      name: $localize`:@@room-type-living-room:Living Room`,
    },
  ],
  [
    RoomType.Other,
    { icon: "other", name: $localize`:@@room-type-other:Other` },
  ],
  [
    RoomType.Kitchen,
    { icon: "kitchen", name: $localize`:@@room-type-kitchen:Kitchen` },
  ],
]);

export const getDeviceIcon = (roomType: string | RoomType) => {
  let icon = "other";
  icon = RoomTypeMapping.get(parseInt(roomType as string, 10))?.icon;
  return `../assets/icons/room/${icon}.svg#icon`;
};
