import { NgModule, Injectable } from "@angular/core";
import { Routes, RouterModule, UrlTree, CanActivate } from "@angular/router";
import { Observable } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import {
  PasswordDialogComponent,
  AuthGuardService,
  RawDataComponent,
  HistoryComponent,
  TargetsComponent,
  RoomsComponent,
  CareGiversComponent,
  RoomsSettingsComponent,
  DashboardSettingsComponent,
  DevicesComponent,
  DeviceDetailsComponent,
  DashboardUsersComponent,
  AdminAuthGuard,
  AppAdminComponent,
  NotFoundComponent,
} from "@walabot-mqtt-dashboard/ui";
import { map } from "rxjs/operators";

@Injectable()
export class ConfigurationGuard implements CanActivate {
  constructor(private dialog: MatDialog) {}
  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const dialogRef = this.dialog.open(PasswordDialogComponent, {
      width: "250px",
      data: { match: "HospitalDemo" },
    });
    return dialogRef.afterClosed().pipe(
      map((result: { [key: string]: boolean }) => {
        if (result.canceled) {
          return false;
        }
        return true;
      }),
    );
  }
}
const monitorRoutes: Routes = [
  {
    path: "rooms",
    component: RoomsComponent,
    data: { title: "Rooms", alertsEnabled: true },
    canActivate: [AuthGuardService],
  },
  {
    path: "history",
    component: HistoryComponent,
    data: { title: "History" },
    canActivate: [AuthGuardService],
  },
];
const allPagesRoutes: Routes = [
  ...monitorRoutes,
  {
    path: "config",
    component: RoomsComponent,
    data: { title: "Device Settings" },
    canActivate: [AuthGuardService],
  },
  {
    path: "suites",
    component: RoomsSettingsComponent,
    data: { title: "Dashboard Suites" },
    canActivate: [AuthGuardService],
  },
  {
    path: "settings",
    component: DashboardSettingsComponent,
    data: { title: "Dashboard Settings" },
    canActivate: [AuthGuardService],
  },
  {
    path: "raw-data",
    component: RawDataComponent,
    data: { title: "Raw Data" },
    canActivate: [AuthGuardService],
  },
  {
    path: "caregivers",
    component: CareGiversComponent,
    data: { title: "CareGivers" },
    canActivate: [AuthGuardService],
  },
  { path: "targets/:id", component: TargetsComponent },
];
const routes: Routes = [
  {
    path: "monitor",
    children: [
      ...monitorRoutes,
      {
        path: "",
        pathMatch: "full",
        redirectTo: "rooms",
      },
      {
        path: "**",
        pathMatch: "full",
        component: NotFoundComponent,
      },
    ],
  },
  {
    path: "installer",
    children: [
      ...allPagesRoutes,
      {
        path: "",
        pathMatch: "full",
        redirectTo: "rooms",
      },
      {
        path: "**",
        pathMatch: "full",
        component: NotFoundComponent,
      },
    ],
  },
  {
    path: "admin",
    children: [
      ...allPagesRoutes,
      {
        path: "admin",
        component: AppAdminComponent,
        canActivate: [AdminAuthGuard],
        children: [
          {
            path: "devices",
            component: DevicesComponent,
            data: { title: "Devices" },
            canActivate: [AdminAuthGuard],
          },
          {
            path: "device/:id",
            component: DeviceDetailsComponent,
            canActivate: [AdminAuthGuard],
          },
          {
            path: "dashboard-users",
            component: DashboardUsersComponent,
            data: { title: "Dashboard Users", roles: ["admin"] },
            canActivate: [AdminAuthGuard],
          },
          {
            path: "**",
            pathMatch: "full",
            redirectTo: "devices",
          },
        ],
      },
      {
        path: "",
        pathMatch: "full",
        redirectTo: "rooms",
      },
      {
        path: "**",
        pathMatch: "full",
        component: NotFoundComponent,
      },
    ],
  },
  {
    path: "**",
    pathMatch: "full",
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
  providers: [ConfigurationGuard],
})
export class AppRoutingModule {}
