<div>
  <mat-spinner *ngIf="!dashboardUsers" #loading></mat-spinner>
  <table *ngIf="dashboardUsers" mat-table [dataSource]="dashboardUsers" class="mat-elevation-z8">
    <ng-container matColumnDef="uid">
      <th mat-header-cell *matHeaderCellDef> UID </th>
      <td mat-cell *matCellDef="let element"> {{element.uid}} </td>
    </ng-container>
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef> Email </th>
      <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>
    <ng-container *ngFor="let role of roles" matColumnDef={{role}}>
      <th mat-header-cell *matHeaderCellDef> {{role}} </th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox [(ngModel)]="element[role]"></mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef> Delete </th>
      <td mat-cell *matCellDef="let element">
        <button mat-raised-button (click)="deleteUser(element.uid)">Delete</button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <div style="display: -webkit-flex">
    <button style="margin-top:7px; margin-bottom:7px" #inviteBtn class="mat-raised-button" (click)="openAddUser()">Add User</button>
    <button style="margin-top:7px; margin-bottom:7px" #saveBtn class="mat-raised-button" (click)="save()">Save</button>
  </div>
  <mat-card #addUserCard *ngIf="userToAdd">
    <mat-card-content>
      <mat-form-field>
        <input [(ngModel)]="userToAdd.email" matInput placeholder="Email">
      </mat-form-field>
      <mat-checkbox [(ngModel)]="userToAdd.roles[role]" style="margin-left: 7px" *ngFor="let role of roles">{{role}}</mat-checkbox>
      <button style="margin-top:7px; margin-bottom:7px" #inviteBtn class="mat-raised-button" (click)="addUser()">Add</button>

    </mat-card-content>
  </mat-card>
  <mat-card *ngIf="invites&&invites.length>0">
    <mat-card-title>Invites</mat-card-title>
    <mat-card-content>
      <table mat-table [dataSource]="invites" class="mat-elevation-z8">
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef> Email </th>
          <td mat-cell *matCellDef="let element">
            {{element.email}}
          </td>
        </ng-container>
        <ng-container *ngFor="let role of roles" matColumnDef={{role}}>
          <th mat-header-cell *matHeaderCellDef> {{role}} </th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox [disabled]="true" [(ngModel)]="element[role]"></mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef> Delete </th>
          <td mat-cell *matCellDef="let element">
            <button mat-raised-button (click)="deletePendingInvite(element.email)">Delete</button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="invitesDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: invitesDisplayedColumns;"></tr>
      </table>
    </mat-card-content>
  </mat-card>
</div>
