/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Suite } from '../models/Suite';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SuitesAPI {

    /**
     * Get suites by a filter
     * @returns Suite OK
     * @throws ApiError
     */
    public static getSuites(): CancelablePromise<Array<Suite>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/suites',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Create New Suite
     * @param requestBody
     * @returns Suite OK
     * @throws ApiError
     */
    public static createSuite(
        requestBody?: {
            name: string;
        },
    ): CancelablePromise<Suite> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/suites',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get Suite by id
     * @param suiteId
     * @returns Suite OK
     * @throws ApiError
     */
    public static getSuiteById(
        suiteId: string,
    ): CancelablePromise<Suite> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/suites/{suiteId}',
            path: {
                'suiteId': suiteId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Update Suite
     * @param suiteId
     * @param requestBody
     * @returns Suite OK
     * @throws ApiError
     */
    public static updateSuite(
        suiteId: string,
        requestBody?: {
            name: string;
        },
    ): CancelablePromise<Suite> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/suites/{suiteId}',
            path: {
                'suiteId': suiteId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Delete Suite
     * @param suiteId
     * @returns Suite OK
     * @throws ApiError
     */
    public static deleteSuite(
        suiteId: string,
    ): CancelablePromise<Suite> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/suites/{suiteId}',
            path: {
                'suiteId': suiteId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

}
