/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * The type of resolution for the Alert
 */
export enum ResolutionType {
    CONFIRMED_WITH_INJURY = 'Injury',
    CONFIRMED_WITHOUT_INJURY = 'Confirmed',
    UNCONFIRMED = 'NoFall',
    LEARNING = 'Test',
}
