<div class="login">
  <div class="logo">
    <object
      data="../../assets/icons/vayyar-home-logo.svg"
      type="image/svg+xml"
    ></object>
  </div>
  <div
    *ngIf="!isDashboardUser"
    style="display: flex; flex-direction: column; margin-top: 30px"
  >
    <span
      style="
        margin-left: auto;
        margin-right: auto;
        color: white;
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
      "
      data-cy="login-title"
      >{{ getLoginTitle() }}</span
    >
    <!-- <a routerLink="/dashboard" routerLinkActive="active">Heroes</a> -->
    <mat-card
      class="login-container"
      *ngIf="!inForgotPassword"
      style="margin-top: 20px"
    >
      <mat-card-content style="padding: 30px 32px">
        <form
          class="login-box"
          #loginForm="ngForm"
          [formGroup]="formGroup"
          (ngSubmit)="login()"
        >
          <div
            id="customBtn"
            class="customGPlusSignIn customBtn mat-elevation-z8"
            (click)="signInWithGoogle()"
          >
            <span class="icon"></span>
            <span class="buttonText">{{ getGoogleButtonText() }}</span>
          </div>
          <div
            *ngIf="appleSignInEnabled"
            id="customBtn"
            class="customGPlusSignIn customBtn mat-elevation-z8"
            (click)="signInWithApple()"
            style="margin-top: 23px"
          >
            <span class="apple-icon"></span>
            <span class="buttonText">{{ getAppleButtonText() }}</span>
          </div>
          <span
            style="
              margin-left: auto;
              margin-right: auto;
              margin-top: 23px;
              margin-bottom: 23px;
              font-size: 14px;
              font-weight: 500;
              letter-spacing: -0.23px;
              color: #61697f;
            "
            >Or</span
          >
          <mat-form-field *ngIf="register" appearance="outline">
            <mat-label i18n="@@your-name">Your Name</mat-label>
            <div class="login-field">
              <div class="icon-wrapper">
                <img src="../assets/icons/user-icon.svg" />
              </div>
              <input matInput type="text" name="name" formControlName="name" required />
            </div>
            <mat-error *ngIf="formGroup.controls.name.hasError('required')">{{ getNameErrorMessage() }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label i18n="@@enter-your-email">Enter your email</mat-label>
            <div class="login-field">
              <div class="icon-wrapper">
                <img src="../assets/icons/email-icon.svg" />
              </div>
              <input matInput type="text" name="email" formControlName="email" required />
            </div>
            <mat-error *ngIf="formGroup.controls.email.invalid">{{ getEmailErrorMessage() }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ getPasswordHintText() }}</mat-label>
            <div class="login-field">
              <div class="icon-wrapper">
                <img src="../assets/icons/pass-icon.svg" />
              </div>
              <input matInput [type]="showPassword ? 'text' : 'password'" name="password" formControlName="password" required />
              <div class="eye-icon">
                <img src="../assets/icons/eye-off.svg" *ngIf="!showPassword" (click)="showPassword = true;"/>
                <img src="../assets/icons/eye-on.svg" *ngIf="showPassword" (click)="showPassword = false;"/>
              </div>
            </div>
            <mat-error *ngIf="formGroup.controls.password.invalid">{{ getPasswordErrorMessage() }}</mat-error>
          </mat-form-field>
          <span *ngIf="register" class="password-requirements">
            <ng-container i18n="@@password-requirements">Password Requirements</ng-container>
            <span class="cdk-visually-hidden" #translatedTooltipMessage i18n="@@password-requirements-tooltip">
              Password must consist of a minimum of 8 characters and contain uppercase, lowercase, digits and non-alphanumeric characters (e.g., !, $, #, %)
            </span>
            <mat-icon class="info-icon" [matTooltip]="translatedTooltipMessage.innerHTML" matTooltipClass="tooltip" matTooltipPosition="right">
              info
            </mat-icon>
          </span>
          <mat-form-field *ngIf="register" appearance="outline" class="re-enter-password">
            <mat-label i18n="@@re-enter-your-password">Re-enter your Password</mat-label>
            <div class="login-field">
              <div class="icon-wrapper">
                <img src="../assets/icons/pass-icon.svg" />
              </div>
              <input matInput [type]="showPassword ? 'text' : 'password'" name="confirmPassword" required formControlName="confirmPassword" [errorStateMatcher]="matcher" />
              <div class="eye-icon">
                <img src="../assets/icons/eye-off.svg" *ngIf="!showPassword" (click)="showPassword = true;"/>
                <img src="../assets/icons/eye-on.svg" *ngIf="showPassword" (click)="showPassword = false;"/>
              </div>
            </div>
            <mat-error *ngIf="formGroup.hasError('confirmPassword') || formGroup.controls.confirmPassword.invalid">
              {{ getConfirmPasswordErrorMessage() }}
            </mat-error>
          </mat-form-field>
          <mat-form-field *ngIf="multiRegion" appearance="outline">
            <mat-label i18n="@@select-your-region">Select your region</mat-label>
            <div class="login-field">
              <div class="icon-wrapper">
                <span class="material-icons material-symbols-outlined">location_on</span>
              </div>
              <mat-select name="region" formControlName="region" [disabled]="regionDisable" class="region-select">
                <mat-option *ngFor="let region of regionList" [value]="region.value" [attr.data-cy]="region.value"> {{ region.name }}</mat-option>
              </mat-select>
            </div>
            <mat-error *ngIf="formGroup.controls.region.invalid">{{ getRegionErrorMessage() }}</mat-error>
          </mat-form-field>
          <span *ngIf="!register" class="forgot-password-button" (click)="forgotPassword()" i18n="@@forgot-password">Forgot Password?</span>
          <button type="submit" mat-raised-button class="btn" [disabled]="!isValid() || inProgress">
            <div class="spinner-wrapper">
              <mat-spinner diameter="25" *ngIf="inProgress"> </mat-spinner>
            </div>
            {{ getRegisterButtonText() }}
          </button>
        </form>
      </mat-card-content>
    </mat-card>
    <app-forgot-password *ngIf="inForgotPassword"></app-forgot-password>
    <div
      class="more-block"
    >
      <div>
        <span
          style="
            font-size: 12px;
            font-weight: 500;
            letter-spacing: -0.17px;
            text-align: center;
            color: #868ca1;
          "
          >{{ getOpenRegisterText() }}
        </span>
        <span
          style="
            font-size: 12px;
            font-weight: 500;
            letter-spacing: -0.17px;
            text-align: center;
            color: #3f79c4;
            text-decoration: underline;
            cursor: pointer;
          "
          data-cy="sign-up"
          (click)="openRegister()"
          >{{ getOpenRegisterButtonText() }}</span
        >
      </div>
      <app-select-language></app-select-language>
    </div>
  </div>
  <!-- <app-root *ngIf="isDashboardUser"></app-root> -->
</div>
