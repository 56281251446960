import { Injectable } from "@angular/core";
import {
  CaregiverAPI,
  DashboardUser,
  DashboardUsers,
  RolesAPI,
} from "@walabot-mqtt-dashboard/api";

@Injectable({
  providedIn: "root",
})
export class DashboardUsersService {
  public async getDashboardUsers(): Promise<DashboardUsers> {
    return RolesAPI.getDashboardUsers();
  }

  public async updateDashboardUsers(
    users: DashboardUser[]
  ): Promise<DashboardUsers> {
    return RolesAPI.updateUserRoles(users);
  }

  public async addUser(user: Pick<DashboardUser, "email" | "roles">) {
    return RolesAPI.addUser(user);
  }

  public async deleteUser(uid: string) {
    return CaregiverAPI.deleteCaregiver(uid);
  }

  public deleteDashboardUserRoles(email: string, uid?: string) {
    return RolesAPI.deleteDashboardUserRole(email, uid);
  }
}
