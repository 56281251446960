/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UploadEspLogs = {
    /**
     * Upload Esp Logs
     */
    type: UploadEspLogs.type;
};

export namespace UploadEspLogs {

    /**
     * Upload Esp Logs
     */
    export enum type {
        UploadEspLogs = 'UploadEspLogs',
    }


}

