/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DeviceConfigGen2 } from './DeviceConfigGen2';

export type ConfigurationHistoryEntry = {
    timestamp: number;
    configEvent: {
        type: ConfigurationHistoryEntry.type;
        payload: any;
    };
    effectiveConfig: DeviceConfigGen2;
};

export namespace ConfigurationHistoryEntry {

    export enum type {
        WalabotConfig = 1,
        AppConfig = 2,
        FullConfig = 3,
    }


}

