/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateWifiCredentials = {
    /**
     * Update Wifi Credentials
     */
    type: UpdateWifiCredentials.type;
    /**
     * The WiFi SSID
     */
    ssid: string;
    /**
     * The Password for the SSID
     */
    password: string;
};

export namespace UpdateWifiCredentials {

    /**
     * Update Wifi Credentials
     */
    export enum type {
        UpdateWifiCredentials = 'UpdateWifiCredentials',
    }


}

