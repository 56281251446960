/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CallLogEntry } from '../models/CallLogEntry';
import type { StatusCallbackData } from '../models/StatusCallbackData';
import type { TwilioCallInfo } from '../models/TwilioCallInfo';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class VoipAPI {

    /**
     * Generate Voip Token
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static generateVoipToken(
        requestBody?: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/voip/token',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Process Voip Outgoing Call
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static processOutgoingCall(
        requestBody?: TwilioCallInfo,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/voip/outgoing',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Process CareGiver Call
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static processCareGiverCall(
        requestBody?: Record<string, string>,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/voip/queue',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Voip wait
     * @returns string OK
     * @throws ApiError
     */
    public static wait(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/voip/wait',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Handle Status Callback
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static handleStatusCallback(
        requestBody?: StatusCallbackData,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/voip/statusCallback',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get call by id
     * @param callSid Unique identifier of voip call
     * @returns CallLogEntry OK
     * @throws ApiError
     */
    public static getCall(
        callSid: string,
    ): CancelablePromise<CallLogEntry> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voip/call/{callSid}',
            path: {
                'callSid': callSid,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Cancel call by id
     * @param callSid Unique identifier of voip call
     * @returns string OK
     * @throws ApiError
     */
    public static cancelCall(
        callSid: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/voip/call/{callSid}',
            path: {
                'callSid': callSid,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get Call Status
     * @param callSid Unique identifier of voip call
     * @returns CallLogEntry OK
     * @throws ApiError
     */
    public static getCallStatus(
        callSid: string,
    ): CancelablePromise<CallLogEntry> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voip/callStatus',
            query: {
                'callSid': callSid,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Generate Voip Token For Client
     * @param clientId Unique identifier of the client
     * @returns string OK
     * @throws ApiError
     */
    public static generateVoipTokenForClient(
        clientId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voip/generateToken',
            query: {
                'clientId': clientId,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

}
