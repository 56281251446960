/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateSensitivityMap = {
    /**
     * Update Sensitivity Map
     */
    type: UpdateSensitivityMap.type;
};

export namespace UpdateSensitivityMap {

    /**
     * Update Sensitivity Map
     */
    export enum type {
        UpdateSensitivityMap = 'UpdateSensitivityMap',
    }


}

