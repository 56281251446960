/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminPairingRequestPayload } from '../models/AdminPairingRequestPayload';
import type { MassPairing } from '../models/MassPairing';
import type { OnGoingPairing } from '../models/OnGoingPairing';
import type { PairingCode } from '../models/PairingCode';
import type { PairingInvite } from '../models/PairingInvite';
import type { PairingRequest } from '../models/PairingRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PairingAPI {

    /**
     * Get On Going Pair Request
     * @returns OnGoingPairing OK
     * @throws ApiError
     */
    public static getOnGoingPairingRequest(): CancelablePromise<Array<OnGoingPairing>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/pairing',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Send Pairing Invitation
     * @param requestBody
     * @returns PairingCode OK
     * @throws ApiError
     */
    public static sendPairingInvitation(
        requestBody?: PairingInvite,
    ): CancelablePromise<PairingCode> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/pairing',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Admin Pair
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static adminPair(
        requestBody?: AdminPairingRequestPayload,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/pairing/pair',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get Pairing Status
     * @param code
     * @returns PairingRequest OK
     * @throws ApiError
     */
    public static getPairingStatus(
        code: string,
    ): CancelablePromise<PairingRequest> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/pairing/{code}',
            path: {
                'code': code,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Start Pairing
     * @param code
     * @returns PairingCode OK
     * @throws ApiError
     */
    public static startPairing(
        code: string,
    ): CancelablePromise<PairingCode> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/pairing/{code}',
            path: {
                'code': code,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Pair with Pairing Code
     * @param code
     * @returns any OK
     * @throws ApiError
     */
    public static pairWithPairingCode(
        code: string,
    ): CancelablePromise<{
        deviceId: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/pairing/{code}',
            path: {
                'code': code,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Delete Pairing Request
     * @param code
     * @returns any OK
     * @throws ApiError
     */
    public static deletePairingRequest(
        code: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/pairing/{code}',
            path: {
                'code': code,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get Mass Pairing code
     * @param uid
     * @returns MassPairing OK
     * @throws ApiError
     */
    public static getMassPairingCode(
        uid: string,
    ): CancelablePromise<MassPairing> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/pairing/mass/{uid}',
            path: {
                'uid': uid,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Delete Mass Pairing Request
     * @param uid
     * @returns any OK
     * @throws ApiError
     */
    public static deleteMassPairingCode(
        uid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/pairing/mass/{uid}',
            path: {
                'uid': uid,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

}
