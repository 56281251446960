import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { DevicesService } from "../devices.service";
import { MatExpansionPanel } from "@angular/material/expansion";
import { DeviceAdminService } from "../device-admin.service";
import { switchMap } from "rxjs/operators";
import { Observable } from "rxjs";
import {
  RoomType,
  Device,
  ApiError,
  DeviceAdminResponse,
  DeviceStateGen2,
  UpdateWifiCredentials,
} from "@walabot-mqtt-dashboard/api";
import { NotificationService } from "../notification.service";
import { RemoteConfigGen2Component } from "../remote-config-gen2/remote-config-gen2.component";

@Component({
  selector: "app-device-details",
  templateUrl: "./device-details.component.html",
  styleUrls: ["./device-details.component.css"],
})
export class DeviceDetailsComponent implements OnInit {
  _device: DeviceAdminResponse;
  roomType: string;
  packageName: string;
  private detailsMap: Array<{ key: string; val: string | number | Date }>;
  public deviceMetaData;
  public deviceState: DeviceStateGen2;
  today = new Date();
  tomorrow = new Date();
  @ViewChild("remoteConfigPanel") remoteConfigPanel: MatExpansionPanel;
  @ViewChild("remoteConfigGen2") remoteConfigGen2: RemoteConfigGen2Component;

  credentials: UpdateWifiCredentials = {
    type: UpdateWifiCredentials.type.UpdateWifiCredentials,
    ssid: "",
    password: "",
  };
  set device(deviceObservable: Observable<DeviceAdminResponse>) {
    deviceObservable.subscribe((device) => {
      this._device = null;
      if (this.remoteConfigGen2) {
        this.remoteConfigPanel.close();
      }
      if (device) {
        this.detailsMap = [];
        Object.keys(device.deviceId).forEach((key) =>
          this.detailsMap.push({
            key: key,
            val: device.deviceId[key] as string | number | Date,
          })
        );
        Object.keys(device.buildInfo).forEach((key) =>
          this.detailsMap.push({
            key: key,
            val: device.buildInfo[key] as string | number | Date,
          })
        );
        Object.keys(device.currentApkMetaData).forEach((key) =>
          this.detailsMap.push({
            key: key,
            val: device.currentApkMetaData[key] as string | number | Date,
          })
        );
        if (device.data) {
          Object.keys(device.data).forEach((key: string) =>
            this.detailsMap.push({
              key: key,
              val: device.data[key] as string | number | Date,
            })
          );
        }
        console.log(device.creationTimestamp);
        this.detailsMap.push({
          key: "Creation Time",
          val: device.creationTimestamp
            ? new Date(device.creationTimestamp)
            : "",
        });
        console.log(this.detailsMap);
        this._device = device;
        this.packageName = device.currentApkMetaData.packageName;
        this.roomType = device.data ? RoomType[device.data.roomType] : "N/A";
      }
    });
  }

  constructor(
    private route: ActivatedRoute,
    private devicesService: DevicesService,
    private deviceAdmin: DeviceAdminService,
    private notify: NotificationService
  ) {}

  ngOnInit() {
    this.tomorrow.setDate(this.tomorrow.getDate() + 1);
    this.device = this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
        this.devicesService.getDeviceById(params.get("id"))
      )
    );
  }

  private generateDateFromMetadataTime(metadataTime: {
    seconds: string;
    nanos: number;
  }): Date {
    return new Date(+metadataTime.seconds * 1000);
  }

  public getLastMqttTime() {
    this.deviceAdmin
      .getDeviceMetaData(this._device.deviceId.deviceId)
      .then((deviceMetaData: Device) => {
        if (deviceMetaData.lastStateTime) {
          const lastHeartBeat = new Date(
            deviceMetaData.lastHeartbeatTime
              ? this.generateDateFromMetadataTime(
                  deviceMetaData.lastHeartbeatTime
                )
              : 0
          );
          const lastState = this.generateDateFromMetadataTime(
            deviceMetaData.lastStateTime
          );
          if (lastState.getTime() > lastHeartBeat.getTime()) {
            deviceMetaData.lastHeartbeatTime = deviceMetaData.lastStateTime;
          }
        }
        this.deviceMetaData = {
          ...deviceMetaData,
          lastHeartbeatTime: this.generateDateFromMetadataTime(
            deviceMetaData.lastHeartbeatTime
          ).toString(),
        };
      })
      .catch((err) => {
        console.log(err);
      });
  }

  public getLastState() {
    this.deviceState = null;
    this.devicesService
      .getDeviceStatus(this._device.deviceId.deviceId)
      .then((deviceState) => {
        console.log("got device state", JSON.stringify(deviceState));
        this.deviceState = deviceState;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  public loadRemoteConfig() {
    this.remoteConfigGen2.load();
  }

  public updateWifi() {
    this.deviceAdmin
      .updateWifiCredentials(this._device.deviceId.deviceId, this.credentials)
      .then(() =>
        this.notify.showMessage("Successfully update WiFi credentials")
      )
      .catch((err: ApiError) => {
        this.notify.showError("Failed to update WiFi credentials", err);
      });
  }
}
