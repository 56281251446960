/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DeviceRoomPresenceState {
    Unknown = 0,
    Occupied = 1,
    Vacant = 2,
    InBed = 3,
}
