import { Component, OnInit } from "@angular/core";
import { BaseComponent } from "../base-component";
import {
  PresenceIndication,
  FallEvent,
  SensitiveFallEvent,
} from "@walabot-mqtt-dashboard/api";
import { DeviceMonitoringService } from "../ui.module";
import { takeUntil } from "rxjs/operators";
import { DeviceState } from "../models";

@Component({
  selector: "app-raw-data",
  templateUrl: "./raw-data.component.html",
  styleUrls: ["./raw-data.component.css"],
})
export class RawDataComponent extends BaseComponent implements OnInit {
  title = "WalabotMqttDashboard";

  deviceStateMap: {
    deviceId: string;
    state: DeviceState;
  }[];
  devicePresenceMap: {
    [deviceId: string]: {
      presenceIndication: PresenceIndication;
      updateTimestamp: number;
    };
  } = {};
  deviceFallsMap: {
    [deviceId: string]: {
      fallEvent: FallEvent | SensitiveFallEvent;
      updateTimestamp;
    };
  } = {};
  mqttStateStr: string;
  constructor(private mqttService: DeviceMonitoringService) {
    super();
  }

  ngOnInit() {
    // this.mqttService.mqttState.pipe(takeUntil(this.ngUnsubsrcibe)).subscribe(state =>
    // {
    //   this.mqttStateStr = MqttConnectionState[state];
    // });
    this.mqttService.deviceStateMap
      .pipe(takeUntil(this.ngUnsubsrcibe))
      .subscribe((deviceStateMap) => {
        this.deviceStateMap = Object.keys(deviceStateMap).map((key) => ({
          deviceId: key,
          state: deviceStateMap[key].state,
        }));
      });
    this.mqttService.presenceObservable
      .pipe(takeUntil(this.ngUnsubsrcibe))
      .subscribe((event) => {
        if (event) {
          this.devicePresenceMap = event;
        }
      });
    this.mqttService.fallsObservables
      .pipe(takeUntil(this.ngUnsubsrcibe))
      .subscribe((event) => {
        if (event) {
          this.deviceFallsMap = event;
        }
      });
  }
}
