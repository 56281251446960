/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PresenceTransition {
    None = 0,
    ExitBed = 1,
    EnterBed = 2,
    EnterRoom = 3,
    ExitRoom = 4,
}
