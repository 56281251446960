/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Product type: Falling, BedExit, Bathroom or TrackingDemo
 */
export enum ProductType {
    Falling = 'Falling',
    BedExit = 'BedExit',
    Bathroom = 'Bathroom',
    TrackingDemo = 'TrackingDemo',
}
