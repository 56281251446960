/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BedExitStatusImminent } from './BedExitStatusImminent';
import type { DeviceStateModel } from './DeviceStateModel';

export type DeviceBedExitEventImminent = {
    type: DeviceBedExitEventImminent.type;
    payload: {
        bedExitOutput: BedExitStatusImminent;
        deviceTime?: number;
        deviceState?: DeviceStateModel;
    };
};

export namespace DeviceBedExitEventImminent {

    export enum type {
        BedExitImminent = 10,
    }


}

