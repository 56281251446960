/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddUserPayload } from '../models/AddUserPayload';
import type { DashboardUser } from '../models/DashboardUser';
import type { RolesResponse } from '../models/RolesResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RolesAPI {

    /**
     * Get Dashboard Users
     * @returns RolesResponse OK
     * @throws ApiError
     */
    public static getDashboardUsers(): CancelablePromise<RolesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/roles',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Add user
     * @param requestBody
     * @returns RolesResponse OK
     * @throws ApiError
     */
    public static addUser(
        requestBody?: AddUserPayload,
    ): CancelablePromise<RolesResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/roles',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Update User Roles
     * @param requestBody
     * @returns RolesResponse OK
     * @throws ApiError
     */
    public static updateUserRoles(
        requestBody?: Array<DashboardUser>,
    ): CancelablePromise<RolesResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/roles',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Delete User Role
     * @param email
     * @param uid unique identifier for the user
     * @returns any OK
     * @throws ApiError
     */
    public static deleteDashboardUserRole(
        email: string,
        uid?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/roles/{email}',
            path: {
                'email': email,
            },
            query: {
                'uid': uid,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

}
