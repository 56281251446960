import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";

import { DevicesComponent } from "./devices/devices.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatCardModule } from "@angular/material/card";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DeviceDetailsComponent } from "./device-details/device-details.component";
import { MatExpansionModule } from "@angular/material/expansion";
import { EmergencyContactsDetailsComponent } from "./emergency-contacts-details/emergency-contacts-details.component";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatTableModule } from "@angular/material/table";
import { CustomAngular2csvComponent } from "../custom-angular2csv/custom-angular2csv.component";
import { AdminAuthGuard } from "../auth/auth-guard.service";
import { JsonToTextDirective } from "./transformations/json-to-text.directive";
import { TimestampToDateDirective } from "./transformations/timestamp-to-date.directive";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { DashboardUsersComponent } from "./dashboard-users/dashboard-users.component";
import { MatDialogModule } from "@angular/material/dialog";
import { FirebaseTimestampPipe } from "./transformations/firebase-timestamp.pipe";
import { DiagnosticsTimestampPipe } from "./transformations/diagnostics-to-timestamp.pipe";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { MatSliderModule } from "@angular/material/slider";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { CountdownTimerDirective } from "./transformations/countdown-timer.directive";
import { SecondsToTimeCounterPipe } from "./transformations/seconds-to-time-counter.pipe";
import { MatTabsModule } from "@angular/material/tabs";
import { RemoteConfigGen2Component } from "./remote-config-gen2/remote-config-gen2.component";
import {
  CaregiversDetailsComponent,
  PairDeviceDialogComponent,
} from "./caregivers-details/caregivers-details.component";
import { MatChipsModule } from "@angular/material/chips";
import { ScrollingModule } from "@angular/cdk/scrolling";
@NgModule({
  declarations: [
    DevicesComponent,
    DeviceDetailsComponent,
    EmergencyContactsDetailsComponent,
    CustomAngular2csvComponent,
    JsonToTextDirective,
    TimestampToDateDirective,
    DashboardUsersComponent,
    FirebaseTimestampPipe,
    DiagnosticsTimestampPipe,
    CountdownTimerDirective,
    SecondsToTimeCounterPipe,
    RemoteConfigGen2Component,
    PairDeviceDialogComponent,
    CaregiversDetailsComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    MatIconModule,
    MatExpansionModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatCardModule,
    MatSelectModule,
    ReactiveFormsModule,
    BrowserModule,
    MatToolbarModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatListModule,
    MatInputModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatGridListModule,
    MatTableModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    MatDialogModule,
    NgxMaterialTimepickerModule,
    MatTabsModule,
    MatChipsModule,
    ScrollingModule,
  ],
  providers: [AdminAuthGuard],
})
export class AdminDashboardModule {}
