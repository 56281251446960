import { Component, Inject, Input } from "@angular/core";

import { DeviceAdminService } from "../device-admin.service";
import {
  DeviceConfigGen2,
  LogLevel,
  TrackerSubRegion,
  HomeDevice,
  Locale,
  SensorMounting,
  DryContactConfig,
  ApiError,
  BedExitWallSide,
  TelemetryPolicy,
  TelemetryTransport,
  TrackerTargetsDebugPolicy,
} from "@walabot-mqtt-dashboard/api";
import { FormControl, Validators } from "@angular/forms";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { NotificationService } from "../notification.service";
import { camelCaseToTitleCase, FilterKeysBySuffix } from "../utils";
import { Environment } from "../../../models";
import { MatChipInputEvent } from "@angular/material/chips";

const DeviceConfigGen2DummyValue = {} as DeviceConfigGen2;
type TelemetryKeys = FilterKeysBySuffix<
  typeof DeviceConfigGen2DummyValue.walabotConfig,
  "Telemetry"
>;

@Component({
  selector: "app-remote-config-gen2",
  templateUrl: "./remote-config-gen2.component.html",
  styleUrls: ["./remote-config-gen2.component.css"],
})
export class RemoteConfigGen2Component {
  @Input() private device: HomeDevice;
  uploadAppLogsProgress;
  uploadDevLogsProgress;
  rebootDeviceProgress;
  loading;
  remoteConfig: DeviceConfigGen2;
  fallingSensitivity: string;
  logLevelValues = Object.values(LogLevel).filter(
    (val) => typeof val === "number"
  );
  logLevels = LogLevel;
  sensorMountingValues = Object.values(SensorMounting).filter(
    (val) => typeof val === "number"
  );
  bedExitWallSideValues = Object.values(BedExitWallSide);
  sensorMounting = SensorMounting;
  bedExitWallSide = BedExitWallSide;
  telemetryPolicyValues = Object.values(TelemetryPolicy).filter(
    (val) => typeof val === "number"
  );
  telemetryPolicies = TelemetryPolicy;
  telemetryTransportValues = Object.values(TelemetryTransport).filter(
    (val) => typeof val === "number"
  );
  telemetryTransports = TelemetryTransport;
  alpha = new FormControl(0, [Validators.min(0), Validators.max(1)]);
  dryContactModes = DryContactConfig.mode;
  dryContactPolicies = DryContactConfig.policy;
  trackerTargetsDebugPolicies = TrackerTargetsDebugPolicy;
  dryContactModeValues = Object.values(DryContactConfig.mode).filter(
    (val) => typeof val === "number"
  );
  dryContactPolicyValues = Object.values(DryContactConfig.policy).filter(
    (val) => typeof val === "number"
  );
  trackerTargetsDebugPolicyValues = Object.values(
    TrackerTargetsDebugPolicy
  ).filter((val) => typeof val === "number");

  // TODO: Don't hardcode locales
  locales = Object.values(Locale);

  sensitivityLevel = new FormControl(0, [Validators.min(0), Validators.max(1)]);
  thMinEventsForFirstDecision = new FormControl(0, [Validators.min(0)]);
  thNumOfDetectionsInChain = new FormControl(0, [Validators.min(0)]);

  allTelemetryFields: Array<keyof TelemetryKeys> = [
    "enableTrackerTargetTelemetry",
    "enableDoorEventTelemetry",
    "enablePeakTelemetry",
    "enableAboveThPointTelemetry",
    "enableIslandPointTelemetry",
    "enableHeightProfileTelemetry",
    "enableOtfPointTelemetry",
    "enableFallingTelemetry",
    "enableSensitiveFallingTelemetry",
    "enablePresenceTelemetry",
    "enableImageParamsTelemetry",
    "enableInterfererLocHistoryTelemetry",
    "enableMtiParamsTelemetry",
    "enableReferenceTelemetry",
    "enableBedExitTelemetry",
    "enableBedExitStateTelemetry",
    "enableClustersTelemetry",
    "enableSubRegionStateTelemetry",
  ];

  constructor(
    private deviceAdmin: DeviceAdminService,
    private notify: NotificationService,
    @Inject("environment") public environment: { [key: string]: Environment }
  ) {}

  public onSubmitData() {
    console.log("submit " + JSON.stringify(this.remoteConfig));
    const fallSensitivity = parseInt(this.fallingSensitivity, 10);
    if (isNaN(fallSensitivity)) {
      delete this.remoteConfig.walabotConfig.fallingSensitivity;
    } else {
      this.remoteConfig.walabotConfig.fallingSensitivity = fallSensitivity;
    }
    if (this.remoteConfig.walabotConfig.trackerSubRegions) {
      this.remoteConfig.walabotConfig.trackerSubRegions.forEach((item) => {
        if (item.isLowSnr === undefined) {
          item.isLowSnr = true;
        }
        if (item.isDoor === undefined) {
          item.isDoor = false;
        }
        if (item.isHorizontal === undefined) {
          item.isHorizontal = true;
        }
      });
    }
    if (!this.remoteConfig.walabotConfig.trackerSubRegions) {
      this.remoteConfig.walabotConfig.trackerSubRegions = [];
    }

    this.deviceAdmin
      .updateDeviceConfig(this.device.deviceId.deviceId, this.remoteConfig)
      .then(() => this.notify.showMessage("Configuration updated successfully"))
      .catch((err: ApiError) => {
        this.notify.showError("Failed to update configuration ", err);
      });
  }

  public load() {
    this.loading = true;
    this.deviceAdmin
      .getDeviceConfig(this.device.deviceId.deviceId)
      .then((deviceConfig) => {
        const config = deviceConfig;
        if (!config.walabotConfig.trackerSubRegions) {
          config.walabotConfig.trackerSubRegions = [];
        }
        config.walabotConfig.trackerSubRegions.map((trackerSubRegion) => {
          trackerSubRegion.isHorizontal =
            trackerSubRegion.isHorizontal === undefined ||
            trackerSubRegion.isHorizontal === null
              ? true
              : trackerSubRegion.isHorizontal;
          trackerSubRegion.isLowSnr =
            trackerSubRegion.isLowSnr === undefined ||
            trackerSubRegion.isLowSnr === null
              ? true
              : trackerSubRegion.isLowSnr;
          trackerSubRegion.isDoor =
            trackerSubRegion.isDoor === undefined ||
            trackerSubRegion.isDoor === null
              ? false
              : trackerSubRegion.isDoor;
        });
        config.appConfig.trackerTargetsDebugPolicy = config.appConfig
          .trackerTargetsDebugPolicy
          ? config.appConfig.trackerTargetsDebugPolicy
          : (TrackerTargetsDebugPolicy.OFF as unknown as DeviceConfigGen2["appConfig"]["trackerTargetsDebugPolicy"]);
        this.loading = false;
        this.remoteConfig = config;
        this.fallingSensitivity = String(
          this.remoteConfig.walabotConfig.fallingSensitivity
        );
        if (!this.remoteConfig.appConfig.dryContacts) {
          this.remoteConfig.appConfig.dryContacts = {
            primary: {},
            secondary: {},
          };
        }

        if (!this.remoteConfig.appConfig.thMinEventsForFirstDecision) {
          this.remoteConfig.appConfig.thMinEventsForFirstDecision = <number>(
            this.environment.thMinEventsForFirstDecision
          );
        }

        if (!this.remoteConfig.appConfig.thNumOfDetectionsInChain) {
          this.remoteConfig.appConfig.thNumOfDetectionsInChain = <number>(
            this.environment.thNumOfDetectionsInChain
          );
        }

        if (!this.remoteConfig.appConfig.sensitivityLevel) {
          this.remoteConfig.appConfig.sensitivityLevel = <number>(
            this.environment.sensitivityLevel
          );
        }

        if (!this.remoteConfig.appConfig.callingDurationSec) {
          this.remoteConfig.appConfig.callingDurationSec = <number>(
            this.environment.callingDurationSec
          );
        }

        if (!("suspendDuration_sec" in this.remoteConfig.appConfig)) {
          this.remoteConfig.appConfig.suspendDuration_sec = <number>(
            this.environment.suspendDuration_sec
          );
        }

        if (
          !("bedExitPredictionThreshold" in this.remoteConfig.walabotConfig)
        ) {
          this.remoteConfig.walabotConfig.bedExitPredictionThreshold = <number>(
            this.environment.bedExitPredictionThreshold
          );
        }

        if (!("bedExitNFramesToReset" in this.remoteConfig.walabotConfig)) {
          this.remoteConfig.walabotConfig.bedExitNFramesToReset = <number>(
            this.environment.bedExitNFramesToReset
          );
        }

        if (!("minTimeOfTarInFallLoc_sec" in this.remoteConfig.walabotConfig)) {
          this.remoteConfig.walabotConfig.minTimeOfTarInFallLoc_sec = <number>(
            this.environment.minTimeOfTarInFallLoc_sec
          );
        }

        if (!("durationUntilConfirm_sec" in this.remoteConfig.walabotConfig)) {
          this.remoteConfig.walabotConfig.durationUntilConfirm_sec = <number>(
            this.environment.durationUntilConfirm_sec
          );
        }

        this.allTelemetryFields.forEach((field) => {
          if (
            !(field in this.remoteConfig.walabotConfig) &&
            field in this.environment
          ) {
            this.remoteConfig.walabotConfig[field] = <boolean>(
              this.environment[field]
            );
          }
        });

        console.log(JSON.stringify(this.remoteConfig));
      })
      .catch((err: ApiError) => {
        this.loading = false;
        this.notify.showError("Failed to load configuartion", err);
      });
  }

  public uploadAppLogs() {
    this.uploadAppLogsProgress = true;
    this.deviceAdmin
      .uploadAppLogs(this.device.deviceId.deviceId)
      .then(() => {
        this.uploadAppLogsProgress = false;
        this.notify.showMessage("Command sent");
      })
      .catch((err: ApiError) => {
        this.notify.showError("Failed to send command ", err);
        this.uploadAppLogsProgress = false;
      });
  }

  public uploadDevLogs() {
    this.uploadDevLogsProgress = true;
    this.deviceAdmin
      .uploadDevLogs(this.device.deviceId.deviceId)
      .then(() => {
        this.uploadDevLogsProgress = false;
        this.notify.showMessage("Command sent");
      })
      .catch((err: ApiError) => {
        this.uploadDevLogsProgress = false;
        this.notify.showError("Failed to send command ", err);
      });
  }

  public rebootDevice() {
    this.rebootDeviceProgress = true;
    this.deviceAdmin
      .rebootDevice(this.device.deviceId.deviceId)
      .then(() => {
        this.rebootDeviceProgress = false;
        this.notify.showMessage("Command sent");
      })
      .catch((err: ApiError) => {
        this.rebootDeviceProgress = false;
        this.notify.showError("Failed to send command ", err);
      });
  }

  public cancelAlarm() {
    this.deviceAdmin
      .cancelAlarm(this.device.deviceId.deviceId)
      .then(() => {
        this.notify.showMessage("Command sent");
      })
      .catch((err: ApiError) => {
        console.log(err);
        this.notify.showError("Failed to send command ", err);
      });
  }

  public onBuzzerSoundChanged(event: MatCheckboxChange) {
    this.remoteConfig.appConfig.volume = event.checked ? 100 : 0;
  }

  addTrackerSubRegion() {
    const trackerSubRegion: TrackerSubRegion = {
      xMin: 0.0,
      xMax: 1.0,
      yMin: 0.3,
      yMax: 1.0,
      zMin: 0.0,
      zMax: 1.2,
      enterDuration: 120,
      exitDuration: 120,
      isPresenceDetection: true,
      isFallingDetection: false,
      isLowSnr: true,
      isDoor: false,
      isHorizontal: true,
    };
    if (!this.remoteConfig.walabotConfig.trackerSubRegions) {
      this.remoteConfig.walabotConfig.trackerSubRegions = [];
    }
    this.remoteConfig.walabotConfig.trackerSubRegions.push(trackerSubRegion);
    return false;
  }

  removeTrackerSubRegion(element) {
    console.log(element);
    this.remoteConfig.walabotConfig.trackerSubRegions =
      this.remoteConfig.walabotConfig.trackerSubRegions.filter(
        (subRegion) => subRegion !== element
      );
    return false;
  }

  onFallSensitivtySelected() {
    console.log(this.remoteConfig.walabotConfig.fallingSensitivity);
  }

  public isLearningModeEnabled(appConfig: DeviceConfigGen2["appConfig"]) {
    return (
      appConfig.learningModeStartTs > 0 &&
      appConfig.learningModeEndTs > 0 &&
      appConfig.learningModeEndTs > appConfig.learningModeStartTs &&
      appConfig.learningModeEndTs > Date.now()
    );
  }

  public toggleLearningMode(enabled) {
    if (enabled) {
      this.remoteConfig.appConfig.learningModeStartTs = Date.now();
      this.remoteConfig.appConfig.learningModeEndTs =
        Date.now() + <number>this.environment.learningModeDuration;
    } else {
      this.remoteConfig.appConfig.learningModeStartTs = 0;
      this.remoteConfig.appConfig.learningModeEndTs = 0;
    }
  }

  // Get nice human readable string from parameter name, such enableTrackerTargetTelemetry
  getDisplayNameFromParameterName(
    parameterName: string,
    ...exclude: Array<string>
  ) {
    exclude.forEach((stringToExclude) => {
      parameterName = parameterName.replace(stringToExclude, "");
    });

    return camelCaseToTitleCase(parameterName);
  }

  addBLEMACAddress(event: MatChipInputEvent): void {
    const value = (event.value || "").trim();

    // Add our fruit
    if (value) {
      if (!this.remoteConfig.appConfig.bleBeaconMacs) {
        this.remoteConfig.appConfig.bleBeaconMacs = [];
      }
      this.remoteConfig.appConfig.bleBeaconMacs.push(value);
    }

    // Clear the input value
    event.chipInput.clear();
  }

  removeBLEMACAddress(mac: string): void {
    const index = this.remoteConfig.appConfig.bleBeaconMacs.indexOf(mac);

    if (index >= 0) {
      this.remoteConfig.appConfig.bleBeaconMacs.splice(index, 1);
    }
  }
}
