/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeviceAlgoFilesResponseBody } from '../models/DeviceAlgoFilesResponseBody';
import type { GenerateLogsLinkPayload } from '../models/GenerateLogsLinkPayload';
import type { GenerateLogsLinkResponse } from '../models/GenerateLogsLinkResponse';
import type { generateTokenResponse } from '../models/generateTokenResponse';
import type { GeolocationResponseBody } from '../models/GeolocationResponseBody';
import type { RegisterDevice } from '../models/RegisterDevice';
import type { RegistrationResponse } from '../models/RegistrationResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DeviceFacingAPI {

    /**
     * Device Registration
     * Used by Vayyar device to register itself with the backend
     * @param keyVer
     * @param requestBody
     * @returns RegistrationResponse OK
     * @throws ApiError
     */
    public static postDevice(
        keyVer?: string,
        requestBody?: RegisterDevice,
    ): CancelablePromise<RegistrationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/device',
            headers: {
                'keyVer': keyVer,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Download Device Asset
     * @param asset Asset name
     * @returns binary OK
     * @throws ApiError
     */
    public static getDeviceAsset(
        asset: string,
    ): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/device-asset',
            query: {
                'asset': asset,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Create Auth Token
     * Used by Vayyar device to generate token for further API calls
     * @param requestBody
     * @returns generateTokenResponse OK
     * @throws ApiError
     */
    public static postTokenOauth(
        requestBody?: {
            deviceId?: string;
        },
    ): CancelablePromise<generateTokenResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/token/oauth',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Refresh Auth Token
     * Used by Vayyar device to refresh authentication token for further API calls
     * @param deviceId unique identifier for the device
     * @param refreshToken a long-lived token used for authentication and generating a new idToken once it expires.
     * @param requestBody
     * @returns generateTokenResponse OK
     * @throws ApiError
     */
    public static putTokenOauth(
        deviceId?: string,
        refreshToken?: string,
        requestBody?: {
            deviceId?: string;
        },
    ): CancelablePromise<generateTokenResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/token/oauth',
            query: {
                'deviceId': deviceId,
                'refreshToken': refreshToken,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get device logs upload link
     * Get FW load link, to be used by either the device to upload internal logs
     * @param deviceId
     * @param requestBody
     * @returns GenerateLogsLinkResponse OK
     * @throws ApiError
     */
    public static postDiagnostics(
        deviceId: string,
        requestBody?: GenerateLogsLinkPayload,
    ): CancelablePromise<GenerateLogsLinkResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/diagnostics/{deviceId}',
            path: {
                'deviceId': deviceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get device algo files download links
     * @param deviceId
     * @returns DeviceAlgoFilesResponseBody OK
     * @throws ApiError
     */
    public static getAlgo(
        deviceId: string,
    ): CancelablePromise<DeviceAlgoFilesResponseBody> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/algo/{deviceId}',
            path: {
                'deviceId': deviceId,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Download Device sensitivity map
     * @param deviceId
     * @returns binary OK
     * @throws ApiError
     */
    public static getAlgoSensitivityMap(
        deviceId: string,
    ): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/algo/sensitivityMap/{deviceId}',
            path: {
                'deviceId': deviceId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Get device geolocation - used to differentitate between Japan and non-Japan devices
     * @returns GeolocationResponseBody OK
     * @throws ApiError
     */
    public static getLocationGeolocation(): CancelablePromise<GeolocationResponseBody> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/location/geolocation',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

}
