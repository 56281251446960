import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { MatSelect } from "@angular/material/select";
import { takeUntil } from "rxjs/operators";
import { combineLatest } from "rxjs";
import {
  DoorEventStatus,
  EventPayloadType,
  FallEvent,
  FallEventStatus,
  PresenceEventStatus,
  RoomEvent,
  SensitiveFallEventStatus,
  Suite,
} from "@walabot-mqtt-dashboard/api";
import { BaseComponent } from "../base-component";
import {
  ConfigService,
  getDeviceIcon,
  HistoryResult,
  HistoryService,
  MeasurementUnits,
  Query,
  RoomsService,
  SuitesService,
} from "../ui.module";
import { DateTime } from "luxon";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { ExportToCsv } from "export-to-csv";
import { SuiteRoomMapping } from "../suites.service";
import { DatePipe, KeyValue } from "@angular/common";
import { DateTimezoneNamePipe } from "../transformations/date-timzone-name.pipe";
import { fallEventExitReasonMap } from "../fall-event-exit-reason";
import { shouldFallNotificationAppear } from "../models";
import { convertMetersToFeet } from "../configuration/device-settings/device-settings-utils";

interface UIDashboardEvent extends RoomEvent {
  roomIcon: string;
  eventTypeStr: string;
  iconClass: string;
  extra?: string;
  tarHeightEst?: number | string;
  exitReason?: string;
}

enum TimeRange {
  HOURS_8,
  HOURS_24,
  WEEK,
  MONTH,
  CUSTOM,
}

interface DateTimeChange {
  input: Element;
  source: any;
  value: Array<Date>;
}

interface EventType {
  type: EventPayloadType;
  status: FallEventStatus | SensitiveFallEventStatus | PresenceEventStatus;
}

@Component({
  selector: "app-history",
  templateUrl: "./history.component.html",
  styleUrls: ["./history.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class HistoryComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  SelectAllSuites = "-1";
  SelectAllTypes = "-1";
  @ViewChild("dateTimeTrigger", { static: false }) dateTimeTrigger: ElementRef;
  @ViewChild("customTimeOption", { static: false })
  customTimeOption: ElementRef;
  @ViewChild("dateSelector", { static: false }) dateSelector: MatSelect;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild("paginator", { static: false, read: ElementRef })
  paginatorEl: ElementRef<HTMLElement>;
  displayedColumns: string[] = [
    "timestamp",
    "suiteName",
    "roomName",
    "eventTypeStr",
    "deviceId",
  ];
  events: UIDashboardEvent[];
  eventStatusMap: {
    [key in EventPayloadType]?: {
      [key in
        | FallEventStatus
        | SensitiveFallEventStatus
        | PresenceEventStatus
        | DoorEventStatus]?: EventType;
    };
  } = {
    [EventPayloadType.FallEvent]: Object.fromEntries(
      Object.values(FallEventStatus).map((status) => [
        status,
        {
          type: EventPayloadType.FallEvent,
          status,
        },
      ])
    ),
    [EventPayloadType.SensitiveFallEvent]: Object.fromEntries(
      Object.values(SensitiveFallEventStatus).map((status) => [
        status,
        {
          type: EventPayloadType.SensitiveFallEvent,
          status,
        },
      ])
    ),
    [EventPayloadType.PresenceEvent]: Object.fromEntries(
      Object.values(PresenceEventStatus).map((status) => [
        status,
        {
          type: EventPayloadType.PresenceEvent,
          status,
        },
      ])
    ),
  };
  filteredEvents = new MatTableDataSource([]);
  selectedSuites: Array<string> = [];
  selectedTypes: Array<EventType | typeof this.SelectAllTypes>;
  eventTypeSelectMap: Map<EventType, { value: string; iconClass: string }>;
  rooms: Map<string, { name: string; suiteId: string; devices: Set<string> }>;
  suiteMapping: { [suiteId: string]: Suite } = {};
  suiteRoomMapping: SuiteRoomMapping = {};
  selectedRange: TimeRange = TimeRange.HOURS_8;
  timeRanges = [
    {
      name: $localize`:@@range-last-8-hours:Last 8 Hours`,
      value: TimeRange.HOURS_8,
    },
    {
      name: $localize`:@@range-last-24-hours:Last 24 Hours`,
      value: TimeRange.HOURS_24,
    },
    { name: $localize`:@@range-last-week:Last Week`, value: TimeRange.WEEK },
    { name: $localize`:@@range-last-month:Last Month`, value: TimeRange.MONTH },
  ];
  customTimeRange = TimeRange.CUSTOM;
  selectedFrom: number = null;
  selectedTo: number = null;
  isLoading = false;
  historyResult: HistoryResult;
  historyQuery: Query;
  keys = [
    "Timestamp",
    "Time & Date",
    "Suite Name",
    "Room Name",
    "Event Type",
    "Device ID",
  ];
  csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalseparator: ".",
    showLabels: true,
    headers: this.keys,
    showTitle: false,
    useBom: true,
    removeNewLines: true,
    filename: "history-events",
  };
  timeFormat = "HH:mm:ss (UTCZZZZZ)";
  dateFormat = "MMMM dd,yyyy";
  minValidDateTime: Date;
  maxValidDateTime = new Date();
  detailedFallHistory: boolean;
  unit: MeasurementUnits;

  private pageWasChangedByUser = false;

  constructor(
    private roomService: RoomsService,
    private historyService: HistoryService,
    private suitesService: SuitesService,
    private datePipe: DatePipe,
    private dateTimezoneNamePipe: DateTimezoneNamePipe,
    @Inject("shouldFallNotificationAppear")
    private shouldFallNotificationAppear: shouldFallNotificationAppear,
    private configService: ConfigService
  ) {
    super();
    this.configService
      .getDashboardConfig()
      .pipe(takeUntil(this.ngUnsubsrcibe))
      .subscribe((dashboardConfig) => {
        this.unit = dashboardConfig
          ? dashboardConfig.measurementUnits
          : MeasurementUnits.Metric;
      });
    const detailedFallHistory =
      (JSON.parse(localStorage.getItem("detailedFallHistory")) as boolean) ??
      false;
    this.detailedFallHistory = detailedFallHistory;
    if (detailedFallHistory) {
      this.displayedColumns = this.displayedColumns.concat([
        "extra",
        "tarHeightEst",
        "exitReason",
      ]);
      this.keys = this.keys.concat([
        "Location (x y cm)",
        "Target Height Estimation (cm)",
        "Exit Reason",
      ]);
      this.csvOptions.headers = this.keys;
    }
    this.eventTypeSelectMap = new Map<
      EventType,
      { value: string; iconClass: string }
    >(
      [
        [
          this.eventStatusMap[EventPayloadType.FallEvent][
            FallEventStatus.Calling
          ],
          {
            value: $localize`:@@event-fall-detection:Fall Detection`,
            iconClass: "fall-detected-icon",
          },
        ],
        [
          this.eventStatusMap[EventPayloadType.SensitiveFallEvent][
            SensitiveFallEventStatus.Calling
          ],
          {
            value: $localize`:@@sensitive-event-fall-detection:Sensitive Fall Detection (Alarm)`,
            iconClass: "fall-detected-icon",
          },
        ],
        detailedFallHistory && [
          this.eventStatusMap[EventPayloadType.FallEvent][
            FallEventStatus.FallDetected
          ],
          {
            value: $localize`:@@event-fall-detected:Fall Detected`,
            iconClass: "fall-detected-icon",
          },
        ],
        detailedFallHistory && [
          this.eventStatusMap[EventPayloadType.FallEvent][
            FallEventStatus.FallConfirmed
          ],
          {
            value: $localize`:@@event-fall-confirmed:Fall Confirmed`,
            iconClass: "fall-detected-icon",
          },
        ],
        detailedFallHistory && [
          this.eventStatusMap[EventPayloadType.FallEvent][
            FallEventStatus.FallExit
          ],
          {
            value: $localize`:@@event-fall-exit:Fall Exit`,
            iconClass: "fall-detected-icon",
          },
        ],
        detailedFallHistory && [
          this.eventStatusMap[EventPayloadType.FallEvent][
            FallEventStatus.Finished
          ],
          {
            value: $localize`:@@event-fall-finished:Fall Finished`,
            iconClass: "fall-detected-icon",
          },
        ],
        detailedFallHistory && [
          this.eventStatusMap[EventPayloadType.SensitiveFallEvent][
            SensitiveFallEventStatus.FallExit
          ],
          {
            value: $localize`:@@sensitive-event-fall-exit:Sensitive Fall Exit`,
            iconClass: "fall-detected-icon",
          },
        ],
        detailedFallHistory && [
          this.eventStatusMap[EventPayloadType.SensitiveFallEvent][
            SensitiveFallEventStatus.Finished
          ],
          {
            value: $localize`:@@sensitive-event-fall-finished:Sensitive Fall Finished`,
            iconClass: "fall-detected-icon",
          },
        ],
        detailedFallHistory && [
          this.eventStatusMap[EventPayloadType.SensitiveFallEvent][
            SensitiveFallEventStatus.FallSuspected
          ],
          {
            value: $localize`:@@sensitive-event-fall-suspected:Sensitive Fall Suspected`,
            iconClass: "fall-detected-icon",
          },
        ],
        [
          this.eventStatusMap[EventPayloadType.PresenceEvent][
            PresenceEventStatus.Occupied
          ],
          {
            value: $localize`:@@event-occupied:Occupied`,
            iconClass: "occupied-icon",
          },
        ],
        [
          this.eventStatusMap[EventPayloadType.PresenceEvent][
            PresenceEventStatus.Vacant
          ],
          {
            value: $localize`:@@event-vacant:Vacant`,
            iconClass: "vacant-icon",
          },
        ],
        [
          this.eventStatusMap[EventPayloadType.PresenceEvent][
            PresenceEventStatus.InBed
          ],
          {
            value: $localize`:@@event-in-bed:In Bed`,
            iconClass: "occupied-icon",
          },
        ],
        [
          this.eventStatusMap[EventPayloadType.PresenceEvent][
            PresenceEventStatus.OutOfBed
          ],
          {
            value: $localize`:@@event-out-of-bed:Out of Bed`,
            iconClass: "out-icon",
          },
        ],
        [
          this.eventStatusMap[EventPayloadType.PresenceEvent][
            PresenceEventStatus.OutOfRoom
          ],
          {
            value: $localize`:@@event-out-of-room:Out of Room`,
            iconClass: "out-icon",
          },
        ],
      ].filter((e) => e) as Array<
        [EventType, { value: string; iconClass: string }]
      >
    );
    this.selectedTypes = (
      [this.SelectAllTypes] as Array<EventType | string>
    ).concat(...this.eventTypeSelectMap.keys());
  }

  ngOnInit() {
    this.rooms = new Map();
    this.filteredEvents.sort = this.sort;
    this.filteredEvents.paginator = this.paginator;
  }

  ngAfterViewInit() {
    combineLatest([
      this.suitesService.suiteList,
      this.suitesService.roomMapping,
      this.roomService.deviceMapping,
      this.roomService.roomList,
    ])
      .pipe(takeUntil(this.ngUnsubsrcibe))
      .subscribe(
        ([suiteList, suiteRoomMapping, roomDeviceMapping, roomList]) => {
          if (suiteRoomMapping && roomDeviceMapping) {
            this.suiteRoomMapping = suiteRoomMapping;
            const rooms = Object.keys(roomDeviceMapping);
            rooms.forEach((deviceId) => {
              const roomId = roomDeviceMapping[deviceId].roomId;
              if (!this.rooms.has(roomId)) {
                this.rooms.set(roomId, {
                  name: null,
                  suiteId: null,
                  devices: new Set<string>(),
                });
              }
              this.rooms.get(roomId).devices.add(deviceId);
            });
          }
          if (roomList) {
            roomList.forEach((room) => {
              if (!this.rooms.has(room.id)) {
                this.rooms.set(room.id, {
                  name: null,
                  suiteId: null,
                  devices: new Set<string>(),
                });
              }
              const roomWithSuiteId = Object.values(suiteRoomMapping)
                .flat()
                .find((r) => r.id === room.id);
              this.rooms.get(room.id).name = room.name;
              if (roomWithSuiteId) {
                this.rooms.get(room.id).suiteId = roomWithSuiteId.suiteId;
              }
            });
          }
          if (suiteList && Object.keys(suiteRoomMapping).length) {
            suiteList.forEach((suite) => {
              this.suiteMapping[suite.id] = suite;
            });
            if (suiteList.length > 0) {
              const selectedSuites = this.selectedSuites
                ? this.selectedSuites.filter(
                    (val) => val !== this.SelectAllSuites
                  )
                : null;
              if (
                !(selectedSuites && selectedSuites.length === suiteList.length)
              ) {
                this.selectedSuites = [this.SelectAllSuites].concat(
                  suiteList.map((suite) => suite.id)
                );
                this.onTimeRangeChange(this.selectedRange);
              }
            }
          }
        }
      );
  }

  onMultiSelectSelectionOpened(isOpened: boolean) {
    if (!isOpened) {
      this.getEvents();
    }
  }

  downloadCSV() {
    const historyQuery = {
      ...this.historyQuery,
      from: this.selectedFrom,
      to: this.selectedTo,
    };
    delete historyQuery.limit;

    this.isLoading = true;
    this.historyService
      .getEvents(historyQuery)
      .then((historyData) => {
        const events = historyData.events
          .filter((el) => {
            let event: FallEvent;
            switch (el.payloadType) {
              case EventPayloadType.FallEvent:
              case EventPayloadType.SensitiveFallEvent:
                event = <FallEvent>JSON.parse(el.payload);
                return this.shouldFallNotificationAppear(event);
              case EventPayloadType.PresenceEvent:
                return true;
            }
          })
          .map((event) => this.convertToUIEvent(event));
        const filteredEvents = this.filter(events, {
          roomIds: historyQuery.roomIds,
          type: this.selectedTypes,
        });
        const csvData = filteredEvents.map((event: UIDashboardEvent) => {
          const item = {
            Timestamp: event.timestamp,
            "Time & Date":
              (this.dateTimezoneNamePipe.transform(
                event.timestamp,
                this.timeFormat
              ) as string) +
              "\n" +
              this.datePipe.transform(event.timestamp, this.dateFormat),
            "Suite Name": event.suiteName,
            "Room Name": event.roomName,
            "Event Type": event.eventTypeStr,
            "Device ID": event.deviceId,
          };
          if (this.detailedFallHistory) {
            item["Location (x y cm)"] = event.extra ?? "";
            item["Target Height Estimation (cm)"] = event.tarHeightEst ?? "";
            item["Exit Reason"] = event.exitReason ?? "";
          }
          return item;
        });

        const csvExporter = new ExportToCsv(this.csvOptions);
        /**
         * Fixme: workaround for https://github.com/Vayyar/VayyarHomeCloud/issues/2349
         * Wait for https://github.com/alexcaza/export-to-csv/issues/10 to be fixed
         */

        // eslint-disable-next-line
          const _getBody = csvExporter["_getBody"].bind(csvExporter);
        csvExporter["_getBody"] = function () {
          try {
            // eslint-disable-next-line
              _getBody();
          } catch (e) {
            console.warn(e);
          }
        };
        csvExporter.generateCsv(csvData);
        this.isLoading = false;
      })
      .catch(() => {
        this.isLoading = false;
      });
  }

  isMetric() {
    return this.unit === MeasurementUnits.Metric;
  }

  private filter(
    events: UIDashboardEvent[],
    filter: { roomIds?: string[]; type?: Array<EventType | string> }
  ) {
    return events.filter((dashboardEvent) => {
      return (
        filter.roomIds.includes(dashboardEvent.roomId) &&
        (filter.type === null ||
          filter.type === undefined ||
          filter.type.find(
            (type) =>
              typeof type !== "string" &&
              type.type === dashboardEvent.payloadType &&
              type.status === dashboardEvent.status
          ))
      );
    });
  }

  onTimeRangeChange(change: TimeRange) {
    if (change !== TimeRange.CUSTOM) {
      this.updateTimeRanges(change);
      console.log(
        `onTimeRangeChange ${JSON.stringify(new Date(this.selectedFrom))} 
          ${JSON.stringify(new Date(this.selectedTo))}`
      );
      this.getEvents();
    }
  }

  updateTimeRanges(range: TimeRange) {
    this.selectedTo = Date.now();
    switch (range) {
      case TimeRange.HOURS_8:
        this.selectedFrom = DateTime.local().minus({ hours: 8 }).valueOf();
        break;
      case TimeRange.HOURS_24:
        this.selectedFrom = DateTime.local().minus({ hours: 24 }).valueOf();
        break;
      case TimeRange.WEEK:
        this.selectedFrom = DateTime.local().minus({ weeks: 1 }).valueOf();
        break;
      case TimeRange.MONTH:
        this.selectedFrom = DateTime.local().minus({ months: 1 }).valueOf();
        break;
    }
  }

  onSelectedCustomDate(event: DateTimeChange) {
    const [to, from] = event.value;
    this.selectedTo = from.getTime();
    this.selectedFrom = to ? to.getTime() : Date.now();
    console.log("onSelectedCustomDate ", this.selectedTo, this.selectedFrom);
    this.dateSelector.close();
    this.getEvents();
  }

  openCustomTimeRange(event: PointerEvent) {
    event.stopImmediatePropagation();
    this.selectedRange = TimeRange.CUSTOM;
    const location = (
      this.customTimeOption.nativeElement as Element
    ).getBoundingClientRect();
    let rightValue: string;
    if (document.body.dir === "rtl") {
      // Value from owl-date-time component
      const dateTimePickerPopupWidth = "18.5em";
      rightValue = `calc(${location.left}px - ${dateTimePickerPopupWidth})`;
    } else {
      rightValue = String(location.right) + "px";
    }
    document.documentElement.style.setProperty(
      "--date-picker-left",
      rightValue
    );
    document.documentElement.style.setProperty(
      "--date-picker-top",
      String(location.y) + "px"
    );
    const sixMonthAgo = new Date();
    sixMonthAgo.setMonth(sixMonthAgo.getMonth() - 6);
    this.minValidDateTime = sixMonthAgo;
    this.maxValidDateTime = new Date();
    (this.dateTimeTrigger.nativeElement as HTMLElement).click();
  }

  private getEvents() {
    const selectedSuites = this.selectedSuites.filter(
      (val) => val !== this.SelectAllSuites
    );
    this.historyResult = null;
    this.events = [];
    this.filteredEvents.data = [];
    if (selectedSuites.length === 0) {
      return;
    }
    this.isLoading = true;
    const roomIds = selectedSuites
      .map((suiteId) => {
        return (this.suiteRoomMapping[suiteId] || []).map((room) => room.id);
      })
      .flat();
    this.historyQuery = {
      roomIds,
      fallEventStatuses: [],
      sensitiveFallEventStatuses: [],
      presenceEventStatuses: [],
      from: this.selectedFrom,
      to: this.selectedTo,
      limit: this.paginator.pageSize * 2,
    };
    this.selectedTypes
      .filter((type) => type !== this.SelectAllTypes)
      .forEach((type: EventType) => {
        switch (type.type) {
          case EventPayloadType.FallEvent:
            this.historyQuery.fallEventStatuses.push(
              <FallEventStatus>type.status
            );
            break;
          case EventPayloadType.SensitiveFallEvent:
            this.historyQuery.sensitiveFallEventStatuses.push(
              <SensitiveFallEventStatus>type.status
            );
            break;
          case EventPayloadType.PresenceEvent:
            this.historyQuery.presenceEventStatuses.push(
              <PresenceEventStatus>type.status
            );
            break;
        }
      });
    this.historyService
      .getEvents(this.historyQuery)
      .then((historyData) => {
        this.isLoading = false;
        console.log(`Events count: ${historyData.events.length}`);
        historyData.events = historyData.events.filter((el) => {
          let event: FallEvent;
          switch (el.payloadType) {
            case EventPayloadType.FallEvent:
            case EventPayloadType.SensitiveFallEvent:
              event = <FallEvent>JSON.parse(el.payload);
              return this.shouldFallNotificationAppear(event);
            case EventPayloadType.PresenceEvent:
              return true;
          }
        });
        this.historyResult = historyData;
        this.events = historyData.events.map((event) =>
          this.convertToUIEvent(event)
        );
        this.filteredEvents.data = this.filter(this.events, {
          roomIds,
          type: this.selectedTypes,
        });
      })
      .catch((err) => {
        console.warn(
          HistoryComponent.name,
          `Failed to load event ${JSON.stringify(err)}`
        );

        this.isLoading = false;
      });
  }

  private loadMore() {
    this.isLoading = true;
    if (this.historyResult) {
      this.historyQuery = {
        ...this.historyQuery,
        to: this.historyResult.events.at(-1).timestamp - 1,
        from: this.selectedFrom,
        limit: this.paginator.pageSize,
      };
      this.historyService
        .getEvents(this.historyQuery)
        .then((historyData) => {
          this.isLoading = false;
          console.log(`LoadMore Events ${historyData.events.length}`);
          historyData.events = historyData.events.filter((el) => {
            let event: FallEvent;
            switch (el.payloadType) {
              case EventPayloadType.FallEvent:
              case EventPayloadType.SensitiveFallEvent:
                event = <FallEvent>JSON.parse(el.payload);
                return this.shouldFallNotificationAppear(event);
              case EventPayloadType.PresenceEvent:
                return true;
            }
          });
          this.historyResult = historyData;
          this.events = this.events.concat(
            historyData.events.map((event) => this.convertToUIEvent(event))
          );
          this.filteredEvents.data = this.filter(this.events, {
            roomIds: this.historyQuery.roomIds,
            type: this.selectedTypes,
          });
        })
        .catch((err) => {
          console.log(`failed to load more ${JSON.stringify(err)}`);
          this.isLoading = false;
        });
    }
  }

  onTableContentChanged() {
    if (this.pageWasChangedByUser) {
      this.paginatorEl.nativeElement.scrollIntoView();
      this.pageWasChangedByUser = false;
    }
  }

  onPageChanged(event: PageEvent) {
    this.pageWasChangedByUser = true;
    const isMovingForward = event.previousPageIndex < event.pageIndex;
    if (
      isMovingForward &&
      event.length - (event.pageIndex + 1) * event.pageSize < event.pageSize &&
      this.historyResult.events.length
    ) {
      this.loadMore();
    }
  }

  private convertToUIEvent(event: RoomEvent): UIDashboardEvent {
    try {
      const retVal: UIDashboardEvent = Object.assign({}, event, {
        roomIcon: getDeviceIcon(event.deviceRoomType),
        eventTypeStr: this.eventTypeSelectMap.get(
          this.eventStatusMap[event.payloadType][event.status]
        ).value,
        iconClass: this.eventTypeSelectMap.get(
          this.eventStatusMap[event.payloadType][event.status]
        ).iconClass,
        suiteName:
          event.suiteName ??
          this.suiteMapping[this.rooms.get(event.roomId).suiteId]?.name ??
          "",
      });
      const isFall = [
        EventPayloadType.FallEvent,
        EventPayloadType.SensitiveFallEvent,
      ].includes(event.payloadType);

      if (isFall) {
        const fallEvent = JSON.parse(event.payload) as FallEvent;
        if (fallEvent.statusUpdateTimestamp) {
          retVal.timestamp = fallEvent.statusUpdateTimestamp;
        }
        if (this.detailedFallHistory) {
          if (this.isMetric()) {
            retVal.extra = `(x_cm: ${fallEvent.fallLocX_cm}, y_cm: ${fallEvent.fallLocY_cm})`;
            retVal.tarHeightEst = fallEvent.tarHeightEst;
          } else {
            const [xft, xin] = convertMetersToFeet(fallEvent.fallLocX_cm / 100),
              [yft, yin] = convertMetersToFeet(fallEvent.fallLocY_cm / 100);
            retVal.extra = `(x_ft'in": ${xft}'${xin}", y_ft'in": ${yft}'${yin}")`;

            if (fallEvent.tarHeightEst) {
              const [tarft, tarin] = convertMetersToFeet(
                fallEvent.tarHeightEst / 100
              );
              retVal.tarHeightEst = `${tarft}'${tarin}"`;
            }
          }
          retVal.exitReason =
            fallEventExitReasonMap.get(fallEvent.exitReason)?.name ??
            fallEvent?.exitReason?.toString();
        }
      }
      return retVal;
    } catch (e) {
      console.warn("convertToUIEvent Error: ", event, e);
      return {} as UIDashboardEvent;
    }
  }

  toggleSelectAllSuites(event: PointerEvent) {
    event.stopPropagation();
    const selectedSuites = this.selectedSuites.filter(
      (val) => val !== this.SelectAllSuites
    );
    if (selectedSuites.length !== Object.keys(this.suiteMapping).length) {
      this.selectedSuites = Object.keys(this.suiteMapping);
      this.selectedSuites = [this.SelectAllSuites].concat(this.selectedSuites);
    } else {
      this.selectedSuites = [];
    }
  }

  toggleSelectAllTypes(event: PointerEvent) {
    event.stopPropagation();
    const selectedTypes = this.selectedTypes.filter(
      (val) => val !== this.SelectAllTypes
    );
    if (selectedTypes.length !== this.eventTypeSelectMap.size) {
      this.selectedTypes = Array.from(this.eventTypeSelectMap.keys());
      this.selectedTypes = (
        [this.SelectAllTypes] as Array<EventType | string>
      ).concat(this.selectedTypes);
    } else {
      this.selectedTypes = [];
    }
  }

  selectedSuiteChanged(event: Array<string>) {
    if (event[0] === this.SelectAllSuites) {
      if (event.length - 1 !== Object.keys(this.suiteMapping).length) {
        this.selectedSuites = event.filter(
          (val) => val !== this.SelectAllSuites
        );
      }
    } else {
      if (event.length === Object.keys(this.suiteMapping).length) {
        this.selectedSuites = [this.SelectAllSuites].concat(
          this.selectedSuites
        );
      }
    }
  }

  onTypeChanged(event: Array<EventType | string>) {
    if (event[0] === this.SelectAllTypes) {
      if (event.length - 1 !== this.eventTypeSelectMap.size) {
        this.selectedTypes = event.filter((val) => val !== this.SelectAllTypes);
      }
    } else {
      if (event.length === this.eventTypeSelectMap.size) {
        this.selectedTypes = (
          [this.SelectAllTypes] as Array<EventType | string>
        ).concat(...this.eventTypeSelectMap.keys());
      }
    }
  }

  refresh() {
    if (this.selectedRange !== TimeRange.CUSTOM) {
      this.updateTimeRanges(this.selectedRange);
    }
    this.getEvents();
  }

  valueAscOrder = (
    a: KeyValue<string, Suite>,
    b: KeyValue<string, Suite>
  ): number => {
    return a.value.name.localeCompare(b.value.name);
  };
}
