/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum FeedbackClassification {
    TRUE_POSITIVE = 'TRUE_POSITIVE',
    FALSE_POSITIVE = 'FALSE_POSITIVE',
    FALSE_NEGATIVE = 'FALSE_NEGATIVE',
    TEST_FALL = 'TEST_FALL',
    DATA_UNAVAILABLE = 'DATA_UNAVAILABLE',
}
