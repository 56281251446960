/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Deprecated in favor of connectionDetails
 * @deprecated
 */
export type MqttDetails = {
    mqttUrl: string;
    mqttPort: number;
    registryId: string;
    registryRegion: string;
    clientId: string;
    auth: ({
        type: MqttDetails.type;
        userName: string;
        password: string;
    } | {
        type: MqttDetails.type;
        privateKey?: string;
        userName: string;
        keyType: MqttDetails.keyType;
    } | {
        type: MqttDetails.type;
        deviceCert: string;
        devicePrivateKey: string;
        caCert: string;
    } | {
        type: MqttDetails.type;
        deviceSecret: string;
        productKey: string;
    });
};

export namespace MqttDetails {

    export enum type {
        USERPASS = 'userpass',
    }

    export enum keyType {
        RSA256 = 'RSA256',
    }


}

