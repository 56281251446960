import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-password-dialog",
  templateUrl: "./password-dialog.component.html",
  styleUrls: ["./password-dialog.component.css"],
})
export class PasswordDialogComponent implements OnInit {
  match;
  invalid;
  constructor(
    public dialog: MatDialogRef<PasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { match: string }
  ) {
    this.match = data.match;
  }
  ngOnInit() {
    document.documentElement.style.setProperty("--password-color", "#3f51b5");
  }

  onSubmit(password) {
    if (password !== this.match) {
      document.documentElement.style.setProperty("--password-color", "red");
      this.invalid = true;
    } else {
      document.documentElement.style.setProperty("--password-color", "#3f51b5");
      this.invalid = true;
      this.dialog.close({});
    }
  }

  onCanceled() {
    this.dialog.close({ canceled: true });
  }
}
