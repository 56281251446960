<!--The content below is only a placeholder and can be replaced.-->
<div>
  <p>MqttState: {{ mqttStateStr }}</p>
  <div class="deviceList" *ngIf="deviceStateMap.length > 0">
    <mat-card *ngFor="let device of deviceStateMap">
      <mat-card-title>{{ device.deviceId }}</mat-card-title>
      <mat-card-content>
        <p>
          State:
          {{
            device.state.timestamp
              ? (device.state.timestamp | date: "dd/MM/yyyy HH:MM")
              : ""
          }}
          {{ device.state | json }}
        </p>
        <p>Presence: {{ devicePresenceMap[device.deviceId] | json }}</p>
        <p>Fall: {{ deviceFallsMap[device.deviceId] | json }}</p>
      </mat-card-content>
    </mat-card>
  </div>
  <div *ngIf="deviceStateMap.length === 0">Waiting For Devices to Connect</div>
</div>
