import { Injectable } from "@angular/core";
import {
  RoomsAPI,
  RoomEvent,
  FallEventStatus,
  SensitiveFallEventStatus,
  PresenceEventStatus,
  AlertAPI,
  ResolutionType,
  UpdateAlertResolutionRequest,
  OpenAPI,
} from "@walabot-mqtt-dashboard/api";

export interface Query {
  roomIds?: string[];
  from?: number;
  to?: number;
  limit?: number;
  fallEventStatuses?: Array<FallEventStatus>;
  sensitiveFallEventStatuses?: Array<SensitiveFallEventStatus>;
  presenceEventStatuses?: Array<PresenceEventStatus>;
}

export interface AlertQuery {
  deviceId?: string | Array<string>;
  fromCreatedAt?: string;
  toCreatedAt?: string;
  source?: string;
  statuses?: string | Array<string>;
  limit?: number;
  offset?: number;
  resolutionTypes?: string | Array<ResolutionType>;
  resolutionEditedBys?: string | Array<string>;
  updatedResolutionTypes?: string | Array<ResolutionType>;
}

export interface HistoryResult {
  events: RoomEvent[];
}

const MAX_URL_LENGTH = 2048;

@Injectable({
  providedIn: "root",
})
export class HistoryService {
  public async getEvents(historyQuery: Query): Promise<HistoryResult> {
    const {
      roomIds,
      from,
      to,
      limit,
      fallEventStatuses,
      sensitiveFallEventStatuses,
      presenceEventStatuses,
    } = historyQuery;
    const allRoomIds = structuredClone(roomIds);
    const promises = [];
    const urlWithoutRoomIds = `${
      OpenAPI.BASE
    }/rooms/eventsHistory?from=${from}&to=${to}&limit=${limit}&fallEventStatuses=${fallEventStatuses.join(
      "&fallEventStatuses="
    )}&sensitiveFallEventStatuses=${sensitiveFallEventStatuses.join(
      "&sensitiveFallEventStatuses="
    )}&presenceEventStatuses=${presenceEventStatuses.join(
      "&presenceEventStatuses="
    )}&roomIds=`;
    while (allRoomIds.length) {
      const chunkRoomIds: Array<string> = [];
      while (
        allRoomIds.length &&
        (urlWithoutRoomIds + chunkRoomIds.join("&roomIds=")).length <=
          MAX_URL_LENGTH
      ) {
        chunkRoomIds.push(allRoomIds.pop());
      }
      if (chunkRoomIds.length) {
        promises.push(
          RoomsAPI.getRoomsEventsHistory(
            chunkRoomIds,
            from,
            to,
            limit,
            fallEventStatuses,
            sensitiveFallEventStatuses,
            presenceEventStatuses
          )
        );
      }
    }
    return Promise.all(promises).then((result: Array<HistoryResult>) => {
      return {
        events: result
          .map((res) => res.events)
          .flat()
          .sort((a, b) => b.timestamp - a.timestamp),
      };
    });
  }

  public getAlerts(historyQuery: AlertQuery) {
    const {
      deviceId,
      fromCreatedAt,
      toCreatedAt,
      source,
      statuses,
      resolutionTypes,
      resolutionEditedBys,
      updatedResolutionTypes,
      limit,
      offset,
    } = historyQuery;
    return AlertAPI.getAlerts(
      undefined,
      deviceId,
      fromCreatedAt,
      toCreatedAt,
      source,
      undefined,
      undefined,
      statuses,
      undefined,
      undefined,
      resolutionTypes,
      resolutionEditedBys,
      updatedResolutionTypes,
      limit,
      offset
    );
  }

  public updateAlertResolution(
    alertId: string,
    updateAlertResolution: UpdateAlertResolutionRequest
  ) {
    return AlertAPI.updateAlertResolution(alertId, updateAlertResolution);
  }
}
