/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Suspend = {
    type: Suspend.type;
};

export namespace Suspend {

    export enum type {
        Suspend = 'Suspend',
    }


}

