/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DryContactConfig = {
    mode?: DryContactConfig.mode;
    policy?: DryContactConfig.policy;
};

export namespace DryContactConfig {

    export enum mode {
        ActiveLow = 0,
        ActiveHigh = 1,
    }

    export enum policy {
        Off = 0,
        OnFall = 1,
        OutOfBed = 2,
        OnSensitiveFall = 3,
        OnAnyFall = 4,
        OnBedExit = 5,
        OnDoorExit = 6,
    }


}

