/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DashboardConfig = {
    presenceAlertsEnabledFrom?: string;
    presenceAlertsEnabledTo?: string;
    timeZone: string;
    timeZoneOffset: number;
    measurementUnits?: DashboardConfig.measurementUnits;
};

export namespace DashboardConfig {

    export enum measurementUnits {
        Metric = 0,
        Feet = 1,
    }


}

