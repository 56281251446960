/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DashboardEventBase } from './DashboardEventBase';
import type { FallEvent } from './FallEvent';

export type DashboardFallEvent = (DashboardEventBase & {
    type: DashboardFallEvent.type;
    payload: FallEvent;
});

export namespace DashboardFallEvent {

    export enum type {
        FallCalling = 1,
        FallDetected = 2,
        FallConfirmed = 3,
        FallExit = 4,
        FallFinished = 5,
    }


}

