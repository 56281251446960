import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";

@Directive({
  selector: "[appVar]",
})
export class VarDirective {
  @Input()
  set appVar(context: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.context.$implicit = this.context.appVar = context;
    this.updateView();
  }

  context: {
    [key: string]: any;
  } = {
    appVar: null,
    $implicit: null,
  };

  constructor(
    private vcRef: ViewContainerRef,
    private templateRef: TemplateRef<any>
  ) {}

  updateView() {
    this.vcRef.clear();
    this.vcRef.createEmbeddedView(this.templateRef, this.context);
  }
}
