/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChannelFirmware } from '../models/ChannelFirmware';
import type { DeviceChannel } from '../models/DeviceChannel';
import type { FirmwareDownloadLink } from '../models/FirmwareDownloadLink';
import type { OTAApkMetaData } from '../models/OTAApkMetaData';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OtaAPI {

    /**
     * Get OTA Apk Meta Data
     * @param currentVersionName
     * @param currentFlavor
     * @param currentBuildType
     * @param packageName
     * @param currentVersionCode
     * @returns OTAApkMetaData OK
     * @throws ApiError
     */
    public static getOtaApkMetaData(
        currentVersionName?: string,
        currentFlavor?: string,
        currentBuildType?: string,
        packageName?: string,
        currentVersionCode?: string,
    ): CancelablePromise<OTAApkMetaData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ota',
            query: {
                'currentVersionName': currentVersionName,
                'currentFlavor': currentFlavor,
                'currentBuildType': currentBuildType,
                'packageName': packageName,
                'currentVersionCode': currentVersionCode,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Generate Download Link
     * Get FW download link, to be used by either the device itself or other clients
     * @param currentVersionName
     * @returns FirmwareDownloadLink OK
     * @throws ApiError
     */
    public static generateDownloadLink(
        currentVersionName?: string,
    ): CancelablePromise<FirmwareDownloadLink> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ota',
            query: {
                'currentVersionName': currentVersionName,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Update device OTA Mapping
     * @param deviceId
     * @param requestBody OK
     * @returns any OK
     * @throws ApiError
     */
    public static updateDeviceOtaMapping(
        deviceId: string,
        requestBody?: DeviceChannel,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/ota/device/{deviceId}',
            path: {
                'deviceId': deviceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get device OTA Mapping
     * @param deviceId
     * @returns DeviceChannel OK
     * @throws ApiError
     */
    public static getDeviceFirmware(
        deviceId: string,
    ): CancelablePromise<DeviceChannel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ota/device/{deviceId}',
            path: {
                'deviceId': deviceId,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Update OTA Channel Firmware
     * @param requestBody OK
     * @returns any OK
     * @throws ApiError
     */
    public static updateChannelFirmware(
        requestBody?: ChannelFirmware,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/ota/channels',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Upload Device Release
     * @param channel Channel name
     * @param packageName Unique identifier for package name
     * @param filename name of the release file, accepted extensions - .bin or .meta
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static postOtaRelease(
        channel: string,
        packageName: string,
        filename: string,
        requestBody: Blob,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ota/release/{packageName}/{channel}',
            path: {
                'channel': channel,
                'packageName': packageName,
            },
            headers: {
                'filename': filename,
            },
            body: requestBody,
            mediaType: 'application/octet-stream',
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

}
