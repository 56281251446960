import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  AnalyticsService,
  BaseComponent,
  UserService,
  FacilityAnalytics,
  AppUser,
  DeviceMonitoringService,
  DATE_TIME_FORMAT,
  RiskByApartmentComponent,
  SuitesService,
  RoomsService,
} from "../ui.module";
import { takeUntil } from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute } from "@angular/router";
import { combineLatest } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { DateTime, Duration } from "luxon";
import { getAllDevicesInSuite } from "../utils";
import { RoomType } from "@walabot-mqtt-dashboard/api";
import { AveragePresenceData } from "../analytics.service";
import { PotentialFallsComponent } from "../potential-falls/potential-falls.component";
import { Environment, moment } from "../models";
import { string_to_unicode_variant as toUnicodeVariant } from "string-to-unicode-variant";
import { MDCTooltip } from "@material/tooltip";

import * as momentTimezone from "moment-timezone/builds/moment-timezone-with-data-10-year-range.min.js";
import { DecimalPipe } from "@angular/common";
import { NewFallersComponent } from "../new-fallers/new-fallers.component";
import { FallsByShiftPerDayComponent } from "../falls-by-shift-per-day/falls-by-shift-per-day.component";
const moment: moment = momentTimezone as moment;

const FallsNyShiftIcon = {
  "Day Morning": `../assets/icons/sun.svg#icon`,
  "Day PM": `../assets/icons/sunset.svg#icon`,
  Night: `../assets/icons/moon.svg#icon`,
};

@Component({
  selector: "app-insights",
  templateUrl: "./insights.component.html",
  styleUrls: ["./insights.component.css"],
  providers: [DecimalPipe],
})
export class InsightsComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  topRiskResidentsDisplayedColumns: string[] = ["suite", "score", "link"];
  fallsHistoryDisplayedColumns = ["date", "diff", "room", "resolution"];

  analytics: FacilityAnalytics;
  dateTimeFormat = DATE_TIME_FORMAT;
  topFallRiskResidentsTitle = $localize`:@@top-fall-risk-residents:Top Estimated Fall Risk Residents`;
  isAdminDashboardUser: boolean;
  isLoading = false;
  basePath: string;
  user: AppUser["user"];
  suiteId: string;
  suitesNumber: number;
  suiteDevicesNumber: number;
  averagePresenceChartData: AveragePresenceData;
  hasBedroom = false;
  hasBathroom = false;
  hasLivingroom = false;
  hasKitchen = false;
  hasHall = false;
  Date = Date;
  Math = Math;
  unresolvedFallsWarningLevel = 15;
  zoneAbbr: string;
  @ViewChild("topFallRiskResidentsTooltip")
  tooltipElement: ElementRef<HTMLElement>;
  fallsByType: "shift" | "days" = "shift";
  demoUser = $localize`:@@demo-user:Demo User`;
  private mdcTooltip: MDCTooltip;

  get isSuiteView() {
    return <boolean>this.route.snapshot.data.isSuiteView;
  }

  isNumber(val: unknown): boolean {
    return typeof val === "number";
  }

  constructor(
    public deviceMonitoringService: DeviceMonitoringService,
    private analyticsService: AnalyticsService,
    private userService: UserService,
    private suitesService: SuitesService,
    private roomService: RoomsService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private numberPipe: DecimalPipe,
    @Inject("environment") public environment: { [key: string]: Environment }
  ) {
    super();
    const zone = moment.tz.guess();
    this.zoneAbbr = moment.tz(zone).format("z");
  }

  ngOnInit(): void {
    this.basePath = localStorage.getItem("basePath");
    this.userService.currentUser
      .pipe(takeUntil(this.ngUnsubsrcibe))
      .subscribe((user) => {
        this.user = user;
        void this.userService.isDashboardUser().then((isAdminDashboardUser) => {
          this.isAdminDashboardUser = isAdminDashboardUser;
        });
      });

    if (this.isSuiteView) {
      combineLatest([this.route.paramMap, this.deviceMonitoringService.ready])
        .pipe(takeUntil(this.ngUnsubsrcibe))
        .subscribe(([params, ready]) => {
          if (ready && params && params.get("suiteId")) {
            this.suiteId = params.get("suiteId");
            void this.getSuiteDevicesNumber();
            void this.getSuiteAnalytics();
          }
        });
    } else {
      combineLatest([this.suitesService.suiteList])
        .pipe(takeUntil(this.ngUnsubsrcibe))
        .subscribe(([suiteList]) => {
          this.suitesNumber = suiteList?.length ?? 0;
        });
      void this.getFacilityAnalytics();
    }
  }

  ngAfterViewInit() {
    this.mdcTooltip = new MDCTooltip(this.tooltipElement.nativeElement);
    this.mdcTooltip.setShowDelay(0);
    this.mdcTooltip.setHideDelay(300);
  }

  getSuiteDevicesNumber() {
    combineLatest([
      this.suitesService.roomMapping,
      this.roomService.deviceMapping,
      this.deviceMonitoringService.devices,
    ])
      .pipe(takeUntil(this.ngUnsubsrcibe))
      .subscribe(([suiteRoomMapping, roomDeviceMapping, devices]) => {
        if (suiteRoomMapping && roomDeviceMapping) {
          const roomsList: Map<string, { name: string; devices: Set<string> }> =
            new Map();
          const rooms = Object.keys(roomDeviceMapping);
          rooms.forEach((deviceId) => {
            const roomId = roomDeviceMapping[deviceId].roomId;
            if (!roomsList.has(roomId)) {
              roomsList.set(roomId, {
                name: null,
                devices: new Set<string>(),
              });
            }
            roomsList.get(roomId).devices.add(deviceId);
          });

          let suiteDevicesNumber = 0;
          const suiteRooms = suiteRoomMapping[this.suiteId];
          suiteRooms?.forEach((room) => {
            const suiteRoom = roomsList.get(room.id);
            if (suiteRoom) {
              suiteDevicesNumber += suiteRoom.devices.size;
            }
          });
          this.suiteDevicesNumber = suiteDevicesNumber;
        }
      });
  }

  getFacilityAnalytics() {
    this.isLoading = true;
    return this.analyticsService
      .getFacilityAnalytics()
      .then((data) => {
        if (data?.fallsByShift?.grouped.length > 0) {
          data.fallsByShift.grouped.sort((a, b) =>
            a.shift_name.localeCompare(b.shift_name)
          );
        }
        this.analytics = data;
      })
      .catch(console.error)
      .finally(() => {
        this.isLoading = false;
      });
  }

  getSuiteAnalytics() {
    this.isLoading = true;
    return this.analyticsService
      .getSuiteAnalytics(this.suiteId)
      .then((data) => {
        data.fallsHistory?.forEach((row, i, a) => {
          if (a[i + 1]) {
            const diffInMs = DateTime.fromISO(row.fall_time)
                .diff(DateTime.fromISO(a[i + 1].fall_time))
                .toMillis(),
              duration = Duration.fromMillis(diffInMs);
            let diff: string;

            if (diffInMs < 60 * 60 * 1000) {
              diff = $localize`:@@duration-in-minutes:${duration.toFormat(
                "m"
              )}:duration: Minutes`;
            } else if (diffInMs < 24 * 60 * 60 * 1000) {
              diff = $localize`:@@duration-in-hours:${duration.toFormat(
                "h"
              )}:duration: Hours`;
            } else {
              diff = $localize`:@@duration-in-days:${duration.toFormat(
                "d"
              )}:duration: Days`;
            }
            row.diff = diff;
          }
        });
        getAllDevicesInSuite(
          this.deviceMonitoringService.suiteStatusMap[this.suiteId]
        ).forEach((device) => {
          switch (device.roomType) {
            case RoomType.Bedroom:
              this.hasBedroom = true;
              break;
            case RoomType.Bathroom:
              this.hasBathroom = true;
              break;
            case RoomType.LivingRoom:
              this.hasLivingroom = true;
              break;
            case RoomType.Kitchen:
              this.hasKitchen = true;
              break;
            case RoomType.Hall:
              this.hasHall = true;
              break;
          }
        });
        const averagePresenceChartData = {
          labels: [],
          colors: [],
          data: [],
        };
        if (
          this.hasHall &&
          typeof data.averagePresence?.in_apt_entryway === "number"
        ) {
          averagePresenceChartData.labels.push(
            `Apartment Entryway: ${this.formatAveragePresenceLegendValue(
              data.averagePresence?.in_apt_entryway
            )}`
          );
          averagePresenceChartData.colors.push("#007BA9");
          averagePresenceChartData.data.push(
            data.averagePresence?.in_apt_entryway
          );
        }
        if (typeof data.averagePresence?.out_of_apt === "number") {
          averagePresenceChartData.labels.push(
            `Out of Apartment: ${this.formatAveragePresenceLegendValue(
              data.averagePresence?.out_of_apt
            )}`
          );
          averagePresenceChartData.colors.push("#D68730");
          averagePresenceChartData.data.push(data.averagePresence?.out_of_apt);
        }
        if (
          this.hasBedroom &&
          typeof data.averagePresence?.in_bedroom === "number"
        ) {
          averagePresenceChartData.labels.unshift(
            `In Bedroom: ${this.formatAveragePresenceLegendValue(
              data.averagePresence?.in_bedroom
            )}`
          );
          averagePresenceChartData.colors.unshift("#2CAAE2");
          averagePresenceChartData.data.unshift(
            data.averagePresence?.in_bedroom
          );
        }
        if (
          this.hasBathroom &&
          typeof data.averagePresence?.in_bathroom === "number"
        ) {
          averagePresenceChartData.labels.unshift(
            `In Bathroom: ${this.formatAveragePresenceLegendValue(
              data.averagePresence?.in_bathroom
            )}`
          );
          averagePresenceChartData.colors.unshift("#ABA334");
          averagePresenceChartData.data.unshift(
            data.averagePresence.in_bathroom
          );
        }
        if (
          this.hasLivingroom &&
          typeof data.averagePresence.in_livingroom === "number"
        ) {
          averagePresenceChartData.labels.unshift(
            `In Living Room: ${this.formatAveragePresenceLegendValue(
              data.averagePresence.in_livingroom
            )}`
          );
          averagePresenceChartData.colors.unshift("#009D86");
          averagePresenceChartData.data.unshift(
            data.averagePresence.in_livingroom
          );
        }
        if (
          this.hasKitchen &&
          typeof data.averagePresence.in_kitchen === "number"
        ) {
          averagePresenceChartData.labels.unshift(
            `In Kitchen: ${this.formatAveragePresenceLegendValue(
              data.averagePresence.in_kitchen
            )}`
          );
          averagePresenceChartData.colors.unshift("#FCD448");
          averagePresenceChartData.data.unshift(
            data.averagePresence.in_kitchen
          );
        }
        this.averagePresenceChartData = averagePresenceChartData;
        this.analytics = data;
      })
      .catch(console.error)
      .finally(() => {
        this.isLoading = false;
      });
  }

  uploadCSV(e: Event) {
    const file = (<HTMLInputElement>e.target).files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      this.isLoading = true;
      void this.analyticsService
        .uploadFacilityAnalytics(formData)
        .then(() => {
          return (
            this.isSuiteView
              ? this.getSuiteAnalytics()
              : this.getFacilityAnalytics()
          ).then(() => {
            this.snackBar.open("Uploaded successfully", "X", {
              duration: 3000,
              horizontalPosition: "left",
              panelClass: ["success-msg"],
            });
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
      (<HTMLInputElement>e.target).value = "";
    }
  }

  getRiskLevelColor(level_id: number) {
    return (
      "#" +
      this.analytics?.dict.risk_level.find((l) => l.id === level_id)?.color
    );
  }

  showRiskResidents() {
    this.dialog.open(RiskByApartmentComponent, {
      width: "456px",
      height: "700px",
      data: this.analytics,
    });
  }

  showNewFallers() {
    this.dialog.open(NewFallersComponent, {
      width: "456px",
      maxHeight: "700px",
      data: this.analytics.newFallersList ?? [],
    });
  }

  showFallsByShiftPerDay() {
    this.dialog.open(FallsByShiftPerDayComponent, {
      width: "456px",
      maxHeight: "700px",
      data: this.analytics,
    });
  }

  showPotentialFalls() {
    this.dialog.open(PotentialFallsComponent, {
      width: "456px",
      height: "700px",
      data: Object.assign(this.analytics, {
        isSuiteView: this.isSuiteView,
      }),
    });
  }

  getFallsNyShiftIcon(shiftName: string) {
    return FallsNyShiftIcon[shiftName] as string;
  }

  private formatAveragePresenceLegendValue(value: number): string {
    return (<(s: string, f: string) => string>toUnicodeVariant)(
      this.numberPipe.transform(value / 1000 / 60 / 60, "1.0-1"),
      "bold sans"
    );
  }
}
