import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { RoomSizeForm, RoomSizeFromForm } from "../device-settings-interface";
import { SensorMounting, TrackerSubRegion } from "@walabot-mqtt-dashboard/api";
import { FormArray, FormGroup } from "@angular/forms";
import { frontDeviceTexture, backDeviceTexture } from "./deviceTextures";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js";
import * as Roboto_Bold from "../../../../assets/fonts/Roboto_Bold.json";

@Component({
  selector: "app-preview3d",
  templateUrl: "./preview3d.component.html",
  styleUrls: ["./preview3d.component.css"],
})
export class Preview3dComponent implements OnInit, OnChanges {
  @Input() config: Record<string, any>;
  @Input() getSettings: () => {
    deviceSettingsForm: {
      sensorMounting: SensorMounting;
    };
  };
  @Input() getRoomSizeFromForm: () => RoomSizeFromForm;
  @Input() getSubregionsFromForm: () => Array<TrackerSubRegion>;
  @Input() getSensorHeightFromForm: () => number;
  @Input() roomSize: FormGroup<RoomSizeForm>;
  @Input() subRegions: FormArray<FormGroup>;
  @Input() selectedSubRegionIndex;

  @ViewChild("div", { static: true }) div: ElementRef<HTMLDivElement>;

  insideUnitTest = false;
  private scene = new THREE.Scene();
  private renderer: THREE.WebGLRenderer;
  private camera: THREE.Camera;
  private animFrameReqId: number;
  private controls: OrbitControls;
  private sensor: THREE.Group;
  private arenaPlanes: Array<THREE.Mesh> = [];
  private subRegionsObjects = new THREE.Group();
  private labelX: THREE.Mesh;
  private labelY: THREE.Mesh;
  private fontBold = new FontLoader().parse(Roboto_Bold);

  ngOnInit(): void {
    if (!this.insideUnitTest) {
      this.renderer = new THREE.WebGLRenderer({ antialias: true });
      this.camera = new THREE.PerspectiveCamera(
        75,
        this.div.nativeElement.clientWidth /
          this.div.nativeElement.clientHeight,
        0.2,
        20000
      );
      let cameraZ = -0.01;
      if (this.isWallMount()) {
        cameraZ = 5;
      }
      this.camera.position.set(0, 6, cameraZ);
      this.camera.lookAt(0, 0, 0);
      this.scene.background = new THREE.Color("#f2f2f0");
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.shadowMap.enabled = true;
      this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
      this.renderer.setSize(
        this.div.nativeElement.clientWidth,
        this.div.nativeElement.clientHeight
      );
      this.div.nativeElement.appendChild(this.renderer.domElement);
      this.controls = new OrbitControls(this.camera, this.div.nativeElement);
      const updateLabels = () => {
        ["X", "Y"].forEach((axis) => {
          (this[`label${axis}`] as THREE.Mesh).quaternion.copy(
            this.camera.quaternion
          );
        });
      };
      this.controls.addEventListener("change", updateLabels);
      const size = 10;
      const divisions = 10;

      const gridHelper = new THREE.GridHelper(size, divisions);
      this.scene.add(gridHelper);
      this.sensor = this.getSensor();
      this.scene.add(this.sensor);
      const ambientLight = new THREE.AmbientLight(0xffffff, 2);
      this.scene.add(ambientLight);
      this.scene.add(this.getArena());
      this.scene.add(this.subRegionsObjects);
      this.animation();
      this.updateScene();
      this.updateSensor();
      this.updateSubRegions();
      this.addAxes();
      updateLabels();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      this.camera &&
      ("config" in changes || "selectedSubRegionIndex" in changes)
    ) {
      this.updateScene();
      this.updateSensor();
      this.updateSubRegions();
    }
  }

  animation() {
    this.renderer.render(this.scene, this.camera);
    this.animFrameReqId = requestAnimationFrame(this.animation.bind(this));
  }

  private getSensor() {
    const group = new THREE.Group(),
      geometry = new THREE.CylinderGeometry(0.2, 0.2, 0.05),
      material = new THREE.MeshBasicMaterial({
        color: new THREE.Color("white"),
        // side: THREE.FrontSide
      }),
      sensor = new THREE.Mesh(geometry, material);

    const frontTexture = new THREE.TextureLoader().load(frontDeviceTexture),
      backTexture = new THREE.TextureLoader().load(backDeviceTexture);
    frontTexture.center = new THREE.Vector2(0.5, 0.5);
    backTexture.center = new THREE.Vector2(0.5, 0.5);
    frontTexture.rotation = -3.9;
    backTexture.rotation = 3.9;
    backTexture.repeat.set(1.8, 1.8);

    group.position.set(0, 1, 0);

    // Create planes for the top and bottom faces
    const planeGeometry = new THREE.CircleGeometry(0.2);
    const topMaterial = new THREE.MeshBasicMaterial({ map: frontTexture });
    const bottomMaterial = new THREE.MeshBasicMaterial({ map: backTexture });

    const topPlane = new THREE.Mesh(planeGeometry, topMaterial);
    const bottomPlane = new THREE.Mesh(planeGeometry, bottomMaterial);

    // Position the planes
    topPlane.position.set(0, 0.05 / 2 + 0.01, 0); // Adjust as necessary
    bottomPlane.position.set(0, -0.05 / 2 - 0.01, 0); // Adjust as necessary

    topPlane.rotation.x = Math.PI / 2;
    topPlane.rotation.y = Math.PI;
    bottomPlane.rotation.x = Math.PI / 2;

    group.add(sensor, topPlane, bottomPlane);

    return group;
  }

  private getArena() {
    const group = new THREE.Group(),
      planeMaterial = new THREE.MeshBasicMaterial({
        color: 0x3388ff,
        transparent: true,
        opacity: 0.2,
        depthWrite: false,
        side: THREE.FrontSide,
      });

    const planeGeometry = new THREE.BoxGeometry(1, 1, 1);
    const frontPlane = new THREE.Mesh(planeGeometry, planeMaterial);
    const sensorPlane = new THREE.Mesh(planeGeometry, planeMaterial);
    const rightPlane = new THREE.Mesh(planeGeometry, planeMaterial);
    const leftPlane = new THREE.Mesh(planeGeometry, planeMaterial);
    const bottomPlane = new THREE.Mesh(planeGeometry, planeMaterial);
    const topPlane = new THREE.Mesh(planeGeometry, planeMaterial);

    this.arenaPlanes.push(
      frontPlane,
      sensorPlane,
      rightPlane,
      leftPlane,
      bottomPlane,
      topPlane
    );
    group.add(
      frontPlane,
      sensorPlane,
      rightPlane,
      leftPlane,
      bottomPlane,
      topPlane
    );

    return group;
  }

  private updateScene() {
    const roomSizeFromForm = this.getRoomSizeFromForm();
    if (
      ![
        roomSizeFromForm.xMin,
        roomSizeFromForm.xMax,
        roomSizeFromForm.yMin,
        roomSizeFromForm.yMax,
      ].every((v) => typeof v === "number")
    ) {
      return;
    }
    const roomSize = this.convertSizeToThreeJs(roomSizeFromForm),
      { x, z } = this.getPositionMargin(roomSize),
      wallWidth = 0.1,
      scale = [
        Math.abs(roomSize.xMax - roomSize.xMin),
        Math.abs(roomSize.yMax - roomSize.yMin),
        Math.abs(roomSize.zMax - roomSize.zMin),
      ];
    // Front plane
    this.arenaPlanes[0].position.set(
      x,
      roomSize.zMin + scale[2] / 2,
      roomSize.yMax
    );
    this.arenaPlanes[0].scale.set(scale[0], scale[2], wallWidth);
    // Sensor plane
    this.arenaPlanes[1].position.set(
      x,
      roomSize.zMin + scale[2] / 2,
      roomSize.yMin
    );
    this.arenaPlanes[1].scale.set(scale[0], scale[2], wallWidth);
    // Right plane
    this.arenaPlanes[2].position.set(
      roomSize.xMax,
      roomSize.zMin + scale[2] / 2,
      z
    );
    this.arenaPlanes[2].scale.set(wallWidth, scale[2], scale[1]);
    // Left plane
    this.arenaPlanes[3].position.set(
      roomSize.xMin,
      roomSize.zMin + scale[2] / 2,
      z
    );
    this.arenaPlanes[3].scale.set(wallWidth, scale[2], scale[1]);
    // Bottom plane
    this.arenaPlanes[4].position.set(x, roomSize.zMin, z);
    this.arenaPlanes[4].scale.set(scale[0], wallWidth, scale[1]);
    // Top
    this.arenaPlanes[5].position.set(x, roomSize.zMax, z);
    this.arenaPlanes[5].scale.set(scale[0], wallWidth, scale[1]);

    let color = "#3388ff";
    if (this.roomSize.invalid) {
      color = "red";
    }
    (<THREE.MeshBasicMaterial>this.arenaPlanes[0].material).color =
      new THREE.Color(color);
    (<THREE.MeshBasicMaterial>this.arenaPlanes[0].material).needsUpdate = true;

    this.controls.update();
  }

  private updateSensor() {
    switch (this.getSettings().deviceSettingsForm.sensorMounting) {
      case SensorMounting.Wall:
        this.sensor.rotation.x = Math.PI / 2;
        this.sensor.rotation.y = 0.79;
        break;
      case SensorMounting.Tilt45Deg:
        this.sensor.rotation.x = (5 * Math.PI) / 6;
        this.sensor.rotation.y = 0.79;
        break;
      case SensorMounting.Ceiling:
        this.sensor.rotation.z = Math.PI;
        this.sensor.rotation.y = 0.79;
        break;
      case SensorMounting.Ceiling45Deg:
        this.sensor.rotation.z = Math.PI;
        break;
    }
    this.sensor.position.setY(this.getSensorHeightFromForm());
  }

  private updateSubRegions() {
    this.subRegionsObjects.clear();
    const subRegions = this.getSubregionsFromForm();
    subRegions.forEach((subRegion, index) => {
      if (
        ![subRegion.xMin, subRegion.xMax, subRegion.yMin, subRegion.yMax].every(
          (v) => typeof v === "number"
        )
      ) {
        return;
      }
      const planeGeometry = new THREE.BoxGeometry(1, 1, 1);
      let color = this.selectedSubRegionIndex === index ? "yellow" : "#87E11D";
      if (this.subRegions.at(index).invalid) {
        color = "red";
      }
      const planeMaterial = new THREE.MeshBasicMaterial({
        color: color,
        transparent: true,
        opacity: 0.2,
        depthWrite: false,
        side: THREE.FrontSide,
      });
      const subRegionSize = this.convertSizeToThreeJs(subRegion),
        { x, z } = this.getPositionMargin(subRegionSize),
        wallWidth = 0.1,
        scale = [
          Math.abs(subRegionSize.xMax - subRegionSize.xMin),
          Math.abs(subRegionSize.yMax - subRegionSize.yMin),
          Math.abs(subRegionSize.zMax - subRegionSize.zMin),
        ];
      const group = new THREE.Group();
      const frontPlane = new THREE.Mesh(planeGeometry, planeMaterial);
      const sensorPlane = new THREE.Mesh(planeGeometry, planeMaterial);
      const rightPlane = new THREE.Mesh(planeGeometry, planeMaterial);
      const leftPlane = new THREE.Mesh(planeGeometry, planeMaterial);
      const bottomPlane = new THREE.Mesh(planeGeometry, planeMaterial);
      const topPlane = new THREE.Mesh(planeGeometry, planeMaterial);

      const margin = 0.01;
      frontPlane.position.set(
        x,
        subRegionSize.zMin + scale[2] / 2,
        subRegionSize.yMax
      );
      frontPlane.scale.set(
        scale[0] - margin,
        scale[2] - margin,
        wallWidth - margin
      );

      sensorPlane.position.set(
        x,
        subRegionSize.zMin + scale[2] / 2,
        subRegionSize.yMin
      );
      sensorPlane.scale.set(
        scale[0] - margin,
        scale[2] - margin,
        wallWidth - margin
      );

      rightPlane.position.set(
        subRegionSize.xMax,
        subRegionSize.zMin + scale[2] / 2,
        z
      );
      rightPlane.scale.set(
        wallWidth - margin,
        scale[2] - margin,
        scale[1] - margin
      );

      leftPlane.position.set(
        subRegionSize.xMin,
        subRegionSize.zMin + scale[2] / 2,
        z
      );
      leftPlane.scale.set(
        wallWidth - margin,
        scale[2] - margin,
        scale[1] - margin
      );

      bottomPlane.position.set(x, subRegionSize.zMin, z);
      bottomPlane.scale.set(
        scale[0] - margin,
        wallWidth - margin,
        scale[1] - margin
      );

      topPlane.position.set(x, subRegionSize.zMax, z);
      topPlane.scale.set(
        scale[0] - margin,
        wallWidth - margin,
        scale[1] - margin
      );
      group.add(
        frontPlane,
        sensorPlane,
        rightPlane,
        leftPlane,
        bottomPlane,
        topPlane
      );
      this.subRegionsObjects.add(group);
    });
  }

  private getPositionMargin(object: {
    xMin: number;
    xMax: number;
    yMin: number;
    yMax: number;
  }) {
    const x = object.xMax - (object.xMax - object.xMin) / 2,
      z = object.yMax - (object.yMax - object.yMin) / 2;

    return { x, z };
  }

  private convertSizeToThreeJs(size: {
    xMin: number;
    xMax: number;
    yMin: number;
    yMax: number;
    zMin: number;
    zMax: number;
  }) {
    const convertedRoomSize = Object.assign({}, size),
      xMin = convertedRoomSize.xMin;
    convertedRoomSize.xMin = size.xMax * -1;
    convertedRoomSize.xMax = xMin * -1;

    return convertedRoomSize;
  }

  private addAxes() {
    ["X", "Y"].forEach((axis) => {
      const b = this.fontBold.generateShapes(axis, 0.3);
      const shapeGeometry = new THREE.ShapeGeometry(b);
      shapeGeometry.computeBoundingBox();
      shapeGeometry.center();
      const mesh = new THREE.Mesh(
        shapeGeometry,
        new THREE.MeshBasicMaterial({ color: 0x3bb1cd, side: THREE.DoubleSide })
      );
      mesh.position.set(
        ...([axis === "X" ? -0.7 : 0, 0, axis === "Y" ? 0.7 : 0] as [
          number,
          number,
          number
        ])
      );
      this.scene.add(mesh);
      this[`label${axis}`] = mesh;
      (this[`label${axis}`] as THREE.Mesh).lookAt(this.camera.position);
    });
    this.addArrows();
  }

  private addArrows() {
    const origin = new THREE.Vector3(0, 0, 0);
    const length = 2;
    const color = 0x3bb1cd;

    const EVKAxes = [
      [-1, 0, 0],
      [0, 0, 1],
    ];

    EVKAxes.forEach((vector: [number, number, number]) => {
      const dir = new THREE.Vector3(...vector).normalize();
      this.scene.add(
        new THREE.ArrowHelper(dir, origin, length, color, 0.3, 0.2)
      );
    });
  }

  private isWallMount() {
    return [SensorMounting.Wall, SensorMounting.Tilt45Deg].includes(
      this.getSettings().deviceSettingsForm.sensorMounting
    );
  }
}
