<div class="container">
  <div class="dialog-title">
    <span class="unresolved-alerts-number">{{ unresolvedAlertsCount }}</span>
    <span i18n="@@unresolved-alerts">Unresolved Alerts</span>
  </div>
  <div class="dialog-content">
    <p [innerHTML]="unresolvedAlertsPromptText | linebreak"></p>
  </div>
  <div class="buttons">
    <button mat-stroked-button class="save" (click)="onConfirmed()" i18n="@@resolve-now">Resolve Now</button>
    <button mat-stroked-button (click)="onClose()" i18n="@@skip" data-cy="skip-btn">Skip</button>
  </div>
</div>
