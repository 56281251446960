/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DeviceInstallInfo = {
    /**
     * Device ID
     */
    deviceId: string;
    /**
     * Device installation time (ISO 8601 format)
     */
    creationTime: string;
    /**
     * Room name
     */
    roomName?: string;
    /**
     * Room type
     */
    roomType?: DeviceInstallInfo.roomType;
};

export namespace DeviceInstallInfo {

    /**
     * Room type
     */
    export enum roomType {
        BATHROOM = 'Bathroom',
        BEDROOM = 'Bedroom',
        FAMILY_ROOM = 'FamilyRoom',
        HALL = 'Hall',
        KITCHEN = 'Kitchen',
        DINING_ROOM = 'DiningRoom',
        LIVING_ROOM = 'LivingRoom',
        OTHER = 'Other',
    }


}

