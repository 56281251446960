/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UserAction = {
    stepName: UserAction.stepName;
    stepStatus: UserAction.stepStatus;
    timestamp: number;
    payload: any;
};

export namespace UserAction {

    export enum stepName {
        StepRoomEstimation = 'room_estimation',
        StepToiletEstimation = 'toilet_estimation',
        StepWallInstallation = 'wall_installation',
        StepPairing = 'pairing',
        StepUnpair = 'unpair',
        StepFactoryReset = 'factory_reset',
        StepFinishedFirstSetup = 'finished_first_setup',
    }

    export enum stepStatus {
        StatusStarted = 'started',
        StatuedComplete = 'complete',
    }


}

