import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { UserService } from "./user.service";

@Injectable()
export class AdminAuthGuard implements CanActivate {
  constructor(private userService: UserService) {}

  async canActivate() {
    let isDashboardUser = false;
    try {
      isDashboardUser = await this.userService.isDashboardUser();
      console.log(isDashboardUser);
    } catch (err) {
      console.warn(err);
    }
    return isDashboardUser;
    // console.log('AuthGuard#canActivate called');
    // return true;
  }
}
