<h3 i18n="@@falls-by-shift-per-day">Falls by Shift per Day - Last 4 Weeks</h3>
<div  style="width: 100%; height: 100%; overflow: auto;">
    <table mat-table [dataSource]="falls">
        <ng-container matColumnDef="suite">
            <th mat-header-cell *matHeaderCellDef i18n="@@apartment">Apartment</th>
            <td mat-cell *matCellDef="let element"> {{element.suite_name}} </td>
        </ng-container>
        <ng-container matColumnDef="shift">
            <th mat-header-cell *matHeaderCellDef i18n="@@shift">Shift</th>
            <td mat-cell *matCellDef="let element"> {{element.shift_name}} </td>
        </ng-container>
        <ng-container matColumnDef="day">
            <th mat-header-cell *matHeaderCellDef i18n="@@day">Day</th>
            <td mat-cell *matCellDef="let element">
                {{days[element.day_of_week - 1]}}
            </td>
        </ng-container>
        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef i18n="@@time-and-date">Date & Time</th>
            <td mat-cell *matCellDef="let element">
                {{element.fall_time | date: dateTimeFormat}} {{zoneAbbr}}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
<button mat-stroked-button i18n="@@close" class="close" (click)="close()">Close</button>
