import { OwlDateTimeIntl } from "@danielmoncada/angular-datetime-picker";
import { Injectable } from "@angular/core";

@Injectable()
export class DateTimeInl extends OwlDateTimeIntl {
  cancelBtnLabel = $localize`:@@cancel:Cancel`;
  setBtnLabel = $localize`:@@set:Set`;
  rangeFromLabel = $localize`:@@from:From`;
  rangeToLabel = $localize`:@@to:To`;
}
