import { Component, OnInit, ViewChild } from "@angular/core";
import {
  AddEmergencyContactPayload,
  CareGiver,
  CareGiverType,
} from "@walabot-mqtt-dashboard/api";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  ValidationErrors,
  UntypedFormControl,
  FormGroup,
  FormControl,
  FormArray,
} from "@angular/forms";
import { MatTable } from "@angular/material/table";
import { BaseComponent } from "../../base-component";
import { CareGiversService } from "../../care-givers.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthService } from "../../auth.service";

interface CareGiverUIElement {
  // TODO: use form control with index
  index: number;
  original?: CareGiver;
  originalIndex: number;
}

interface ContactForm {
  name: FormControl<string>;
  phoneNumber: FormControl<string>;
}

const { required } = Validators as ValidationErrors;
@Component({
  selector: "app-care-givers",
  templateUrl: "./care-givers.component.html",
  styleUrls: ["./care-givers.component.css"],
})
export class CareGiversComponent extends BaseComponent implements OnInit {
  @ViewChild(MatTable, { static: false })
  careGiversTable: MatTable<any>;
  phoneForm = new UntypedFormGroup({
    phone: new UntypedFormControl(undefined, [required]),
    name: new UntypedFormControl(undefined, [required]),
    surname: new UntypedFormControl(undefined, [required]),
  });

  emergencyContacts: CareGiver[];
  displayedColumns = ["position", "name", "phonenumber", "actions"];
  dataSource: CareGiverUIElement[];
  isInvalidDragEvent = false;
  careGiversForm: FormGroup;
  inProgress = false;

  get careGiversFormArray(): FormArray {
    return this.careGiversForm.get("careGiversFormArray") as FormArray;
  }

  constructor(
    private careGiversService: CareGiversService,
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar
  ) {
    super();
    this.careGiversForm = this.fb.group(
      {
        careGiversFormArray: this.fb.array([]),
      },
      {}
    );
  }

  async ngOnInit() {
    this.inProgress = true;
    await this.load();
    this.inProgress = false;
  }

  private load() {
    return this.careGiversService
      .getEmergencyContacts()
      .then((emergencyContacts) => {
        console.log(emergencyContacts);
        this.emergencyContacts = emergencyContacts.sort(
          (careGiverA, careGiverB) => careGiverA.priority - careGiverB.priority
        );
        this.setDataSource(this.emergencyContacts);
      });
  }

  private setDataSource(emergencyContacts: CareGiver[]) {
    this.careGiversFormArray.clear();
    this.dataSource = emergencyContacts
      .filter((contact) => contact.type !== CareGiverType.Owner)
      .map((careGiver, index) => {
        const formGroup: UntypedFormGroup = this.fb.group({
          position: this.fb.control(index),
          name: this.fb.control(careGiver.name, required as Validators),
          phoneNumber: this.fb.control(
            careGiver.phoneNumber,
            required as Validators
          ),
        });
        formGroup.disable();
        this.careGiversFormArray.push(formGroup);
        return {
          index,
          original: careGiver,
          originalIndex: index,
        };
      });
    this.careGiversForm.markAsPristine();
  }

  reorder(event: CdkDragDrop<string[]>) {
    if (this.isInvalidDragEvent) {
      this.isInvalidDragEvent = false;
      return;
    }
    moveItemInArray(this.dataSource, event.previousIndex, event.currentIndex);
    const currentGroup = this.careGiversFormArray.at(event.previousIndex);
    this.careGiversFormArray.removeAt(event.previousIndex);
    this.careGiversFormArray.insert(event.currentIndex, currentGroup);
    const newDataSource: Array<CareGiverUIElement> = [];
    let isDirty = false;
    for (let i = 0; i < this.careGiversFormArray.length; i++) {
      const element: CareGiverUIElement = this.dataSource[i];
      const originalIndex = element.originalIndex;
      const newElement: CareGiverUIElement = {
        index: i,
        original: element.original,
        originalIndex,
      };
      newDataSource.push(newElement);
      if (newElement.index !== originalIndex) {
        isDirty = true;
      }
    }
    if (isDirty) {
      this.careGiversFormArray.markAsDirty();
    } else {
      this.careGiversFormArray.markAsPristine();
    }
    this.dataSource = newDataSource;
  }

  async finishEdit(index: number, element: CareGiverUIElement) {
    const form: FormGroup<ContactForm> = this.careGiversFormArray.at(
      index
    ) as FormGroup<ContactForm>;
    if (!element.original) {
      const user = this.authService.currentUser.value.user;
      const newCareGiver: AddEmergencyContactPayload = {
        name: form.get("name").value,
        phoneNumber: form.get("phoneNumber").value,
        email: user.email,
      };
      this.inProgress = true;
      try {
        await this.careGiversService.addEmergencyContact(newCareGiver);
        form.disable();
        await this.load();
      } catch (error) {
        console.error("Unable to edit care-giver: ", error);
        this.showErrorMsg(
          $localize`:@@failed-to-add-contact:Failed to add contact`
        );
      }
      this.inProgress = false;
    } else {
      const careGiver: CareGiver = {
        type: element.original.type,
        name: form.get("name").value,
        uid: element.original.uid,
        phoneNumber: form.get("phoneNumber").value,
        // Priority should be the same until pressing the global save which orders the careGivers
        priority: element.original.priority,
      } as CareGiver;
      try {
        await this.careGiversService.updateEmergencyContact(careGiver);
        form.disable();
        await this.load();
      } catch (error) {
        console.error("Unable to edit care-giver: ", error);
        this.showErrorMsg(
          $localize`:@@failed-to-edit-contact:Failed to edit contact`
        );
      }
    }
  }

  addEmergencyContact() {
    const index = this.careGiversFormArray.length;
    const name = "";
    const phoneNumber = "";
    const formGroup: UntypedFormGroup = this.fb.group({
      position: this.fb.control(index),
      name: this.fb.control(name, required as Validators),
      phoneNumber: this.fb.control(phoneNumber, required as Validators),
    });
    this.careGiversFormArray.push(formGroup);
    this.dataSource.push({
      index,
      original: null,
      originalIndex: index,
    });
    this.careGiversTable.renderRows();
  }

  async remove(index: number, element: CareGiverUIElement) {
    this.inProgress = true;
    try {
      await this.careGiversService.removeEmergencyContact(element.original.uid);
      this.inProgress = false;
      return await this.load();
    } catch (error) {
      console.error("Unable to remove care-giver: ", error);
    }
  }

  cancelEdit(index: number, element: CareGiverUIElement) {
    if (!element.original) {
      this.dataSource.splice(index, 1);
      this.careGiversFormArray.removeAt(index);
      this.careGiversTable.renderRows();
    } else {
      this.careGiversFormArray.at(index).disable();
      this.careGiversFormArray.at(index).reset({
        name: element.original.name,
        phoneNumber: element.original.phoneNumber,
      });
    }
  }

  onInvalidDragEventMouseDown() {
    this.isInvalidDragEvent = true;
  }

  dragStarted() {
    if (this.isInvalidDragEvent) {
      document.dispatchEvent(new Event("mouseup"));
    }
  }

  async cancel() {
    this.inProgress = true;
    await this.load();
    this.inProgress = false;
  }

  async save() {
    const careGivers: CareGiver[] = [];
    for (let i = 0; i < this.careGiversFormArray.length; i++) {
      const careGiver: CareGiver = {
        name: (this.careGiversFormArray.at(i) as FormGroup<ContactForm>).get(
          "name"
        ).value,
        phoneNumber: (
          this.careGiversFormArray.at(i) as FormGroup<ContactForm>
        ).get("phoneNumber").value,
        uid: this.dataSource[i].original.uid,
        email: this.dataSource[i].original.email,
        priority: i,
      } as CareGiver;
      careGivers.push(careGiver);
    }
    this.inProgress = true;
    await this.careGiversService.updateCareGivers(careGivers);
    await this.load();
    this.inProgress = false;
  }

  private showErrorMsg(msg: string) {
    this.snackBar.open(msg, "X", {
      duration: 3000,
      horizontalPosition: "left",
      panelClass: ["success-msg", "error"],
    });
  }
}
