import { Directive, ElementRef, Input } from "@angular/core";

type JSONValue =
  | string
  | number
  | boolean
  | { [x: string]: JSONValue }
  | Array<JSONValue>;
@Directive({
  selector: "[appJsonToText]",
})
export class JsonToTextDirective {
  @Input() set appJsonToText(json) {
    const text = this.orderedJsonStringify(json);
    this.el.nativeElement.appendChild(document.createTextNode(text));
  }

  constructor(private el: ElementRef<HTMLElement>) {}

  private sortObjByKey(value: JSONValue | null) {
    if (value === null) {
      return value as null;
    }

    return typeof value === "object"
      ? Array.isArray(value)
        ? value.map((val) => this.sortObjByKey(val) as JSONValue | null)
        : Object.keys(value)
            .sort()
            .reduce((o, key) => {
              const v = value[key];
              o[key] = this.sortObjByKey(v) as JSONValue | null;
              return o;
            }, {})
      : (value as JSONValue | null);
  }

  private orderedJsonStringify(obj) {
    return JSON.stringify(this.sortObjByKey(obj as JSONValue | null));
  }
}
