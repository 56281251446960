/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CancelFall = {
    /**
     * Cancel Fall
     */
    type: CancelFall.type;
};

export namespace CancelFall {

    /**
     * Cancel Fall
     */
    export enum type {
        CancelFall = 'CancelFall',
    }


}

