<form>
  <mat-form-field>
    <input
      #password
      matInput
      placeholder="Password"
      type="password"
      required
      name="password"
    />
  </mat-form-field>
  <div style="display: flex; flex-direction: row">
    <button
      mat-raised-button
      style="flex: 1; margin-right: 1em"
      (click)="onSubmit(password.value)"
    >
      OK
    </button>
    <button mat-raised-button style="flex: 1" (click)="onCanceled()">
      Cancel
    </button>
  </div>
</form>
