/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum EventPayloadType {
    FallEvent = 'fall_event',
    SensitiveFallEvent = 'sensitive_fall_event',
    PresenceEvent = 'presence_event',
    DoorEvent = 'door_event',
    UnknownEvent = 'unknown_event',
}
