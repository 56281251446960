/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type RfProfile = {
    /**
     * RF regulation zone
     */
    rfRegulationZone: RfProfile.rfRegulationZone;
    /**
     * RF transmission bandwidth in MHz
     */
    rfBandWidth: RfProfile.rfBandWidth;
};

export namespace RfProfile {

    /**
     * RF regulation zone
     */
    export enum rfRegulationZone {
        WW = 'WW',
        JP = 'JP',
    }

    /**
     * RF transmission bandwidth in MHz
     */
    export enum rfBandWidth {
        BW500 = 'BW500',
        BW2500 = 'BW2500',
    }


}

