import { Injectable } from "@angular/core";
import {
  CareGiver,
  DeviceStatus,
  UserAction,
  HomeDevice,
  PairedCareGiver,
  DeviceAdminAPI,
  CaregiverAPI,
  PartialCareGiver,
  DeviceStateGen2,
} from "@walabot-mqtt-dashboard/api";
@Injectable({
  providedIn: "root",
})
// TODO: split this monolith to several services
export class DevicesService {
  public async getDevices(fromDeviceId: string, limit: number) {
    const devices = await this.queryDevices({
      fromDeviceId,
      limit,
    });
    return devices;
  }

  public async getDeviceById(deviceId: string): Promise<HomeDevice> {
    const devices = await this.queryDevices({ deviceId });
    return devices[0];
  }

  public async findDeviceByName(value: string): Promise<HomeDevice[]> {
    const devices = await this.queryDevices({
      deviceName: value,
    });
    return devices;
  }

  public async findDeviceBySerial(serial: string): Promise<HomeDevice[]> {
    const remaining = 16 - serial.length;
    let searchVal = serial;
    if (remaining > 0) {
      searchVal = serial.concat("z".repeat(remaining));
    }
    const devices = await this.queryDevices({
      startSerial: serial,
      endSerial: searchVal,
    });
    console.log("found", devices.length, "devices");
    return devices;
  }

  public async findDeviceById(value: string): Promise<HomeDevice[]> {
    let deviceId = value;
    if (value.match("^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$")) {
      deviceId = "id_" + btoa(value).replace("=", "");
      console.log(deviceId);
    }
    const devices = await this.queryDevices({ deviceId });
    return devices;
  }

  public async findDeviceByCareGiverUid(uid: string): Promise<HomeDevice[]> {
    const devices = await this.queryDevices({ caregiverUid: uid });
    return devices;
  }

  public async findDeviceByCareGiverName(name: string): Promise<HomeDevice[]> {
    const devices = await this.queryDevices({ caregiverName: name });
    return devices;
  }

  public async findDeviceByCareGiverNumber(
    phoneNumber: string
  ): Promise<HomeDevice[]> {
    const devices = await this.queryDevices({ caregiverNumber: phoneNumber });
    return devices;
  }

  public async findDeviceIdsByStatus(deviceStatus: DeviceStatus) {
    const devicesState = await DeviceAdminAPI.queryDevicesState(deviceStatus);
    return devicesState.map((state) => {
      return { deviceId: { deviceId: state.deviceId } } as HomeDevice;
    });
  }

  public async findDevicesByCreationDate(
    fromDateTime: Date,
    toDateTime: Date
  ): Promise<HomeDevice[]> {
    const startTimestamp = fromDateTime.getTime();
    const endTimestamp = toDateTime.getTime();

    console.log(
      "getting devices created  ",
      fromDateTime.toUTCString(),
      "until",
      toDateTime.toUTCString()
    );
    const devices = await this.queryDevices({
      startCreationTimestamp: startTimestamp,
      endCreationTimestamp: endTimestamp,
      orderBy: "creationTimestamp",
      direction: "asc",
    });
    console.log("found", devices.length, "devices");
    return devices;
  }

  public async getEmergencyContacts(ownerUid: string): Promise<CareGiver[]> {
    const emergencyContacts =
      await DeviceAdminAPI.getCaregiverEmergencyContacts(ownerUid);
    return emergencyContacts;
  }

  public async getCareGivers(
    deviceId: string
  ): Promise<(CareGiver | PairedCareGiver)[]> {
    console.log("getting care givers");
    const careGivers = await CaregiverAPI.getCareGivers(deviceId);
    return careGivers;
  }

  public async getPairedCareGiver(deviceId: string): Promise<CareGiver> {
    return await CaregiverAPI.getPairedCaregiver(deviceId);
  }

  public updatePairedCareGiver(
    uid: string,
    careGiver: CareGiver
  ): Promise<CareGiver> {
    return CaregiverAPI.updateCaregiver(uid, careGiver);
  }

  public adminUpdateCaregiver(uid: string, careGiver: PartialCareGiver) {
    return CaregiverAPI.adminUpdateCaregiver(uid, careGiver);
  }

  public async getDeviceOffline(thresholdMillis: number): Promise<any[]> {
    const threshold = Date.now() - thresholdMillis;
    const deviceOfflineState = await DeviceAdminAPI.getDeviceOfflineState(
      threshold
    );

    const retVal = deviceOfflineState.map((state) => {
      return {
        id: state.deviceId,
        timestamp: state.timestamp,
        status: state.status,
      };
    });
    return retVal;
  }

  public async getDeviceStatus(deviceId: string): Promise<DeviceStateGen2> {
    const deviceState = await DeviceAdminAPI.getDeviceState(deviceId);
    return deviceState;
  }

  public getUserActionsHistory(): Promise<UserAction[]> {
    return Promise.resolve([]) as Promise<UserAction[]>;
  }

  public getFailedRoomEstimation(): Promise<
    { deviceId: string; id: string; data: UserAction }[]
  > {
    return Promise.resolve([]) as Promise<
      { deviceId: string; id: string; data: UserAction }[]
    >;
  }

  queryDevices(filter: {
    deviceId?: string;
    deviceName?: string;
    startCreationTimestamp?: number;
    endCreationTimestamp?: number;
    startSerial?: string;
    endSerial?: string;
    caregiverUid?: string;
    caregiverName?: string;
    caregiverNumber?: string;
    fromDeviceId?: string;
    orderBy?: string;
    direction?: "desc" | "asc";
    limit?: number;
  }): Promise<HomeDevice[]> {
    return DeviceAdminAPI.queryDevices(
      filter.deviceId,
      filter.deviceName,
      filter.startCreationTimestamp,
      filter.endCreationTimestamp,
      filter.startSerial,
      filter.endSerial,
      filter.caregiverUid,
      filter.caregiverName,
      filter.caregiverNumber,
      filter.fromDeviceId,
      filter.orderBy,
      filter.direction,
      filter.limit
    );
  }
}
