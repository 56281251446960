import { Component, Inject, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { OpenAPI } from "@walabot-mqtt-dashboard/api";
import { UserService } from "../../ui.module";
import { getLatestUserRegion } from "../../auth.service";
import { Environment } from "../../models";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  title: string;
  constructor(
    private router: Router,
    private userService: UserService,
    @Inject("environment") private environment: { [key: string]: Environment }
  ) {
    if (environment.multiRegion) {
      const latestUserRegion = getLatestUserRegion();
      OpenAPI.BASE = environment.apiUrl[latestUserRegion] as string;
    } else {
      OpenAPI.BASE = this.environment.apiUrl as string;
    }
    OpenAPI.TOKEN = this.getToken;
    this.title = this.environment.name as string;
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      console.log(event);
      if (event instanceof NavigationEnd) {
        console.log("navigation ended");
        const route = this.router.routerState.snapshot.root.firstChild;
        this.title = route
          ? (route.data.title as string)
          : (this.environment.name as string);
      }
    });
  }
  private getToken = async () => {
    return await this.userService.getToken();
  };
}
