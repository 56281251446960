<div class="devices-screen">
  <h2>
    Devices
  </h2>
  <div *ngIf="recentDevices">
    <h2>
      Recently views devices
    </h2>
    <mat-list class="devices">
      <mat-list-item *ngFor="let device of recentDevices" [class.selected]="device === selectedDevice"
        (click)="onSelect(device)">
        <!-- <a routerLink="/device/{{device.deviceId}}"> -->
        <span class="badge">{{device.deviceId.deviceId}}</span> {{device.deviceId.androidSerial}}
        <!-- </a> -->
      </mat-list-item>
    </mat-list>
  </div>
  <div class="datepicker_container">
    <div class="datepicker">
      <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #fromDatePicker></mat-datepicker>
      <input matInput #fromDatePickerInput [matDatepicker]="fromDatePicker" placeholder="From Creation Date">
      <input matinput class="timepicker" #fromTimePickerInput placeholder="From time" aria-label="Time"
        [ngxTimepicker]="fromTimePicker" readonly>
      <ngx-material-timepicker #fromTimePicker [enableKeyboardInput]="true"></ngx-material-timepicker>
    </div>
    <div class="datepicker">
      <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #toDatePicker></mat-datepicker>
      <input matInput #toDatePickerInput [matDatepicker]="toDatePicker" placeholder="To Creation Date">
      <input matinput #toTimePickerInput class="timepicker" placeholder="To time" aria-label="Time"
        [ngxTimepicker]="toTimePicker" readonly>
      <ngx-material-timepicker #toTimePicker [enableKeyboardInput]="true"></ngx-material-timepicker>
    </div>
    <button style="height:fit-content;margin-top:auto; margin-bottom:auto;" mat-raised-button
      (click)="searchByCreationDate(fromDatePickerInput.value,toDatePickerInput.value,toTimePickerInput.value)">Search</button>
  </div>
  <mat-form-field>
    <input matInput placeholder="Search By Serial" #searchBySerialBox data-search-by="androidSerial"
      (keyup)="onSearchBySerial($event,searchBySerialBox.value)">
  </mat-form-field>
  <mat-form-field>
    <input margin="10px" matInput placeholder="Search By CareGiver Name" #searchByCareGiver
      (keyup)="onSearchByCareGiverName($event,searchByCareGiver.value)">
  </mat-form-field>
  <mat-form-field>
    <input matInput placeholder="Search By Device Id/Mac" #searchByIdBox data-search-by="id"
      (keyup)="onSearchById($event,searchByIdBox.value)">
  </mat-form-field>
  <mat-form-field>
    <input matInput placeholder="Search By CareGiver UID" #searchByNameBox data-search-by="caregiverUID"
      (keyup)="onSearchByCareGiverUid($event,searchByNameBox.value)">
  </mat-form-field>
  <mat-form-field>
    <input matInput placeholder="Search By phoneNumber" #searchByPhoneBox
      (keyup)="onSearchByPhone($event,searchByPhoneBox.value)">
  </mat-form-field>
  <mat-form-field>
    <mat-label>Search By Status</mat-label>
    <mat-select matNativeControl [(value)]="statusFilter" (selectionChange)="onSearchByStatus(statusFilter)">
      <mat-option *ngFor="let status of deviceStatus" [value]="status">
        {{status}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-list class="devices">
    <mat-list-item *ngFor="let device of devices" [class.selected]="device === selectedDevice"
                   [attr.data-cy]="device.deviceId.deviceId" (click)="onSelect(device)">
      <!-- <a routerLink="/device/{{device.deviceId}}"> -->
      <div class="device">
        <span>{{device.deviceId.deviceId}}</span>
        <span> {{device.deviceId.androidSerial}}</span>
        <span>{{device.creationTimestamp | date: 'dd/MM/yyyy HH:MM'}}</span>
      </div>
      <!-- </a> -->
    </mat-list-item>
  </mat-list>
  <button (click)='loadMore()'>Load More</button>
</div>
