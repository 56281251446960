/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Monitoring = {
    type: Monitoring.type;
};

export namespace Monitoring {

    export enum type {
        Monitoring = 'Monitoring',
    }


}

