import { Injectable } from "@angular/core";
import {
  EmergencyContactsAPI,
  CareGiver,
  CareGiverType,
  AddEmergencyContactPayload,
} from "@walabot-mqtt-dashboard/api";

@Injectable({
  providedIn: "root",
})
export class CareGiversService {
  public async getEmergencyContacts() {
    return EmergencyContactsAPI.getEmergencyContactsById("home");
  }

  public async addEmergencyContact(contact: AddEmergencyContactPayload) {
    return EmergencyContactsAPI.addEmergencyContact("home", contact);
  }

  public async removeEmergencyContact(contactId: string) {
    return EmergencyContactsAPI.removeEmergencyContact("home", contactId);
  }

  public async updateEmergencyContact(contact: CareGiver) {
    const { name, phoneNumber } = contact;
    return EmergencyContactsAPI.updateOneEmergencyContact("home", contact.uid, {
      name,
      phoneNumber,
    });
  }

  public async updateCareGivers(careGivers: CareGiver[]) {
    return EmergencyContactsAPI.updateEmergencyContacts("home", careGivers);
  }
}

export { CareGiver, CareGiverType };
