import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ApiError } from "@walabot-mqtt-dashboard/api";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  constructor(private snackBar: MatSnackBar) {}

  showMessage(message: string) {
    this.snackBar.open(message, null, {
      duration: 2000,
    });
  }

  showError(message: string, err: ApiError) {
    if (err && err.status) {
      if (err.status === 400) {
        message += "Error: Device is not aviailable";
      } else {
        message += ` Error Code: ${err.status}`;
        if (err.message) {
          message += ", " + err.message;
        }
      }
    }
    this.snackBar.open(message, null, {
      duration: 2000,
    });
  }
}
