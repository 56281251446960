/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DoorEvent } from './DoorEvent';

export type DeviceDoorEvent = {
    type: DeviceDoorEvent.type;
    payload: DoorEvent;
};

export namespace DeviceDoorEvent {

    export enum type {
        DoorEvent = 13,
    }


}

