import { Component, Inject, Input } from "@angular/core";
import { DevicesService } from "../devices.service";
import { ApiError, CareGiver } from "@walabot-mqtt-dashboard/api";
import { DeviceAdminService } from "../device-admin.service";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-caregivers-details",
  templateUrl: "./caregivers-details.component.html",
  styleUrls: ["./caregivers-details.component.css"],
})
export class CaregiversDetailsComponent {
  private _deviceId: string;
  pairedCareGiver: CareGiver = {} as CareGiver;
  loadingPairing: boolean;
  loadingUpdating: boolean;

  @Input() set deviceId(deviceId: string) {
    if (this._deviceId !== deviceId) {
      this._deviceId = deviceId;
      void this.getPairedCareGiver(this._deviceId);
    }
  }

  constructor(
    private devicesService: DevicesService,
    private deviceAdminService: DeviceAdminService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  pair() {
    const dialogRef = this.dialog.open(PairDeviceDialogComponent, {
      width: "30%",
      data: { deviceId: this._deviceId },
    });
    dialogRef.afterClosed().subscribe((email: string) => {
      if (email) {
        this.loadingPairing = true;
        this.deviceAdminService
          .pair(this._deviceId, email, this.pairedCareGiver.uid)
          .then(() => {
            this.snackBar.open("Paired Successfully", null, { duration: 3000 });
            this.loadingPairing = false;

            this.getPairedCareGiver(this._deviceId)
              .then(() => (this.loadingPairing = false))
              .catch(() => (this.loadingPairing = false));
          })
          .catch((err: ApiError) => {
            this.loadingPairing = false;
            this.snackBar.open(
              `Failed to pair: ${err.status} ${err.statusText}`,
              null,
              { duration: 3000 }
            );
          });
      }
    });
  }

  updateCaregiver() {
    this.loadingUpdating = true;
    this.devicesService
      .adminUpdateCaregiver(this.pairedCareGiver.uid, {
        voiceEnabled: this.pairedCareGiver.voiceEnabled,
      })
      .then(() => {
        this.snackBar.open("Updated Successfully", null, { duration: 3000 });
      })
      .finally(() => {
        this.loadingUpdating = false;
      });
  }

  private getPairedCareGiver(deviceId: string) {
    return this.devicesService
      .getPairedCareGiver(deviceId)
      .then((pairedCareGiver) => {
        this.pairedCareGiver = pairedCareGiver;
      });
  }
}

@Component({
  selector: "app-pair-device",
  templateUrl: "pair-device.html",
  styleUrls: ["./pair-device.css"],
})
export class PairDeviceDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<PairDeviceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { deviceId: string }
  ) {}

  cancel() {
    this.dialogRef.close();
  }

  save(email: { email: string }): void {
    console.log("SAVE EMAIL");
    this.dialogRef.close(email.email);
  }
}
