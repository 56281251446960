/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DevicePresenceEvent = {
    type: DevicePresenceEvent.type;
    payload: {
        presenceDetected: boolean;
        presenceTargetType: number;
        roomPresenceIndication?: number;
        timestamp: number;
        eventId?: string;
        timestampStr: string;
        deviceId: string;
        extra: string;
        timestampMillis: number;
        trackerTargets: Array<{
            id: number;
            xPosCm: number;
            yPosCm: number;
            zPosCm: number;
            amplitude: number;
            posture: number;
        }>;
        presenceRegionMap: Record<string, number>;
    };
};

export namespace DevicePresenceEvent {

    export enum type {
        Presence = 4,
    }


}

