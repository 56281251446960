/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * VayyarCare LED control
 */
export enum LedPolicy {
    ErrorsOnly = 'ErrorsOnly',
    AllEvents = 'AllEvents',
    Off = 'Off',
}
