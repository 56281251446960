/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FallInferenceRequest } from '../models/FallInferenceRequest';
import type { FallInferenceResponse } from '../models/FallInferenceResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FallInferenceAPI {

    /**
     * Create/Update Fall Inference
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static createOrUpdateFallInference(
        requestBody?: FallInferenceRequest,
    ): CancelablePromise<'OK'> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/inference/sync',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get Inference
     * @param deviceIds The unique identifier of the device
     * @param fromCreatedAt
     * @param toCreatedAt
     * @param fromSourceTime
     * @param toSourceTime
     * @param inferenceType
     * @param limit
     * @param offset
     * @returns FallInferenceResponse OK
     * @throws ApiError
     */
    public static queryFallInferences(
        deviceIds?: (string | Array<string>),
        fromCreatedAt?: string,
        toCreatedAt?: string,
        fromSourceTime?: string,
        toSourceTime?: string,
        inferenceType?: string,
        limit?: number,
        offset?: number,
    ): CancelablePromise<Array<FallInferenceResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/inference/fall',
            query: {
                'deviceIds': deviceIds,
                'fromCreatedAt': fromCreatedAt,
                'toCreatedAt': toCreatedAt,
                'fromSourceTime': fromSourceTime,
                'toSourceTime': toSourceTime,
                'inferenceType': inferenceType,
                'limit': limit,
                'offset': offset,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

}
