<div class="login">
    <div class="logo">
        <object data="../../assets/logo-v.svg" type="image/svg+xml"></object>
    </div>
    <div *ngIf="!isDashboardUser" style="display: flex; flex-direction: column;">
        <span style="margin-left: auto;margin-right: auto;color: #5C6E73;font-size: 24px;font-weight: 400;font-stretch: normal;font-style: normal;" data-cy="login-title">
            {{ getLoginTitle() }}
        </span>
        <!-- <a routerLink="/dashboard" routerLinkActive="active">Heroes</a> -->
        <mat-card class="login-container" *ngIf="!inForgotPassword" style="margin-top: 20px">
            <mat-card-content style="padding: 30px 32px">
                <form class="login-box" #loginForm="ngForm" [formGroup]="formGroup" (ngSubmit)="login()">
                    <mat-form-field *ngIf="register" appearance="outline">
                        <mat-label i18n="@@your-name">Your Name</mat-label>
                        <div class="login-field">
                            <div class="icon-wrapper">
                                <img src="../assets/icons/user-icon.svg" />
                            </div>
                            <input matInput type="text" name="name" formControlName="name" required />
                        </div>
                        <mat-error *ngIf="formGroup.controls.name.hasError('required')">{{ getNameErrorMessage() }}</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label i18n="@@enter-your-email">Enter your email</mat-label>
                        <div class="login-field">
                            <div class="icon-wrapper">
                                <img src="../assets/icons/email-icon.svg" />
                            </div>
                            <input matInput type="text" name="email" formControlName="email" required />
                        </div>
                        <mat-error *ngIf="formGroup.controls.email.invalid">{{ getEmailErrorMessage() }}</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>{{ getPasswordHintText() }}</mat-label>
                        <div class="login-field">
                            <div class="icon-wrapper">
                                <img src="../assets/icons/pass-icon.svg" />
                            </div>
                            <input matInput [type]="showPassword ? 'text' : 'password'" name="password" formControlName="password" required />
                            <div class="eye-icon">
                                <img src="../assets/icons/eye-off.svg" *ngIf="!showPassword" (click)="showPassword = true;"/>
                                <img src="../assets/icons/eye-on.svg" *ngIf="showPassword" (click)="showPassword = false;"/>
                            </div>
                        </div>
                        <mat-error *ngIf="formGroup.controls.password.invalid">{{ getPasswordErrorMessage() }}</mat-error>
                    </mat-form-field>
                    <span *ngIf="register" class="password-requirements">
                        <ng-container i18n="@@password-requirements">Password Requirements</ng-container>
                        <span class="cdk-visually-hidden" #translatedTooltipMessage i18n="@@password-requirements-tooltip">
                            Password must consist of a minimum of 8 characters and contain uppercase, lowercase, digits and non-alphanumeric characters (e.g., !, $, #, %)
                        </span>
                        <mat-icon class="info-icon" [matTooltip]="translatedTooltipMessage.innerHTML" matTooltipClass="tooltip" matTooltipPosition="right">
                            info
                        </mat-icon>
                    </span>
                    <mat-form-field *ngIf="register" appearance="outline" class="re-enter-password">
                        <mat-label i18n="@@re-enter-your-password">Re-enter your Password</mat-label>
                        <div class="login-field">
                            <div class="icon-wrapper">
                                <img src="../assets/icons/pass-icon.svg" />
                            </div>
                            <input matInput [type]="showPassword ? 'text' : 'password'" name="confirmPassword" required formControlName="confirmPassword" [errorStateMatcher]="matcher" />
                            <div class="eye-icon">
                                <img src="../assets/icons/eye-off.svg" *ngIf="!showPassword" (click)="showPassword = true;"/>
                                <img src="../assets/icons/eye-on.svg" *ngIf="showPassword" (click)="showPassword = false;"/>
                            </div>
                        </div>
                        <mat-error *ngIf="formGroup.hasError('confirmPassword') || formGroup.controls.confirmPassword.invalid">
                            {{ getConfirmPasswordErrorMessage() }}
                        </mat-error>
                    </mat-form-field>
                    <span *ngIf="!register" class="forgot-password-button" (click)="forgotPassword()" i18n="@@forgot-password">Forgot Password?</span>
                    <button type="submit" mat-raised-button class="btn" [disabled]="!isValid() || inProgress">
                        <div class="spinner-wrapper">
                            <mat-spinner diameter="25" *ngIf="inProgress"> </mat-spinner>
                        </div>
                        {{ getRegisterButtonText() }}
                    </button>
                </form>
            </mat-card-content>
        </mat-card>
        <app-forgot-password *ngIf="inForgotPassword"></app-forgot-password>
        <div class="more-block">
            <div>
                <span style="font-size: 20px;font-weight: 400;letter-spacing: -0.17px;text-align: center;color: #868ca1;">
                    {{ getOpenRegisterText() }}
                </span>
                <span style="font-size: 20px;font-weight: 400;letter-spacing: -0.17px;text-align: center;color: #3f79c4;cursor: pointer;" data-cy="sign-up" (click)="openRegister()">
                    {{ getOpenRegisterButtonText() }}
                </span>
            </div>
        </div>
    </div>
    <!-- <app-root *ngIf="isDashboardUser"></app-root> -->
</div>