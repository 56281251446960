/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DashboardEventBase } from './DashboardEventBase';
import type { DashboardPresenceEvent } from './DashboardPresenceEvent';

export type DashboardPresenceEventContainer = (DashboardEventBase & {
    type: DashboardPresenceEventContainer.type;
    payload: DashboardPresenceEvent;
});

export namespace DashboardPresenceEventContainer {

    export enum type {
        Presence = 0,
    }


}

