import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "secondsToDuration",
})
export class SecondsToDurationPipe implements PipeTransform {
  transform(seconds: number): string {
    const minutes = seconds / 60;
    if (minutes < 60) {
      return $localize`:@@duration-in-minutes:${Math.round(
        minutes
      )}:duration: Minutes`;
    } else {
      return $localize`:@@duration-in-hours:${Math.round(
        minutes / 60
      )}:duration: Hours`;
    }
  }
}
