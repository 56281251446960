/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum SensitiveFallEventStatus {
    FallSuspected = 'fall_suspected',
    Calling = 'calling',
    Finished = 'finished',
    FallExit = 'fall_exit',
}
