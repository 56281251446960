<h3 *ngIf="!analytics.isSuiteView" i18n="@@potential-falls">Potential Falls</h3>
<h3 *ngIf="analytics.isSuiteView" i18n="@@potential-falls-past-6-months">Potential Falls - Past 6 months</h3>
<span *ngIf="analytics.isSuiteView" style="margin-top: -20px;">
    <b>{{analytics?.potentialFalls.value ?? '-'}}</b>&nbsp;<ng-container i18n="@@in-last-four-weeks">in last 4 weeks</ng-container>
    <span class="delimiter"></span>
    <b>{{analytics?.potentialFallList.length}}</b>&nbsp;<ng-container i18n="@@in-last-six-months">in last 6 months</ng-container>
</span>
<div  style="width: 100%; height: 100%; overflow: auto;">
    <table mat-table [dataSource]="analytics.potentialFallList">
        <ng-container matColumnDef="suite">
            <th mat-header-cell *matHeaderCellDef i18n="@@apartment">Apartment</th>
            <td mat-cell *matCellDef="let element"> {{element.suite_name}} </td>
        </ng-container>
        <ng-container matColumnDef="roomType">
            <th mat-header-cell *matHeaderCellDef i18n="@@room-type">Room Type</th>
            <td mat-cell *matCellDef="let element">
                {{element.room}}
            </td>
        </ng-container>
        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef i18n="@@time-and-date">Date & Time</th>
            <td mat-cell *matCellDef="let element">
                {{element.fall_time | date: dateTimeFormat}} {{zoneAbbr}}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.last]="analytics.isSuiteView && row | isLastInFourWeeks: analytics.potentialFallList: analytics.startTime"></tr>
    </table>
</div>
<button mat-stroked-button i18n="@@close" class="close" (click)="close()">Close</button>
