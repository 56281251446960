export const fallEventExitReasonMap = new Map<number, { name: string }>([
  [0, { name: $localize`:@@fall-event-exit-reason-reset:reset` }],
  [1, { name: $localize`:@@fall-event-exit-reason-enter:enter` }],
  [
    2,
    { name: $localize`:@@fall-event-exit-reason-fallConfirmed:fallConfirmed` },
  ],
  [
    11,
    {
      name: $localize`:@@fall-event-exit-reason-exitBreathingFilter:exitBreathingFilter`,
    },
  ],
  [12, { name: $localize`:@@fall-event-exit-reason-exitStand:exitStand` }],
  [
    13,
    {
      name: $localize`:@@fall-event-exit-reason-exitSitInToilet:exitSitInToilet`,
    },
  ],
  [
    14,
    {
      name: $localize`:@@fall-event-exit-reason-exitPetEntrance:exitPetEntrance`,
    },
  ],
  [
    15,
    {
      name: $localize`:@@fall-event-exit-reason-exitSitStandOverTime:exitSitStandOverTime`,
    },
  ],
  [
    16,
    { name: $localize`:@@fall-event-exit-reason-exitSitInFall:exitSitInFall` },
  ],
  [
    17,
    {
      name: $localize`:@@fall-event-exit-reason-exitNoTransition:exitNoTransition`,
    },
  ],
  [
    18,
    {
      name: $localize`:@@fall-event-exit-reason-exitTrackDuringEclipse:exitTrackDuringEclipse`,
    },
  ],
  [
    19,
    {
      name: $localize`:@@fall-event-exit-reason-exitTooManyTrks:exitTooManyTrks`,
    },
  ],
  [
    20,
    {
      name: $localize`:@@fall-event-exit-reason-notEnterFeDue2Pet:notEnterFeDue2Pet`,
    },
  ],
  [
    21,
    {
      name: $localize`:@@fall-event-exit-reason-enterFallTrans:enterFallTrans`,
    },
  ],
  [
    22,
    { name: $localize`:@@fall-event-exit-reason-enterWithLstf:enterWithLstf` },
  ],
  [
    23,
    {
      name: $localize`:@@fall-event-exit-reason-noEnergyInFallLoc:noEnergyInFallLoc`,
    },
  ],
  [
    24,
    { name: $localize`:@@fall-event-exit-reason-mitigatedFall:mitigatedFall` },
  ],
  [
    25,
    {
      name: $localize`:@@fall-event-exit-reason-exitSitStandOverTime2:exitSitStandOverTime2`,
    },
  ],
  [
    30,
    { name: $localize`:@@fall-event-exit-reason-eclipsedFall:eclipsedFall` },
  ],
  [
    40,
    { name: $localize`:@@fall-event-exit-reason-exitSitInTime:exitSitInTime` },
  ],
  [
    41,
    {
      name: $localize`:@@fall-event-exit-reason-exitExclusionRegion:exitExclusionRegion`,
    },
  ],
]);
