import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  InjectionToken,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  Chart,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  ScatterController,
  ScatterDataPoint,
  Title,
  Tooltip,
} from "chart.js";
import { takeUntil } from "rxjs/operators";
import { BaseComponent } from "../base-component";
import { ConfigService, DeviceMonitoringService } from "../ui.module";
import { ActivatedRoute } from "@angular/router";
import {
  NgResizeObserver,
  ngResizeObserverProviders,
} from "ng-resize-observer";
import { combineLatest } from "rxjs";
import {
  DeviceConfigGen2,
  SensorMounting,
  TrackerSubRegion,
} from "@walabot-mqtt-dashboard/api";
import { DeviceOfflineStatus } from "../models";

interface TrackerTarget {
  xPosCm: number;
  yPosCm: number;
  zPosCm: number;
  amplitude: number;
  id: number;
  posture?: number;
}

const formatData = (value) =>
  `${(parseInt(value as string, 10) / 100).toFixed(1)} M`;

export const EXTRA_DEVICE_ID = new InjectionToken<object>("CONTAINER_DATA");
const SENSOR_COLOR = "rgba(90, 243, 164)";
const MONITORING_AREA_COLOR = "rgba(166, 218, 255, 0.38)";
const SUB_REGION_COLOR = "rgba(0, 110, 249, 0.4)";

@Component({
  selector: "app-targets",
  templateUrl: "./targets.component.html",
  styleUrls: ["./targets.component.css"],
  providers: [...ngResizeObserverProviders],
})
export class TargetsComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @Output() closePanel = new EventEmitter<void>();

  //    UNKNOWN, STANDING, SITTING, LAYING, FALLING
  private imageUrl = [
    { url: "../../assets/targets/standing_man.svg", aspectRatio: 63 / 148 },
    { url: "../../assets/targets/standing_man.svg", aspectRatio: 63 / 148 },
    { url: "../../assets/targets/sitting_man.svg", aspectRatio: 72 / 118 },
    { url: "../../assets/targets/lie_down.svg", aspectRatio: 173 / 42 },
    { url: "../../assets/targets/fall_icon.svg", aspectRatio: 119 / 128 },
  ];
  private images = this.imageUrl.map((res) => {
    const image = new Image();
    image.src = res.url;
    return image;
  });

  private ctx: CanvasRenderingContext2D;
  @ViewChild("rootContainer")
  private rootContainer: ElementRef<HTMLElement>;
  @ViewChild("targetViewCanvas", { static: false })
  private canvas: ElementRef<HTMLCanvasElement>;
  private targetsView: Chart;
  _deviceId: string;
  loading = false;
  deviceName: string;
  roomName: string;
  config: DeviceConfigGen2;
  sensorColor = SENSOR_COLOR;
  monitoringAreaColor = MONITORING_AREA_COLOR;
  subRegionColor = SUB_REGION_COLOR;

  private set device(deviceId: string) {
    this.loading = true;
    this._deviceId = deviceId;
    this.configService
      .getConfig(deviceId)
      .then((config) => {
        this.config = config;
        this.loading = false;
        const roomSizes: [number, number, number, number] = [
          "xMin",
          "xMax",
          "yMin",
          "yMax",
        ].map((value) => {
          return config.walabotConfig[value] * 100;
        }) as [number, number, number, number];
        this.initTargetsView(
          ...roomSizes,
          config.walabotConfig.trackerSubRegions
        );
        combineLatest([
          this.deviceMonitoring.presenceObservable,
          this.deviceMonitoring.deviceStateMap,
        ])
          .pipe(takeUntil(this.ngUnsubsrcibe))
          .subscribe(([presenceMap, stateMap]) => {
            const presenceEvent = presenceMap[this._deviceId];
            const stateEvent = stateMap ? stateMap[this._deviceId] : null;
            const state = stateEvent
              ? stateEvent.state.status
              : DeviceOfflineStatus.DISCONNECTED;
            if (
              presenceEvent &&
              presenceEvent.presenceIndication.trackerTargets &&
              state !== DeviceOfflineStatus.DISCONNECTED
            ) {
              this.setTargets(presenceEvent.presenceIndication.trackerTargets);
            }
          });
      })
      .catch((error) => console.error("Unable to get config: ", error));
  }

  constructor(
    private deviceMonitoring: DeviceMonitoringService,
    private configService: ConfigService,
    private route: ActivatedRoute,
    private resizeObserver: NgResizeObserver,
    @Inject(EXTRA_DEVICE_ID)
    private extras: { deviceId: string; roomName: string; deviceName: string }
  ) {
    super();
  }

  ngOnInit(): void {
    Chart.register(
      LineController,
      LineElement,
      ScatterController,
      PointElement,
      LinearScale,
      Title,
      Tooltip
    );
    if (this.route.paramMap) {
      this.route.paramMap.subscribe((params) => {
        if (params && params.get("id")) {
          this.device = params.get("id");
        }
      });
      if (this.extras) {
        this.device = this.extras.deviceId ? this.extras.deviceId : "";
        this.deviceName = this.extras.deviceName ? this.extras.deviceName : "";
        this.roomName = this.extras.roomName ? this.extras.roomName : "";
      }
    }
  }

  private initTargetsView(
    xMinCm: number,
    xMaxCm: number,
    yMinCm: number,
    yMaxCm: number,
    subRegionList?: TrackerSubRegion[]
  ) {
    if (subRegionList) {
      subRegionList.forEach((item: TrackerSubRegion) => {
        item.xMin = item.xMin * 100;
        item.yMin = item.yMin * 100;

        item.xMax = item.xMax * 100;
        item.yMax = item.yMax * 100;
      });
    }
    const xTickSize = 50;
    const yTickSize = 50;
    const aspectRatio = (xMaxCm - xMinCm) / (yMaxCm - yMinCm);
    if (this.resizeObserver) {
      this.resizeObserver
        .pipe(takeUntil(this.ngUnsubsrcibe))
        .subscribe((event) => {
          if (event.target.localName === "app-targets") {
            let width = parseInt(
              (event.target.firstElementChild as HTMLElement).style.width,
              10
            );
            let height = parseInt(
              (event.target.firstElementChild as HTMLElement).style.height,
              10
            );
            if (isNaN(width) || isNaN(height)) {
              width = event.contentRect.width;
              height = event.contentRect.height;
            }

            if (aspectRatio > 1) {
              height = width / aspectRatio;
            } else {
              width = height * aspectRatio;
            }

            if (height > this.rootContainer.nativeElement.clientHeight) {
              return;
            }
            if (width > this.rootContainer.nativeElement.clientWidth) {
              return;
            }
            this.targetsView.canvas.style.width = String(width) + "px";
            this.targetsView.canvas.style.height = String(height) + "px";
          }
        });
    }

    // (number | ScatterDataPoint | BubbleDataPoint)[],

    this.ctx = this.canvas.nativeElement.getContext("2d");

    this.targetsView = new Chart(this.ctx, {
      plugins: [
        {
          id: "createRect",
          beforeDraw: (chart) => {
            this.createRect(
              chart,
              xMinCm,
              yMinCm,
              xMaxCm,
              yMaxCm,
              subRegionList
            );
          },
        },
      ],
      type: "scatter",
      data: {
        datasets: [
          {
            data: [],
            borderColor: "3f8de4",
          },
        ],
      },
      options: {
        animation: {
          duration: 1000,
        },
        borderColor: "#3f8de4",
        responsive: true,
        maintainAspectRatio: false,
        onResize: (size) => {
          this.resize(size);
        },
        layout: {
          padding: {
            top: 5,
            left: 5,
            right: 5,
            bottom: 45,
          },
        },
        scales: {
          legend: { display: false },
          x: {
            axis: "x",
            type: "linear",
            title: {
              display: false,
              text: "X",
              align: "start",
            },
            position: {
              y: 0,
            },
            display: true,
            min: xMinCm,
            max: xMaxCm,
            beginAtZero: true,
            ticks: {
              stepSize: xTickSize,
              autoSkip: false,
              callback: (value: number, index, ticks) => {
                if (value === 0 || index === 0 || index === ticks.length - 1) {
                  return formatData(value);
                } else {
                  return "";
                }
              },
            },
          },
          y: {
            axis: "y",
            type: "linear",
            title: {
              display: false,
              text: "Y",
              align: "start",
            },
            position: {
              x: 0,
            },
            display: true,
            min: Math.min(0, yMinCm),
            max: yMaxCm,
            beginAtZero: true,
            ticks: {
              stepSize: yTickSize,
              autoSkip: false,
              callback: (value: number, index, ticks) => {
                if (
                  (index === 0 && value !== 0) ||
                  index === ticks.length - 1
                ) {
                  return formatData(value);
                } else {
                  return "";
                }
              },
            },
          },
        },
      },
    }) as Chart;

    if (aspectRatio > 1) {
      this.targetsView.canvas.style.width = "500px";
      this.targetsView.canvas.style.height = String(500 / aspectRatio) + "px";
    } else {
      this.targetsView.canvas.style.height = "400px";
      this.targetsView.canvas.style.width = String(400 * aspectRatio) + "px";
    }
  }

  createRect(
    chart: Chart,
    xMinCm: number,
    yMinCm: number,
    xMaxCm: number,
    yMaxCm: number,
    subRegionList?: TrackerSubRegion[]
  ) {
    // this.ctx.fillStyle = 'rgba(0, 110, 249, 0.4)';
    const {
      ctx,
      // chartArea: { left, top, right, bottom },
      scales: { x, y },
    } = chart;
    // const midX = x.getPixelForValue(0);
    // const midY = y.getPixelForValue(0);

    ctx.save();
    // console.log('clearRect room : ', left, top, right, bottom)
    // ctx.clearRect(left, top, right - left, bottom - top);

    // Sensor
    ctx.fillStyle = SENSOR_COLOR;
    const sensorSizeCm = 20;
    let bottomSensorY = -sensorSizeCm / 2;
    if (this.config.walabotConfig.sensorMounting === SensorMounting.Wall) {
      bottomSensorY = 0;
    }
    let leftX: number = x.getPixelForValue(-sensorSizeCm / 2);
    let topY: number = y.getPixelForValue(sensorSizeCm / 2);
    let rightX: number = x.getPixelForValue(sensorSizeCm / 2);
    let bottomY: number = y.getPixelForValue(bottomSensorY);
    ctx.fillRect(leftX, topY, rightX - leftX, bottomY - topY);

    // Monitoring area
    ctx.fillStyle = MONITORING_AREA_COLOR;
    // ctx.fillRect(left, top, right - left, bottom - top);
    leftX = x.getPixelForValue(xMinCm);
    topY = y.getPixelForValue(yMaxCm);
    rightX = x.getPixelForValue(xMaxCm);
    bottomY = y.getPixelForValue(yMinCm);
    ctx.fillRect(leftX, topY, rightX - leftX, bottomY - topY);

    if (subRegionList) {
      subRegionList.forEach((item: TrackerSubRegion) => {
        const sleftX: number = x.getPixelForValue(item.xMin);
        const stopY: number = y.getPixelForValue(item.yMax);
        const srightX: number = x.getPixelForValue(item.xMax);
        const sbottomY: number = y.getPixelForValue(item.yMin);
        const rectW = srightX - sleftX;
        const rectH = sbottomY - stopY;
        ctx.fillStyle = SUB_REGION_COLOR;
        ctx.fillRect(sleftX, stopY, rectW, rectH);

        // set subregion name
        if (item.name) {
          ctx.fillStyle = "#000000";
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.font = `16px Metropolis`;
          const subrCenterX = sleftX + rectW / 2;
          const subrCenterY = stopY + rectH / 2;
          ctx.fillText(item.name, subrCenterX, subrCenterY);
        }
      });
    }

    ctx.restore();
  }

  private getImageForPosture(posture: number) {
    const normalizedPosture =
      posture == null ? 0 : Math.max(0, Math.min(posture, 4));
    return this.images[normalizedPosture];
  }

  resize(size: { width: number; height: number }) {
    this.images.forEach((image, index) => {
      if (this.imageUrl[index].aspectRatio > 1) {
        const newWidth = size.width * 0.1;
        image.width = newWidth;
        image.height = newWidth / this.imageUrl[index].aspectRatio;
      } else {
        const newHeight = size.height * 0.1;
        image.width = newHeight * this.imageUrl[index].aspectRatio;
        image.height = newHeight;
      }
    });
  }

  isSensorMountingSpecified() {
    return this.config && "sensorMounting" in this.config.walabotConfig;
  }

  formatSensorMounting() {
    return SensorMounting[this.config.walabotConfig.sensorMounting].replace(
      /(?!^)([A-Z]|\d+)/g,
      " $&"
    );
  }

  private setTargets(targets: TrackerTarget[]) {
    const uiTargets: ScatterDataPoint[] = [];
    const images = [];
    targets.forEach((target) => {
      uiTargets.push({ x: target.xPosCm, y: target.yPosCm });
      const image = this.getImageForPosture(0);
      images.push(image);
    });
    this.targetsView.data.datasets[0].data = uiTargets;
    this.targetsView.options.elements.point.pointStyle = images;
    this.targetsView.update();
  }

  close() {
    this.closePanel.emit();
  }

  ngOnDestroy() {
    if (this.targetsView) {
      this.targetsView.destroy();
    }
    super.ngOnDestroy();
  }
}
