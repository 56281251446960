import { Pipe, PipeTransform } from "@angular/core";
import { PotentialFall } from "../analytics.service";

@Pipe({
  name: "isLastInFourWeeks",
})
export class IsLastInFourWeeksPipe implements PipeTransform {
  transform(
    row: PotentialFall,
    potentialFallList: PotentialFall[],
    startTime: string
  ): unknown {
    const i = potentialFallList.indexOf(row),
      startTimeDate = new Date(startTime),
      fallTime = new Date(row.fall_time);

    if (potentialFallList[i + 1]) {
      return (
        fallTime >= startTimeDate &&
        new Date(potentialFallList[i + 1].fall_time) < startTimeDate
      );
    } else if (i === potentialFallList.length - 1) {
      return fallTime >= startTimeDate;
    }

    return false;
  }
}
