/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SuiteDetails } from '../models/SuiteDetails';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserApiAPI {

    /**
     * Get installation details for a suite of the calling caregiver
     * @param suiteName
     * @returns SuiteDetails OK
     * @throws ApiError
     */
    public static userQuerySuiteDetails(
        suiteName: string,
    ): CancelablePromise<SuiteDetails> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/userapi/suite/{suiteName}',
            path: {
                'suiteName': suiteName,
            },
            errors: {
                404: `Suite name not found error`,
            },
        });
    }

}
