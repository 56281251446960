import { Component } from "@angular/core";
import { MatBottomSheetRef } from "@angular/material/bottom-sheet";

@Component({
  selector: "app-update-notification",
  templateUrl: "./update-notification.component.html",
  styleUrls: ["./update-notification.component.css"],
})
export class UpdateNotificationComponent {
  constructor(
    private bottomSheetRef: MatBottomSheetRef<UpdateNotificationComponent>
  ) {}

  dismiss() {
    this.bottomSheetRef.dismiss({ result: false });
  }

  update() {
    this.bottomSheetRef.dismiss({ result: true });
  }
}
