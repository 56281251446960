/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { FallEvent } from './FallEvent';

export type DeviceFallEvent = {
    type: DeviceFallEvent.type;
    payload: FallEvent;
};

export namespace DeviceFallEvent {

    export enum type {
        Fall = 5,
    }


}

