import { Component, ElementRef, ViewChild } from "@angular/core";
import { StatusPageBase } from "../status-page-base";
import { Mixin } from "../utils";

@Component({
  selector: "app-rooms",
  templateUrl: "./rooms.component.html",
  styleUrls: ["./rooms.component.css"],
})
export class RoomsComponent extends Mixin(StatusPageBase) {
  @ViewChild("popup", { static: false }) popup: ElementRef<HTMLElement>;
  @ViewChild("feedbackPopup", { static: false })
  feedbackPopup: ElementRef<HTMLElement>;
}
