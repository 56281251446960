import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "linebreak",
})
export class LinebreakPipe implements PipeTransform {
  transform(value: string): unknown {
    if (!value || !value.length) {
      return value;
    }

    return value.replace(/(\\r\\n)|([\r\n])/gim, "<br/>");
  }
}
