/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeviceInfo } from '../models/DeviceInfo';
import type { DeviceState } from '../models/DeviceState';
import type { DeviceTest } from '../models/DeviceTest';
import type { PairedDevice } from '../models/PairedDevice';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DeviceAPI {

    /**
     * Get paired devices
     * @returns PairedDevice OK
     * @throws ApiError
     */
    public static getPairedDevices(): CancelablePromise<Array<PairedDevice>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/device',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get device information
     * @param deviceId
     * @returns DeviceInfo OK
     * @throws ApiError
     */
    public static getDevice(
        deviceId: string,
    ): CancelablePromise<DeviceInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/device/{deviceId}',
            path: {
                'deviceId': deviceId,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Update device information
     * @param deviceId
     * @param requestBody
     * @returns DeviceInfo OK
     * @throws ApiError
     */
    public static putDevice(
        deviceId: string,
        requestBody?: DeviceInfo,
    ): CancelablePromise<DeviceInfo> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/device/{deviceId}',
            path: {
                'deviceId': deviceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get device state
     * @param deviceId
     * @returns DeviceState OK
     * @throws ApiError
     */
    public static getDeviceState(
        deviceId: string,
    ): CancelablePromise<DeviceState> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/device/{deviceId}/state',
            path: {
                'deviceId': deviceId,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Update device checkup status
     * @param deviceId
     * @param requestBody
     * @returns DeviceTest OK
     * @throws ApiError
     */
    public static updateTestDeviceConfig(
        deviceId: string,
        requestBody?: DeviceTest,
    ): CancelablePromise<DeviceTest> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/device/{deviceId}/devicecheck',
            path: {
                'deviceId': deviceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * get device checkup status
     * @param deviceId
     * @returns DeviceTest OK
     * @throws ApiError
     */
    public static getTestDeviceConfig(
        deviceId: string,
    ): CancelablePromise<DeviceTest> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/device/{deviceId}/devicecheck',
            path: {
                'deviceId': deviceId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

}
