/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Vayyar App standard BLE server or custom ones
 */
export enum BLEServerType {
    Off = 'Off',
    VayyarApp = 'VayyarApp',
    Custom1 = 'Custom1',
}
