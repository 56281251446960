<div>
  <mat-spinner *ngIf="loading"></mat-spinner>
  <form *ngIf="remoteConfig" (ngSubmit)="onSubmitData()" #form="ngForm">
    <div class="remote-config-form">
      <mat-card>
        <mat-card-title>Walabot Configuration:</mat-card-title>
        <mat-card-content class="walabot-config-card">
          <mat-card>
            <mat-card-title>Tracker Region</mat-card-title>
            <mat-card-content>
              <p>
                <mat-form-field>
                  <input matInput placeholder="xMin" matInput type="number" value={{remoteConfig.walabotConfig.xMin}}
                         [(ngModel)]="remoteConfig.walabotConfig.xMin" name="xMin">
                </mat-form-field>
              </p>
              <p>
                <mat-form-field>
                  <input matInput placeholder="xMax" matInput type="number" value={{remoteConfig.walabotConfig.xMax}}
                         [(ngModel)]="remoteConfig.walabotConfig.xMax" name="xMax">
                </mat-form-field>
              </p>
              <p>
                <mat-form-field>
                  <input matInput placeholder="yMin" matInput type="number" value={{remoteConfig.walabotConfig.yMin}}
                         [(ngModel)]="remoteConfig.walabotConfig.yMin" name="yMin">
                </mat-form-field>
              </p>
              <p>
                <mat-form-field>
                  <input matInput placeholder="yMax" matInput type="number" value={{remoteConfig.walabotConfig.yMax}}
                         [(ngModel)]="remoteConfig.walabotConfig.yMax" name="yMax">
                </mat-form-field>
              </p>
              <p>
                <mat-form-field>
                  <input matInput placeholder="zMin" matInput type="number" value={{remoteConfig.walabotConfig.zMin}}
                         [(ngModel)]="remoteConfig.walabotConfig.zMin" name="zMin">
                </mat-form-field>
              </p>
              <p>
                <mat-form-field>
                  <input matInput placeholder="zMax" matInput type="number" value={{remoteConfig.walabotConfig.zMax}}
                         [(ngModel)]="remoteConfig.walabotConfig.zMax" name="zMax">
                </mat-form-field>
              </p>
              <p>
                <mat-form-field>
                  <input matInput placeholder="enterDuration" matInput type="number"
                         value={{remoteConfig.walabotConfig.enterDuration}}
                         [(ngModel)]="remoteConfig.walabotConfig.enterDuration" name="enterDuration">
                </mat-form-field>
              </p>
              <p>
                <mat-form-field>
                  <input matInput placeholder="exitDuration" matInput type="number"
                         value={{remoteConfig.walabotConfig.exitDuration}}
                         [(ngModel)]="remoteConfig.walabotConfig.exitDuration" name="exitDuration">
                </mat-form-field>
              </p>
              <p>
                <mat-form-field>
                  <input matInput placeholder="minTimeOfTarInFallLoc_sec" matInput type="number"
                         [(ngModel)]="remoteConfig.walabotConfig.minTimeOfTarInFallLoc_sec" name="minTimeOfTarInFallLoc_sec">
                </mat-form-field>
              </p>
              <p>
                <mat-form-field>
                  <input matInput placeholder="durationUntilConfirm_sec" matInput type="number"
                         [(ngModel)]="remoteConfig.walabotConfig.durationUntilConfirm_sec" name="durationUntilConfirm_sec">
                </mat-form-field>
              </p>
            </mat-card-content>
          </mat-card>
          <mat-card>
            <p>
              <mat-form-field>
                <input matInput placeholder="Sensor Height" type="number"
                       value={{remoteConfig.walabotConfig.sensorHeight}}
                       [(ngModel)]="remoteConfig.walabotConfig.sensorHeight" name="sensorHeight">
              </mat-form-field>
            </p>
            <p>
              <mat-form-field>
                <mat-select placeholder="Falling Sensitivity" value='{{fallingSensitivity}}'
                            [(ngModel)]="fallingSensitivity" name="fallingSensitivity"
                            (ngModelChange)="onFallSensitivtySelected($event)">
                  <mat-option value=undefined></mat-option>
                  <mat-option value=0>No Falling</mat-option>
                  <mat-option value=1>Low Sensitivity</mat-option>
                  <mat-option value=2>Regular Sensitivity</mat-option>
                </mat-select>
              </mat-form-field>
            </p>
            <p>
              <mat-form-field>
                <mat-select placeholder="Sensor Mounting" [(ngModel)]="remoteConfig.walabotConfig.sensorMounting"
                            name="sensorMounting">
                  <mat-option *ngFor="let sensorMountingVal of sensorMountingValues" [value]=sensorMountingVal>
                    {{sensorMounting[sensorMountingVal]}}</mat-option>
                </mat-select>
              </mat-form-field>
            </p>
            <p>
              <mat-checkbox matInput name="Enable Heatup" [(ngModel)]="remoteConfig.walabotConfig.performHeatup">
                Enable Heatup
              </mat-checkbox>
            </p>
            <p>
              <mat-checkbox matInput name="Enable AGC" [(ngModel)]="remoteConfig.walabotConfig.performAgc">
                Enable AGC
              </mat-checkbox>
            </p>
            <p>
              <mat-checkbox matInput name="fallingMitigatorEnabled" [(ngModel)]="remoteConfig.walabotConfig.fallingMitigatorEnabled">
                Enable Falling Mitigator
              </mat-checkbox>
            </p>
            <p>
              <mat-form-field>
                <input #input input numeric decimal="0" placeholder="Falling Mitigator Threshold" matInput min="-1"
                       step="0.1" max="1" type="number" value={{remoteConfig.walabotConfig.fallingMitigatorThreshold}}
                       [(ngModel)]="remoteConfig.walabotConfig.fallingMitigatorThreshold" name="fallingMitigatorThreshold"
                       [disabled]="!remoteConfig.walabotConfig.fallingMitigatorEnabled">
                <mat-error>Value must be between ({{input.min | number: '1.1-1'}}) - ({{input.max | number: '1.1-1'}})</mat-error>
              </mat-form-field>
            </p>
            <ng-container *ngIf="environment.applicationId === 'bed-exit-detector'">
              <p>
                <mat-form-field>
                  <input matInput placeholder="Bed Exit Prediction Threshold" type="number" [min]="0" [max]="1" [step]="0.1"
                         [(ngModel)]="remoteConfig.walabotConfig.bedExitPredictionThreshold" name="bedExitPredictionThreshold">
                </mat-form-field>
              </p>
              <p>
                <mat-form-field>
                  <input matInput placeholder="Bed Exit Number of Frames To Reset" type="number"
                         title="Bed Exit Number of Frames To Reset"
                         [(ngModel)]="remoteConfig.walabotConfig.bedExitNFramesToReset" name="bedExitNFramesToReset">
                </mat-form-field>
              </p>
              <p>
                <mat-form-field>
                  <mat-select placeholder="Bed Exit Wall Side" [(ngModel)]="remoteConfig.walabotConfig.bedExitWallSide"
                              name="bedExitWallSide">
                    <mat-option *ngFor="let bedExitWallSideVal of bedExitWallSideValues" [value]=bedExitWallSideVal>
                      {{bedExitWallSide[bedExitWallSideVal]}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </p>
            </ng-container>
            <mat-accordion class="telemetry">
              <mat-expansion-panel (opened)="panelOpenState = true"
              (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Telemetry Configuration <br> (v39 and up)
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p *ngFor="let field of allTelemetryFields">
                  <mat-checkbox matInput [name]="field" [(ngModel)]="remoteConfig.walabotConfig[field]">
                    {{getDisplayNameFromParameterName(field, "Telemetry")}}
                  </mat-checkbox>
                </p>
              </mat-expansion-panel>
            </mat-accordion>
          </mat-card>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-title>App Configuration</mat-card-title>
        <mat-card-content>
          <p>
            <mat-checkbox matInput name="Slient Mode" [(ngModel)]="remoteConfig.appConfig.silentMode">Silent Mode
            </mat-checkbox>
          </p>
          <p>
            <mat-checkbox matInput name="Buzzer"
                          [(ngModel)]="remoteConfig.appConfig.volume"
                          (change)="onBuzzerSoundChanged($event)"
                          value="{{remoteConfig.appConfig.volume}}">Buzzer Sound
            </mat-checkbox>
          </p>
          <p>
            <mat-form-field>
              <input matInput placeholder="Presence Report Min Rate Millis" type="number"
                     [(ngModel)]="remoteConfig.appConfig.presenceReportMinRateMills" name="presenceMinRate">
            </mat-form-field>
          </p>
          <p>
            <mat-form-field>
              <input matInput placeholder="Alert timeout seconds" type="number"
                     [(ngModel)]="remoteConfig.appConfig.confirmedToAlertTimeoutSec" name="alertTimeout">
            </mat-form-field>
          </p>
          <ng-container *ngIf="environment.applicationId === 'fall-detector'">
            <p>
              <mat-form-field>
                <input matInput placeholder="Calling Duration Seconds" type="number"
                       [(ngModel)]="remoteConfig.appConfig.callingDurationSec" name="callingDuration">
              </mat-form-field>
            </p>
          </ng-container>
          <p>
            <mat-form-field>
              <input matInput placeholder="Suspend Duration Seconds" type="number"
                     [(ngModel)]="remoteConfig.appConfig.suspendDuration_sec" name="suspendDuration_sec">
            </mat-form-field>
          </p>
          <p>
            <!-- <mat-checkbox matInput name="ledPolicy" [(ngModel)]="ledLight">
              Led Light
            </mat-checkbox> -->
            <mat-form-field>
              <mat-select placeholder="Led Light" [(ngModel)]="remoteConfig.appConfig.ledPolicy" name="ledPolicy">
                <mat-option value="AllEvents">On</mat-option>
                <mat-option value="Off">Off</mat-option>
                <mat-option value="ErrorsOnly">Errors Only</mat-option>
              </mat-select>
            </mat-form-field>
          </p>
          <p>
            <mat-form-field>
              <mat-select placeholder="Log Level" [(ngModel)]="remoteConfig.appConfig.logLevel" name="logLevel">
                <mat-option *ngFor="let logLevelVal of logLevelValues" [value]=logLevelVal>{{logLevels[logLevelVal]}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </p>
          <p>
            <mat-form-field>
              <mat-select placeholder="Telemetry Policy" [(ngModel)]="remoteConfig.appConfig.telemetryPolicy"
                          name="telemetryPolicy">
                <mat-option *ngFor="let telemetryPolicy of telemetryPolicyValues" [value]=telemetryPolicy>
                  {{telemetryPolicies[telemetryPolicy]}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </p>
          <p>
            <mat-checkbox matInput name="Enable Telemetries On Event During Suspend" [(ngModel)]="remoteConfig.appConfig.enableTelemetriesOnEventDuringSuspend">
              Enable Telemetries On Event During Suspend
            </mat-checkbox>
          </p>
          <p>
            <mat-form-field>
              <mat-select placeholder="Telemetry Transport" [(ngModel)]="remoteConfig.appConfig.telemetryTransport"
                          name="telemetryTransport">
                <mat-option *ngFor="let telemetryTransport of telemetryTransportValues" [value]=telemetryTransport>
                  {{telemetryTransports[telemetryTransport]}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </p>
          <p>
            <mat-checkbox matInput name="Tracker Data" [(ngModel)]="remoteConfig.appConfig.dspRecordsPublishPolicy">
              Tracker Data
            </mat-checkbox>
          </p>
          <p>
            <mat-checkbox matInput name="Enable Analytics" [(ngModel)]="remoteConfig.appConfig.enableAnalytics">
              Analytics Enabled
            </mat-checkbox>
          </p>
          <p>
            <mat-checkbox matInput name="Enable Test Mode" [(ngModel)]="remoteConfig.appConfig.enableTestMode">
              Test Mode Enabled
            </mat-checkbox>
          </p>
          <div>
            <span>Primary Dry Contact</span>
            <p>
              <mat-form-field>
                <mat-select placeholder="PrimaryDryContactPolicy"
                            [(ngModel)]="remoteConfig.appConfig.dryContacts.primary.policy"
                            name="primaryDryContactPolicy">
                  <mat-option *ngFor="let dryContactPolicy of dryContactPolicyValues" [value]=dryContactPolicy>
                    {{dryContactPolicies[dryContactPolicy]}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field>
                <mat-select placeholder="PrimaryDryContactMode"
                            [(ngModel)]="remoteConfig.appConfig.dryContacts.primary.mode" name="primaryDryContactMode">
                  <mat-option *ngFor="let dryContactMode of dryContactModeValues" [value]=dryContactMode>
                    {{dryContactModes[dryContactMode]}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </p>
          </div>
          <div>
            <span>Secondary Dry Contact</span>
            <p>
              <mat-form-field>
                <mat-select placeholder="secondaryDryContactPolicy"
                            [(ngModel)]="remoteConfig.appConfig.dryContacts.secondary.policy"
                            name="secondaryDryContactPolicy">
                  <mat-option *ngFor="let dryContactPolicy of dryContactPolicyValues" [value]=dryContactPolicy>
                    {{dryContactPolicies[dryContactPolicy]}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field>
                <mat-select placeholder="SecondaryDryContactMode"
                            [(ngModel)]="remoteConfig.appConfig.dryContacts.secondary.mode"
                            name="secondaryDryContactMode">
                  <mat-option *ngFor="let dryContactMode of dryContactModeValues" [value]=dryContactMode>
                    {{dryContactModes[dryContactMode]}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </p>
          </div>
          <ng-container *ngIf="environment.applicationId === 'bed-exit-detector'">
            <p>
              <mat-form-field>
                <input matInput placeholder="Dry contact activation duration" type="number"
                       title="Dry contact activation duration"
                       [(ngModel)]="remoteConfig.appConfig.dryContactActivationDuration_sec" name="dryContactActivationDuration_sec">
              </mat-form-field>
            </p>
          </ng-container>
          <div>
          <p>
            <mat-checkbox matInput name="Offline Mode" [(ngModel)]="remoteConfig.appConfig.offlineMode">
              Offline Mode
            </mat-checkbox>
          </p>
          </div>
          <div style="display:flex; flex-direction: column">
            <mat-checkbox matInput name="Learning Mode" [checked]="isLearningModeEnabled(remoteConfig.appConfig)"
                          (change)="toggleLearningMode($event.checked)">
              Learning Mode
            </mat-checkbox>
            <span style="margin-left:2em" *ngIf="isLearningModeEnabled(remoteConfig.appConfig)"
                  [appCounterDownDirective]="remoteConfig.appConfig.learningModeEndTs"
                  (value)="value= $event">{{value|secondsToTimeCounter}}</span>
          </div>
          <div>
            <p>
              <mat-form-field>
                <mat-select placeholder="Enable trackerTargetsDebugPolicy"
                            [(ngModel)]="remoteConfig.appConfig.trackerTargetsDebugPolicy"
                            name="Enable trackerTargetsDebugPolicy">
                  <mat-option *ngFor="let trackerTargetsDebugPolicy of trackerTargetsDebugPolicyValues" [value]=trackerTargetsDebugPolicy>
                    {{trackerTargetsDebugPolicies[trackerTargetsDebugPolicy]}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </p>
          </div>
          <div style="display:flex; flex-direction: column">
            <mat-checkbox matInput name="Demo Mode" [(ngModel)]="remoteConfig.appConfig.demoMode">
              Demo Mode
            </mat-checkbox>
          </div>
          <div style="display:flex; flex-direction: column">
            <mat-checkbox matInput name="Out of Bed" [(ngModel)]="remoteConfig.appConfig.enableOutOfBed">
              Out of Bed
            </mat-checkbox>
          </div>
           <div style="display:flex; flex-direction: column">
            <mat-checkbox matInput name="Enable Door Events" [(ngModel)]="remoteConfig.appConfig.enableDoorEvents">
              Enable Door Events
            </mat-checkbox>
          </div>
          <div style="display:flex; flex-direction: column">
            <mat-checkbox matInput name="Sensitive mode" [(ngModel)]="remoteConfig.appConfig.enableSensitiveMode">
              Sensitive mode
            </mat-checkbox>
            <p>
              <mat-form-field>
                <input [formControl]="sensitivityLevel" input numeric decimal="2" placeholder="Sensitivity Level" matInput min="0"
                       max="1" step="0.01" type="number" value={{remoteConfig.appConfig.sensitivityLevel}}
                       [(ngModel)]="remoteConfig.appConfig.sensitivityLevel" name="sensitivityLevel"
                       [disabled]="!remoteConfig.appConfig.enableSensitiveMode">
              </mat-form-field>
            </p>
            <p>
              <mat-form-field>
                <input [formControl]="thMinEventsForFirstDecision" input numeric decimal="0" placeholder="Min Events For First Decision" matInput min="0"
                       step="1" type="number" value={{remoteConfig.appConfig.thMinEventsForFirstDecision}}
                       [(ngModel)]="remoteConfig.appConfig.thMinEventsForFirstDecision" name="thMinEventsForFirstDecision"
                       [disabled]="!remoteConfig.appConfig.enableSensitiveMode">
              </mat-form-field>
            </p>
            <p>
              <mat-form-field>
                <input [formControl]="thNumOfDetectionsInChain" input numeric decimal="0" placeholder="Num Of Detections In Chain" matInput min="0"
                       step="1" type="number" value={{remoteConfig.appConfig.thNumOfDetectionsInChain}}
                       [(ngModel)]="remoteConfig.appConfig.thNumOfDetectionsInChain" name="thNumOfDetectionsInChain"
                       [disabled]="!remoteConfig.appConfig.enableSensitiveMode">
              </mat-form-field>
            </p>
            <p>
              <mat-checkbox matInput name="enableBeaconScanner" [(ngModel)]="remoteConfig.appConfig.enableBeaconScanner">
                Enable Beacon Scanner
              </mat-checkbox>
            </p>
            <p>
              <mat-form-field style="width: 100%;">
                <mat-label>BLE Beacon MAC addresses</mat-label>
                <mat-chip-list #chipList aria-label="Enter fruits">
                  <mat-chip *ngFor="let mac of remoteConfig.appConfig.bleBeaconMacs"
                                (removed)="removeBLEMACAddress(mac)">
                    {{mac.MAC}}
                    <button matChipRemove>
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip>
                  <input #chipInput="matChipInput" placeholder="New MAC address"
                         [matChipInputFor]="chipList"
                         (matChipInputTokenEnd)="addBLEMACAddress($event)"/>
                </mat-chip-list>
                <button [disabled]="!chipInput.inputElement.value" mat-raised-button type="button"
                        style="margin-top: 10px;"
                (click)="addBLEMACAddress($any({value: chipInput.inputElement.value, chipInput: chipInput})); $event.stopPropagation()">
                  Add
                </button>
              </mat-form-field>
            </p>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-content>
          <p>
            <button type="button" mat-raised-button (click)="uploadAppLogs()">
              <mat-spinner *ngIf="uploadAppLogsProgress"></mat-spinner>
              Upload App Logs
            </button>
          </p>
          <p>
            <button type="button" mat-raised-button (click)="uploadDevLogs()">
              <mat-spinner *ngIf="uploadDevLogsProgress"></mat-spinner>
              Upload Dev Logs
            </button>
          </p>
          <p>
            <button type="button" mat-raised-button (click)="rebootDevice()">
              <mat-spinner *ngIf="rebootDeviceProgress"></mat-spinner>
              Reboot Device
            </button>
          </p>
          <p>
            <button type="button" mat-raised-button (click)="cancelAlarm()">
              <!-- <mat-spinner *ngIf="rebootDeviceProgress"></mat-spinner> -->
              Cancel Alarm
            </button>
          </p>
        </mat-card-content>
      </mat-card>
    </div>
    <mat-card>
      <mat-card-title>
        Tracker Sub Regions
      </mat-card-title>
      <mat-card-content>
        <button mat-raised-button type="button" (click)="addTrackerSubRegion()">Add tracker sub region</button>
        <div style="display:flex;flex-direction: row;">
          <mat-card *ngFor="let trackerSubRegion of remoteConfig.walabotConfig.trackerSubRegions; let i = index;">
            <mat-card-header>
              <mat-card-title>{{i}}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <p>
                <mat-form-field>
                  <input matInput placeholder="name" type="text" value={{trackerSubRegion.name}}
                         [(ngModel)]="trackerSubRegion.name" name="name{{i}}">
                </mat-form-field>
              </p>
              <p>
                <mat-form-field>
                  <input matInput placeholder="xMin" type="number" value={{trackerSubRegion.xMin}}
                         [(ngModel)]="trackerSubRegion.xMin" name="xMin{{i}}">
                </mat-form-field>
              </p>
              <p>
                <mat-form-field>
                  <input matInput placeholder="xMax" type="number" value={{trackerSubRegion.xMax}}
                         [(ngModel)]="trackerSubRegion.xMax" name="xMax{{i}}">
                </mat-form-field>
              </p>
              <p>
                <mat-form-field>
                  <input matInput placeholder="yMin" matInput type="number" value={{trackerSubRegion.yMin}}
                         [(ngModel)]="trackerSubRegion.yMin" name="yMin{{i}}">
                </mat-form-field>
              </p>
              <p>
                <mat-form-field>
                  <input matInput placeholder="yMax" type="number" value={{trackerSubRegion.yMax}}
                         [(ngModel)]="trackerSubRegion.yMax" name="yMax{{i}}">
                </mat-form-field>
              </p>
              <p>
                <mat-form-field>
                  <input matInput placeholder="zMin" matInput type="number" value={{trackerSubRegion.zMin}}
                         [(ngModel)]="trackerSubRegion.zMin" name="zMin{{i}}">
                </mat-form-field>
              </p>
              <p>
                <mat-form-field>
                  <input matInput placeholder="zMax" type="number" value={{trackerSubRegion.zMax}}
                         [(ngModel)]="trackerSubRegion.zMax" name="zMax{{i}}">
                </mat-form-field>
              </p>
              <p>
                <mat-form-field>
                  <input matInput placeholder="enterDuration" type="number" value={{trackerSubRegion.enterDuration}}
                         [(ngModel)]="trackerSubRegion.enterDuration" name="enterDuration{{i}}">
                </mat-form-field>
              </p>
              <p>
                <mat-form-field>
                  <input matInput placeholder="exitDuration" type="number" value={{trackerSubRegion.exitDuration}}
                         [(ngModel)]="trackerSubRegion.exitDuration" name="exitDuration{{i}}">
                </mat-form-field>
              </p>
              <p>
                <mat-checkbox matInput name="isPresenceDetection{{i}}"
                              [(ngModel)]="trackerSubRegion.isPresenceDetection">
                  Presence Detection
                </mat-checkbox>
              </p>
              <p>
                <mat-checkbox matInput name="isFallingDetection{{i}}" [(ngModel)]="trackerSubRegion.isFallingDetection">
                  Fall Detection
                </mat-checkbox>
              </p>
              <p>
                <mat-checkbox matInput name="isLowSnr{{i}}" [(ngModel)]="trackerSubRegion.isLowSnr">
                  isLowSnr
                </mat-checkbox>
              </p>
              <p>
                <mat-checkbox matInput name="isDoor{{i}}" [(ngModel)]="trackerSubRegion.isDoor">
                  isDoor
                </mat-checkbox>
              </p>
              <p>
                <mat-checkbox matInput name="isHorizontal{{i}}" [(ngModel)]="trackerSubRegion.isHorizontal">
                  isHorizontal
                </mat-checkbox>
              </p>
              <button mat-raised-button type="button" (click)="removeTrackerSubRegion(trackerSubRegion)">Remove</button>
            </mat-card-content>
          </mat-card>
        </div>
      </mat-card-content>
    </mat-card>
    <button [disabled]="form.invalid" mat-raised-button type="submit">Save</button>
  </form>
</div>
