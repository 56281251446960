/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MqttHook } from '../models/MqttHook';
import type { MqttHookBulkReqBody } from '../models/MqttHookBulkReqBody';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MqttHooksAPI {

    /**
     * Bulk create MqttHook for multiple devices
     * @param requestBody
     * @returns MqttHook OK
     * @throws ApiError
     */
    public static postMqtthooks(
        requestBody?: MqttHookBulkReqBody,
    ): CancelablePromise<MqttHook> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/mqtthooks',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Create MqttHook for device
     * @param deviceId
     * @param requestBody
     * @returns MqttHook OK
     * @throws ApiError
     */
    public static postMqtthooks1(
        deviceId: string,
        requestBody?: MqttHook,
    ): CancelablePromise<MqttHook> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/mqtthooks/{deviceId}',
            path: {
                'deviceId': deviceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Read MqttHook for device
     * @param deviceId
     * @returns MqttHook OK
     * @throws ApiError
     */
    public static getMqtthooks(
        deviceId: string,
    ): CancelablePromise<MqttHook> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/mqtthooks/{deviceId}',
            path: {
                'deviceId': deviceId,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Update MqttHook for device
     * @param deviceId
     * @param requestBody
     * @returns MqttHook OK
     * @throws ApiError
     */
    public static putMqtthooks(
        deviceId: string,
        requestBody?: MqttHook,
    ): CancelablePromise<MqttHook> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/mqtthooks/{deviceId}',
            path: {
                'deviceId': deviceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Delete MqttHook for device
     * @param deviceId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteMqtthooks(
        deviceId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/mqtthooks/{deviceId}',
            path: {
                'deviceId': deviceId,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

}
