import { Injectable, Injector } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { DateTimeAdapter } from "@danielmoncada/angular-datetime-picker";
import {
  DashboardConfig,
  RoomsAPI,
  TrackerSubRegion,
  SensorMounting,
  LogLevel,
  DeviceConfigGen2,
  DeviceAdminAPI,
  DeviceCommand,
  CancelFall,
  DeviceCommandGen2Reboot,
} from "@walabot-mqtt-dashboard/api";
import MeasurementUnits = DashboardConfig.measurementUnits;
import { DeviceMonitoringService } from "./ui.module";

export enum Language {
  ENGLISH = "en",
  HEBREW = "he",
  CHINESE_TRADITIONAL = "zh-TW",
  CHINESE_SIMPLIFIED = "zh-CN",
  GERMAN = "de",
  NORWEGIAN = "no",
  SPANISH = "es",
  FRENCH = "fr",
  JAPANESE = "ja",
}

const isInLearningMode = (config: {
  learningModeEndTs?: number;
  learningModeStartTs?: number;
}): boolean => {
  const now = Date.now();
  return (
    config.learningModeStartTs != null &&
    config.learningModeEndTs != null &&
    config.learningModeStartTs < now &&
    config.learningModeEndTs > now
  );
};

@Injectable({
  providedIn: "root",
})
export class ConfigService {
  private _dashboardConfig: DashboardConfig;
  private _dashboardConfigSubject: BehaviorSubject<DashboardConfig>;

  constructor(
    private dateTimeAdapter: DateTimeAdapter<any>,
    private injector: Injector
  ) {
    this.dateTimeAdapter.setLocale(this.getLanguage());
    this._dashboardConfigSubject = new BehaviorSubject<DashboardConfig>(null);
  }

  public async loadConfig() {
    const config = await RoomsAPI.getDashboardConfig();
    this._dashboardConfig = config;
    this._dashboardConfigSubject.next(this._dashboardConfig);
    return config;
  }

  public getDashboardConfig() {
    return this._dashboardConfigSubject;
  }

  public async setDashboardConfig(config: DashboardConfig) {
    try {
      await RoomsAPI.updateDashboardConfig(config);
      this._dashboardConfig = config;
      this._dashboardConfigSubject.next(config);
    } catch (error) {
      console.error("Unable to set dashboard configuration: ", error);
    }
  }

  public rebootDevice(deviceId: string) {
    const command: DeviceCommand = {
      type: DeviceCommandGen2Reboot.type.Reboot,
    };
    return this.sendCommand(deviceId, command);
  }

  public dismissFallAlert(
    roomId: string,
    eventType: "fall" | "sensitiveFall",
    feedback: string
  ) {
    return DeviceAdminAPI.dismissAlert(roomId, eventType, { feedback });
  }

  public cancelAlarm(deviceId: string) {
    const command: DeviceCommand = {
      type: CancelFall.type.CancelFall,
    };
    return this.sendCommand(deviceId, command);
  }

  private async sendCommand(deviceId: string, command: DeviceCommand) {
    console.log(
      `sending command to ${deviceId} command: ${JSON.stringify(command)}`
    );
    return DeviceAdminAPI.sendCommand(deviceId, command);
  }

  public async sendConfig(deviceId: string, deviceConfig: DeviceConfigGen2) {
    console.log(
      `sending config to ${deviceId} config: ${JSON.stringify(deviceConfig)}`
    );
    return DeviceAdminAPI.setDeviceConfig(deviceId, deviceConfig).then(
      (updatedDeviceConfig) => {
        const monitoringService = this.injector.get(DeviceMonitoringService);
        monitoringService.deviceConfigMap[deviceId] = updatedDeviceConfig;
        return updatedDeviceConfig;
      }
    );
  }

  public async getConfig(deviceId: string): Promise<DeviceConfigGen2> {
    const response = await DeviceAdminAPI.getDeviceConfig(deviceId);
    const config = response[0];

    return config;
  }

  public async exportDeviceRelateInfo() {
    return DeviceAdminAPI.exportDeviceRelateInfo();
  }

  public getLanguage() {
    let lang = localStorage.getItem("lang") as Language;
    if (!lang) {
      lang = Language.ENGLISH;
    }
    return lang;
  }

  public setLanguage(lang: Language) {
    localStorage.setItem("lang", lang);
  }
}

export {
  TrackerSubRegion,
  DashboardConfig,
  SensorMounting,
  LogLevel,
  DeviceConfigGen2,
  MeasurementUnits,
  isInLearningMode,
};
