/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { SensitiveFallEvent } from './SensitiveFallEvent';

export type DeviceSensitiveFallEvent = {
    type: DeviceSensitiveFallEvent.type;
    payload: SensitiveFallEvent;
};

export namespace DeviceSensitiveFallEvent {

    export enum type {
        SensitiveFall = 8,
    }


}

