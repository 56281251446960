<div>
  <mat-card class="caregiver-details">
    <div>
    <p>
      <mat-form-field>
        <input [readonly]=true matInput placeholder="Name: " value={{pairedCareGiver?.name}}>
      </mat-form-field>
    </p>
    <p>
      <mat-form-field>
        <input [readonly]=true matInput placeholder="Email: " value={{pairedCareGiver?.email}}>
      </mat-form-field>
    </p>
    <p>
      <mat-form-field>
        <input [readonly]=true matInput placeholder="UID: " value={{pairedCareGiver?.uid}}>
      </mat-form-field>
    </p>
    </div>
    <div>
      <p>
        <mat-checkbox [(ngModel)]="pairedCareGiver.voiceEnabled">Voice Call</mat-checkbox>
      </p>
      <button [disabled]="loadingUpdating" mat-raised-button (click)="updateCaregiver()" style="width: fit-content;">Save
        <mat-spinner *ngIf="loadingUpdating" [diameter]="20"></mat-spinner>
      </button>
    </div>
  </mat-card>
  <br>
  <button [disabled]="loadingPairing" mat-raised-button (click)="pair()" style="width: fit-content;">Pair
    <mat-spinner *ngIf="loadingPairing" [diameter]="20"></mat-spinner>
  </button>
</div>
