/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Tenant } from '../models/Tenant';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TenantAPI {

    /**
     * Get tenants by a filter
     * @param name Filter results with name
     * @param likeName Filter results with wildcard match name
     * @param tenantIds Filter results by a List of Tenant IDs
     * @param startCreationTimestamp
     * @param endCreationTimestamp
     * @param limit
     * @param offset
     * @returns Tenant OK
     * @throws ApiError
     */
    public static getTenants(
        name?: string,
        likeName?: string,
        tenantIds?: Array<string>,
        startCreationTimestamp?: number,
        endCreationTimestamp?: number,
        limit?: number,
        offset?: number,
    ): CancelablePromise<Array<Tenant>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tenant',
            query: {
                'name': name,
                'likeName': likeName,
                'tenantIds': tenantIds,
                'startCreationTimestamp': startCreationTimestamp,
                'endCreationTimestamp': endCreationTimestamp,
                'limit': limit,
                'offset': offset,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Create New Tenant
     * @param requestBody
     * @returns Tenant OK
     * @throws ApiError
     */
    public static createTenant(
        requestBody?: {
            name: string;
        },
    ): CancelablePromise<Tenant> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/tenant',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get Tenant by id
     * @param tenantId
     * @returns Tenant OK
     * @throws ApiError
     */
    public static getTenantById(
        tenantId: string,
    ): CancelablePromise<Tenant> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tenant/{tenantId}',
            path: {
                'tenantId': tenantId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Update Tenant
     * @param tenantId
     * @param requestBody
     * @returns Tenant OK
     * @throws ApiError
     */
    public static updateTenant(
        tenantId: string,
        requestBody?: {
            name: string;
        },
    ): CancelablePromise<Tenant> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/tenant/{tenantId}',
            path: {
                'tenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

}
