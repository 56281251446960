import { Injectable, OnDestroy } from "@angular/core";

import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { SwUpdate } from "@angular/service-worker";
import { UpdateNotificationComponent } from "./ui.module";
import { interval, Subscription } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class UpdateService implements OnDestroy {
  private updateDismissed = false;
  private updateCheckInterval: Subscription = null;
  constructor(private swUpdate: SwUpdate, private snackbar: MatBottomSheet) {
    console.log("Update Service activated swEnabled:", swUpdate.isEnabled);
    if (swUpdate.isEnabled) {
      swUpdate
        .checkForUpdate()
        .then(() => console.log("Checking for updates"))
        .catch((err) => console.warn("Failed to check for updates", err));
      this.updateCheckInterval = interval(1000 * 60).subscribe(() => {
        swUpdate
          .checkForUpdate()
          .then(() => console.log("Checking for updates"))
          .catch((err) => console.warn("Failed to check for updates", err));
      });
    }
    this.swUpdate.versionUpdates.subscribe(() => {
      console.log("A dashboard update is available");
      this.openBottomSheet();
    });
  }

  ngOnDestroy() {
    if (this.updateCheckInterval) {
      this.updateCheckInterval.unsubscribe();
      this.updateCheckInterval = null;
    }
  }

  private openBottomSheet() {
    this.snackbar
      .open(UpdateNotificationComponent, { hasBackdrop: false })
      .afterDismissed()
      .subscribe((data: { [key: string]: any }) => {
        if (data && data.result) {
          this.swUpdate
            .activateUpdate()
            .then(() => window.location.reload())
            .catch((error) => console.error("Unable to load update:", error));
        } else {
          this.updateDismissed = true;
        }
      });
  }
}
