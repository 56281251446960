/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DashboardEventBase } from './DashboardEventBase';
import type { SensitiveFallEvent } from './SensitiveFallEvent';

export type DashboardSensitiveFallEvent = (DashboardEventBase & {
    type: DashboardSensitiveFallEvent.type;
    payload: SensitiveFallEvent;
});

export namespace DashboardSensitiveFallEvent {

    export enum type {
        Calling = 1,
        FallSuspected = 2,
        FallExit = 4,
        Finished = 5,
    }


}

