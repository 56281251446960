import { Component, OnInit } from "@angular/core";
import { DevicesService } from "../devices.service";
import { Router } from "@angular/router";
import { HomeDevice, DeviceStatus } from "@walabot-mqtt-dashboard/api";

@Component({
  selector: "app-devices",
  templateUrl: "./devices.component.html",
  styleUrls: ["./devices.component.css"],
})
export class DevicesComponent implements OnInit {
  private selectedDevice: HomeDevice;
  recentDevices: HomeDevice[];
  devices: Partial<HomeDevice>[] = [];
  deviceStatus = [...new Set([...Object.values(DeviceStatus)])];
  statusFilter;
  constructor(private devicesService: DevicesService, private router: Router) {}

  ngOnInit() {
    void this.devicesService
      .getDevices(null, 10)
      .then((devices) => (this.devices = devices));
  }

  public onSelect(device: HomeDevice) {
    console.log("onDeviceClicked");
    void this.router.navigate([
      "admin/admin/device/" + device.deviceId.deviceId,
    ]);
    this.selectedDevice = device;
  }

  public onSearchByName(event: KeyboardEvent, value: string) {
    if (event.key === "Enter") {
      console.log("searching for " + value);
      void this.devicesService
        .findDeviceByName(value)
        .then((devices) => (this.devices = devices));
    }
  }

  public onSearchByPhone(event: KeyboardEvent, value: string) {
    if (event.key === "Enter") {
      console.log("searching for " + value);
      void this.devicesService
        .findDeviceByCareGiverNumber(value)
        .then((devices) => (this.devices = devices));
    }
  }

  public onSearchByCareGiverUid(event: KeyboardEvent, value: string) {
    if (event.key === "Enter") {
      console.log("searching for " + value);
      void this.devicesService
        .findDeviceByCareGiverUid(value)
        .then((devices) => (this.devices = devices));
    }
  }

  public onSearchByCareGiverName(event: KeyboardEvent, value: string) {
    if (event.key === "Enter") {
      console.log("searching for " + value);
      void this.devicesService
        .findDeviceByCareGiverName(value)
        .then((devices) => (this.devices = devices));
    }
  }

  public onSearchById(event: KeyboardEvent, value: string) {
    if (event.key === "Enter") {
      console.log("searching for " + value);
      void this.devicesService
        .findDeviceById(value)
        .then((devices) => (this.devices = devices));
    }
  }

  public onSearchBySerial(event: KeyboardEvent, value: string) {
    if (event.key === "Enter") {
      console.log("searching for " + value);
      void this.devicesService
        .findDeviceBySerial(value)
        .then((devices) => (this.devices = devices));
    }
  }

  public searchByCreationDate(
    fromDate = new Date().toDateString(),
    toDate = new Date().toDateString(),
    toTime = "00:00"
  ) {
    const fromDateTime = new Date(fromDate + " " + toTime);
    const toDateTime = new Date(toDate + " " + toTime);
    console.log("searchByCreationDate", fromDateTime, toDateTime);
    if (!isNaN(fromDateTime.getTime()) && !isNaN(toDateTime.getTime())) {
      void this.devicesService
        .findDevicesByCreationDate(fromDateTime, toDateTime)
        .then((devices) => (this.devices = devices));
    }
  }

  public onSearchByStatus(status: DeviceStatus) {
    void this.devicesService
      .findDeviceIdsByStatus(status)
      .then((devices) => (this.devices = devices));
  }

  public loadMore() {
    if (this.devices && this.devices.length > 0) {
      const fromId = this.devices[this.devices.length - 1].deviceId.deviceId;
      void this.devicesService.getDevices(fromId, 10).then((devices) => {
        devices.forEach((device) => this.devices.push(device));
      });
    }
  }
}
