import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

interface DialogData {
  unresolvedAlertsCount: number;
  btnHandler?: (confirmed: boolean) => void;
}

@Component({
  selector: "app-unresolved-alerts-dialog",
  templateUrl: "./unresolved-alerts-dialog.component.html",
  styleUrls: ["./unresolved-alerts-dialog.component.css"],
})
export class UnresolvedAlertsDialogComponent {
  unresolvedAlertsCount: number;
  unresolvedAlertsPromptText = $localize`:@@unresolved-alerts-prompt-text:There are unresolved fall alerts. <br/> Please attend now or skip and resolve later.`;

  constructor(
    public dialog: MatDialogRef<UnresolvedAlertsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.unresolvedAlertsCount = this.data.unresolvedAlertsCount;
  }

  onConfirmed() {
    if (this.data.btnHandler) {
      this.data.btnHandler(true);
      this.dialog.close({ confirmed: true });
    }
  }

  onClose() {
    this.dialog.close({ confirmed: false });
  }
}
