/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { generateTokenResponse } from '../models/generateTokenResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ExternalAuthAPI {

    /**
     * Create Auth Token from email and password
     * Used by customer to generate token for further API calls
     * @param requestBody
     * @returns generateTokenResponse OK
     * @throws ApiError
     */
    public static vayyarLogin(
        requestBody?: {
            email?: string;
            password?: string;
        },
    ): CancelablePromise<generateTokenResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/token/idToken',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }

}
