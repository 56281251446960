/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DashboardEventBase } from './DashboardEventBase';
import type { DeviceState } from './DeviceState';

export type DashboardStatusEvent = (DashboardEventBase & {
    type: DashboardStatusEvent.type;
    payload: DeviceState;
});

export namespace DashboardStatusEvent {

    export enum type {
        Status = 2,
    }


}

