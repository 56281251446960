/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AlibabaIotPlatformDetails = {
    /**
     * schema identifier of parsing the content
     */
    type: AlibabaIotPlatformDetails.type;
    /**
     * MQTT Host URL to connect to
     */
    mqttHost: string;
    /**
     * MQTT Port to connect to
     */
    mqttPort: number;
    /**
     * Product Key to connect with
     */
    productKey: string;
    /**
     * Device Name to connect with
     */
    deviceName: string;
    /**
     * Device Secret to connect with
     */
    deviceSecret: string;
};

export namespace AlibabaIotPlatformDetails {

    /**
     * schema identifier of parsing the content
     */
    export enum type {
        ALIBABA_IOT_PLATFORM = 'alibaba-iot-platform',
    }


}

