<div style="padding-left: 1em; padding-right: 1em; padding-top: 1em">
  <!-- toolbar -->
  <div style="display: flex; flex-direction: row">
    <!-- toolbar left search -->
    <div class="custom-select-div dt-select-div">
      <span i18n="@@time-and-date">Time & Date</span>
      <mat-select
        #dateSelector
        [(ngModel)]="selectedRange"
        name="timeRange"
        (ngModelChange)="onTimeRangeChange($event)"
        panelClass="custom-select"
        data-cy="date-time-select"
      >
        <div #customTimeOption></div>
        <mat-option *ngFor="let timeRange of timeRanges" [value]="timeRange.value" [attr.data-cy]="'date-time-value-' + timeRange.value">
          {{ timeRange.name }}
        </mat-option>
        <mat-option [value]="customTimeRange" class="custom-time-range">
          <div style="border-top: 2px solid #e3e3e3" (click)="openCustomTimeRange($event)">
            <span i18n="@@custom-range"> Custom Range </span>
            <div class="arrow-icon">
              <img src="../assets/icons/arrow.svg" />
            </div>
          </div>
          <input
            #dateTimeTrigger
            style="display: none"
            [owlDateTime]="dt1"
            [selectMode]="'range'"
            [owlDateTimeTrigger]="dt1"
            [min]="minValidDateTime"
            [max]="maxValidDateTime"
            placeholder="Date Time"
            i18n-placeholder="@@date-time-placeholder"
            (dateTimeInput)="onSelectedCustomDate($event)"
          />
          <owl-date-time [panelClass]="'pickerCls'" #dt1> </owl-date-time>
        </mat-option>
      </mat-select>
    </div>
    <div class="custom-select-div" style="margin-left: 16px">
      <span i18n="@@suite">Suite</span>
      <mat-select
        [(ngModel)]="selectedSuites"
        multiple
        (openedChange)="onMultiSelectSelectionOpened($event)"
        (ngModelChange)="selectedSuiteChanged($event)"
        panelClass="custom-select suite-select-panel"
        data-cy="suite-select"
      >
        <mat-option (click)="toggleSelectAllSuites($event)" [value]="SelectAllSuites" i18n="@@select-all" data-cy="suite-select-all">
          Select All
        </mat-option>
        <mat-option *ngFor="let suite of suiteMapping | keyvalue: valueAscOrder" [value]="suite.key" [disabled]="selectedSuites.length > 9 && !selectedSuites.includes(suite.key)" [attr.data-cy]="suite.key">
          {{ suite.value.name }}
        </mat-option>
      </mat-select>
    </div>
    <div class="custom-select-div" style="margin-left: 16px">
      <span i18n="@@event-type">Event Type</span>
      <mat-select multiple
        [(ngModel)]="selectedTypes"
        placeholder="All"
        i18n-placeholder="@@all"
        (openedChange)="onMultiSelectSelectionOpened($event)"
        (ngModelChange)="onTypeChanged($event)"
        panelClass="custom-select"
        data-cy="event-type-select"
      >
        <mat-option data-cy="event-type-all" [value]="SelectAllTypes" (click)="toggleSelectAllTypes($event)">
          <div style="align-items: center; display: flex; flex-direction: row">
            <span style="vertical-align: middle; margin-left: 1em" i18n="@@all">All</span>
          </div>
        </mat-option>
        <mat-option *ngFor="let type of eventTypeSelectMap | keyvalue" [value]="type.key" [attr.data-cy]="'event-type-' + type.key.type + '-' + type.key.status">
          <div style="align-items: center; display: flex; flex-direction: row">
            <div [class]="type.value.iconClass" style="vertical-align: middle"></div>
            <span>{{ type.value.value }}</span>
          </div>
        </mat-option>
      </mat-select>
    </div>
    <!-- toolbar right buttons -->
    <button mat-raised-button type="button" class="btn btn-secondary download" i18n="@@export-to-csv" [disabled]="selectedSuites.length === 0" (click)="downloadCSV()">
      <span class="fa fa-download"></span>Export to CSV
    </button>
    <button mat-button class="refresh-btn" (click)="refresh()">
      <mat-icon>cached</mat-icon>
      <span i18n="@@refresh-results">Refresh Results</span>
    </button>
  </div>
  <div class="range-date">
    <span i18n="@@dates-of-report">Dates of Report</span>:
    {{ selectedFrom | date: "MMMM dd, yyyy" }} - {{ selectedTo | date: "MMMM dd, yyyy" }}
  </div>

  <!-- table -->
  <table
    mat-table
    matSort
    class="event-table mat-elevation-z8"
    [dataSource]="filteredEvents"
    data-cy="events-table"
    (contentChanged)="onTableContentChanged()"
  >
    <ng-container matColumnDef="timestamp">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@time-and-date">Time & Date</th>
      <td mat-cell *matCellDef="let element" [attr.data-timestamp]="element.timestamp">
        <div>{{ element.timestamp | dateTimezoneName: timeFormat }}</div>
        <div>
          {{ element.timestamp | date: dateFormat }}
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="suiteName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@suite-name">Suite Name</th>
      <td mat-cell *matCellDef="let element" [attr.data-suite-id]="element.suiteId">
        <div style="display: flex; flex-direction: row; align-items: center">
          <span style="vertical-align: middle">{{ element.suiteName }}</span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="roomName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@room-name">Room Name</th>
      <td mat-cell *matCellDef="let element">
        <div style="display: flex; flex-direction: row; align-items: center">
          <svg class="status-icon">
            <use [attr.xlink:href]="element.roomIcon"></use>
          </svg>
          <span style="vertical-align: middle">{{ element.roomName }}</span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="eventTypeStr">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@event-type">Event Type</th>
      <td mat-cell *matCellDef="let element">
        <div style="display: flex; flex-direction: row; align-items: center">
          <div [class]="element.iconClass" style="vertical-align: middle"></div>
          <span style="vertical-align: middle; margin-left: 1em">{{ element.eventTypeStr }}</span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="deviceId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@device-id">Device ID</th>
      <td mat-cell *matCellDef="let element">
        <span>{{ element.deviceId }}</span>
      </td>
    </ng-container>

    <ng-container *ngIf="detailedFallHistory" matColumnDef="extra">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="location-th pre-wrap">
        <span *ngIf="isMetric(); else feet" i18n="@@location-x-y-cm">Location (x,y cm)</span>
        <ng-template #feet>
          <span i18n="@@location-feet">Location (x,y ft'in")</span>
        </ng-template>
      </th>
      <td mat-cell *matCellDef="let element">
        <span>{{ element.extra }}</span>
      </td>
    </ng-container>
    <ng-container *ngIf="detailedFallHistory" matColumnDef="tarHeightEst">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="pre-wrap">
        <span *ngIf="isMetric(); else feet" i18n="@@target-height-estimation-cm">Target Height Estimation (cm)</span>
        <ng-template #feet>
          <span i18n="@@target-height-estimation-feet">Target Height Estimation (ft'in")</span>
        </ng-template>
      </th>
      <td mat-cell *matCellDef="let element">
        <span>{{ element.tarHeightEst }}</span>
      </td>
    </ng-container>
    <ng-container *ngIf="detailedFallHistory" matColumnDef="exitReason">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@fall-event-exit-reason" class="pre-wrap">Exit Reason</th>
      <td mat-cell *matCellDef="let element">
        <span>{{ element.exitReason }}</span>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
    <mat-progress-spinner color="primary" mode="indeterminate">
    </mat-progress-spinner>
  </mat-card>
  <mat-paginator #paginator [pageSizeOptions]="[100, 10, 20]" (page)="onPageChanged($event)"></mat-paginator>
</div>
