import { Injectable, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";

// TODO: Add Angular decorator.
@Injectable()
export abstract class BaseComponent implements OnDestroy {
  ngUnsubsrcibe = new Subject<void>();

  ngOnDestroy() {
    this.ngUnsubsrcibe.next();
    this.ngUnsubsrcibe.complete();
  }
}
