/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PresenceEventStatus {
    Occupied = 'Occupied',
    Vacant = 'Vacant',
    InBed = 'InBed',
    OutOfBed = 'OutOfBed',
    OutOfRoom = 'OutOfRoom',
    Unknown = 'Unknown',
}
