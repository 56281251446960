/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateBaseUrl = {
    /**
     * Update Base URL
     */
    type: UpdateBaseUrl.type;
    /**
     * The backend base URL the device is expected to use
     */
    baseUrl: string;
};

export namespace UpdateBaseUrl {

    /**
     * Update Base URL
     */
    export enum type {
        UpdateBaseUrl = 'UpdateBaseUrl',
    }


}

