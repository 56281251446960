<h3>Fall Risk per Apartment</h3>
<div class="chips">
    <div *ngFor="let level of analytics?.dict.risk_level" class="chip" [ngStyle]="{backgroundColor: '#' + level.color}">
        {{level.name}}
    </div>
</div>
<button mat-stroked-button type="button" class="btn btn-secondary download" (click)="downloadCSV()" tabindex="-1">
    <span class="material-icons material-symbols-outlined">output</span>
    <span i18n="@@export">Export</span>
</button>
<div  style="width: 100%; height: 100%; overflow: auto;">
    <table mat-table [dataSource]="topRiskResidents">
        <ng-container matColumnDef="suite">
            <th mat-header-cell *matHeaderCellDef i18n="@@apartment">Apartment</th>
            <td mat-cell *matCellDef="let element" [ngStyle]="{color: getRiskLevelColor(element.risk_level_id)}"> {{element.suite_name}} </td>
        </ng-container>
        <ng-container matColumnDef="score">
            <th mat-header-cell *matHeaderCellDef i18n="@@risk-level">Risk Level</th>
            <td mat-cell *matCellDef="let element" [ngStyle]="{color: getRiskLevelColor(element.risk_level_id)}">
                {{element.risk_score}}
            </td>
        </ng-container>
        <ng-container matColumnDef="link">
            <th mat-header-cell *matHeaderCellDef>Apartment View Link</th>
            <td mat-cell *matCellDef="let element">
                <a routerLink="/{{basePath}}/insights/{{element.suite_id}}" (click)="close()">></a>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
<button mat-stroked-button i18n="@@close" class="close" (click)="close()">Close</button>
