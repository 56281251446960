import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  DeviceInfo,
  DeviceRoomConfig,
  Room,
  RoomType,
  Suite,
} from "@walabot-mqtt-dashboard/api";
import {
  DeviceMonitoringService,
  DeviceRoomConfigMapping,
  RoomsService,
} from "../../ui.module";
import { getDeviceIcon, RoomTypeMapping } from "../../device-icon";
import { MatSelectChange } from "@angular/material/select";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-suite",
  templateUrl: "./suite.component.html",
  styleUrls: ["./suite.component.css"],
})
export class SuiteComponent implements OnChanges {
  @Input() suite: {
    suite: Suite;
    rooms: Array<{ room: Room; devices: DeviceRoomConfigMapping }>;
  };
  @Output() configureDevice: EventEmitter<DeviceRoomConfig> =
    new EventEmitter<DeviceRoomConfig>();
  @Output() moveDevice: EventEmitter<Room> = new EventEmitter<Room>();
  @Output() deleteDevice: EventEmitter<{
    device: DeviceRoomConfig;
    room: Room;
  }> = new EventEmitter<{
    device: DeviceRoomConfig;
    room: Room;
  }>();

  types = Object.values(RoomType)
    .filter((val) => typeof val === "number")
    .map((val) => val as RoomType);

  deviceInfo: { [deviceId: string]: DeviceInfo } = {};

  constructor(
    private deviceService: DeviceMonitoringService,
    private roomsService: RoomsService,
    private snackBar: MatSnackBar
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if ("suite" in changes) {
      this.suite.rooms.forEach((room) => {
        Object.values(room.devices).forEach((device) => {
          this.deviceService
            .getDeviceInfo(device.deviceId)
            .then((deviceInfo) => {
              this.deviceInfo[device.deviceId] = deviceInfo;
            })
            .catch((err) => {
              console.error(err);
            });
        });
      });
    }
  }

  getDeviceIcon(roomType: string | RoomType) {
    return getDeviceIcon(roomType);
  }

  getDeviceName(type: RoomType) {
    return RoomTypeMapping.get(parseInt(String(type), 10)).name;
  }

  onConfigureDevice(device: DeviceRoomConfig) {
    this.configureDevice.emit(device);
  }

  onMoveDevice(room: Room) {
    this.moveDevice.emit(room);
  }

  onDeleteDevice(device: DeviceRoomConfig, room: Room) {
    this.deleteDevice.emit({ device, room });
  }

  roomTypeChanged(deviceId: string, room: Room, e: MatSelectChange) {
    this.roomsService
      .changeDeviceTypeAndDeviceRoomType(
        deviceId,
        room.id,
        <RoomType>e.value,
        room.name
      )
      .catch((error) => {
        console.error("Failed to update room type: ", error);
        this.showErrorMsg(
          $localize`:@@failed-to-save-room-type:Failed to save room type`
        );
      });
  }

  showErrorMsg(msg: string) {
    this.snackBar.open(msg, "X", {
      duration: 3000,
      horizontalPosition: "left",
      panelClass: ["success-msg", "error"],
    });
  }
}
