<div style="margin-left: 20px; margin-right: 20px">
  <form [formGroup]="careGiversForm" *ngIf="emergencyContacts">
    <div style="margin-top: 21px; display: inline-block; margin-bottom: 1em">
      <button
        mat-button
        class="add-btn"
        (click)="addEmergencyContact()"
        [disabled]="careGiversFormArray.length>=4"
      >
        <img src="../assets/icons/plus-icon.svg" class="plus-icon" />
        <span style="margin-left: 10px" i18n="@@add-contact">Add Contact </span>
      </button>
    </div>
    <table
      #careGiversTable
      formArrayName="careGiversFormArray"
      mat-table
      [dataSource]="dataSource"
      class="contact-list mat-elevation-z8"
      style="width: 100%"
      cdkDropList
      (cdkDropListDropped)="reorder($event)"
      [cdkDropListData]="dataSource"
    >
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef style="width: 40px"></th>
        <td
          mat-cell
          *matCellDef="let element;let index=index;"
          class="text"
          style="
            display: flex;
            align-items: center;
            padding-left: 0px;
            pointer-events: none;
          "
        >
          <span style="margin-left: 20px; width: 1em; pointer-events: none">
            {{element.index + 1}}
          </span>
          <div
            [class]="this.careGiversForm.disabled&&!inProgress?'grippy':'grippy-disabled'"
            style="pointer-events: all; margin-left: 14px"
            class="grippy"
            cdkDragHandle
          ></div>
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>
          <div class="header">
            <div></div>
            <span i18n="@@full-name">Full Name</span>
          </div>
        </th>
        <td
          mat-cell
          *matCellDef="let element;let index=index"
          class="text"
          [formGroupName]="index"
          (mousedown)="onInvalidDragEventMouseDown()"
        >
          <input
            style="
              margin-left: 20px;
              background: transparent;
              border: transparent;
              pointer-events: all;
            "
            class="caregiver-name"
            formControlName="name"
            placeholder="Enter Full Name"
            i18n-placeholder="@@enter-full-name-placeholder"
          />
        </td>
      </ng-container>
      <ng-container matColumnDef="phonenumber">
        <th mat-header-cell *matHeaderCellDef>
          <div class="header">
            <div></div>
            <span i18n="@@phone-number">Phone Number</span>
          </div>
        </th>
        <td
          mat-cell
          *matCellDef="let element;let index=index"
          [formGroupName]="index"
          (mousedown)="onInvalidDragEventMouseDown()"
          style="width: 55%"
        >
          <app-intl-tel-input
            name="phone"
            formControlName="phoneNumber"
            placeholder="Enter Phone Number"
            i18n-placeholder="@@enter-phone-number-placeholder"
          ></app-intl-tel-input>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions" stickyEnd>
        <th mat-header-cell *matHeaderCellDef></th>
        <td
          mat-cell
          *matCellDef="let element;let index=index"
          class="text"
          (mousedown)="onInvalidDragEventMouseDown()"
        >
          <div
            class="caregiver-menu-container"
            *ngIf="!(careGiversFormArray.at(index).enabled||careGiversForm.dirty||inProgress)"
          >
            <img
              src="../../../assets/icons/menu-icon.svg"
              [matMenuTriggerFor]="menu"
              (click)="$event.stopPropagation()"
              style="cursor: pointer; pointer-events: all"
            />
            <mat-menu #menu="matMenu" xPosition="before">
              <button
                mat-menu-item
                data-cy="edit-contact"
                (click)="careGiversFormArray.at(index).enable()"
                i18n="@@edit"
              >
                Edit
              </button>
              <button
                mat-menu-item
                data-cy="remove-contact"
                (click)="remove(index,element)"
                i18n="@@remove"
              >
                Remove
              </button>
            </mat-menu>
          </div>
          <div *ngIf="careGiversFormArray.at(index).enabled" class="edit-btns">
            <img
              src="../../../assets/icons/edit-cancel.svg"
              style="margin-right: 9px"
              (click)="cancelEdit(index,element)"
            />
            <img
              src="../../../assets/icons/edit-done.svg"
              (click)="finishEdit(index,element)"
            />
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns; let index=index;"
        [attr.data-cy]="careGiversFormArray.at(index).value.name"
        cdkDrag
        [ngClass]="{'selected':careGiversFormArray.at(index).enabled}"
        [cdkDragData]="row"
        cdkDrag
        (cdkDragStarted)="dragStarted()"
      ></tr>
    </table>
    <div
      style="
        display: inline-flex;
        float: right;
        margin-bottom: 1em;
        margin-top: 2em;
      "
      *ngIf="careGiversForm.dirty&&careGiversForm.disabled&&!inProgress"
    >
      <button mat-button class="cancel-btn" (click)="cancel()" i18n="@@cancel">
        Cancel
      </button>
      <button
        mat-button
        class="btn"
        [disabled]="inProgress"
        (click)="save()"
        i18n="@@save-changes"
      >
        <span> Save Changes</span>
      </button>
    </div>
  </form>
  <div style="width: 100%; display: flex; margin-top: 2em" *ngIf="inProgress">
    <mat-spinner style="margin-left: auto; margin-right: auto"></mat-spinner>
  </div>
  <!-- <div cdkDropList lass="contact-list" (cdkDropListDropped)="reorder($event)">
      <div class="contact-box" *ngFor="let contact of emergencyContacts" cdkDrag>
          {{contact.priority+1}}. {{contact.name}}
      </div>
  </div> -->
  <!-- <div cdkDropList class="contact-list" (cdkDropListDropped)="reorder($event)">
      <div class="contact-box" *ngFor="let contact of emergencyContacts" cdkDrag>
          <span>{{contact.priority+1}}.{{contact.name}}</span>
      </div>
  </div> -->
</div>
