import { convertMetersToFeet } from "./device-settings-utils";

/**
 * When the appConfig.enableDoorEvents is true.
 * If a subregion is set to "isDoor": true -> the subregion can exceed the room size (an exception to the regular limitation)
 */
export const DOOR_THICKNESS_IN_METERS = 0.1; // 10cm

export const ROOM_LIMITS_IN_METERS = {
  // wall
  lengthMin: 0.3,
  lengthMax: 4,
  leftMin: -3,
  leftMax: 0,
  rightMin: 0,
  rightMax: 2,
  standardHeight: 1.524,
  inBedHeightMin: 1.0,
  inBedHeightMax: 2.5,
  xAxisWallLength: 4,
  // tilt45
  tiltHeightMin: 2.1,
  tiltHeightMax: 2.4,
  // ceiling
  heightMin: 2.3,
  heightMax: 3,
  XMinValueMin: -2,
  XMinValueMax: 0,
  XMaxValueMin: 0,
  XMaxValueMax: 2,
  YMinValueMin: -2.5,
  YMinValueMax: 0,
  YMaxValueMin: 0,
  YMaxValueMax: 2.5,
  // ceiling45
  YMinCeiling45ValueMin: -3,
  YMinCeiling45ValueMax: 0,
  YMaxCeiling45ValueMin: 0,
  YMaxCeiling45ValueMax: 3,
  yAxisCeilingLength: 5,
};

export const ROOM_LIMITS = {
  wall: {
    YMaxValueMin: {
      meters: ROOM_LIMITS_IN_METERS.lengthMin,
      feet: convertToFeetSystem(ROOM_LIMITS_IN_METERS.lengthMin).feet,
      inches: convertToFeetSystem(ROOM_LIMITS_IN_METERS.lengthMin).inches,
    },
    YMaxValueMax: {
      meters: ROOM_LIMITS_IN_METERS.lengthMax,
      feet: convertToFeetSystem(ROOM_LIMITS_IN_METERS.lengthMax).feet,
      inches: convertToFeetSystem(ROOM_LIMITS_IN_METERS.lengthMax).inches,
    },
    XMinValueMin: {
      meters: ROOM_LIMITS_IN_METERS.leftMin,
      feet: convertToFeetSystem(ROOM_LIMITS_IN_METERS.leftMin).feet,
      inches: convertToFeetSystem(ROOM_LIMITS_IN_METERS.leftMin).inches,
    },
    XMinValueMax: {
      meters: ROOM_LIMITS_IN_METERS.leftMax,
      feet: convertToFeetSystem(ROOM_LIMITS_IN_METERS.leftMax).feet,
      inches: convertToFeetSystem(ROOM_LIMITS_IN_METERS.leftMax).inches,
    },
    XMaxValueMin: {
      meters: ROOM_LIMITS_IN_METERS.rightMin,
      feet: convertToFeetSystem(ROOM_LIMITS_IN_METERS.rightMin).feet,
      inches: convertToFeetSystem(ROOM_LIMITS_IN_METERS.rightMin).inches,
    },
    XMaxValueMax: {
      meters: ROOM_LIMITS_IN_METERS.rightMax,
      feet: convertToFeetSystem(ROOM_LIMITS_IN_METERS.rightMax).feet,
      inches: convertToFeetSystem(ROOM_LIMITS_IN_METERS.rightMax).inches,
    },
    standardHeight: {
      meters: ROOM_LIMITS_IN_METERS.standardHeight,
      feet: convertToFeetSystem(ROOM_LIMITS_IN_METERS.standardHeight).feet,
      inches: convertToFeetSystem(ROOM_LIMITS_IN_METERS.standardHeight).inches,
    },
    heightMin: {
      meters: ROOM_LIMITS_IN_METERS.inBedHeightMin,
      feet: convertToFeetSystem(ROOM_LIMITS_IN_METERS.inBedHeightMin).feet,
      inches: convertToFeetSystem(ROOM_LIMITS_IN_METERS.inBedHeightMin).inches,
    },
    heightMax: {
      meters: ROOM_LIMITS_IN_METERS.inBedHeightMax,
      feet: convertToFeetSystem(ROOM_LIMITS_IN_METERS.inBedHeightMax).feet,
      inches: convertToFeetSystem(ROOM_LIMITS_IN_METERS.inBedHeightMax).inches,
    },
    xAxisLength: {
      meter: ROOM_LIMITS_IN_METERS.xAxisWallLength,
      feet: convertToFeetSystem(ROOM_LIMITS_IN_METERS.xAxisWallLength).feet,
      inches: convertToFeetSystem(ROOM_LIMITS_IN_METERS.xAxisWallLength).inches,
    },
  },
  tilt45: {
    YMaxValueMin: {
      meters: ROOM_LIMITS_IN_METERS.lengthMin,
      feet: convertToFeetSystem(ROOM_LIMITS_IN_METERS.lengthMin).feet,
      inches: convertToFeetSystem(ROOM_LIMITS_IN_METERS.lengthMin).inches,
    },
    YMaxValueMax: {
      meters: ROOM_LIMITS_IN_METERS.lengthMax,
      feet: convertToFeetSystem(ROOM_LIMITS_IN_METERS.lengthMax).feet,
      inches: convertToFeetSystem(ROOM_LIMITS_IN_METERS.lengthMax).inches,
    },
    XMinValueMin: {
      meters: ROOM_LIMITS_IN_METERS.leftMin,
      feet: convertToFeetSystem(ROOM_LIMITS_IN_METERS.leftMin).feet,
      inches: convertToFeetSystem(ROOM_LIMITS_IN_METERS.leftMin).inches,
    },
    XMinValueMax: {
      meters: ROOM_LIMITS_IN_METERS.leftMax,
      feet: convertToFeetSystem(ROOM_LIMITS_IN_METERS.leftMax).feet,
      inches: convertToFeetSystem(ROOM_LIMITS_IN_METERS.leftMax).inches,
    },
    XMaxValueMin: {
      meters: ROOM_LIMITS_IN_METERS.rightMin,
      feet: convertToFeetSystem(ROOM_LIMITS_IN_METERS.rightMin).feet,
      inches: convertToFeetSystem(ROOM_LIMITS_IN_METERS.rightMin).inches,
    },
    XMaxValueMax: {
      meters: ROOM_LIMITS_IN_METERS.rightMax,
      feet: convertToFeetSystem(ROOM_LIMITS_IN_METERS.rightMax).feet,
      inches: convertToFeetSystem(ROOM_LIMITS_IN_METERS.rightMax).inches,
    },
    heightMin: {
      meters: ROOM_LIMITS_IN_METERS.tiltHeightMin,
      feet: convertToFeetSystem(ROOM_LIMITS_IN_METERS.tiltHeightMin).feet,
      inches: convertToFeetSystem(ROOM_LIMITS_IN_METERS.tiltHeightMin).inches,
    },
    heightMax: {
      meters: ROOM_LIMITS_IN_METERS.tiltHeightMax,
      feet: convertToFeetSystem(ROOM_LIMITS_IN_METERS.tiltHeightMax).feet,
      inches: convertToFeetSystem(ROOM_LIMITS_IN_METERS.tiltHeightMax).inches,
    },
    xAxisLength: {
      meter: ROOM_LIMITS_IN_METERS.xAxisWallLength,
      feet: convertToFeetSystem(ROOM_LIMITS_IN_METERS.xAxisWallLength).feet,
      inches: convertToFeetSystem(ROOM_LIMITS_IN_METERS.xAxisWallLength).inches,
    },
  },
  ceiling: {
    heightMin: {
      meters: ROOM_LIMITS_IN_METERS.heightMin,
      feet: convertToFeetSystem(ROOM_LIMITS_IN_METERS.heightMin).feet,
      inches: convertToFeetSystem(ROOM_LIMITS_IN_METERS.heightMin).inches,
    },
    heightMax: {
      meters: ROOM_LIMITS_IN_METERS.heightMax,
      feet: convertToFeetSystem(ROOM_LIMITS_IN_METERS.heightMax).feet,
      inches: convertToFeetSystem(ROOM_LIMITS_IN_METERS.heightMax).inches,
    },
    XMinValueMin: {
      meters: ROOM_LIMITS_IN_METERS.XMinValueMin,
      feet: convertToFeetSystem(ROOM_LIMITS_IN_METERS.XMinValueMin).feet,
      inches: convertToFeetSystem(ROOM_LIMITS_IN_METERS.XMinValueMin).inches,
    },
    XMinValueMax: {
      meters: ROOM_LIMITS_IN_METERS.XMinValueMax,
      feet: convertToFeetSystem(ROOM_LIMITS_IN_METERS.XMinValueMax).feet,
      inches: convertToFeetSystem(ROOM_LIMITS_IN_METERS.XMinValueMax).inches,
    },
    XMaxValueMin: {
      meters: ROOM_LIMITS_IN_METERS.XMaxValueMin,
      feet: convertToFeetSystem(ROOM_LIMITS_IN_METERS.XMaxValueMin).feet,
      inches: convertToFeetSystem(ROOM_LIMITS_IN_METERS.XMaxValueMin).inches,
    },
    XMaxValueMax: {
      meters: ROOM_LIMITS_IN_METERS.XMaxValueMax,
      feet: convertToFeetSystem(ROOM_LIMITS_IN_METERS.XMaxValueMax).feet,
      inches: convertToFeetSystem(ROOM_LIMITS_IN_METERS.XMaxValueMax).inches,
    },
    YMinValueMin: {
      meters: ROOM_LIMITS_IN_METERS.YMinValueMin,
      feet: convertToFeetSystem(ROOM_LIMITS_IN_METERS.YMinValueMin).feet,
      inches: convertToFeetSystem(ROOM_LIMITS_IN_METERS.YMinValueMin).inches,
    },
    YMinValueMax: {
      meters: ROOM_LIMITS_IN_METERS.YMinValueMax,
      feet: convertToFeetSystem(ROOM_LIMITS_IN_METERS.YMinValueMax).feet,
      inches: convertToFeetSystem(ROOM_LIMITS_IN_METERS.YMinValueMax).inches,
    },
    YMaxValueMin: {
      meters: ROOM_LIMITS_IN_METERS.YMaxValueMin,
      feet: convertToFeetSystem(ROOM_LIMITS_IN_METERS.YMaxValueMin).feet,
      inches: convertToFeetSystem(ROOM_LIMITS_IN_METERS.YMaxValueMin).inches,
    },
    YMaxValueMax: {
      meters: ROOM_LIMITS_IN_METERS.YMaxValueMax,
      feet: convertToFeetSystem(ROOM_LIMITS_IN_METERS.YMaxValueMax).feet,
      inches: convertToFeetSystem(ROOM_LIMITS_IN_METERS.YMaxValueMax).inches,
    },
  },
  ceiling45: {
    heightMin: {
      meters: ROOM_LIMITS_IN_METERS.heightMin,
      feet: convertToFeetSystem(ROOM_LIMITS_IN_METERS.heightMin).feet,
      inches: convertToFeetSystem(ROOM_LIMITS_IN_METERS.heightMin).inches,
    },
    heightMax: {
      meters: ROOM_LIMITS_IN_METERS.heightMax,
      feet: convertToFeetSystem(ROOM_LIMITS_IN_METERS.heightMax).feet,
      inches: convertToFeetSystem(ROOM_LIMITS_IN_METERS.heightMax).inches,
    },
    XMinValueMin: {
      meters: ROOM_LIMITS_IN_METERS.XMinValueMin,
      feet: convertToFeetSystem(ROOM_LIMITS_IN_METERS.XMinValueMin).feet,
      inches: convertToFeetSystem(ROOM_LIMITS_IN_METERS.XMinValueMin).inches,
    },
    XMinValueMax: {
      meters: ROOM_LIMITS_IN_METERS.XMinValueMax,
      feet: convertToFeetSystem(ROOM_LIMITS_IN_METERS.XMinValueMax).feet,
      inches: convertToFeetSystem(ROOM_LIMITS_IN_METERS.XMinValueMax).inches,
    },
    XMaxValueMin: {
      meters: ROOM_LIMITS_IN_METERS.XMaxValueMin,
      feet: convertToFeetSystem(ROOM_LIMITS_IN_METERS.XMaxValueMin).feet,
      inches: convertToFeetSystem(ROOM_LIMITS_IN_METERS.XMaxValueMin).inches,
    },
    XMaxValueMax: {
      meters: ROOM_LIMITS_IN_METERS.XMaxValueMax,
      feet: convertToFeetSystem(ROOM_LIMITS_IN_METERS.XMaxValueMax).feet,
      inches: convertToFeetSystem(ROOM_LIMITS_IN_METERS.XMaxValueMax).inches,
    },
    YMinValueMin: {
      meters: ROOM_LIMITS_IN_METERS.YMinCeiling45ValueMin,
      feet: convertToFeetSystem(ROOM_LIMITS_IN_METERS.YMinCeiling45ValueMin)
        .feet,
      inches: convertToFeetSystem(ROOM_LIMITS_IN_METERS.YMinCeiling45ValueMin)
        .inches,
    },
    YMinValueMax: {
      meters: ROOM_LIMITS_IN_METERS.YMinCeiling45ValueMax,
      feet: convertToFeetSystem(ROOM_LIMITS_IN_METERS.YMinCeiling45ValueMax)
        .feet,
      inches: convertToFeetSystem(ROOM_LIMITS_IN_METERS.YMinCeiling45ValueMax)
        .inches,
    },
    YMaxValueMin: {
      meters: ROOM_LIMITS_IN_METERS.YMaxCeiling45ValueMin,
      feet: convertToFeetSystem(ROOM_LIMITS_IN_METERS.YMaxCeiling45ValueMin)
        .feet,
      inches: convertToFeetSystem(ROOM_LIMITS_IN_METERS.YMaxCeiling45ValueMin)
        .inches,
    },
    YMaxValueMax: {
      meters: ROOM_LIMITS_IN_METERS.YMaxCeiling45ValueMax,
      feet: convertToFeetSystem(ROOM_LIMITS_IN_METERS.YMaxCeiling45ValueMax)
        .feet,
      inches: convertToFeetSystem(ROOM_LIMITS_IN_METERS.YMaxCeiling45ValueMax)
        .inches,
    },
    yAxisLength: {
      meter: ROOM_LIMITS_IN_METERS.yAxisCeilingLength,
      feet: convertToFeetSystem(ROOM_LIMITS_IN_METERS.yAxisCeilingLength).feet,
      inches: convertToFeetSystem(ROOM_LIMITS_IN_METERS.yAxisCeilingLength)
        .inches,
    },
  },
};

export const SENSOR_HEIGHT_MAX_DIGITS_AFTER_DECIMAL_POINT = 1;

export function convertToFeetSystem(meters: number) {
  const feetSystem = convertMetersToFeet(meters);
  return {
    feet: feetSystem[0],
    inches: feetSystem[1],
  };
}
