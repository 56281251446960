import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  Validators,
  ValidationErrors,
  FormBuilder,
  FormControl,
} from "@angular/forms";
import { AuthService } from "../ui.module";

const { required, email } = Validators as ValidationErrors | null;

interface ForgotForm {
  email: FormControl<string>;
}

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.css"],
})
export class ForgotPasswordComponent implements OnInit {
  formGroup: FormGroup<ForgotForm>;
  emailSent: boolean;
  inProgress: boolean;
  linkSentStr: string;

  constructor(private authService: AuthService, private fb: FormBuilder) {
    this.formGroup = this.fb.group({
      email: ["", [required, email]],
    });
  }

  ngOnInit(): void {
    this.emailSent = false;
  }

  confirm() {
    this.inProgress = true;
    const email = this.formGroup.controls.email.value;
    this.linkSentStr = $localize`:@@reset-password-link-sent:We've just emailed a link to reset your password to
    ${email}:email:`;
    this.authService
      .resetPassword(email)
      .then(() => {
        console.log("email sent!");
        this.inProgress = false;
        this.emailSent = true;
      })
      .catch((err) => {
        console.log("email sent error!");

        this.inProgress = false;
        console.warn(err);
        this.formGroup.setErrors({
          email: true,
        });
      });
  }

  getEmailErrorMessage() {
    return this.formGroup.hasError("required")
      ? $localize`:@@you-must-enter-value:You must enter a value`
      : this.formGroup.hasError("email")
      ? $localize`:@@not-a-valid-email:Not a valid email`
      : "";
  }

  isValid() {
    return this.formGroup.controls.email.valid;
  }
}
