/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SoftwareUpdate = {
    type: SoftwareUpdate.type;
};

export namespace SoftwareUpdate {

    export enum type {
        SoftwareUpdate = 'SoftwareUpdate',
    }


}

