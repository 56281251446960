/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Learning = {
    type: Learning.type;
};

export namespace Learning {

    export enum type {
        Learning = 'Learning',
    }


}

