import { Component, Inject } from "@angular/core";
import { FacilityAnalytics, FallsByShift } from "../analytics.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DATE_TIME_FORMAT } from "../ui.module";
import { moment } from "../models";

import * as momentTimezone from "moment-timezone/builds/moment-timezone-with-data-10-year-range.min.js";
const moment: moment = momentTimezone as moment;

@Component({
  selector: "app-falls-by-shift-per-day",
  templateUrl: "./falls-by-shift-per-day.component.html",
  styleUrls: ["./falls-by-shift-per-day.component.css"],
})
export class FallsByShiftPerDayComponent {
  falls: Array<FallsByShift> = [];
  displayedColumns = ["suite", "shift", "day", "date"];
  dateTimeFormat = DATE_TIME_FORMAT;
  zoneAbbr: string;
  days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  constructor(
    public dialog: MatDialogRef<FallsByShiftPerDayComponent>,
    @Inject(MAT_DIALOG_DATA)
    public analytics: FacilityAnalytics
  ) {
    this.falls = analytics?.fallsByShift.byDay.filter((v) => v.fall_time);
    const zone = moment.tz.guess();
    this.zoneAbbr = moment.tz(zone).format("z");
  }

  close() {
    this.dialog.close();
  }
}
