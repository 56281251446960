/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateDeviceWebhookIdPayload } from '../models/CreateDeviceWebhookIdPayload';
import type { UpdateDeviceWebhookIdPayload } from '../models/UpdateDeviceWebhookIdPayload';
import type { Webhook } from '../models/Webhook';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WebhooksAPI {

    /**
     * Get all Webhooks
     * @returns any OK
     * @throws ApiError
     */
    public static getWebhooks(): CancelablePromise<Array<{
        webhookId: string;
        webhook: Webhook;
    }>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/webhooks/webhooks',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Create Webhook
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static createWebhook(
        requestBody?: Webhook,
    ): CancelablePromise<{
        webhookId: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/webhooks/webhook',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get Webhook
     * @param webhookId
     * @returns Webhook OK
     * @throws ApiError
     */
    public static getWebhook(
        webhookId: string,
    ): CancelablePromise<Webhook> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/webhooks/webhook/{webhookId}',
            path: {
                'webhookId': webhookId,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Update Webhook
     * @param webhookId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateWebhook(
        webhookId: string,
        requestBody?: Webhook,
    ): CancelablePromise<{
        webhookId: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/webhooks/webhook/{webhookId}',
            path: {
                'webhookId': webhookId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Delete Webhook
     * @param webhookId
     * @returns any OK
     * @throws ApiError
     */
    public static removeWebhook(
        webhookId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/webhooks/webhook/{webhookId}',
            path: {
                'webhookId': webhookId,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Get Webhooks of all devices
     * @returns any OK
     * @throws ApiError
     */
    public static getDevices(): CancelablePromise<Array<{
        webhookId?: string;
        deviceId?: string;
    }>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/webhooks/devices',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Attach Device to Webhook
     * @param deviceId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static createDeviceWebhookId(
        deviceId: string,
        requestBody?: CreateDeviceWebhookIdPayload,
    ): CancelablePromise<{
        webhookId: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/webhooks/device/{deviceId}',
            path: {
                'deviceId': deviceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get Attached Webhook ID for device
     * @param deviceId
     * @returns any OK
     * @throws ApiError
     */
    public static getDeviceWebhookId(
        deviceId: string,
    ): CancelablePromise<{
        webhookId: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/webhooks/device/{deviceId}',
            path: {
                'deviceId': deviceId,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Replace attached Webhook for device
     * @param deviceId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateDeviceWebhookId(
        deviceId: string,
        requestBody?: UpdateDeviceWebhookIdPayload,
    ): CancelablePromise<{
        webhookId: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/webhooks/device/{deviceId}',
            path: {
                'deviceId': deviceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Detach Webhook for device
     * @param deviceId
     * @returns any OK
     * @throws ApiError
     */
    public static removeDeviceWebhookId(
        deviceId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/webhooks/device/{deviceId}',
            path: {
                'deviceId': deviceId,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

}
