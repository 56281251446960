/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DeviceTestStatus {
    NotTested = 'NotTested',
    InProgress = 'InProgress',
    Completed = 'Completed',
    Failed = 'Failed',
    Interrupted = 'Interrupted',
    FallTested = 'FallTested',
    PresenceTested = 'PresenceTested',
    UserConfigured = 'UserConfigured',
}
