/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Webhook = {
    name: string;
    protocol: Webhook.protocol;
    hostName: string;
    port?: number;
    headers?: Record<string, string>;
    httpMethod: Webhook.httpMethod;
    statusPath?: string;
    fallEventPath?: string;
    presenceEventPath?: string;
    roomEventPath?: string;
    bedExitPath?: string;
    outOfBedEventPath?: string;
    bedExitDismissEventPath?: string;
    doorEventPath?: string;
};

export namespace Webhook {

    export enum protocol {
        HTTPS = 'https',
        HTTP = 'http',
    }

    export enum httpMethod {
        POST = 'POST',
        PUT = 'PUT',
    }


}

