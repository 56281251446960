import { Injectable } from "@angular/core";
import {
  DeviceConfigGen2,
  DeviceAdminAPI,
  PairingAPI,
  DeviceCommand,
  UploadEspLogs,
  UploadDspLogs,
  DeviceCommandGen2Reboot,
  CancelFall,
  UpdateWifiCredentials,
} from "@walabot-mqtt-dashboard/api";

@Injectable({
  providedIn: "root",
})
export class DeviceAdminService {
  public async getDeviceMetaData(deviceId: string) {
    console.log("getDeviceMetaData for", deviceId);
    return DeviceAdminAPI.getDeviceMetaData(deviceId);
  }

  public async getDeviceConfig(deviceId: string): Promise<DeviceConfigGen2> {
    return DeviceAdminAPI.getDeviceConfig(deviceId).then(
      (deviceConfigArray) => {
        const deviceConfig = deviceConfigArray[0];
        // null or undefined
        if (deviceConfig.appConfig.volume == null) {
          deviceConfig.appConfig.volume = 100;
        }
        return deviceConfig;
      }
    );
  }

  private async sendCommand(
    deviceId: string,
    command: DeviceCommand
  ): Promise<any> {
    console.log(
      `sending command to ${deviceId} command: ${JSON.stringify(command)}`
    );
    return DeviceAdminAPI.sendCommand(deviceId, command);
  }

  public updateDeviceConfig(
    deviceId: string,
    deviceConfig: Partial<DeviceConfigGen2>
  ): Promise<DeviceConfigGen2> {
    return DeviceAdminAPI.setDeviceConfig(
      deviceId,
      deviceConfig as DeviceConfigGen2
    );
  }

  public async uploadAppLogs(deviceId: string): Promise<any> {
    const command: DeviceCommand = {
      type: UploadEspLogs.type.UploadEspLogs,
    };
    return this.sendCommand(deviceId, command);
  }

  public async uploadDevLogs(deviceId: string): Promise<any> {
    const command: DeviceCommand = {
      type: UploadDspLogs.type.UploadDspLogs,
    };
    return this.sendCommand(deviceId, command);
  }

  public async rebootDevice(deviceId: string): Promise<any> {
    const command: DeviceCommand = {
      type: DeviceCommandGen2Reboot.type.Reboot,
    };
    return this.sendCommand(deviceId, command);
  }

  public async cancelAlarm(deviceId: string): Promise<any> {
    const command: DeviceCommand = {
      type: CancelFall.type.CancelFall,
    };
    return this.sendCommand(deviceId, command);
  }

  public async pair(deviceId: string, email: string, uid: string) {
    return PairingAPI.adminPair({ deviceId, email, uid });
  }

  public async updateWifiCredentials(
    deviceId: string,
    payload: UpdateWifiCredentials
  ): Promise<any> {
    return DeviceAdminAPI.sendCommand(deviceId, payload);
  }
}
