// import { Directive, OnDestroy, Input } from '@angular/core';
// import { timer, Observable } from 'rxjs';
// @Directive({
//   selector: '[appCountdownTimer]'
// })
// export class CountdownTimerDirective implements OnDestroy
// {

//   private timer: Observable<number>;
//   @Input() when: number;
//   constructor()
//   {
//     this.timer = timer(1000, 2000);
//     this.timer.subscribe(val => { console.log(val); });

//   }

//   ngOnDestroy()
//   {
//     //this.countSub$.unsubscribe();
//   }

// }

import {
  Directive,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
} from "@angular/core";

import { Subject, Observable, SubscriptionLike, timer } from "rxjs";
import { switchMap, take, tap } from "rxjs/operators";

@Directive({
  selector: "[appCounterDownDirective]",
})
export class CountdownTimerDirective implements OnChanges, OnDestroy {
  private counter$ = new Subject<any>();
  private timerSub$: SubscriptionLike;
  private timer$: Observable<number>;
  @Input("appCounterDownDirective") until: number;
  @Output() value = new EventEmitter<number>();

  constructor() {
    this.timerSub$ = this.counter$
      .pipe(
        switchMap((options: { count: number }) =>
          timer(0, 1000).pipe(
            take(options.count),
            tap(() => this.value.emit(--options.count))
          )
        )
      )
      .subscribe();
  }

  ngOnChanges() {
    this.counter$.next({
      count: Math.round(Math.max((this.until - Date.now()) / 1000, 0)),
    });
  }

  ngOnDestroy() {
    this.timerSub$.unsubscribe();
  }
}
