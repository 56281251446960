import { EventEmitter, Injectable } from "@angular/core";
import {
  DeviceFallEvent,
  DeviceSensitiveFallEvent,
  FallEventStatus,
  Room,
  SensitiveFallEventStatus,
  Suite,
} from "@walabot-mqtt-dashboard/api";
import { BehaviorSubject, combineLatest, Observable, Subscription } from "rxjs";
import {
  AuthService,
  DeviceMonitoringService,
  RoomsService,
  DeviceRoomConfigMapping,
  SuitesService,
  DATE_TIME_FORMAT,
} from "./ui.module";
import { User } from "firebase/auth";
import { DatePipe } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class EventBusService {
  fallCount = new EventEmitter();
  disconnectedCount = new EventEmitter();
  private _unresolvedAlertsCountSubject: BehaviorSubject<number> =
    new BehaviorSubject<number>(undefined);
  get unresolvedAlertsCount(): Observable<number> {
    return this._unresolvedAlertsCountSubject.asObservable();
  }
  set unresolvedAlertsCountValue(count: number) {
    this._unresolvedAlertsCountSubject.next(count);
  }
  private roomMapping: { [roomId: string]: Room };
  private deviceMapping: DeviceRoomConfigMapping = {};
  private suiteMapping: { [suiteId: string]: Suite };
  private activeNotifications: { [roomId: string]: Notification } = {};
  _rawEventSubs: Subscription;
  _roomEventSubs: Subscription;
  _roomSubs: Subscription;

  constructor(
    private auth: AuthService,
    private monitoringService: DeviceMonitoringService,
    private roomService: RoomsService,
    private suitesService: SuitesService,
    private datePipe: DatePipe
  ) {
    if (!this.auth.currentUser) {
      return;
    }
    this.auth.currentUser.subscribe((user: { user: User }) => {
      if (user && user.user && !window.frameElement) {
        this.init();
      }
    });
  }

  getRoomAndSuiteName(deviceId: string) {
    let roomName: string = null,
      suiteName: string = null;
    const deviceRoomConfig = this.deviceMapping[deviceId];
    if (deviceRoomConfig) {
      const room = this.roomMapping[deviceRoomConfig.roomId];
      if (room) {
        roomName = room.name;
        const suite = this.suiteMapping[room.suiteId];
        if (suite) {
          suiteName = suite.name;
        }
      } else {
        return;
      }
    }

    return { roomName, suiteName };
  }

  private init() {
    if (this._roomSubs) {
      this._roomSubs.unsubscribe();
    }
    if (this._rawEventSubs) {
      this._rawEventSubs.unsubscribe();
    }
    if (this._roomEventSubs) {
      this._roomEventSubs.unsubscribe();
    }
    this.roomMapping = {};
    this.suiteMapping = {};
    this._roomSubs = combineLatest([
      this.suitesService.suiteList,
      this.suitesService.roomMapping,
      this.roomService.roomList,
      this.roomService.deviceMapping,
    ]).subscribe(([suiteList, suiteRoomMapping, rooms, deviceMapping]) => {
      if (!suiteList) {
        return;
      }
      if (!rooms) {
        return;
      }
      if (rooms.length <= 0 || Object.keys(deviceMapping).length <= 0) {
        return;
      }
      rooms.forEach((room) => {
        const roomWithSuiteId = Object.values(suiteRoomMapping)
          .flat()
          .find((r) => r.id === room.id);
        this.roomMapping[room.id] = room;
        if (roomWithSuiteId) {
          this.roomMapping[room.id].suiteId = roomWithSuiteId.suiteId;
        }
      });
      suiteList.forEach((suite) => {
        this.suiteMapping[suite.id] = suite;
      });
      suiteList.forEach((suite) => {
        this.suiteMapping[suite.id] = suite;
      });
      this.deviceMapping = deviceMapping;
    });

    this._rawEventSubs = this.monitoringService.rawEventsObservable.subscribe(
      (event) => {
        if (
          [
            DeviceFallEvent.type.Fall,
            DeviceSensitiveFallEvent.type.SensitiveFall,
          ].includes(+event.event.type)
        ) {
          const fallEvent = event.event as
              | DeviceFallEvent
              | DeviceSensitiveFallEvent,
            isDismissed = fallEvent.payload.dismissed;
          if (
            (+event.event.type === DeviceFallEvent.type.Fall &&
              [
                FallEventStatus.Calling,
                FallEventStatus.Finished,
                FallEventStatus.FallExit,
                FallEventStatus.Canceled,
              ].includes((<DeviceFallEvent>fallEvent).payload.status)) ||
            (+event.event.type ===
              DeviceSensitiveFallEvent.type.SensitiveFall &&
              [
                SensitiveFallEventStatus.Calling,
                SensitiveFallEventStatus.Finished,
                SensitiveFallEventStatus.FallExit,
              ].includes((<DeviceSensitiveFallEvent>fallEvent).payload.status))
          ) {
            const { roomName, suiteName } = this.getRoomAndSuiteName(
              event.deviceId
            );
            const deviceRoomConfig = this.deviceMapping[event.deviceId];
            if (!roomName || !suiteName) {
              return;
            }
            if (
              isDismissed &&
              this.activeNotifications[deviceRoomConfig.roomId]
            ) {
              const notification =
                this.activeNotifications[deviceRoomConfig.roomId];
              notification.close();
            } else if (
              !isDismissed &&
              !this.activeNotifications[deviceRoomConfig.roomId]
            ) {
              if (suiteName) {
                const message = `A fall has been detected in ${suiteName}, ${roomName} at ${this.datePipe.transform(
                  new Date(fallEvent.payload.timestamp),
                  DATE_TIME_FORMAT
                )}`;
                const notification = new Notification(
                  "Vayyar Care has detected a fall",
                  {
                    body: message,
                    image: "../assets/notification/notification.png",
                    requireInteraction: true,
                    icon: "../assets/notification/logo_round.png",
                  }
                );
                notification.onclick = () => {
                  window.focus();
                };
                notification.onclose = () => {
                  delete this.activeNotifications[deviceRoomConfig.roomId];
                };
                this.activeNotifications[deviceRoomConfig.roomId] =
                  notification;
              }
            }
          }
        }
      }
    );
    this._roomEventSubs = this.monitoringService.roomEventsObservable.subscribe(
      (event) => {
        if (
          event.event.alarmDismissed &&
          this.activeNotifications[event.roomId]
        ) {
          const notification = this.activeNotifications[event.roomId];
          notification.close();
        }
      }
    );
  }

  clear() {
    this._unresolvedAlertsCountSubject.next(undefined);
  }
}
