import { Directive, ElementRef, Input } from "@angular/core";

@Directive({
  selector: "[appTimestampToDate]",
})
export class TimestampToDateDirective {
  @Input() set appTimestampToDate(timestamp: string | number | Date) {
    const text = new Date(timestamp).toUTCString();
    this.el.nativeElement.appendChild(document.createTextNode(text));
  }
  constructor(private el: ElementRef<HTMLElement>) {}
}
