import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { DashboardUsersService } from "../dashboard-users.service";
import {
  DashboardUser,
  DashboardUserRole,
  DashboardUsers,
  DashboardUserInvite,
} from "@walabot-mqtt-dashboard/api";

import { MatSnackBar } from "@angular/material/snack-bar";

interface NewUser {
  email: string;
  roles: { [key in DashboardUserRole]: boolean };
}

@Component({
  selector: "app-dashboard-users",
  templateUrl: "./dashboard-users.component.html",
  styleUrls: ["./dashboard-users.component.css"],
})
export class DashboardUsersComponent implements OnInit {
  @ViewChild("saveBtn", { static: true })
  saveBtn: ElementRef<HTMLButtonElement>;
  @ViewChild("loading") loadingProgressBtn;
  @ViewChild("addUserCard") addUserCard;
  dashboardUsers: DashboardUser[];
  displayedColumns: string[];
  invitesDisplayedColumns: string[];
  roles: Array<DashboardUserRole>;
  userToAdd: NewUser;
  invites;
  constructor(
    private dashboardUsersService: DashboardUsersService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.getDashboardUsers();
  }

  public openAddUser() {
    const userToAdd = {
      email: "",
      roles: {},
    };
    this.roles.forEach((role) => {
      userToAdd.roles[role] = false;
    });
    this.userToAdd = userToAdd as NewUser;
  }

  public addUser() {
    const rolesArr: Array<DashboardUserRole> = [];
    this.roles.forEach((role) => {
      if (this.userToAdd.roles[role]) {
        rolesArr.push(role);
      }
    });
    this.dashboardUsersService
      .addUser({ email: this.userToAdd.email, roles: rolesArr })
      .then(() => {
        this.userToAdd = null;
        this.getDashboardUsers();
      })
      .catch((err) => {
        this.userToAdd = null;
        this.showMessage(JSON.stringify(err));
      });
  }

  public save() {
    this.saveBtn.nativeElement.disabled = true;
    const userRoles: DashboardUser[] = this.dashboardUsers.map((user) => {
      const rolesArr: DashboardUserRole[] = [];
      this.roles.forEach((role) => {
        if (user[role]) {
          rolesArr.push(role);
        }
      });
      return {
        uid: user.uid,
        roles: rolesArr,
        email: user.email,
      } as DashboardUser;
    });
    console.log(userRoles);
    this.dashboardUsersService
      .updateDashboardUsers(userRoles)
      .then((result) => {
        this.saveBtn.nativeElement.disabled = false;
        this.setDashboardUsers(result.dashboardUsers);
        this.setInvites(result.invites);
        this.showMessage("New roles saved successfully");
      })
      .catch((err) => {
        this.saveBtn.nativeElement.disabled = false;
        this.showMessage(JSON.stringify(err));
      });
  }

  public getDashboardUsers() {
    this.displayedColumns = ["uid", "email"];
    this.invitesDisplayedColumns = ["email"];
    this.roles = Object.keys(DashboardUserRole).map((key) => {
      const role = DashboardUserRole[key] as DashboardUserRole;
      this.displayedColumns.push(role);
      this.invitesDisplayedColumns.push(role);
      return role;
    });
    this.displayedColumns.push("delete");
    this.invitesDisplayedColumns.push("delete");
    void this.reloadAllLists();
  }

  private showMessage(message: string) {
    this.snackBar.open(message, null, {
      duration: 2000,
    });
  }

  private setDashboardUsers(result: DashboardUser[]) {
    this.dashboardUsers = result.map((user) => {
      const dashboardUserUIModel = {
        uid: user.uid,
        email: user.email,
        roles: [],
      };

      this.roles.forEach((role) => {
        dashboardUserUIModel[role] = user.roles.find((r) => r === role) != null;
      });
      console.log(dashboardUserUIModel);
      return dashboardUserUIModel;
    });
  }

  private setInvites(invites: DashboardUserInvite[]) {
    this.invites = invites.map((user) => {
      const dashboardUserUIModel = { email: user.email };
      this.roles.forEach((role) => {
        dashboardUserUIModel[role] = user.roles.find((r) => r === role) != null;
      });
      console.log(dashboardUserUIModel);
      return dashboardUserUIModel;
    });
  }

  deletePendingInvite(email: string, uid?: string) {
    console.log("deletePendingInvite", email, uid);
    this.dashboardUsersService
      .deleteDashboardUserRoles(email, uid)
      .then(() => this.reloadAllLists())
      .catch((err) => {
        this.showMessage(JSON.stringify(err));
      });
  }

  deleteUser(uid: string) {
    this.dashboardUsersService
      .deleteUser(uid)
      .then(() => this.reloadAllLists())
      .catch((err) => {
        this.showMessage(JSON.stringify(err));
      });
  }

  private reloadAllLists() {
    return this.dashboardUsersService
      .getDashboardUsers()
      .then((result: DashboardUsers) => {
        this.setDashboardUsers(result.dashboardUsers);
        this.setInvites(result.invites);
      })
      .catch((err) => {
        this.showMessage(JSON.stringify(err));
      });
  }
}
