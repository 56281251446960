export type FilterKeysBySuffix<T, S extends string> = {
  [K in keyof T as K extends string
    ? // eslint-disable-next-line @typescript-eslint/no-unused-vars
      K extends `${infer _}${S}`
      ? K
      : never
    : never]: T[K];
};

export function camelCaseToTitleCase(s: string): string {
  // Split string by upper case letters
  const words: string[] = [];
  let start = 0;
  for (let i = 1; i < s.length; i++) {
    if (s[i].toUpperCase() === s[i]) {
      words.push(s.substring(start, i));
      start = i;
    }
  }
  words.push(s.substring(start));

  // Convert each word to title case and join them with a space
  return words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}
