import {
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { BaseComponent } from "./base-component";
import {
  ConfigService,
  DATE_TIME_FORMAT,
  DeviceMonitoringService,
  EventBusService,
  EXTRA_DEVICE_ID,
  MAX_BUZZER_SOUND,
  MIN_BUZZER_SOUND,
  RoomsService,
  RoomTypeMapping,
  TargetsComponent,
} from "./ui.module";
import {
  DashboardConfig,
  DeviceConfigGen2,
  DeviceFallEvent,
  DevicePresenceEvent,
  DeviceSensitiveFallEvent,
  DeviceStatus,
  FallEventStatus,
  Presence,
  RoomType,
  SensitiveFallEventStatus,
} from "@walabot-mqtt-dashboard/api";
import { takeUntil } from "rxjs/operators";
import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal, PortalInjector } from "@angular/cdk/portal";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { SuitesService } from "./suites.service";
import {
  BedExitEventImminent,
  DeviceOfflineStatus,
  DeviceState,
  DeviceStatusDetails,
  Environment,
  MockableFallEvent,
  MockableSensitiveFallEvent,
  RoomDetails,
  StatusSuiteDetails,
  SuiteDetails,
} from "./models";
import { getAllDevicesInSuite } from "./utils";
import { Language } from "./config.service";

type SortingType = "by-alert" | "alphabetically";
const statusPageSortingTypeKey = "statusPageSortingType";

const WEAK_WIFI = -70;

/**
 * Provides base suites structure with alert functionality
 */
@Directive()
export class StatusPageBase extends BaseComponent implements OnInit, OnDestroy {
  popup: ElementRef<HTMLElement>;
  feedbackPopup: ElementRef<HTMLElement>;

  generalConfig: DashboardConfig;

  selectedSuite: SuiteDetails = null;
  selectedRoomId: string;
  feedBackPopupIsOpened = false;
  feedBackPopupShouldDismissAll = false;

  RoomType = RoomType;
  Presence = Presence;

  get suiteStatusArr(): SuiteDetails[] {
    return Object.values(this.monitoringService.suiteStatusMap)
      .filter((suiteDetails) => getAllDevicesInSuite(suiteDetails).length)
      .sort(this.sortSuiteStatusMapCallback.bind(this));
  }

  get disconnectedStatusSuiteArr(): StatusSuiteDetails[] {
    return Object.values(this.monitoringService.suiteStatusMap)
      .map((suiteDetails) => {
        return Object.values(suiteDetails.rooms).map((room) => ({
          id: suiteDetails.id,
          name: suiteDetails.name,
          alert: suiteDetails.alert,
          room,
        }));
      })
      .flat()
      .map((item) => {
        return Object.values(item.room.devices).map((device) => ({
          ...item,
          room: {
            id: item.room.id,
            name: item.room.name,
            type: item.room.type,
            device,
          },
        }));
      })
      .flat()
      .filter((deviceRoomSuite) => {
        const isDisconnected = this.isDeviceDisconnected(
          deviceRoomSuite.id,
          deviceRoomSuite.room.id,
          deviceRoomSuite.room.device.deviceId
        );
        return isDisconnected;
      })
      .sort(
        (a, b) =>
          this.deviceStateMap[b.room.device.deviceId].updateTimestamp -
          this.deviceStateMap[a.room.device.deviceId].updateTimestamp
      );
  }

  get alertsEnabled() {
    return <boolean>this.route.snapshot.data.alertsEnabled;
  }

  bedExitImminentMapping: {
    [deviceId: string]: {
      bedExitImminentEvent: BedExitEventImminent;
      updateTimestamp: number;
    };
  };
  fallEventDismissFeedBack: "yes" | "no" | "test";
  insideIFrame = window.frameElement;

  deviceStateMap: {
    [deviceId: string]: { state: DeviceState; updateTimestamp: number };
  } = {};
  fallMapping: {
    [deviceId: string]: {
      eventType:
        | DeviceFallEvent.type.Fall
        | DeviceSensitiveFallEvent.type.SensitiveFall;
      fallEvent: MockableFallEvent | MockableSensitiveFallEvent;
      updateTimestamp: number;
    };
  } = {};
  dateTimeFormat = DATE_TIME_FORMAT;
  sorting: SortingType = "by-alert";
  fallMappingName = {
    [FallEventStatus.Calling]: $localize`:@@event-fall-detection:Fall Detection`,
    [FallEventStatus.FallDetected]: $localize`:@@event-fall-detected:Fall Detected`,
    [FallEventStatus.FallConfirmed]: $localize`:@@event-fall-confirmed:Fall Confirmed`,
    [FallEventStatus.FallExit]: $localize`:@@event-fall-exit:Fall Exit`,
    [FallEventStatus.Finished]: $localize`:@@event-fall-finished:Fall Finished`,
    [FallEventStatus.Canceled]: $localize`:@@event-fall-canceled:Fall Canceled`,
    [FallEventStatus.OnCall]: $localize`:@@event-fall-oncall:Fall On Call`,
    [FallEventStatus.Resolution]: $localize`:@@event-fall-resolution:Fall Resolution`,
    [SensitiveFallEventStatus.FallSuspected]: $localize`:@@event-fall-suspected:Fall Suspected`,
  };
  // "finished" === FallEventStatus.Finished === SensitiveFallEventStatus.Finished
  readonly FinishedFallStatus = FallEventStatus.Finished;
  isAdmin =
    (localStorage.getItem("basePath") === "admin" &&
      sessionStorage.getItem("isAdminDashboardUser")) ??
    false;
  language: Language;
  readonly JaLang = Language.JAPANESE;

  protected navigationExtras: NavigationExtras;

  constructor(
    public monitoringService: DeviceMonitoringService,
    public route: ActivatedRoute,
    protected deviceConfigService: ConfigService,
    protected roomService: RoomsService,
    protected hostElement: ElementRef<HTMLElement>,
    protected eventBus: EventBusService,
    protected overlay: Overlay,
    protected injector: Injector,
    protected suitesService: SuitesService,
    protected router: Router,
    @Inject("environment") protected environment: { [key: string]: Environment }
  ) {
    super();
    this.sorting =
      <SortingType>localStorage.getItem(statusPageSortingTypeKey) ?? "by-alert";
    this.navigationExtras = this.router.getCurrentNavigation()?.extras;

    this.language = this.deviceConfigService.getLanguage();
  }

  ngOnInit() {
    if (this.isFallingApp() && this.route.snapshot.data.alertsEnabled) {
      this.monitoringService.fallsObservables
        .pipe(takeUntil(this.ngUnsubsrcibe))
        .subscribe((fallMapping) => {
          this.fallMapping = fallMapping;
        });
    }
    if (this.isBedExitApp()) {
      this.monitoringService.bedExitImminentObservables
        .pipe(takeUntil(this.ngUnsubsrcibe))
        .subscribe((bedExitImminentMapping) => {
          this.bedExitImminentMapping = bedExitImminentMapping;
        });
    }
    this.monitoringService.deviceStateMap
      .pipe(takeUntil(this.ngUnsubsrcibe))
      .subscribe((deviceStateMap) => {
        this.deviceStateMap = deviceStateMap;
      });
  }

  onSimulateFall() {
    let deviceId: string;
    suites: for (const suiteDetails of this.suiteStatusArr) {
      for (const roomDetails of Object.values(suiteDetails.rooms)) {
        for (const device of Object.values(roomDetails.devices)) {
          deviceId = device.deviceId;
          break suites;
        }
      }
    }
    this.mockFallAlert(deviceId);
    this.monitoringService.simulatedFallEventDeviceId = deviceId;
  }

  openFeedBack(
    e: MouseEvent,
    card: HTMLElement,
    suiteDetails: SuiteDetails,
    roomId?: string
  ) {
    this.selectedSuite = suiteDetails;
    e.stopPropagation();
    this.openPopup(e, card, this.feedbackPopup);
    this.fallEventDismissFeedBack = undefined;
    this.feedBackPopupShouldDismissAll = typeof roomId !== "string";
    this.selectedRoomId = roomId;
    this.feedBackPopupIsOpened = true;
  }

  protected openPopup(
    e: MouseEvent,
    card: HTMLElement,
    popup: ElementRef<HTMLElement>
  ) {
    const rightSide =
      this.hostElement.nativeElement.getBoundingClientRect().left +
      this.hostElement.nativeElement.getBoundingClientRect().width;

    popup.nativeElement.classList.add("left-arrow");
    popup.nativeElement.classList.remove("right-arrow");

    let top =
      card.offsetTop +
      card.clientHeight / 2 -
      popup.nativeElement.clientHeight / 2;
    let left = card.offsetLeft + card.clientWidth + 17;
    if (top < 0) {
      top = 0;
    }
    if (
      this.hostElement.nativeElement.getBoundingClientRect().left +
        left +
        popup.nativeElement.clientWidth >
      rightSide
    ) {
      left = card.offsetLeft - this.popup.nativeElement.clientWidth - 18;
      popup.nativeElement.classList.remove("left-arrow");
      popup.nativeElement.classList.add("right-arrow");
    }
    popup.nativeElement.style.top = `${top}px`;
    popup.nativeElement.style.left = `${left}px`;
    e.stopPropagation();
  }

  dismissFallAlert() {
    this.monitoringService
      .dismissFallAlert(
        this.selectedSuite.id,
        this.selectedRoomId,
        this.fallEventDismissFeedBack
      )
      .finally(() => {
        this.feedBackPopupIsOpened = false;
      });
  }

  cancelBedExitAlarm(suiteId: string) {
    this.monitoringService.cancelBedExitAlarm(suiteId);
  }

  getClass(suiteId: string, roomId: string, deviceId: string) {
    const device =
      this.monitoringService.suiteStatusMap[suiteId].rooms[roomId].devices[
        deviceId
      ];
    if (device.deviceStatus === DeviceOfflineStatus.DISCONNECTED) {
      return "disconnected";
    } else if (device.deviceStatus === DeviceStatus.SOFTWARE_UPDATE) {
      return "software-update";
    } else if (device.deviceStatus === DeviceStatus.SUSPEND) {
      return "suspended";
    } else if (device.isFallActive) {
      return "fall";
    } else if (device.isBedExitActive) {
      return "bed-exit";
    } else if (!device.isPresenceDetected) {
      return "vacant";
    } else if (device.isPresenceDetected) {
      return "presence";
    }
  }

  getDeviceIcon(suiteId: string, roomId: string, deviceId: string) {
    const device =
      this.monitoringService.suiteStatusMap[suiteId].rooms[roomId].devices[
        deviceId
      ];
    let icon = "other";
    if (device) {
      const roomType = RoomType[RoomType[device.roomType]] as RoomType;
      icon = RoomTypeMapping.get(roomType)?.icon;
    }
    return `../assets/icons/room/${icon}.svg#icon`;
  }

  isWeakWifi(suiteId: string, roomId: string, deviceId: string) {
    const device =
      this.monitoringService.suiteStatusMap[suiteId].rooms[roomId].devices[
        deviceId
      ];
    return (
      device.deviceWifiState &&
      device.deviceWifiState.rssi &&
      device.deviceWifiState.rssi < WEAK_WIFI
    );
  }

  getTooltipText(suiteId: string, roomId: string, deviceId: string) {
    const device =
      this.monitoringService.suiteStatusMap[suiteId].rooms[roomId].devices[
        deviceId
      ];
    return $localize`:@@weak-wifi-warning:<h6>Wi-Fi Warning</h6> This device’s Wi-Fi connection is weak (RSSI ${device.deviceWifiState.rssi}:rssiValue:)<br> Please check the connection`;
  }

  isLoading(suiteId: string, roomId: string, deviceId: string) {
    const device =
      this.monitoringService.suiteStatusMap[suiteId].rooms[roomId].devices[
        deviceId
      ];
    return (
      device.deviceStatus === DeviceStatus.INITIALIZING &&
      !device.isFallActive &&
      !device.isPresenceDetected &&
      !device.isBedExitActive
    );
  }

  getDeviceStatus(suiteId: string, roomId: string, deviceId: string) {
    const device =
      this.monitoringService.suiteStatusMap[suiteId].rooms[roomId].devices[
        deviceId
      ];
    const roomType = RoomType[RoomType[device.roomType]] as RoomType;
    let status =
      roomType !== undefined && roomType !== null
        ? RoomTypeMapping.get(roomType).name
        : $localize`:@@unknown-room:Unknown Room`;
    if (device) {
      if (device.deviceStatus === DeviceStatus.MONITORING) {
        status = $localize`:@@monitoring:Monitoring`;
      } else if (device.deviceStatus === DeviceOfflineStatus.DISCONNECTED) {
        status = $localize`:@@disconnected:Disconnected`;
      } else if (device.deviceStatus === DeviceStatus.SOFTWARE_UPDATE) {
        status = $localize`:@@software-update:Software Update`;
      } else if (device.deviceStatus === DeviceStatus.SUSPEND) {
        status = $localize`:@@device-suspended:Suspended`;
      } else if (device.deviceStatus === DeviceStatus.TEST) {
        status = $localize`:@@test-mode:Test Mode`;
      } else if (device.deviceStatus === DeviceStatus.LEARNING) {
        status = $localize`:@@learning-mode:Learning Mode`;
      } else if (device.deviceStatus === DeviceStatus.SILENT) {
        status = $localize`:@@silent-mode:Silent Mode`;
      }
    }
    return status;
  }

  getOccupiedStatus(suiteId: string, roomId: string, deviceId: string) {
    const device =
      this.monitoringService.suiteStatusMap[suiteId].rooms[roomId].devices[
        deviceId
      ];
    let status: string;

    if (device.isPresenceDetected === false) {
      status = $localize`:@@event-vacant:Vacant`;
    } else if (device.isPresenceDetected === true) {
      status = $localize`:@@event-occupied:Occupied`;
    }
    if (device.roomType == RoomType.Bedroom) {
      if (Object.keys(device.presenceRegions).length > 0) {
        if (device.presenceRegions[0] === Presence.HasPresence) {
          status = $localize`:@@event-in-bed:In Bed`;
        }
      }
    }

    return status;
  }

  isDeviceDisconnected(suiteId: string, roomId: string, deviceId: string) {
    const device =
      this.monitoringService.suiteStatusMap[suiteId].rooms[roomId].devices[
        deviceId
      ];

    return device.deviceStatus === DeviceOfflineStatus.DISCONNECTED;
  }

  isDeviceSuspended(suiteId: string, roomId: string, deviceId: string) {
    const device =
      this.monitoringService.suiteStatusMap[suiteId].rooms[roomId].devices[
        deviceId
      ];

    return device.deviceStatus === DeviceStatus.SUSPEND;
  }

  isDeviceHasAlert(suiteId: string, roomId: string, deviceId: string) {
    const device =
      this.monitoringService.suiteStatusMap[suiteId].rooms[roomId].devices[
        deviceId
      ];

    return device.isBedExitActive || device.isFallActive;
  }

  isDeviceInitialized(suiteId: string, roomId: string, deviceId: string) {
    const device =
      this.monitoringService.suiteStatusMap[suiteId].rooms[roomId].devices[
        deviceId
      ];

    return device.deviceStatus !== DeviceStatus.INITIALIZING;
  }

  isFallingApp() {
    return this.environment.applicationId == "fall-detector";
  }

  isBedExitApp() {
    return this.environment.applicationId == "bed-exit-detector";
  }

  @HostListener("click", ["$event"])
  protected onDropClick() {
    this.feedBackPopupIsOpened = false;
  }

  getAlertForSuite(suiteId: string) {
    if (this.isFallingApp() && !this.alertsEnabled) {
      return {
        isFallActive: false,
        isPresented: false,
        isBedExitActive: false,
      };
    }
    return this.monitoringService.getAlertForSuite(suiteId);
  }

  hasDisconnectedDevice(suiteId: string) {
    const suite = this.monitoringService.suiteStatusMap[suiteId];

    return Object.values(suite.rooms).some((roomDetails) => {
      return Object.values(roomDetails.devices).some((deviceDetails) => {
        return deviceDetails.deviceStatus === DeviceOfflineStatus.DISCONNECTED;
      });
    });
  }

  roomsToArray(roomMap: { [roomId: string]: RoomDetails }): RoomDetails[] {
    const arr = Object.values(roomMap).sort((room1, room2) => {
      return room1.name.localeCompare(room2.name);
    });
    return arr;
  }

  devicesToArray(deviceMap: {
    [deviceId: string]: DeviceStatusDetails;
  }): DeviceStatusDetails[] {
    const arr = Object.values(deviceMap).sort((device1, device2) => {
      return (
        this.getRoomTypeOrder(device1.roomType) -
        this.getRoomTypeOrder(device2.roomType)
      );
    });
    return arr;
  }

  protected getRoomTypeOrder(roomType: RoomType) {
    if (roomType == RoomType.Bedroom) {
      return 0;
    }
    if (roomType == RoomType.Bathroom) {
      return 1;
    }
    if (roomType == RoomType.LivingRoom) {
      return 2;
    }
    return 3;
  }

  getTotalNumberOfDevicesText() {
    return this.monitoringService.totalNumberOfDevices > 1
      ? $localize`:@@total-number-of-devices-plural:Connected Devices: ${this.monitoringService.totalNumberOfDevices}:totalNumber: Devices.`
      : $localize`:@@total-number-of-devices-single:Connected Devices: 1 Device`;
  }

  createInjector(dataToPass): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(EXTRA_DEVICE_ID, dataToPass);
    return new PortalInjector(this.injector, injectorTokens);
  }

  overlayRef: OverlayRef;
  openTracker(suite: SuiteDetails, roomId: string, deviceId: string) {
    console.log(suite);
    const device =
      this.monitoringService.suiteStatusMap[suite.id].rooms[roomId].devices[
        deviceId
      ];
    const roomTypeInt = parseInt(String(device.roomType), 10);
    let deviceName = device.deviceId;
    if (!isNaN(roomTypeInt)) {
      deviceName = RoomTypeMapping.get(roomTypeInt)
        ? RoomTypeMapping.get(roomTypeInt).name
        : device.deviceId;
    }
    const roomName = suite.name;
    this.feedBackPopupIsOpened = false;
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();
    const overlayConfig = {
      width: "800px",
      height: "600px",
      positionStrategy,
    };
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
    this.overlayRef = this.overlay.create(overlayConfig);
    const trackerPortal = new ComponentPortal(
      TargetsComponent,
      null,
      this.createInjector({
        deviceId,
        roomName,
        deviceName,
      })
    );
    const trackerComponent = this.overlayRef.attach(trackerPortal);
    trackerComponent.instance.closePanel.subscribe(() => {
      this.overlayRef.detach();
    });
  }

  ngOnDestroy() {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
    super.ngOnDestroy();
  }

  suiteTrackBy(index, suite: SuiteDetails) {
    return suite.id;
  }

  roomTrackBy(index, room: RoomDetails) {
    return room.id;
  }

  deviceTrackBy(index, device: DeviceStatusDetails) {
    return device.deviceId;
  }

  mockFallAlert(deviceId: string) {
    setTimeout(() => {
      this.monitoringService.handleMessage({
        data: {
          type: "state",
          deviceId: deviceId,
          event: JSON.stringify({
            status: DeviceStatus.MONITORING,
            timestamp: Date.now(),
          }),
        },
      });
      this.monitoringService.handleMessage({
        data: {
          type: "event",
          deviceId: deviceId,
          event: JSON.stringify({
            type: DeviceFallEvent.type.Fall,
            payload: Object.assign(
              {},
              {
                status: FallEventStatus.Calling,
                timestamp: new Date().getTime(),
                isMock: true,
              }
            ),
          }),
        },
      });
    });
  }

  mockOutOfRoom(deviceId: string) {
    this.mockPresenceDetected(deviceId, false);
  }

  mockDisconnected(deviceId: string) {
    setTimeout(() => {
      this.monitoringService.handleMessage({
        data: {
          type: "state",
          deviceId: deviceId,
          event: JSON.stringify({
            status: DeviceOfflineStatus.DISCONNECTED,
            timestamp: Date.now(),
          }),
        },
      });
    });
  }

  mockDismissFall(roomId: string) {
    setTimeout(() => {
      this.monitoringService.handleMessage({
        data: {
          type: "roomEvent",
          roomId: roomId,
          event: JSON.stringify({
            alarmDismissed: true,
          }),
        },
      });
    });
  }

  mockPresenceDetected(deviceId: string, presenceDetected = true) {
    setTimeout(() => {
      this.monitoringService.handleMessage({
        data: {
          type: "state",
          deviceId: deviceId,
          event: JSON.stringify({
            status: DeviceStatus.MONITORING,
            timestamp: Date.now(),
          }),
        },
      });
      this.monitoringService.handleMessage({
        data: {
          type: "event",
          deviceId: deviceId,
          event: JSON.stringify({
            type: DevicePresenceEvent.type.Presence,
            payload: {
              presenceRegionMap: {},
              presenceDetected: true,
            },
          }),
        },
      });
      if (!presenceDetected) {
        this.monitoringService.handleMessage({
          data: {
            type: "event",
            deviceId: deviceId,
            event: JSON.stringify({
              type: DevicePresenceEvent.type.Presence,
              payload: {
                presenceRegionMap: {},
                presenceDetected: false,
              },
            }),
          },
        });
      }
    });
  }

  onSortingChanged() {
    localStorage.setItem(statusPageSortingTypeKey, this.sorting);
  }

  onBuzzerSoundClick(deviceId: string) {
    this.deviceConfigService
      .sendConfig(deviceId, {
        appConfig: {
          volume:
            this.monitoringService.deviceConfigMap[deviceId].appConfig.volume >
            MIN_BUZZER_SOUND
              ? MIN_BUZZER_SOUND
              : MAX_BUZZER_SOUND,
        },
      } as DeviceConfigGen2)
      .then((deviceConfig) => {
        this.monitoringService.deviceConfigMap[deviceId] = deviceConfig;
      })
      .catch((err: string) => {
        console.error(`Changing buzzer sound failed: ${err}`);
      });
  }

  protected sortSuiteStatusMapCallback(
    suiteA: SuiteDetails,
    suiteB: SuiteDetails
  ) {
    if (this.sorting === "by-alert") {
      const alertA = this.getAlertForSuite(suiteA.id),
        alertB = this.getAlertForSuite(suiteB.id);

      if (
        alertA.isPresented &&
        (alertA.isFallActive || alertA.isBedExitActive) &&
        alertB.isPresented &&
        (alertB.isFallActive || alertB.isBedExitActive)
      ) {
        if (alertA.isFallActive) {
          const [lastAlertTimestampInA, lastAlertTimestampInB] = [
            suiteA,
            suiteB,
          ].map((suite) =>
            Math.max(
              ...Object.values(suite.rooms)
                .map((room) => Object.keys(room.devices))
                .flat()
                .map(
                  (deviceId) => this.fallMapping[deviceId].fallEvent.timestamp
                )
            )
          );

          return lastAlertTimestampInA < lastAlertTimestampInB ? 1 : -1;
        } else {
          const [lastAlertTimestampInA, lastAlertTimestampInB] = [
            suiteA,
            suiteB,
          ].map((suite) =>
            Math.max(
              ...Object.values(suite.rooms)
                .map((room) => Object.keys(room.devices))
                .flat()
                .map(
                  (deviceId) =>
                    this.bedExitImminentMapping[deviceId].bedExitImminentEvent
                      .deviceTime
                )
            )
          );

          return lastAlertTimestampInA < lastAlertTimestampInB ? 1 : -1;
        }
      }
      if (
        alertA.isPresented &&
        (alertA.isFallActive || alertA.isBedExitActive) &&
        !alertB.isPresented
      ) {
        return -1;
      }
      if (
        alertB.isPresented &&
        (alertB.isFallActive || alertB.isBedExitActive) &&
        !alertA.isPresented
      ) {
        return 1;
      }
      if (
        this.hasDisconnectedDevice(suiteA.id) &&
        this.hasDisconnectedDevice(suiteB.id)
      ) {
        const [lastUpdateTimestampInA, lastUpdateTimestampInB] = [
          suiteA,
          suiteB,
        ].map((suite) =>
          Math.max(
            ...Object.values(suite.rooms)
              .map((room) => Object.keys(room.devices))
              .flat()
              .filter(
                (deviceId) =>
                  this.deviceStateMap[deviceId].state.status ===
                  DeviceOfflineStatus.DISCONNECTED
              )
              .map((deviceId) => this.deviceStateMap[deviceId].updateTimestamp)
          )
        );

        return lastUpdateTimestampInA < lastUpdateTimestampInB ? 1 : -1;
      }
      if (
        this.hasDisconnectedDevice(suiteA.id) &&
        !this.hasDisconnectedDevice(suiteB.id)
      ) {
        return -1;
      }
      if (
        this.hasDisconnectedDevice(suiteB.id) &&
        !this.hasDisconnectedDevice(suiteA.id)
      ) {
        return 1;
      }
      return suiteA.name.localeCompare(suiteB.name);
    } else {
      return suiteA.name.localeCompare(suiteB.name);
    }
  }
}
