import { Pipe, PipeTransform } from "@angular/core";
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
 */
@Pipe({ name: "diagnosticsTimestamp" })
export class DiagnosticsTimestampPipe implements PipeTransform {
  transform(diagnosticsFileName: string): Date {
    // console.log(diagnosticsFileName, parseInt(diagnosticsFileName.split('.')[0], 10), new Date(parseInt(diagnosticsFileName.split('.')[0], 10)));
    return new Date(parseInt(diagnosticsFileName.split(".")[0], 10));
  }
}
