/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Bed Exit: Set `Vayyar_BedExitParams::wallDirection`
 */
export enum BedExitWallSide {
    NotSet = 'NotSet',
    OnRight = 'OnRight',
    OnLeft = 'OnLeft',
    NoWall = 'NoWall',
}
