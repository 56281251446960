/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GcpIotCoreDetails = {
    /**
     * Schema identifier of parsing the content
     */
    type: GcpIotCoreDetails.type;
    /**
     * The GCP project ID that owns the registry and device
     */
    projectId: string;
    /**
     * The IoT Core registry that contains the device
     */
    registryId: string;
    /**
     * The region for the IoT Core registry that contains the device
     */
    region: string;
    /**
     * The ID of the device
     */
    deviceId: string;
    /**
     * Host name for the MQTT broker to connect to
     */
    hostName?: string;
    /**
     * URL scheme for the MQTT broker to connect to
     */
    scheme?: GcpIotCoreDetails.scheme;
};

export namespace GcpIotCoreDetails {

    /**
     * Schema identifier of parsing the content
     */
    export enum type {
        GCP_IOT_CORE = 'gcp-iot-core',
    }

    /**
     * URL scheme for the MQTT broker to connect to
     */
    export enum scheme {
        Mqtt = 'mqtt',
        Mqtts = 'mqtts',
        Ws = 'ws',
        Wss = 'wss',
    }


}

