import { Inject, Injectable } from "@angular/core";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { BehaviorSubject } from "rxjs";
import { Environment } from "./models";

export enum FirebaseErrorCode {
  AUTH_EMAIL_ALREADY_IN_USE = "auth/email-already-in-use",
}

export interface AppUser {
  user: firebase.User;
}
export interface UserRegion {
  region: string;
  email: string;
}

const DefaultRegion = "us";
// for multiRegion
export function getLatestUserRegion() {
  const latestUserRegionStr = localStorage.getItem("latest-user-region");

  if (latestUserRegionStr) {
    const latestUserRegion = JSON.parse(latestUserRegionStr) as UserRegion;
    return latestUserRegion.region;
  }
  // default us
  return DefaultRegion;
}

@Injectable({
  providedIn: "root",
})
export class AuthService {
  currentUser: BehaviorSubject<AppUser>;
  constructor(
    @Inject("environment") environment: { [key: string]: Environment }
  ) {
    firebase.initializeApp(environment.fbConfig);
    this.currentUser = new BehaviorSubject<AppUser>(null);
    window["currentUser"] = this.currentUser;
    firebase.auth().onAuthStateChanged((user) => {
      console.log("onAuthStateChanged ", user);
      this.currentUser.next({ user });
    });
  }

  public getUser() {
    return firebase.auth().currentUser;
  }

  public async getToken() {
    const currentUser = firebase.auth().currentUser;
    if (currentUser) {
      return await firebase.auth().currentUser.getIdToken(false);
    }
    return null;
  }

  public loginWithEmail(email: string, password: string) {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }

  public updateCurrentUser(user: AppUser) {
    return firebase.auth().updateCurrentUser(user.user);
  }

  public loginWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: "select_account",
    });
    return firebase.auth().signInWithPopup(provider);
  }

  public loginWithApple() {
    const provider = new firebase.auth.OAuthProvider("apple.com");
    provider.addScope("email");
    provider.addScope("name");
    return firebase.auth().signInWithPopup(provider);
  }

  public logout() {
    return firebase.auth().signOut();
  }

  public async register(
    name: string,
    email: string,
    password: string
  ): Promise<firebase.User> {
    try {
      const result = await firebase
        .auth()
        .createUserWithEmailAndPassword(email.trim(), password.trim());
      await result.user.updateProfile({ displayName: name.trim() });
      return result.user;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public resetPassword(email: string) {
    return firebase
      .auth()
      .sendPasswordResetEmail(email.trim(), { url: window.location.href });
  }

  public async isDashboardUser(): Promise<boolean> {
    const currentUser = firebase.auth().currentUser;
    if (!currentUser) {
      return await new Promise<boolean>((resolve) => {
        const sub = firebase.auth().onAuthStateChanged((user) => {
          sub();
          if (user) {
            const isDashboardUser = true;
            return resolve(isDashboardUser);
          }
          return resolve(false);
        });
      });
    } else {
      try {
        const isDashboardUser = true;
        return isDashboardUser;
      } catch (err) {
        console.error("failed to retrieve Id Token", err);
        return false;
      }
    }
  }
}
