/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum RoomType {
    Bathroom = 0,
    Bedroom = 1,
    FamilyRoom = 2,
    Hall = 3,
    Kitchen = 4,
    DiningRoom = 5,
    LivingRoom = 6,
    Other = 7,
}
