/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UploadCoreDump = {
    /**
     * Upload DSP core dump files
     */
    type: UploadCoreDump.type;
};

export namespace UploadCoreDump {

    /**
     * Upload DSP core dump files
     */
    export enum type {
        UploadCoreDump = 'UploadCoreDump',
    }


}

