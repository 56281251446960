/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Test = {
    type: Test.type;
};

export namespace Test {

    export enum type {
        Test = 'Test',
    }


}

