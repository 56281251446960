/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * VayyarCare algo processing profile
 */
export enum AlgoProfile {
    Tracking = 'Tracking',
    Falling = 'Falling',
}
