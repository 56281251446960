/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DownloadFirmware = {
    /**
     * Download Firmware
     */
    type: DownloadFirmware.type;
    /**
     * The version name of the firmware provided by the download URL
     */
    versionName: string;
    /**
     * URL of the OTA image of the firmware to download
     */
    downloadUrl: string;
};

export namespace DownloadFirmware {

    /**
     * Download Firmware
     */
    export enum type {
        DownloadFirmware = 'DownloadFirmware',
    }


}

