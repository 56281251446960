/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UploadTelemetries = {
    /**
     * Upload Telemetries
     */
    type: UploadTelemetries.type;
    expiry: number;
};

export namespace UploadTelemetries {

    /**
     * Upload Telemetries
     */
    export enum type {
        UploadTelemetries = 'UploadTelemetries',
    }


}

