import { FormGroup, UntypedFormControl } from "@angular/forms";
import { ɵLocalizeFn } from "@angular/localize";
import { ErrorStateMatcher } from "@angular/material/core";

export function filterNegativeValueFeet(
  event: KeyboardEvent,
  inchesValue: number
) {
  // 1. We don't allow for feet to have a negative value if inches is already negative
  // 2. We don't allow for feet to have a "." in it
  if ((event.key !== "0" && inchesValue < 0) || event.key == ".") {
    return false;
  }
  return true;
}

export function filterNegativeValueInches(
  event: KeyboardEvent,
  feetValue: number
) {
  // We only allow for inches to have a negative value if feet has 0 in value
  if (event.key == "-" && feetValue !== 0) {
    return false;
  }
  return true;
}

export function convertMetersToFeet(meters: number) {
  if (meters == null) {
    return null;
  }
  const absMeters = Math.abs(meters);
  let inches = Number(Number(absMeters / 0.0254).toFixed(10));
  let feet = Number(Math.trunc(inches / 12).toFixed(10));
  inches = Number(Number(inches - feet * 12).toFixed(10));
  feet = isNegative(meters) ? -feet : feet;
  inches = isNegative(meters) && feet === 0 ? -inches : inches;
  inches = parseInt(String(inches * 100)) / 100;
  return [feet, inches];
}

export function convertFeetAndInchesToMeters(feet: number, inches: number) {
  const totalInches = Math.abs(feet) * 12 + Math.abs(inches);
  const meters = Number(Number(totalInches * 0.0254).toFixed(4));
  // if feet was negative, return a negative meters result
  return isNegative(feet) || isNegative(inches) ? -meters : meters;
}

export function isNegative(num: number): boolean {
  return num < 0 || (num === 0 && Object.is(num, -0));
}

export function modifyNegativeZeroForFeet(
  feet: number // to display -0 in an input field
) {
  return Object.is(feet, -0) ? "-0" : feet;
}

/**
 * Check that the value of the feet is inside the limits. In this case, the inch value can be any value.
 * For example, if feet minimum is 10, feet maximum is 12 and checked value is 11, in comparison 10 < 11 < 12,
 * inches value doesn't matter.
 * If the feet value is equal to the minimum or maximum value, check that the inch value is not greater
 * than inches maximum and is not less than inches minimum, accordingly.
 */
export function isFeetValueInRange(
  valueFeet,
  minFeet,
  maxFeet,
  valueInches,
  minInches,
  maxInches
) {
  return (
    isFeetValueLessOrEqualThan(valueFeet, maxFeet, valueInches, maxInches) &&
    isFeetValueGreaterOrEqualThan(valueFeet, minFeet, valueInches, minInches)
  );
}

/**
 * @see isFeetValueInRange
 */
export function isFeetValueLessOrEqualThan(
  valueFeet,
  maxFeet,
  valueInches,
  maxInches
) {
  return (
    valueFeet < maxFeet ||
    (valueFeet === maxFeet &&
      (valueFeet >= 0 ? valueInches <= maxInches : valueInches >= maxInches))
  );
}

/**
 * @see isFeetValueInRange
 */
export function isFeetValueGreaterOrEqualThan(
  valueFeet,
  minFeet,
  valueInches,
  minInches
) {
  return (
    valueFeet > minFeet ||
    (valueFeet === minFeet &&
      (valueFeet >= 0 ? valueInches >= minInches : valueInches <= minInches))
  );
}

export function getDurationErrorMessage(
  form: FormGroup,
  field: string,
  value: number
) {
  const control = form.get(field);
  if (control.errors && control.errors.min) {
    return $localize`:@@duration-negative:Value should be >= ${value}:value:`;
  }

  if (control.errors && control.errors.required) {
    return $localize`:@@duration-notempty:Cannot be empty`;
  }
}

export function getRoomNameErrorMessage(form: FormGroup, field: string) {
  const control = form.get(field);
  if (control.errors && control.errors.exist) {
    return $localize`@@room-name-is-already-in-use-title:Room name is already in use`;
  }

  if (control.errors && control.errors.required) {
    return $localize`:@@duration-notempty:Cannot be empty`;
  }
}

export function getLearningModeMessage(
  timeDiffMillis: number,
  windowLocalize?: ɵLocalizeFn //  for e2e to use this function
) {
  if (timeDiffMillis < 0) {
    return "";
  }
  const seconds = timeDiffMillis / 1000;
  const minutes = seconds / 60;
  const hours = minutes / 60;
  const days = hours / 24;
  const daysAdjusted = Math.floor(days);
  const hoursAdjusted = Math.floor(hours % 24);
  const minutesAdjusted = Math.floor(minutes % 60);

  const localize = windowLocalize ?? $localize;
  const minutesStr = localize`:@@duration-in-minutes:${minutesAdjusted}:duration: Minutes`;

  const hoursStr = localize`:@@duration-in-hours:${hoursAdjusted}:duration: Hours`;
  const daysStr = localize`:@@duration-in-days:${daysAdjusted}:duration: Days`;
  const inStr = localize`:@@duration-in:in`;
  const andStr = localize`:@@duration-and:and`;
  let str = "";
  if (daysAdjusted > 0) {
    str = `${inStr} ${daysStr}, ${hoursStr} ${andStr} ${minutesStr}`;
  } else if (hoursAdjusted > 0) {
    str = `${inStr} ${hoursStr} ${andStr} ${minutesStr}`;
  } else if (minutesAdjusted > 0) {
    str = `${inStr} ${minutesStr}`;
  } else {
    str = localize`:@@duration-now:now`;
  }
  // console.log("getLearningModeMessage: ", str);
  return str;
}

export class RegionErrorStateMatcher implements ErrorStateMatcher {
  constructor(private errorKeys: string[]) {}

  isErrorState(control: UntypedFormControl | null): boolean {
    const invalidCtrl = !!(control && control.invalid);

    let invalidParent = false;
    for (const errorKey of this.errorKeys) {
      invalidParent = !!(
        control &&
        control.parent &&
        control.parent.hasError(errorKey)
      );
      if (invalidParent) {
        break;
      }
    }
    return invalidCtrl || invalidParent;
  }
}
