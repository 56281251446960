/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Telemetry policy: Off (never send), On (always send) or on event (fall/presence/bed-exit)
 */
export enum TelemetryPolicy {
    Off = 0,
    On = 1,
    OnFall = 2,
    OnPresence = 3,
    OnBedExit = 4,
}
