/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Locale {
    English = 'en-US',
    Chinese = 'zh-CH',
    German = 'de-DE',
    Vietnamese = 'vi-VN',
    Japanese = 'ja-JP',
    Italian = 'it-IT',
    Norwegian = 'nb-NO',
    Swedish = 'sv-SE',
    Hebrew = 'iw-IL',
    French = 'fr-FR',
    EN_GB = 'en-GB',
}
