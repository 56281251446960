import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import {
  FormGroup,
  FormControl,
  UntypedFormBuilder,
  Validators,
  ValidationErrors,
  ValidatorFn,
} from "@angular/forms";
import {
  RoomType,
  Room,
  Suite,
  DeviceInfo,
  DeviceStateGen2,
  DeviceConfigGen2,
  SensorMounting,
} from "@walabot-mqtt-dashboard/api";
import { ConfigService, isInLearningMode } from "../../../config.service";
import { getDeviceIcon, RoomTypeMapping } from "../../../device-icon";
import {
  getDurationErrorMessage,
  getRoomNameErrorMessage,
  RegionErrorStateMatcher,
} from "../device-settings-utils";
import { DeviceConfig } from "../device-settings-interface";
import { DATE_TIME_FORMAT, MIN_BUZZER_SOUND } from "../../../ui.module";

const { required } = Validators as ValidationErrors;
const MIN_DURATION_VALUE = 0;
const DRY_CONTACT_DEFAULT = {
  policy: 1,
  mode: 1,
};
@Component({
  selector: "app-general-details",
  templateUrl: "./general-details.component.html",
  styleUrls: ["./general-details.component.css"],
})
export class GeneralDetailsComponent implements OnInit {
  @Input() validateRoomName: (roomName: string) => boolean;
  @Input() configurationForm: FormGroup;
  @Input() config: DeviceConfigGen2;
  @Input() type: RoomType;
  @Input() _device: DeviceConfig;
  @Input() room: Room;
  @Input() suite: Suite;
  @Input() deviceInfo: DeviceInfo;
  @Input() lastSeen: number;
  @Input() wifiState: DeviceStateGen2["wifiState"];
  @Input() inE2ETest: boolean;
  @Output() validateRoomSize = new EventEmitter();

  inRebootProgress: boolean;
  dateTimeFormat = DATE_TIME_FORMAT;
  roomNameValMatcher = new RegionErrorStateMatcher(["roomNameValuesInvalid"]);
  enterDurationValMatcher = new RegionErrorStateMatcher([
    "enterDurationValuesInvalid",
  ]);
  exitDurationValMatcher = new RegionErrorStateMatcher([
    "exitDurationValuesInvalid",
  ]);
  roomTypes = Object.values(RoomType)
    .filter((val) => typeof val === "number")
    .map((val) => val as RoomType);

  get deviceSettings() {
    return this.configurationForm.get("deviceSettingsForm") as FormGroup;
  }

  get roomType(): FormControl<RoomType> {
    return this.deviceSettings.get("roomType") as FormControl<RoomType>;
  }

  get sensorMounting(): FormControl<SensorMounting> {
    return this.deviceSettings.get(
      "sensorMounting"
    ) as FormControl<SensorMounting>;
  }
  SensorMounting = SensorMounting;
  RoomType = RoomType;

  get roomNameValidator(): ValidatorFn {
    return (control: FormControl<string>) => {
      const errors: {
        [key: string]: any;
      } = {};
      if (this.validateRoomName(control.value)) {
        errors.exist = true;
      }

      return errors;
    };
  }

  /**
   * Ceiling should be deprecated. We don't want clients to use that.
   * But we still want to have backward compatibility.
   * So we allow the option for clients that already used that.
   */
  get shouldDisplayToggleCeiling() {
    return (
      this.inE2ETest ||
      this.config.walabotConfig.sensorMounting === SensorMounting.Ceiling
    );
  }

  constructor(
    private fb: UntypedFormBuilder,
    private configService: ConfigService
  ) {}

  ngOnInit(): void {
    this.configurationForm.setControl(
      "deviceSettingsForm",
      this.fb.group({
        roomName: ["", [required, this.roomNameValidator]],
        roomType: [RoomType.Bathroom],
        silentMode: [""],
        testMode: [""],
        learningMode: [""],
        fallingMitigatorEnabled: [""],
        enableDoorEvents: [""],
        enableOutOfBed: [""],
        sensorMounting: [""],
        dryContactPrimaryPolicy: [""],
        dryContactPrimaryMode: [""],
        dryContactSecondaryPolicy: [""],
        dryContactSecondaryMode: [""],
        volume: [""],
        ledLight: [""],
        enterDuration: ["", [required, Validators.min(0)]],
        exitDuration: ["", [required, Validators.min(0)]],
        buzzerSound: [""],
      })
    );
    this.setFormData(this.type);
  }

  setFormData(type: RoomType) {
    this.configurationForm.markAsPristine();
    this.deviceSettings.get("roomType").setValue(type);
    this.deviceSettings
      .get("buzzerSound")
      .setValue(this.config.appConfig.volume > MIN_BUZZER_SOUND);
    this.deviceSettings
      .get("enterDuration")
      .setValue(this.config.walabotConfig.enterDuration);
    this.deviceSettings
      .get("exitDuration")
      .setValue(this.config.walabotConfig.exitDuration);

    this.deviceSettings
      .get("sensorMounting")
      .setValue(this.config.walabotConfig.sensorMounting);
    this.deviceSettings
      .get("ledLight")
      .setValue(this.config.appConfig.ledPolicy || "AllEvents");
    this.deviceSettings
      .get("testMode")
      .setValue(this.config.appConfig.enableTestMode);
    this.deviceSettings
      .get("learningMode")
      .setValue(isInLearningMode(this.config.appConfig));
    this.deviceSettings
      .get("fallingMitigatorEnabled")
      .setValue(this.config.walabotConfig.fallingMitigatorEnabled);
    this.deviceSettings
      .get("enableDoorEvents")
      .setValue(this.config.appConfig.enableDoorEvents);
    this.deviceSettings
      .get("enableOutOfBed")
      .setValue(this.config.appConfig.enableOutOfBed);
    const dryContacts = this.config.appConfig.dryContacts;
    this.deviceSettings
      .get("dryContactPrimaryPolicy")
      .setValue(dryContacts?.primary?.policy ?? DRY_CONTACT_DEFAULT.policy);
    this.deviceSettings
      .get("dryContactPrimaryMode")
      .setValue(dryContacts?.primary?.mode ?? DRY_CONTACT_DEFAULT.mode);
    this.deviceSettings
      .get("dryContactSecondaryPolicy")
      .setValue(dryContacts?.secondary?.policy ?? DRY_CONTACT_DEFAULT.policy);
    this.deviceSettings
      .get("dryContactSecondaryMode")
      .setValue(dryContacts?.secondary?.mode ?? DRY_CONTACT_DEFAULT.mode);

    this.deviceSettings
      .get("silentMode")
      .setValue(this.config.appConfig.silentMode);
    this.deviceSettings.get("roomName").setValue(this.room.name);
  }

  getDeviceIcon(roomType: string | RoomType) {
    return getDeviceIcon(roomType);
  }

  getDeviceName(type: RoomType) {
    return RoomTypeMapping.get(parseInt(String(type), 10)).name;
  }

  getDurationErrorMessage(form: FormGroup, field: string) {
    return getDurationErrorMessage(form, field, MIN_DURATION_VALUE);
  }

  getRoomNameErrorMessage(form: FormGroup, field: string) {
    return getRoomNameErrorMessage(form, field);
  }

  validateRoomSizeAgainstSensorMount() {
    setTimeout(() => {
      this.validateRoomSize.emit();
    });
  }

  rebootDevice() {
    this.inRebootProgress = true;
    this.configService
      .rebootDevice(this._device.deviceId)
      .then(() => {
        this.inRebootProgress = false;
      })
      .catch(() => {
        this.inRebootProgress = false;
      });
  }
}
