/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DeviceEventType {
    Presence = 4,
    Fall = 5,
    PresenceMap = 6,
    BedExit = 7,
}
