<div style="display: flex; flex-direction: column; width: 100%; height: 100%; padding: 2%; box-sizing: border-box; padding-bottom: 50px">
  <mat-spinner *ngIf="!config" style="margin: auto"></mat-spinner>
  <form *ngIf="config" [formGroup]="dashboardSettingsForm">
    <div class="settings-row">
      <div>
        <p class="setting-title" i18n="@@metrics-title">Metrics</p>
        <mat-button-toggle-group
          #group="matButtonToggleGroup"
          style="display: flex; flex-direction: row"
          formControlName="measurementUnits"
          class="metrics-button-group"
        >
          <mat-button-toggle [value]="units[0]" i18n="@@meters" data-cy="meters-btn">
            Meters
          </mat-button-toggle>
          <mat-button-toggle [value]="units[1]" i18n="@@feet" data-cy="feet-btn">
            Feet
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div *ngIf="environment.applicationId === 'bed-exit-detector'">
        <div class="alert-title-box setting-title">
          <span i18n="@@alert-times">Alert times</span>
          <mat-icon color="primary" class="info-icon" [matTooltip]="alertTooltipText" matTooltipClass="tooltip" matTooltipPosition="right">info</mat-icon>
        </div>
        <div class="alerts-field-box">
          <div class="alerts">
            <mat-form-field style="width: 150px;">
              <input
                matInput
                type="time"
                placeholder="From"
                formControlName="alertsFrom"
                i18n-placeholder="@@from"
                data-cy="alert-from"
                (ngModelChange)="onLastAlertTimeInputChanged('alertsFrom')"
              />
              <mat-error *ngIf="dashboardSettingsForm.hasError('alertsFromInvalid', 'alertsFrom')" i18n="@@alert-times-should-be-less-than-to">
                Should be less than to
              </mat-error>
              <mat-error *ngIf="dashboardSettingsForm.hasError('alertsFromRequired', 'alertsFrom')" i18n="@@alert-times-cannot-be-empty">
                Cannot be empty
              </mat-error>
            </mat-form-field>
            <mat-form-field style="width: 150px;">
              <input
                matInput
                type="time"
                placeholder="To"
                formControlName="alertsTo"
                i18n-placeholder="@@to"
                data-cy="alert-to"
                (ngModelChange)="onLastAlertTimeInputChanged('alertsTo')"
              />
              <mat-error *ngIf="dashboardSettingsForm.hasError('alertsToInvalid', 'alertsTo')" i18n="@@alert-times-should-be-more-than-from">
                Should be more than from
              </mat-error>
              <mat-error *ngIf="dashboardSettingsForm.hasError('alertsToRequired', 'alertsTo')" i18n="@@alert-times-cannot-be-empty">
                Cannot be empty
              </mat-error>
            </mat-form-field>
            <mat-form-field class="timeZone">
              <mat-label i18n="@@timezone">Timezone</mat-label>
              <mat-select formControlName="timeZone">
                <mat-option *ngFor="let timeZone of timeZones" [value]="timeZone.value">
                  {{ timeZone.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <button
              mat-button
              class="clear-alert-btn"
              [disabled]="(!config.presenceAlertsEnabledFrom && !config.presenceAlertsEnabledTo) || inProgress"
              (click)="clearAlert()"
              i18n="@@clear-alert"
            >
              Clear alert
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="buttons">
      <button
        mat-button
        class="reset-btn"
        [disabled]="!dashboardSettingsForm.dirty || inProgress"
        (click)="reset()"
        i18n="@@reset"
      >
        Reset
      </button>
      <button
        mat-button
        class="save-btn"
        [disabled]="!dashboardSettingsForm.dirty || dashboardSettingsForm.invalid || inProgress"
        (click)="save()"
      >
        <div class="spinner-wrapper">
          <mat-spinner diameter="15" *ngIf="inProgress"> </mat-spinner>
        </div>
        <span i18n="@@save">Save</span>
      </button>
    </div>
  </form>
  <app-select-language showLabel="true" style="margin-left: -2%;"></app-select-language>
  <span class="cdk-visually-hidden" #translatedTooltipMessage i18n="@@detailed-fall-history-tooltip">
    When enabled, the history page will display a more detailed history of fall events, including events that did not produce an alert.
  </span>
  <mat-slide-toggle [(ngModel)]="detailedFallHistory" (change)="onDetailedFallHistoryChanged()" class="detailed-fall-history" labelPosition="before">
    <span i18n="@@detailed-fall-history" style="font-size: 14px">Detailed Fall History</span>
    <mat-icon color="primary" class="info-icon" [matTooltip]="translatedTooltipMessage.innerHTML" matTooltipClass="tooltip" matTooltipPosition="right">
      info
    </mat-icon>
  </mat-slide-toggle>
  <br>
</div>
