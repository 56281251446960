import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
@Injectable({
  providedIn: "root",
})
export class UsersService {
  constructor(private authService: AuthService) {}

  public async createUser(name: string, email: string, password: string) {
    try {
      await this.authService.register(name, email, password);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}
