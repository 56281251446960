/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CareGiverType } from './CareGiverType';

export type CareGiver = {
    type?: CareGiverType;
    name?: string;
    email: string;
    uid: string;
    phoneNumber?: string;
    /**
     * @deprecated
     */
    priority?: number;
    voipClientId?: string;
    careRecipient?: {
        name: string;
        yearOfBirth: number;
        relationship: CareGiver.relationship;
        hasFallHistory: boolean;
    };
    voiceEnabled?: boolean;
    tenantId?: string;
};

export namespace CareGiver {

    export enum relationship {
        Son = 'Son',
        Daughter = 'Daughter',
        Brother = 'Brother',
        Sister = 'Sister',
        Friend = 'Friend',
        Spouse = 'Spouse',
        Granddaughter = 'Granddaughter',
        Grandson = 'Grandson',
        Other = 'Other',
    }


}

