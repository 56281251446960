import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "secondsToTimeCounter",
})
export class SecondsToTimeCounterPipe implements PipeTransform {
  transform(value: any): any {
    const hours = Math.floor(value / 3600);
    value %= 3600;
    const minutes = Math.floor(value / 60);
    const seconds = value % 60;
    return `${hours}:${minutes}:${seconds}`;
  }
}
