/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DeviceCommandGen2Reboot = {
    /**
     * Reboot
     */
    type: DeviceCommandGen2Reboot.type;
};

export namespace DeviceCommandGen2Reboot {

    /**
     * Reboot
     */
    export enum type {
        Reboot = 'Reboot',
    }


}

