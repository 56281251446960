/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Initializing = {
    type: Initializing.type;
};

export namespace Initializing {

    export enum type {
        Initializing = 'Initializing',
    }


}

