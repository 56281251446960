/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SupportTicket } from '../models/SupportTicket';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SupportAPI {

    /**
     * Create a new ticket
     * @param userId
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static postSupportTickets(
        userId: string,
        requestBody?: SupportTicket,
    ): CancelablePromise<'OK'> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/support/tickets/{userId}',
            path: {
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
            },
        });
    }

}
