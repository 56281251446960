import { Injectable, Injector } from "@angular/core";
import { BehaviorSubject, Observable, Subscription, skip } from "rxjs";
import { AuthService, DeviceMonitoringService } from "./ui.module";
import {
  RoomsAPI,
  Room,
  DeviceRoomConfig,
  RoomDetailsResponse,
  DashboardConfig,
  RoomType,
  RoomDetails,
  CaregiverAPI,
} from "@walabot-mqtt-dashboard/api";
import { SuitesService } from "./suites.service";

export type DeviceRoomConfigMapping = { [deviceId: string]: DeviceRoomConfig };

@Injectable({
  providedIn: "root",
})
export class RoomsService {
  private _roomSubject: BehaviorSubject<Room[]> = new BehaviorSubject<
    RoomDetails[]
  >(null);
  private _deviceRoomMapping: BehaviorSubject<DeviceRoomConfigMapping> =
    new BehaviorSubject({});
  private roomListSub: Subscription;

  get roomList(): Observable<Room[]> {
    return this._roomSubject.asObservable();
  }

  get deviceMapping(): Observable<DeviceRoomConfigMapping> {
    return this._deviceRoomMapping.asObservable();
  }

  constructor(private auth: AuthService, private injector: Injector) {}

  public async init() {
    const rooms = await RoomsAPI.getRoomsDevices();
    this._roomSubject.next(rooms.rooms);
    this._deviceRoomMapping.next(this.responseToDeviceMapping(rooms));
    if (this.roomListSub) {
      this.roomListSub.unsubscribe();
    }
    this.onWatchRoomsChnages();
  }

  private onWatchRoomsChnages() {
    this.roomListSub = this.roomList.pipe(skip(1)).subscribe((rooms) => {
      (async () => {
        if (rooms) {
          if (rooms.length > 0) {
            await this.loadDeviceRoomMapping();
          } else {
            this._deviceRoomMapping.next({});
          }
        }
      })().catch((err) => {
        console.log(err);
      });
    });
  }

  public addRoom(name: string, type: RoomType) {
    console.log("Adding room ", name);
    return RoomsAPI.createRoom({ name, type }).then((room) => {
      this._roomSubject.value.push(room);
      this._roomSubject.next(this._roomSubject.value);
      return room;
    });
  }

  public removeRoom(id: string) {
    return RoomsAPI.deleteRoom(id);
  }

  private responseToDeviceMapping(
    response: RoomDetailsResponse
  ): DeviceRoomConfigMapping {
    const result: DeviceRoomConfigMapping = {};
    response.rooms.forEach((room) => {
      room.devices.forEach(
        (device: DeviceRoomConfig) => (result[device.deviceId] = device)
      );
    });
    return result;
  }

  public async loadDeviceRoomMapping(): Promise<void> {
    try {
      const response = await RoomsAPI.getRoomsDevices();
      this._deviceRoomMapping.next(this.responseToDeviceMapping(response));
    } catch (e) {
      throw new Error("Unable to Retrieve Device Room Mapping");
    }
  }

  public async removeDeviceFromRoom(deviceId: string, roomId: string) {
    try {
      await RoomsAPI.deleteDeviceFromRoom(roomId, deviceId);
      return this.loadDeviceRoomMapping();
    } catch (e) {
      throw new Error(
        `Failed to remove device: ${deviceId} from room ${roomId}`
      );
    }
  }

  public async assignDeviceToRoom(deviceId: string, roomId: string) {
    const uid = this.auth.getUser().uid;
    const body: { uid: string; deviceId: string; roomId: string } = {
      uid,
      deviceId,
      roomId,
    };
    try {
      await RoomsAPI.assignDeviceToRoom(roomId, deviceId, body);
      return this.loadDeviceRoomMapping();
    } catch (e) {
      throw new Error(`Failed to assign device: ${deviceId} to room ${roomId}`);
    }
  }

  public async changeDeviceTypeAndDeviceRoomType(
    deviceId: string,
    roomId: string,
    roomType: RoomType,
    roomName: string
  ) {
    const uid = this.auth.getUser().uid;
    const linkDeviceBody: {
      roomType: RoomType;
    } = { roomType };
    const updateDeviceConfigBody: {
      uid: string;
      type: RoomType;
      deviceId: string;
      roomId: string;
    } = { uid, deviceId, roomId, type: roomType };
    try {
      await CaregiverAPI.linkDevice(uid, deviceId, linkDeviceBody);
      await RoomsAPI.updateDeviceConfig(
        roomId,
        deviceId,
        updateDeviceConfigBody
      );
      return this.updateRoom(roomId, roomName, roomType).then((room) => {
        this._deviceRoomMapping.value[deviceId].type = roomType;
        const monitoringService = this.injector.get(DeviceMonitoringService);
        const suiteId = Object.values(monitoringService.roomMapping)
          .flat()
          .find((room) => {
            return room.id === roomId;
          })?.suiteId;
        monitoringService.suiteStatusMap[suiteId].rooms[roomId].devices[
          deviceId
        ].roomType = roomType;
        return room;
      });
    } catch (e) {
      throw new Error("Unable to change the room type");
    }
  }

  public async updateRoom(id: string, name: string, type: RoomType) {
    const body: Room = { id, name, type };
    try {
      return await RoomsAPI.updateRoom(id, body).then((room) => {
        const roomToUpdate = this._roomSubject.value.find(
          (room) => room.id === id
        );
        roomToUpdate.name = name;
        roomToUpdate.type = type;
        const suitesService = this.injector.get(SuitesService),
          monitoringService = this.injector.get(DeviceMonitoringService);
        suitesService.updateRoomInfoInMapping(roomToUpdate);
        monitoringService.updateRoomInfoInMapping(roomToUpdate);
        return room;
      });
    } catch (err) {
      console.warn(err);
    }
  }

  public clear() {
    this._deviceRoomMapping.next({});
    this._roomSubject.next([]);
  }
}

export {
  RoomDetailsResponse,
  Room,
  RoomType,
  DashboardConfig,
  DeviceRoomConfig,
};
