/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * VayyarCare LED control: If set to 0, LED will light only for error conditions
 */
export enum LedMode {
    AllOff = 0,
    AllOn = 1,
}
